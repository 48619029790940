import styled from 'styled-components';

export default styled.div`
  font-size: var(--base-font-14-12);
  font-weight: 400;
  color: var(--color_body_text);
  line-height: 22px;
  color: #333333;
  .phone {
    margin-left: 8px;
  }
  .name-info-wrapper {
    margin-bottom: 8px;
    font-weight: 500;
    color: var(--color_body_text);
    font-size: var(--base-font-16-14);
  }
  .other-info-wrapper {
    font-weight: 400;
    color: rgba(var(--color_body_text_rgb), 0.6);
  }
`;
