import React from 'react';
import cn from 'classnames';
import Style from './style';

const HasFission = ({ intl, commissionValues }) => {
  const [firstRatio = 0, secondRatio = 0] = commissionValues?.map((item) => item / 10) || [];
  return (
    <Style>
      <div className='fission-image-wrapper'>
        <img
          src='https://img01.yzcdn.cn/upload_files/2021/09/29/FmonQxYbINOw-M5yQYVyrYY2mCBr.png'
          alt='activity flow'
          className='fission-image'
        />
        <span className='you'>{intl.formatMessage({ id: 'you', defaultMessage: '你' })}</span>
        <span className='buddy_1'>{intl.formatMessage({ id: 'buddy_1', defaultMessage: '好友1' })}</span>
        <span className='buddy_2'>{intl.formatMessage({ id: 'buddy_2', defaultMessage: '好友2' })}</span>
        <span className={cn('circle-block', 'first-percent', { oval: firstRatio > 9 })}>{firstRatio + '%'}</span>
        <span className={cn('circle-block', 'sub-first-percent', { oval: firstRatio > 9 })}>{firstRatio + '%'}</span>
        <span className={cn('circle-block', 'sub-second-percent', { oval: firstRatio > 9 })}>{secondRatio + '%'}</span>
      </div>
    </Style>
  );
};

export default HasFission;
