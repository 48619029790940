import React from 'react';
import AddressBox from 'cpn/address-box';
import cn from 'classnames';
import Style from './style';
import isEmpty from 'lodash/isEmpty';

const AddressItem = (props) => {
  const {
    address,
    onClick = () => {},
    icon,
    isCurrent = false,
    intl,
    iconCallBack,
    setEditAddressVisible,
    isNotEdit = false,
    extendTip = '',
  } = props;
  const { userInfo = {} } = window?.global_data || {};
  const isLogin = userInfo && !isEmpty(userInfo);
  const onAddressSelect = () => {
    if (iconCallBack && isLogin) {
      iconCallBack();
    }
    if (!isLogin && setEditAddressVisible) {
      setEditAddressVisible(true);
    }
  };
  return (
    <Style className={cn(`address-cannot-edit-${isNotEdit}`)}>
      {isCurrent && <div className='address-img-wrapper'></div>}

      <section onClick={(e) => !isNotEdit && onClick(e)} className={cn('current-address-info', { 'click-able': icon })}>
        <div className='info-wrapper'>
          {isCurrent && (
            <div className='address-svg-wrapper'>
              <svg width={20} height={20}>
                <use xlinkHref='#icondizhi' />
              </svg>
            </div>
          )}

          <div>
            {/* {address?.isDefault ? (
                            <header className='default-address-title'>
                                {intl.formatMessage({
                                    id: 'account.address.default_address',
                                    defaultMessage: '默认地址',
                                    description: '默认地址',
                                })}
                            </header>
                        ) : null} */}
            <div className='current-address-detail'>
              <AddressBox {...address} />
            </div>
            {!!address?.isDefault && <span className='default-address-tag'>默认</span>}
            {extendTip}
          </div>
        </div>
        {!isNotEdit && (
          <p className='select-address-btn' onClick={onAddressSelect}>
            {icon ? (
              <>
                {isCurrent && (
                  <span className='change-text'>
                    {intl.formatMessage({
                      id: isLogin ? '6ef55012ec6047468d4f914f3f668bf0' : 'account.address.edit_address',
                      defaultMessage: '更换地址',
                      description: '更换地址',
                    })}
                  </span>
                )}
                <svg className='icon icon-select-address' width='16' height='16' aria-hidden='true'>
                  <use xlinkHref={icon} />
                </svg>
              </>
            ) : null}
          </p>
        )}
      </section>
    </Style>
  );
};

export default AddressItem;
