import styled, { css } from 'styled-components';

export default styled.div`
  width: 100%;
  .category-item-slider {
    width: 100%;
    .category-item-slider-values {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
    }
    .zent-slider-track {
      background-color: var(--color_button) !important;
    }
    .zent-slider-tooltip {
      .zent-slider-point {
        border-color: var(--color_button) !important;
      }
      &:after {
        display: none !important;
      }
      .zent-slider-tooltip-content {
        display: none !important;
      }
    }
  }
`;
