import Style from './style';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import React from 'react';

const PageTitle = (props) => {
  const { list, history } = props;
  const onClickItem = (item) => {
    history.push(item.href);
  };
  return (
    <Style>
      {list.map((item, index) => {
        return (
          <>
            {index > 0 && <span className='link-title-item title-item-gap'>/</span>}
            <span
              onClick={() => {
                onClickItem(item);
              }}
              className={cn('link-title-item', { current: index === list?.length - 1 })}
            >
              {item?.text}
            </span>
          </>
        );
      })}
    </Style>
  );
};

export default withRouter(PageTitle);
