import styled from 'styled-components';

export default styled.span`
  .no-shipping-wrapper {
    background: var(--color_card, #fff);
  }
  .deliver-wrapper {
    background: var(--color_card, #fff);
    padding: var(--pm-24-16) var(--pm-24-16) 0;
    margin-top: 16px;
    border-radius: var(--border_radius_card);
  }
  .container {
    padding: var(--pm-24-16) var(--pm-24-16) 0;
    background: var(--color_card, #fff);
    border-radius: var(--border_radius_card);
  }
  .mt-40 {
    margin-top: 40px;
  }
  .deliver-label {
    font-weight: 600;
    font-size: 20px;
    color: var(--color_body_text);
  }

  .deliver-content {
    padding: 24px 0;
  }

  .unable-send {
    padding: 10px var(--pm-16-10);
    background: rgba(152, 36, 36, 0.06);
    font-size: var(--base-font-14-12);
    color: #982424;
    line-height: 24px;
    margin-bottom: var(--pm-16-10);
  }

  .has-no-way {
    text-align: center;
    padding: var(--pm-24-18);
    color: rgba(var(--color_body_text_rgb), 0.6);
    font-size: var(--base-font-14-12);
    margin-top: var(--pm-8-0);
  }

  .jinggao-icon {
    width: 24px;
    height: 24px;
    fill: #982424;
    margin-right: 10px;
    vertical-align: middle;
  }

  .icon {
    display: inline-block;
    width: 120px;
    height: 120px;
    fill: #9b9ea4;
    margin-bottom: 14px;
  }
`;
