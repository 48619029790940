import styled from 'styled-components';

export default styled.div`
  background: #ffffff;
  .page-info {
    padding-top: var(--pm-24-16);
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1000px) {
    .zent-pagination.zent-pagination--lite .zent-pagination-page-button--no-border {
      margin-left: 0px !important;
      font-size: var(--base-font-14-12) !important;
    }
  }
  @media screen and (max-width: 750px) {
    .page-info {
      padding-bottom: 16px;
    }
  }
`;
