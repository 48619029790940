import React, { useMemo } from 'react';
import Image from 'components/base/image';
import useGoodsImage from 'hook/use-goods-image';
import DefaultImg from 'cpn/default-img';
import useFillImage from 'hook/use-fill-image';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Style from './style';

/**
 * 展示商品图片
 */
function GoodsImage({
  goodsInfo,
  coverImgUrl: fCoverImgUrl = '',
  hoverImgUrl: fHoverImgUrl = '',
  imageRatio = '',
  isCut = true.valueOf,
  hoverShowSecondPic = false,
  className = '',
}) {
  const imageInfo = useGoodsImage(goodsInfo); // 若外界没传递则从商品里获取
  const coverImgUrl = fCoverImgUrl || imageInfo?.coverImgUrl;
  const hoverImgUrl = fHoverImgUrl || imageInfo?.hoverImgUrl;

  const { title, handle } = goodsInfo || {};
  const formateHoverImage = useFillImage(hoverImgUrl)?.image;
  const isSoldOut = !goodsInfo?.quantity;
  const heightPercent = useMemo(() => {
    if (imageRatio) {
      // 兼容商品分组3.0旧数据
      if (imageRatio.includes('%')) {
        return imageRatio;
      }
      if (imageRatio !== 'auto') {
        const arr = imageRatio.split('-').map(Number);
        return `${(arr[1] / arr[0]) * 100}%`;
      }
    }
    return '100%';
  }, [imageRatio]);

  return (
    <Style
      className={cn(
        { hover: formateHoverImage && hoverShowSecondPic },
        { 'customer-ratio': imageRatio !== 'auto' },
        className,
      )}
      heightPercent={heightPercent}
      isCut={isCut}
    >
      {coverImgUrl ? (
        <Image className='goods-img' src={coverImgUrl} alt={title || handle} />
      ) : (
        <DefaultImg square width='41%' height='41%' paddingTopPer={heightPercent} />
      )}
      {formateHoverImage && <div className='hover-img' style={{ backgroundImage: `url(${formateHoverImage})` }} />}
      {isSoldOut && (
        <div className='sold-out'>
          <FormattedMessage id='goods.sold_out' />
        </div>
      )}
    </Style>
  );
}

export default GoodsImage;
