import { isEmpty } from "lodash";
import { formateTimeByTimeZone } from "../util/datetime";

export const getDeliveryFullTime = (localDelivery) => {
  if (isEmpty(localDelivery)) return null;
  const { deliveryDate, deliveryStartTime, deliveryEndTime, deliveryFullTime } = localDelivery;
  const full = `${formateTimeByTimeZone(deliveryDate, 'YYYY-MM-DD')} ${deliveryStartTime}-${deliveryEndTime}`;
  return deliveryDate && deliveryStartTime && deliveryEndTime ? full : deliveryFullTime;
}

export const getPickupFullTime = (pickupOrder) => {
  const { pickupDate, pickupStartTime, pickupEndTime, pickupFullTime } = pickupOrder;
  const full = `${formateTimeByTimeZone(pickupDate, 'YYYY-MM-DD')} ${pickupStartTime}-${pickupEndTime}`;

  return pickupDate && pickupStartTime && pickupEndTime ? full : pickupFullTime;
};