import styled from 'styled-components';

export default styled.div`
  .distribution-pc-header {
    display: flex;
    padding: 0px 80px 0px 80px;
    align-items: center;
    height: 60px;
    align-items: center;
    background: #fff;
    /* width: 100%; */
    justify-content: space-between;
  }

  .distribution-header-logo {
    cursor: pointer;
  }

  .distribution-nav {
    display: flex;
    align-items: center;
    .default-logo {
      margin-right: 80px;
      font-size: 24px;
      color: #476cf0;
      line-height: 24px;
      letter-spacing: 3px;
    }
    .logo-name {
      display: inline-block;
      padding-right: 4px;
      margin-right: 4px;
      border-right: 1px solid #476cf0;
    }

    .logo {
      max-height: 40px;
      max-width: 160px;
      display: block;
      margin-right: 80px;
    }
  }

  .nav-item {
    a {
      margin-right: 40px;
      font-size: 14px;
      font-weight: 300;
      /* color: #1a1a1b; */
      color: var(--color_text) !important;
      line-height: 36px;
      display: inline-block;
      cursor: pointer;
      &.is-active {
        font-size: 14px;
        font-weight: 500;
        color: var(--color_text) !important;
        position: relative;
        &::after {
          content: '';
          display: block;
          height: 4px;
          background: var(--color_button);
          border-radius: 2px;
          position: absolute;
          width: 100%;
          bottom: -12px;
        }
      }
    }
  }

  .distribution-setting {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .distribution-question {
    margin-right: 30px;
  }

  /* .distribution-setting-question-icon {
        width: 24px;
        height: 24px;
        fill: #476cf0;
    }

    .distribution-setting-header-icon {
        height: 24px;
        width: 24px;
        fill: #476cf0;
    } */

  .me-item {
    line-height: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #15161b;
    line-height: 20px;
  }

  .back-header {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .distribution-pc-header {
      display: none;
    }

    /* .page-bg {
            position: relative;
            text-align: center;
            z-index: 2;
        }
        .page-bg:after {
            width: 140%;
            height: 210px;
            position: absolute;
            left: -20%;
            top: 0;
            z-index: -1;
            content: '';
            border-radius: 0 0 50% 50%;
            background: ${(props) => props.bgColor || '#fff'};
        } */
    .back-header {
      display: block;
    }
  }
`;
