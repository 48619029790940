/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Style from './style';
import { useEffect } from 'react';
import { Button, Dialog } from 'zent';

const { openDialog, closeDialog } = Dialog;
const CLOVER_DIALOG_ID = 'CLOVER_DIALOG_ID';

export const PayClover = (props) => {
  const { cloverKey = '158bc518aea222b7eae0463d0fddcb57', intl, onSubmit = () => {} } = props;
  useEffect(() => {
    // 移除clover页脚
    const timer = setTimeout(() => {
      const cloverFooter = document.getElementsByClassName('clover-footer')?.[0];
      cloverFooter?.remove?.();
    }, 2000);
    const Clover = window?.Clover;
    if (!Clover) {
      return;
    }

    const clover = new Clover(cloverKey);
    const elements = clover.elements();

    const elementStyles = {
      input: {
        color: '#32325d',
        'background-color': '#ffffff',
        height: 28,
        fontWeight: 500,
        fontSize: '14px',
      },
      'input::placeholder': {
        color: '#cfd7df',
      },
      'input:-webkit-autofill': {
        color: '#cfd7df',
      },
      '.brand': {
        height: 20,
        top: 2,
      },
    };
    const form = document.getElementById('payment-form');
    const cardNumber = elements.create('CARD_NUMBER', elementStyles);
    const cardDate = elements.create('CARD_DATE', elementStyles);
    const cardCvv = elements.create('CARD_CVV', elementStyles);
    const cardPostalCode = elements.create('CARD_POSTAL_CODE', elementStyles);
    cardNumber.mount('#card-number');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');

    // 校验错误信息
    const displayCloverError = document.getElementById('clover-error');

    const showError = (result) => {
      let errorText = '';
      try {
        for (const key in result) {
          if (result?.[key]?.error) {
            errorText = result?.[key]?.error;
            throw new Error('break');
          }
        }
        displayCloverError.textContent = errorText;
      } catch (err) {
        if (err?.message === 'break') {
          displayCloverError.textContent = errorText;
        }
      }
    };
    // 输入监听
    cardNumber.addEventListener('change', function (event) {
      showError(event);
    });

    cardDate.addEventListener('change', function (event) {
      showError(event);
    });

    cardCvv.addEventListener('change', function (event) {
      showError(event);
    });

    cardPostalCode.addEventListener('change', function (event) {
      showError(event);
    });

    form.addEventListener('submit', function (event) {
      event.preventDefault();
      clover.createToken().then(function (result) {
        if (result.errors) {
          displayCloverError.textContent = Object.values(result.errors)?.[0];
        } else {
          const paymentMethodOptions = { type: 'card', card: { token: result?.token } };
          onSubmit({ paymentMethodOptions });
          closeDialog(CLOVER_DIALOG_ID);
        }
      });
    });

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <Style>
      <div className='cell payClover' id='example-2'>
        <form action='/charge' method='post' id='payment-form'>
          <div className='row'>
            <div className='field'>
              <span className='label'>Card number</span>
              <div id='card-number' />
            </div>
          </div>
          <div className='row'>
            <div className='field half-width'>
              <span className='label'>Card date</span>
              <div id='card-date' />
            </div>
            <div className='field half-width'>
              <span className='label'>Card cvv</span>
              <div id='card-cvv' />
            </div>
            <div className='field half-width'>
              <span className='label'>Card postal code</span>
              <div id='card-postal-code' />
            </div>
          </div>
          <div id='clover-error' className='error' role='alert' />
          <div className='submit-wrapper'>
            <button type='submit' data-tid='elements_examples.form.pay_button'>
              {intl.formatMessage({ defaultMessage: '确认支付', id: 'order.pay.pay_confirm' })}
            </button>
          </div>
        </form>
      </div>
    </Style>
  );
};

export const openPayCloverDialog = ({ cloverKey, intl, params, onSubmit }) => {
  openDialog({
    dialogId: CLOVER_DIALOG_ID,
    title: intl.formatMessage({ id: 'order.pay.credit_card', defaultMessage: '信用卡支付' }),
    children: <PayClover cloverKey={cloverKey} intl={intl} payParmas={params} onSubmit={onSubmit} />,
    style: { width: '640px' },
    maskClosable: false,
  });
};
