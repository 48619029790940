import React from 'react';
import { useIntl } from 'react-intl';
import Style from './style';

const DistributionLogo = (props) => {
  const { shopName, logo } = props;
  const intl = useIntl();
  const distributionText = intl.formatMessage({
    id: 'distribution_alliance',
    defaultMessage: '分销联盟',
  });

  return (
    <Style>
      <div className='distribution-logo'>
        <p>
          {!logo && (
            <>
              <span>{shopName || 'BASE'}</span>
              {distributionText}
            </>
          )}
          {logo && <img src={logo} alt={distributionText} />}
        </p>
      </div>
    </Style>
  );
};

export default DistributionLogo;
