export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
export const LAYOUT_HORIZONTAL = 'horizontal';
export const LAYOUT_VERTICAL = 'vertical';

// 过滤器类型
export const FILTER_ITEM_TYPE = {
  COLLECTION_IDS: 'collection_ids', // 商品集合：collection_ids
  VENDOR: 'vendor', // 供应商： vendor
  MIN_PRICE: 'min_price', // 商品价格：min_price
  GOODS_TYPE: 'goods_type', // 商品类型：goods_type
  GOODS_TAGS: 'goods_tags', // 商品标签：goods_tags
  GOODS_SKU: 'goods_sku', // 商品sku：goods_sku
};

export const FILTER_ITEM_STYLE = {
  RADIO: 'radio', // 单选框
  CHECKBOX: 'checkbox', // 单选框
  SLIDER: 'slider', // 滑块
};
