import { intlLiToYuan } from 'utils/money';
import { useMemo } from 'react';
import useCountrySelect from 'hook/use-country-select';
import { DiscountType, DiscountLimitType } from 'const/discount';
import { intlMessage as $t } from 'utils/intl/formate';

// 根据返回数据，逻辑返回优惠券展示的文案，如：title，condition，userange
// 本来这里的方法不需要用hook，但因为要使用useIntl，所以。。。
export const useCoupons = ({ intl, currency, data = {}, multiCurrencyInfo, noCouponsModal = false }) => {
  //获取优惠券title信息文案
  const language = !__isServer ? window?.global_data?.shopSetting?.language : 'en';
  const { getCountryAndProvinceName, countryList } = useCountrySelect({ locale: language });

  // 梯度折扣标题
  const getPromotionTitle = (promotionRules = []) => {
    const list = promotionRules.sort((p, n) =>
      p?.discountGets?.discountValue > n?.discountGets?.discountValue ? 1 : -1,
    );
    const firstItem = list[0];
    const lastItem = list[list.length - 1];
    if (firstItem?.discountGets?.type === 'amount') {
      const firstValue = intlLiToYuan({ currency, value: firstItem?.discountGets?.discountValue, multiCurrencyInfo });
      const lastValue = intlLiToYuan({ currency, value: lastItem?.discountGets?.discountValue, multiCurrencyInfo });
      return `${firstValue} ～ ${lastValue}`;
    }
    if (firstItem?.discountGets?.type === 'percentage') {
      return `${firstItem?.discountGets?.discountValue}% ～ ${lastItem?.discountGets?.discountValue}% OFF`;
    }
  };

  // 梯度折扣优惠文案
  const getPromotionText = (params) => {
    const { minimumRequirement = {}, promotionRules = [] } = params;
    const text: string[] = [];
    if (promotionRules.length > 1) {
      promotionRules.map((item) => {
        const discountGets = item?.discountGets || {};
        const minimumRequirement = item?.minimumRequirement || {};
        if (minimumRequirement.type === 'amount') {
          const requireValue = intlLiToYuan({ currency, value: minimumRequirement?.requireValue, multiCurrencyInfo });
          if (discountGets.type === 'amount') {
            const discountValue = intlLiToYuan({ currency, value: discountGets?.discountValue, multiCurrencyInfo });
            text.push(
              intl.formatMessage(
                { id: 'c74f5cf9c8c34671a3e83b8774331fdd', defaultMessage: '满 {condition} 减 {result}' },
                { condition: requireValue, result: discountValue },
              ),
            );
          }
          if (discountGets.type === 'percentage') {
            text.push(
              intl.formatMessage(
                { id: '5a2740986a094b2eacd6b6b011329b6c', defaultMessage: '满 {condition} - {result}% 折扣' },
                { condition: requireValue, result: discountGets?.discountValue },
              ),
            );
          }
        }
        if (minimumRequirement.type === 'quantity') {
          if (discountGets.type === 'amount') {
            const discountValue = intlLiToYuan({ currency, value: discountGets?.discountValue, multiCurrencyInfo });
            text.push(
              intl.formatMessage(
                { id: '026c8f49eaab4108a163083e7a1ab763', defaultMessage: '满 {condition} 件减 {result}' },
                { condition: minimumRequirement?.requireValue, result: discountValue },
              ),
            );
          }
          if (discountGets.type === 'percentage') {
            text.push(
              intl.formatMessage(
                { id: '93307aa4f2f942af841c3f9216c99a90', defaultMessage: '满 {condition} 件 {result}% 折扣' },
                { condition: minimumRequirement?.requireValue, result: discountGets?.discountValue },
              ),
            );
          }
        }
      });
    } else {
      text.push(getUseCondition(minimumRequirement));
    }
    return text.join('，');
  };

  const getCouponsName = ({ discountType = '', discountGets, promotionRules }) => {
    if (promotionRules.length > 1) {
      return getPromotionTitle(promotionRules);
    }
    const { discountValue } = discountGets || {};
    // discountQuantity
    // 如果订单有退款存在，优先取退款状态，否则取发货状态
    let name = '';
    switch (discountType) {
      case DiscountType.Percentage:
        name = `${discountValue}% OFF`;
        break;
      case DiscountType.FixedAmount:
        const val = intlLiToYuan({
          currency,
          value: discountValue,
          showZero: true,
          multiCurrencyInfo,
        });
        name = $t(intl, 'cd24e96f4f8f48e7b3fe8268879fa385', { val: val }, `立减${val}`);
        break;
      case DiscountType.FreeShipping:
        name = intl.formatMessage({
          id: 'code_free_shipping',
          defaultMessage: '免运费',
        });
        break;
    }
    return name;
  };
  //获取优惠券的使用要求文案
  const getUseCondition = ({ type, requireValue }) => {
    // 如果订单有退款存在，优先取退款状态，否则取发货状态
    let name = '';
    switch (type) {
      case DiscountLimitType.Amount:
        name = intl.formatMessage(
          {
            id: '51f9d0305d454bbdb8d91ff383fb2816',
            defaultMessage: `满${requireValue}可用`,
          },
          {
            requireValue: ` ${intlLiToYuan({
              currency,
              value: requireValue,
              showZero: true,
            })} `,
          },
        );
        break;
      case DiscountLimitType.Quantity:
        name = intl.formatMessage(
          {
            id: '2f6000de109c426798d11e045cb108d0',
            defaultMessage: `满${requireValue}件商品可用`,
          },
          {
            requireValue: ` ${requireValue} `,
          },
        );
        break;
      default:
        name = intl.formatMessage({
          id: 'ff78faa97dbb4c11b9964ad334759257',
          defaultMessage: `无门槛使用`,
        });
        break;
    }
    return name;
  };
  // 获取优惠券的使用范围文案
  const getUseRange = ({
    type,
    goodsRange,
    freeShippingRange,
    freeShippingCountries = [],
    excludeSpecifiedShippingAmount,
  }) => {
    // 如果订单有退款存在，优先取退款状态，否则取发货状态
    let name = '';
    switch (type) {
      case DiscountType.Percentage:
      case DiscountType.FixedAmount:
        name =
          !goodsRange || goodsRange === 'all'
            ? intl.formatMessage({
                id: 'ac5e065c6d184ed5acc28e1d9f3a83ce',
                defaultMessage: `全场商品可用`,
              })
            : intl.formatMessage({
                id: '10d6268676244c65b3a12ef6e90442f7',
                defaultMessage: `适用于部分商品`,
              });
        break;
      case DiscountType.FreeShipping:
        if (freeShippingRange === 'all') {
          name = intl.formatMessage({
            id: 'd9f969278fe54422852cf41330514870',
            defaultMessage: '适用于所有国家和地区',
          });
        } else {
          name = intl.formatMessage({
            id: 'b7f8eaa7e36e47e1a99953b767c563d8',
            defaultMessage: '适用于',
          });
          freeShippingCountries.map((countryCode) => {
            const { country } = getCountryAndProvinceName({ countryCode });
            name += country + '、';
          });
          name =
            name.substring(0, name.length - 1) +
            intl.formatMessage({
              id: '02f5d89390924152b23a0f19e2f1d84c',
              defaultMessage: '国家/地区',
            });
        }
        if (excludeSpecifiedShippingAmount) {
          name +=
            ',' +
            intl.formatMessage(
              {
                id: '7efdc3f8968e4114a5105c43909ff110',
                defaultMessage: `适用于${excludeSpecifiedShippingAmount} 以下的运费`,
              },
              {
                excludeSpecifiedShippingAmount: intlLiToYuan({
                  currency,
                  value: excludeSpecifiedShippingAmount,
                  showZero: true,
                }),
              },
            );
        }
        break;
    }
    return name;
  };
  const getUsePage = ({ discountType, goodsRange, activityId }) => {
    let page = '';
    switch (discountType) {
      case DiscountType.Percentage:
      case DiscountType.FixedAmount:
        page =
          !goodsRange || goodsRange === 'all'
            ? (page = `/collections/all?activityId=${activityId}&noCouponsModal=${noCouponsModal}`)
            : (page = `/discount/activity?activityId=${activityId}&type=cardCode&noCouponsModal=${noCouponsModal}`);
        break;
      case DiscountType.FreeShipping:
        page = `/collections/all?activityId=${activityId}&noCouponsModal=${noCouponsModal}`;
        break;
    }
    return page;
  };
  const title = useMemo(() => {
    return getCouponsName({
      discountGets: data?.discountGets,
      discountType: data?.discountType,
      promotionRules: data?.promotionRules || [],
    });
  }, [data?.discountGets, data?.discountType]);

  const useCondition = useMemo(() => {
    return getPromotionText({
      minimumRequirement: data?.minimumRequirement,
      promotionRules: data.promotionRules,
    });
  }, [data?.minimumRequirement, data?.promotionRules]);

  const useRange = useMemo(() => {
    return getUseRange({ ...data, type: data?.discountType });
  }, [data, data?.discountType, countryList]);

  const usePage = useMemo(() => {
    return getUsePage({
      discountType: data?.discountType,
      goodsRange: data?.goodsRange,
      activityId: data?.activityId,
    });
  }, [data.discountType, data.goodsRange, data.activityId]);

  return {
    title,
    useCondition,
    useRange,
    usePage,
  };
};
