import { useState, useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as tools from '../../../util';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import useDistributorInfo from './use-distributor-info';

const useDeepCompare = (value) => {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }
  return ref.current;
};

const useDeepEffect = (callback, deps) => {
  useEffect(callback, useDeepCompare(deps));
};

const PAGE_SIZE = 20;

/**
 *
 * @param {q} url 数据请求地址
 * @param {*} params 初始化参数
 */
export default function useDistributorList(fetchCallback, params = {}, initFilter = {}) {
  const { pageSize = PAGE_SIZE } = params;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(initFilter);
  const [query, setQuery] = useState({
    current: 1,
    pageSize,
    ...params,
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const { distributorInfo, needLogin } = useDistributorInfo();
  const { affiliateId, kdtId } = distributorInfo;
  /**
   * 分页切换,更新页数
   * @param pagination
   */
  const onTableChange = (pagination) => {
    setQuery({
      ...query,
      current: pagination.current,
    });
  };

  /**
   * 更新查询条件,页数重置1
   * @param {筛选条件} params
   */
  const onQueryChange = (params) => {
    setQuery({
      ...query,
      ...params,
      current: 1,
    });
  };

  /**
   * 更新筛选条件，页数重置1
   * @param {筛选条件} params
   */
  const onFilterChange = (params) => {
    setFilter({
      ...filter,
      ...params,
    });
    setQuery({
      ...query,
      current: 1,
    });
  };
  /**
   * 切换tab时，覆盖筛选条件，页数重置1
   * @param {筛选条件} params
   */
  const onCoverFilter = (params) => {
    setFilter(params);
    setQuery({
      ...query,
      current: 1,
    });
  };

  const fetchData = async () => {
    const params = tools.filterEmptyObj({
      ...filter,
      ...query,
      ...pick(distributorInfo, ['affiliateId', 'kdtId']),
      page: query?.current, // 后端需要page
    });
    setLoading(true);
    delete params?.current;
    fetchCallback(params)
      .then((res) => {
        const { totalCount } = res.paginator || {};
        const { items = [] } = res || {};
        unstable_batchedUpdates(() => {
          setTotal(totalCount);
          setDataSource(items);
          setLoading(false);
        });
      })
      .catch(() => {});
  };

  useDeepEffect(() => {
    if (affiliateId && kdtId) {
      fetchData();
    }
  }, [filter, query, distributorInfo]);

  const refreshListData = () => {
    fetchData();
    setSelectedRowKeys([]);
  };

  return {
    pagination: {
      current: query?.current,
      total,
      pageSize,
    },
    loading,
    dataSource,
    filter,
    setFilter: onFilterChange,
    query,
    setQuery: onQueryChange,
    coverFilter: onCoverFilter,
    setPagination: onTableChange,
    refreshListData,
    setSelectedRowKeys,
    selectedRowKeys,
    needLogin,
  };
}
