import styled, { css } from 'styled-components';

export default styled.div`
  margin-top: var(--pm-24-16);
  display: flex;
  ${(props) => {
    if (props.lastStep) {
      return css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--pm-24-16);
        background-color: var(--color_card,#fff);
      `
    } else {
      return css`
        justify-content: flex-end;
      `;
    }
  }}
  .return-icon {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    /* fill: #1a1a1b; */
    font-weight: 300;
    fill: var(--color_button);
  }
  .back-text {
    cursor: pointer;
    font-style: inherit;
    font-weight: 300;
  }
  .action-btn-wrapper {
    color: var(--color_button);
    .btn {
      min-width: 97px;
    }
  }
  @media screen and (max-width: 750px) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 12px 16px;
    z-index: 1000;
    box-shadow: 0px -1px 12px rgba(26, 26, 27, 0.04);
    .btn {
      width: 100%;
    }
  }
`;
