/** 拼团状态  */
export enum ActivityStatusType {
  Waiting = 'waiting', // 待开始
  Scheduled = 'scheduled', // 进行中
  Expired = 'expired', // 已过期
}

/** 活动的状态，此团态为组合状态 */
export enum ActivityStatus {
  ActivityEnd = 'activity_end', // 活动结束了且团没成功
  GroupEnd = 'group_end', // 人不够，团单解散了
  GroupSuccess = 'group_success', // 拼团成功啦
  GroupJoined = 'group_joined', // 您已参团-当前已登录才会看到这个状态
  GroupWaiting = 'group_waiting', // 您可参团
}

/** 拼团状态  */
export enum GroupBuyingStatus {
  Init = 'init', // 还在初始化拼团状态
  Waiting = 'waiting', // 待成团
  Success = 'success', // 拼团成功
  Failure = 'failure', // 成团失败
}
