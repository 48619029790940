import Style from './style';
import { useEffect } from 'react';
import { Notify, Dialog } from 'zent';
import { getLocale } from '../../../../util/i18n';
import { buriedPoint } from '../../../../util/log-buried-point';

const { openDialog } = Dialog;

export const PayStripe = (props) => {
  const { publishableKey, clientSecret, accountId, intl, successRedirectUrl, language, token } = props;
  useEffect(() => {
    // const { language } = initData?.shopSetting;
    let stripe;
    stripe = Stripe(publishableKey, {
      stripeAccount: accountId,
      locale: getLocale(language) === 'zh-CN' ? 'zh' : getLocale(language),
    });
    const elements = stripe.elements();

    const elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    const cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
      showIcon: true,
    });
    cardNumber.mount('#example2-card-number');
    cardNumber.on('ready', () => {
      document.querySelector('#card-num-label').classList.remove('hidden');
    });

    const cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardExpiry.mount('#example2-card-expiry');
    cardExpiry.on('ready', () => {
      document.querySelector('#card-expiry-label')?.classList?.remove('hidden');
    });

    const cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardCvc.mount('#example2-card-cvc');
    cardCvc.on('ready', () => {
      document.querySelector('#card-cvc-label').classList.remove('hidden');
    });

    function registerElements(elements, exampleName) {
      const formClass = `.${exampleName}`;
      const example = document.querySelector(formClass);
      const form = example.querySelector('#stripe-form');
      const error = form.querySelector('.error');
      const errorMessage = error.querySelector('.message');

      function enableInputs() {
        Array.prototype.forEach.call(
          form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
          function (input) {
            input.removeAttribute('disabled');
          },
        );
      }

      function disableInputs() {
        Array.prototype.forEach.call(
          form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
          function (input) {
            input.setAttribute('disabled', 'true');
          },
        );
      }

      function triggerBrowserValidation() {
        // The only way to trigger HTML5 form validation UI is to fake a user submit
        // event.
        const submit = document.createElement('input');
        submit.type = 'submit';
        submit.style.display = 'none';
        form.appendChild(submit);
        submit.click();
        submit.remove();
      }

      // Listen for errors from each Element, and show error messages in the UI.
      const savedErrors = {};
      elements.forEach(function (element, idx) {
        element.on('change', function (event) {
          if (event.error) {
            error.classList.add('visible');
            savedErrors[idx] = event.error.message;
            errorMessage.innerText = event.error.message;
          } else {
            savedErrors[idx] = null;

            // Loop over the saved errors and find the first one, if any.
            const nextError = Object.keys(savedErrors)
              .sort()
              .reduce(function (maybeFoundError, key) {
                return maybeFoundError || savedErrors[key];
              }, null);

            if (nextError) {
              // Now that they've fixed the current error, show another one.
              errorMessage.innerText = nextError;
            } else {
              // The user fixed the last error; no more errors.
              error.classList.remove('visible');
              errorMessage.innerText = '';
            }
          }
        });
      });
      const inputs = document.querySelectorAll('.example2 .input');
      Array.prototype.forEach.call(inputs, function (input) {
        input.addEventListener('focus', function () {
          input.classList.add('focused');
        });
        input.addEventListener('blur', function () {
          input.classList.remove('focused');
        });
        input.addEventListener('keyup', function () {
          if (input.value.length === 0) {
            input.classList.add('empty');
          } else {
            input.classList.remove('empty');
          }
        });
      });
      // Listen on the form's 'submit' handler...
      form.addEventListener('submit', function (e) {
        e.preventDefault();
        buriedPoint('checkout_fill_credit_card_info');
        // Trigger HTML5 validation UI on the form if any of the inputs fail
        // validation.
        let plainInputsValid = true;
        Array.prototype.forEach.call(form.querySelectorAll('input'), function (input) {
          if (input.checkValidity && !input.checkValidity()) {
            plainInputsValid = false;
          }
        });
        if (!plainInputsValid) {
          triggerBrowserValidation();
          return;
        }

        // Show a loading screen...
        example.classList.add('submitting');

        // Disable all inputs.
        disableInputs();

        // Use Stripe.js to create a token. We only need to pass in one Element
        // from the Element group in order to create a token. We can also pass
        // in the additional customer data we collected in our form.
        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: { card: cardNumber },
          })
          .then(function (result) {
            // buriedPoint('add_payment_info', { payment_type: '信用卡' });
            // Stop loading!
            example.classList.remove('submitting');
            if (result?.paymentIntent?.status === 'requires_capture' || result?.paymentIntent?.status === 'succeeded') {
              // If we received a token, show the token ID.
              window.history.replaceState(null, '', `../../me/order/${token}`);

              window.location.href = successRedirectUrl;
            } else {
              // Otherwise, un-disable inputs.
              const infoError = result?.error || {};
              const errorInfo =
                infoError?.message ||
                intl.formatMessage({
                  defaultMessage: '操作失败，请重试',
                  id: '915593a1d4fd928aaf3d29d880996d62',
                });
              Notify.error(errorInfo, 1500);
              enableInputs();
            }
          })
          .catch(() => {
            // buriedPoint('add_payment_info', { payment_type: '信用卡' });
          });
      });
    }

    registerElements([cardNumber, cardExpiry, cardCvc], 'example');
  });

  return (
    <Style>
      <div className='cell example example2' id='example-2'>
        <form id='stripe-form'>
          <div className='row'>
            <div className='field'>
              <div id='example2-card-number' className='input empty' />
              <label
                htmlFor='example2-card-number'
                data-tid='elements_examples.form.card_number_label'
                className='hidden'
                id='card-num-label'
              >
                Card number
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='field half-width'>
              <div id='example2-card-expiry' className='input empty' />
              <label
                htmlFor='example2-card-expiry'
                data-tid='elements_examples.form.card_expiry_label'
                className='hidden'
                id='card-expiry-label'
              >
                Expiration
              </label>
            </div>
            <div className='field half-width'>
              <div id='example2-card-cvc' className='input empty' />
              <label
                htmlFor='example2-card-cvc'
                data-tid='elements_examples.form.card_cvc_label'
                className='hidden'
                id='card-cvc-label'
              >
                CVC
              </label>
            </div>
          </div>
          <div className='error' role='alert'>
            <span className='message' />
          </div>
          <div className='success'>
            <h3 className='title' data-tid='elements_examples.success.title' />
            <p className='message'>
              <span data-tid='elements_examples.success.message' />
              <span className='token' />
            </p>
          </div>
          <div className='submit-wrapper'>
            <button type='submit' data-tid='elements_examples.form.pay_button'>
              {intl.formatMessage({ defaultMessage: '确认支付', id: 'order.pay.pay_confirm' })}
            </button>
          </div>
        </form>
      </div>
    </Style>
  );
};

export const openPayStripeDialog = ({
  history,
  publishableKey,
  clientSecret,
  accountId,
  intl,
  successRedirectUrl,
  language,
  token,
}) => {
  openDialog({
    title: intl.formatMessage({ id: 'order.pay.credit_card', defaultMessage: '信用卡支付' }),
    children: (
      <PayStripe
        publishableKey={publishableKey}
        clientSecret={clientSecret}
        accountId={accountId}
        intl={intl}
        successRedirectUrl={successRedirectUrl}
        language={language}
        token={token}
      />
    ),
    style: { width: '640px' },
    maskClosable: false,
    onClose() {
      if (!location.href.includes('orderToken=')) {
        history.replace(`${location.pathname}${location.search}&orderToken=${token}`)
      }
    }
  });
};
