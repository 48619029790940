import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import Style from './style';

const ClearSelectedBtn = (props) => {
  const { disabled, onClear } = props;
  const intl = useIntl();

  return (
    <Style>
      <div
        className={cn('filter-category-clear-btn', disabled ? 'filter-category-clear-btn-disabled' : '')}
        onClick={() => {
          !disabled && onClear && onClear();
        }}
      >
        {intl.formatMessage({
          id: 'general.clear',
          defaultMessage: '清除',
        })}
      </div>
    </Style>
  );
};

export default ClearSelectedBtn;
