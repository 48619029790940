import React, { useState, useCallback, useEffect } from 'react';
import { Style, DialogStyle } from './style';
import { Form, Checkbox, Validators, Button, FormStrategy, openDialog, closeDialog } from 'zent';
import HtDialog from '../../../standard/dialog';
import HtButton from '../../../standard/button';
import FormFileds from 'components/base/form-fileds';
import FormInputAreaPhone from './form-input-area-phone';
import deliveryApi from 'api/delivery';

const dialogId = 'OPEN_PERSON_FORM_DIALOG';
const { openHtDialog, closeHtDialog } = HtDialog;
const PersonFormDialog = (props) => {
  const { theme, intl, $t, isEdit, defaultValue, onSuccess } = props;

  const personForm = Form.useForm(FormStrategy.View);
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState<string>('');
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [visibleIsDefault, setVisibleIsDefault] = useState<boolean>(false);

  useEffect(() => {
    const initValue = {
      firstName: '',
      lastName: '',
      code: '',
      phoneNumberPrefix: '',
      phone: '',
    };
    if (isEdit && defaultValue) {
      const { firstName, lastName, phone, isDefault } = defaultValue;
      initValue.firstName = firstName;
      initValue.lastName = lastName;
      if (phone?.includes('+')) {
        const phoneMapInfo = phone.trim().split(' ');
        if (phoneMapInfo.length === 2) {
          const [phoneNumberPrefix, phone = ''] = phoneMapInfo;
          initValue.phone = phone;
          setPhoneNumberPrefix(phoneNumberPrefix);
        }
        if (phoneMapInfo.length >= 3) {
          const [code = '', phoneNumberPrefix = '', phone = ''] = phoneMapInfo;
          initValue.code = code;
          initValue.phone = phone;
          setPhoneNumberPrefix(phoneNumberPrefix);
        }
      }
      setIsDefault(Boolean(isDefault));
    }
    personForm.initialize(initValue);
    if (window?.global_data?.userInfo?.customerId) {
      setVisibleIsDefault(true);
    }
  }, []);

  const onChangeAreaPhone = (item) => {
    setPhoneNumberPrefix(`+${item.phoneNumberPrefix}`);
  };

  // 提交提货人
  const onSubmit = () => {
    const { firstName, lastName, phone, code } = personForm.getValue();
    const userInfo = window?.global_data.userInfo;
    const params = {
      firstName,
      lastName,
      isDefault: Number(isDefault),
      phone: `${code} ${phoneNumberPrefix} ${phone}`,
    };
    if (userInfo.customerId) {
      // 已登录
      params.customerId = userInfo.customerId;
      if (isEdit) {
        deliveryApi
          .updatePickUpInfo({
            ...params,
            uniqueId: defaultValue.uniqueId,
          })
          .then(() => {
            closeHtDialog(dialogId);
            onSuccess && onSuccess();
          });
      } else {
        deliveryApi.createPickUpInfo(params).then(() => {
          closeHtDialog(dialogId);
          onSuccess && onSuccess();
        });
      }
    } else {
      // 未登录
      closeHtDialog(dialogId);
      onSuccess && onSuccess(params);
    }
  };

  const onSubmitFail = useCallback(() => {
    console.log('submit fail');
  }, []);
  return (
    <Style>
      <DialogStyle />
      <div className='person-form-container'>
        <Form form={personForm} className='person-form' onSubmit={onSubmit} onSubmitFail={onSubmitFail}>
          <div className='form-item-wrap'>
            <div className='form-item-inline names-item'>
              <FormFileds
                name='firstName'
                label={$t('account.form.first_name', '名字') + '*'}
                validators={[
                  Validators.required($t('account.form.please_enter_first_name', '请输入名字')),
                  Validators.maxLength(32, $t('5f7b66782d9f4922b95cfbdf444f56a7', '超出最大长度{num}', { num: 32 })),
                ]}
                style={{ marginTop: 0, flex: 1 }}
              />
              <FormFileds
                name='lastName'
                label={$t('order.address.firstname', '姓氏') + '*'}
                validators={[
                  Validators.required($t('reviews.about.reviewer.name', '请输入姓氏')),
                  Validators.maxLength(32, $t('5f7b66782d9f4922b95cfbdf444f56a7', '超出最大长度{num}', { num: 32 })),
                ]}
                style={{ marginTop: 0, flex: 1 }}
              />
            </div>
            <div className='form-item-inline area-phone-item'>
              <FormInputAreaPhone
                form={personForm}
                intl={intl}
                defaultValue={defaultValue}
                onChange={onChangeAreaPhone}
                isEdit={isEdit}
              />
            </div>
            {visibleIsDefault && (
              <div className='form-item-inline'>
                <Checkbox
                  className='checkbox-default-item'
                  checked={isDefault}
                  onChange={(e) => setIsDefault(e.target.checked)}
                >
                  {$t('cfab72a6156c4ad1b52c03a6f3380599', '设为默认提货人')}
                </Checkbox>
              </div>
            )}
          </div>
          <div className='person-form-footer-btn'>
            <HtButton onClick={() => closeHtDialog(dialogId)} type='secondary' theme={theme}>
              {$t('common_cancel', '取消')}
            </HtButton>
            <HtButton htmlType='submit' theme={theme}>
              {$t('common_confirm', '确定')}
            </HtButton>
          </div>
        </Form>
      </div>
    </Style>
  );
};

export const openPersonFormDialog = ({ intl, isEdit, defaultValue, onSuccess, theme }) => {
  const $t = (id, defaultMessage = 'default', params = {}) => {
    if (!id) return '';
    return intl.formatMessage({ id, defaultMessage }, params);
  };

  openHtDialog({
    theme,
    className: 'person-form-dialog',
    dialogId,
    title: isEdit
      ? $t('22224069afbd4b95b19567e4111b2509', '编辑提货人')
      : $t('8facd2ab88234b7395cedf1894011bc2', '添加提货人'),
    children: (
      <PersonFormDialog
        theme={theme}
        intl={intl}
        $t={$t}
        isEdit={isEdit}
        defaultValue={defaultValue}
        onSuccess={onSuccess}
      />
    ),
  });
};
