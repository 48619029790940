import styled from 'styled-components';

export default styled.div`
  border-top: 1px solid var(--color_borders);
  padding-top: 20px;
  .item-block {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    .main-title {
      color: rgba(var(--color_body_text_rgb), 0.6);
    }
    &.discount-block {
      display: block; // 去掉flex布局
    }
  }
  .font-light {
    text-align: right;
    margin-bottom: 12px;
  }
  .ship-free-discount-code {
    height: 40px;
    margin: 8px 0;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    background: rgba(var(--color_sale_text_rgb), 0.1);
    border-radius: var(--border_radius_tag);
    .ship-free-discount-code-icon {
      width: 16px;
      height: 16px;
      fill: var(--color_sale_text);
      display: inline-block;
      margin-right: 2px;
    }
    .ship-free-discount-text {
      word-break: break-word;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
    }
  }
  .sum-count-wrapper {
    border-top: 1px solid var(--color_borders);
    .total-price {
      ${(props) => props.theme.getBaseSize(props.theme.h7)};
    }
  }
`;
