/* eslint-disable default-case */
/* eslint-disable radix */
/**
 * expectedTimeUnit 预期取货时间单位； <pre> minute:分钟 hour:小时 day:天 </pre>java.lang.String
 * expectedTimeValue 预期取货时间值java.lang.Integer
 * isEnableExpectedTime 是否选择预期取货时间java.lang.Integer
 * longestExpectedTime      最长可预约时间； 当天、1天、2天 ... 730天，前端直接传入吧java.lang.Integer
 * timeCustomDetails
 *
 * 时区问题，由于配置的时候是按照商家所在时区配置的，所以需要先把时间转换成商家的时区去计算出可选的时间列表
 */

import { useMemo, useState } from 'react';
import { DayMsec, formateTimeByTimeZone, HourMsec, MinuteMsec } from 'utils/datetime';
import moment from 'moment';
import set from 'lodash/set';
import { useIntl } from 'react-intl';

const formateDayToWeekStr = (day) => {
  let weekStr = '';
  switch (day) {
    case 1:
      weekStr = 'Mon';
      break;
    case 2:
      weekStr = 'Tues';
      break;
    case 3:
      weekStr = 'Wed';
      break;
    case 4:
      weekStr = 'Thur';
      break;
    case 5:
      weekStr = 'Fri';
      break;
    case 6:
      weekStr = 'Sat';
      break;
    default:
      weekStr = 'Sun';
      break;
  }
  return weekStr;
};

const formateWeekStrToDay = (str) => {
  let day = 0;
  switch (str) {
    case 'Mon':
      day = 1;
      break;
    case 'Tues':
      day = 2;
      break;
    case 'Wed':
      day = 3;
      break;
    case 'Thur':
      day = 4;
      break;
    case 'Fri':
      day = 5;
      break;
    case 'Sat':
      day = 6;
      break;
    default:
      day = 7;
      break;
  }
  return day;
};

const getNumStrWithZero = (num) => {
  let str = `${num}`;
  if (num >= 0 && num < 10) {
    str = `0${num}`;
  }
  return str;
};

enum TimePickerSplitType {
  hour = 'hour',
  same_as_custom_details = 'same_as_custom_details',
}

enum TimePickerConfigType {
  allDay = 'all_day',
  weekRepetition = 'week_repetition',
  daysRepetition = 'days_repetition',
  workdayRepetition = 'workday_repetition',
}

const useTimeList = ({
  expectedTimeUnit = 'hour',
  expectedTimeValue = 2,
  isEnableExpectedTime = 0,
  longestExpectedTime = 30,
  timeCustomDetails = [
    {
      intervals: ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
      timeSections: [{ startTime: '0:00', endTime: '24:00' }],
    },
  ],
  timeIntervalDetail = TimePickerSplitType.hour, // 时间段细分方式
  timeInterval = TimePickerConfigType.allDay,
}) => {
  // const { intervals = [], timeSections = [] } = timeCustomDetails || [];
  // const { startTime = '', endTime = '' } = timeSections;
  const intl = useIntl();

  const [rangeOriginList, setRangeOriginList] = useState({});

  // 计算提前预约时长
  const expectedTimeUnitMsec = useMemo(() => {
    let msec = DayMsec;
    switch (expectedTimeUnit) {
      case 'minute':
        msec = MinuteMsec;
        break;
      case 'hour':
        msec = HourMsec;
        break;
      default:
        msec = DayMsec;
        break;
    }

    return isEnableExpectedTime ? msec * expectedTimeValue : 0;
  }, [expectedTimeUnit, expectedTimeValue, isEnableExpectedTime]);

  // 商家填写的自提时间段可能是乱序的，需要先进行排序再去做后续处理
  const formatTimeDetails = useMemo(() => {
    timeCustomDetails?.forEach((item) => {
      item.timeSections.sort((sectionA, sectionB) => {
        if (sectionA.startTime > sectionB.startTime) {
          return 1;
        }
        return -1;
      });
    });
    return timeCustomDetails;
  }, [timeCustomDetails]);

  const getTimeList = ({ startTime, endTime, isTodayFirstTime }) => {
    const list: { start?: string; end?: string }[] = [];
    const startMin = isTodayFirstTime ? 0 : Number(startTime.split(':')[1]);
    const startHour = isTodayFirstTime ? Number(startTime.split(':')[0]) + 1 : Number(startTime.split(':')[0]); // 9:01 从下一个时间段开始
    let endHour = Number(endTime.split(':')[0]);
    let endMin = Number(endTime.split(':')[1]);
    if (startHour < endHour || (startHour === endHour && startMin < endMin)) {
      if (endHour === 24 && endMin === 0) {
        // 24:00 后端校验不通过，显示为23:59
        endHour = 23;
        endMin = 59;
      }
      switch (timeIntervalDetail) {
        case TimePickerSplitType.hour: // 目前只有按时分段
          for (let h = startHour; h <= endHour; h++) {
            const hStr = getNumStrWithZero(h);
            const minStr = getNumStrWithZero(startMin);
            if (h === startHour && !isTodayFirstTime) {
              list.push({ start: `${hStr}:${minStr}`, end: `${getNumStrWithZero(h + 1)}:00` });
            } else if (h === endHour && endMin > 0) {
              list.push({ start: `${hStr}:00`, end: `${hStr}:${getNumStrWithZero(endMin)}` });
            } else if (h !== endHour) {
              list.push({ start: `${hStr}:00`, end: `${getNumStrWithZero(h + 1)}:00` });
            }
          }
          break;
        case TimePickerSplitType.same_as_custom_details:
          list.push({
            start: `${getNumStrWithZero(startHour)}:${getNumStrWithZero(startMin)}`,
            end: `${getNumStrWithZero(endHour)}:${getNumStrWithZero(endMin)}`,
          });
          break;
      }
    }
    return list;
  };

  const getDateTimeList = () => {
    const now = new Date().getTime();
    const shopNow = formateTimeByTimeZone(now); // 获取商家所在时区的当前时间
    const shopTimestamp = moment(shopNow).valueOf(); // 获取商家所在时区当前的时间戳
    const shopToDayDate = moment(shopNow).format('YYYY-MM-DD'); // 获取商家所在时区当前的日期
    const list = [];
    const start = shopTimestamp + expectedTimeUnitMsec;
    const end = longestExpectedTime
      ? start + DayMsec * longestExpectedTime
      : moment(`${shopToDayDate} 23:59:59`).valueOf(); // 最长可预约时间，0为当天

    if (
      timeInterval === TimePickerConfigType.allDay &&
      timeIntervalDetail === TimePickerSplitType.same_as_custom_details
    ) {
      // 全天24小时按自提时间拆分
      // 这种情况就不需要拆具体的时间了，拆出日期显示就可
      for (let timestamp = start; timestamp <= end; timestamp += DayMsec) {
        const formateDateTime = timestamp;
        const date = moment(formateDateTime).format('YYYY-MM-DD');
        const dateTime = { date, time: [] };
        // 仅这种条件下才存在时间段列表为空的情况
        list.push(dateTime);
      }
    } else if (formatTimeDetails.length === 1 && formatTimeDetails?.[0]?.intervals.length === 7) {
      // 每天重复
      for (let timestamp = start; timestamp <= end; timestamp += DayMsec) {
        // 先转成商家时区，获取具体的年月日，周，时
        const formateDateTime = timestamp;
        const date = moment(timestamp).format('YYYY-MM-DD');
        const hour = moment(formateDateTime).hour();
        const minute = moment(formateDateTime).minute();
        const dateTime = { date, time: [] };
        formatTimeDetails?.[0]?.timeSections?.forEach((time) => {
          const { startTime = '9:00', endTime = '18:00' } = time || {};
          const startHour = Number(startTime.split(':')[0]) || 0;

          const timeList = getTimeList({
            startTime: timestamp === start && startHour <= hour ? `${hour}:${minute}` : startTime,
            endTime,
            isTodayFirstTime: timestamp === start && startHour <= hour,
          });

          dateTime.time = dateTime.time.concat(timeList);
        });
        if (dateTime.time.length > 0) {
          list.push(dateTime);
        }
      }
    } else {
      // 每周重复
      for (let timestamp = start; timestamp <= end; timestamp += DayMsec) {
        // 先转成商家时区，获取具体的年月日，周，时
        const formateDateTime = timestamp;
        const date = moment(timestamp).format('YYYY-MM-DD');
        const day = moment(formateDateTime).day();
        const weekStr = formateDayToWeekStr(day);
        const hour = moment(formateDateTime).hour();
        const minute = moment(formateDateTime).minute();

        formatTimeDetails.forEach((item) => {
          const { intervals = [''], timeSections = [] } = item;
          if (intervals.includes(weekStr)) {
            const dateTime = { date, time: [] };
            timeSections.forEach((time) => {
              const { startTime = '9:00', endTime = '18:00' } = time || {};
              const startHour = Number(startTime.split(':')[0]) || 0;

              const timeList = getTimeList({
                startTime: timestamp === start && startHour <= hour ? `${hour}:${minute}` : startTime,
                endTime,
                isTodayFirstTime: timestamp === start && startHour <= hour,
              });

              dateTime.time = dateTime.time.concat(timeList);
            });
            if (dateTime.time.length > 0) {
              list.push(dateTime);
            }
          }
        });
      }
    }
    return list;
  };

  const getPickerDateList = () => {
    return new Promise((resolve, reject) => {
      const list = getDateTimeList();

      if (!list?.length) {
        reject();
      }
      if (
        timeInterval === TimePickerConfigType.allDay &&
        timeIntervalDetail === TimePickerSplitType.same_as_custom_details
      ) {
        resolve({ list, onlyDate: true });
      } else {
        resolve({ list });
      }
      const formateToRangeOriginList = {};
      list.forEach((item) => {
        const year = moment(item?.date).year();
        const month = moment(item?.date).month() + 1;
        const dateNum = moment(item?.date).date();
        const day = moment(item?.date).day();
        const timeList = item?.time.map((time) => `${time.start}-${time.end}`);

        set(formateToRangeOriginList, `${year}.${month}.${dateNum}`, { day, list: timeList });
      });
      setRangeOriginList(formateToRangeOriginList);
    });
  };

  // 自提配置转成Str
  const timeSettingsStr = useMemo(() => {
    const settings = [];
    if (formatTimeDetails?.length) {
      formatTimeDetails.forEach((item) => {
        const weeks = [];
        const times = [];
        item.intervals.forEach((week) => {
          const day = formateWeekStrToDay(week);
          weeks.push(
            intl.formatMessage({
              id: `pickup.time.week.${day || 7}`,
              defaultMessage: `周${day || 7}`,
            }),
          );
        });
        item.timeSections.forEach((time) => {
          const { startTime = '9:00', endTime = '18:00' } = time || {};
          times.push(`${startTime}-${endTime}`);
        });
        if (weeks?.length === 7) {
          settings.push(
            `${intl.formatMessage({
              id: '884ba1ac50ad4d7286ccbcd0ec6ff65b',
              defaultMessage: '每天',
            })}:${times.join('、')}`,
          );
        } else {
          settings.push(`${weeks.join('、')}:${times.join('、')}`);
        }
      });
    }
    return settings.join(';');
  }, [formatTimeDetails]);

  return {
    getPickerDateList,
    rangeOriginList,
    timeSettingsStr,
  };
};
export default useTimeList;
