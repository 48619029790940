import styled from 'styled-components';

export default styled.div`
  .page-container {
    /* padding: 0px 80px 40px 80px; */
    width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
  }
  background: #f7f8fa;
  @media screen and (max-width: 750px) {
    background: #f7f8fa;
    .page-container {
      width: auto;
      padding: 0 16px;
    }
    .sub-header-container {
      background: none;
      border-bottom: none;
    }
  }
`;
