import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { DistributionMobileHeader } from '../mobile-header';
import Back from '../back';
import HeaderSetting from '../header-setting';
import Style from './style';

const DistributionHeader = (props) => {
  const {
    history,
    hideMobileHeader,
    hidePcHeader,
    bgColor = '#f00',
    pcLogo,
    title,
    affiliateId,
    initialData,
    isActive,
    currency,
    noBackground,
    style,
  } = props;
  const shopName = initialData?.shopSetting?.name;
  const intl = useIntl();

  const goToDashboard = () => {
    history.push('/affiliate/dashboard');
  };
  const distributionTitle = intl.formatMessage({
    id: 'distribution_alliance',
    defaultMessage: '分销联盟',
    description: '分销联盟',
  });
  return (
    <Style bgColor={bgColor}>
      {!hidePcHeader && (
        <div className='distribution-pc-header'>
          <div className='distribution-nav'>
            <div className='distribution-header-logo' onClick={goToDashboard}>
              {!pcLogo && (
                <div className='default-logo'>
                  {shopName} | {distributionTitle}
                </div>
              )}
              {pcLogo && <img className='logo' src={pcLogo} alt={`${shopName} |  ${distributionTitle}`} />}
            </div>

            <div className='nav-item'>
              <NavLink activeClassName='is-active' to='/affiliate/dashboard'>
                {intl.formatMessage({
                  id: 'store.edit.homepage',
                  defaultMessage: '首页',
                  description: '首页',
                })}
              </NavLink>
            </div>
            {/* <div className="nav-item">数据报告</div> */}
            {isActive && (
              <div className='nav-item'>
                <NavLink activeClassName='is-active' to='/affiliate/commission-bill'>
                  {intl.formatMessage({
                    id: '55edd09f4e9145ea9319bb17e68844e5',
                    defaultMessage: '佣金账单',
                    description: '佣金账单',
                  })}
                </NavLink>
              </div>
            )}
          </div>
          <HeaderSetting history={history} currency={currency} affiliateId={affiliateId} />
        </div>
      )}
      {!hideMobileHeader && (
        <DistributionMobileHeader
          initialData={initialData}
          bgColor={bgColor}
          pcLogo={pcLogo}
          history={history}
          affiliateId={affiliateId}
          noBackground={noBackground}
          style={style}
          isActive
        />
      )}
      {hideMobileHeader && <Back className='back-header' history={history} title={title} />}
    </Style>
  );
};

export default DistributionHeader;
