import React, { useMemo, useState } from 'react';
import HtDialog from 'components/standard/dialog';
import { Button, Input, NumberInput, Radio } from 'zent';
import Styles from './style';
import { POINTS_TYPE } from '../../const';
import { getPointNameWithNum } from 'utils/points';
import { formatAmountWithFixed, intlLiToYuan } from 'utils/money';
import openPointsRulesDialog from 'components/page/points-rules-dialog';

const { openHtDialog, closeHtDialog } = HtDialog;
const RadioGroup = Radio.Group;

const UsePointsDialogContent = (props) => {
  const { intl, currency, onClose = () => {}, pointsInfo = {}, theme, onConfirm = () => {} } = props;

  const {
    singleName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
    complexName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
    usablePoints = 0,
    selectedPoints = 0,
    pointsDeductRule,
    maxUsablePoints = 0,
    maxUsablePointsAmount = 0,
    type = POINTS_TYPE.none,
  } = pointsInfo;

  const [currentType, setCurrentType] = useState(type);
  const [customPointsError, setCustomPointsError] = useState(false);
  const [currentCustomPoints, setCurrentCustomPoints] = useState(selectedPoints || '');

  const currentCustomPointsAmount = useMemo(() => {
    const points = Number(currentCustomPoints);
    return points && pointsDeductRule
      ? formatAmountWithFixed({
          currency,
          amount: (points / pointsDeductRule?.points) * pointsDeductRule?.amount,
        })
      : 0;
  }, [currentCustomPoints]);

  const onConfirmUsePoints = () => {
    switch (currentType) {
      case POINTS_TYPE.maximum:
        onConfirm({
          type: currentType,
          selectedPoints: maxUsablePoints,
          pointsDeductAmount: maxUsablePointsAmount,
        });
        break;
      case POINTS_TYPE.none:
        onConfirm({ type: currentType, selectedPoints: 0, pointsDeductAmount: 0 });
        break;
      default:
        onConfirm({
          type: currentType,
          selectedPoints: currentCustomPoints && currentCustomPoints <= maxUsablePoints ? currentCustomPoints : 0,
          pointsDeductAmount:
            currentCustomPointsAmount && currentCustomPointsAmount <= maxUsablePointsAmount
              ? currentCustomPointsAmount
              : 0,
        });
        break;
    }
    onClose();
  };

  const onCustomNumChange = (val) => {
    setCurrentCustomPoints(val);
    if (val > maxUsablePoints) {
      setCustomPointsError(true);
    } else {
      setCustomPointsError(false);
    }
  };
  return (
    <Styles>
      <div className='header'>
        <span>
          {intl?.formatMessage({ id: '4078d224a5084bfa8c183c2c545b379c', defaultMessage: '剩余' })}: {usablePoints}
        </span>
        <span
          onClick={() =>
            openPointsRulesDialog({
              intl,
              theme,
              currency,
              pointsSettings: {
                globalRule: { singleName, complexName, isActivated: true },
                orderDeductRule: pointsDeductRule ? { ...pointsDeductRule, isActivated: true } : {},
              },
            })
          }
        >
          {intl?.formatMessage({ id: '73e46bb799d44ed7af17068e2948e9b2', defaultMessage: '使用规则' })}
        </span>
      </div>
      <RadioGroup className='content' value={currentType} onChange={(e) => setCurrentType(e?.target?.value)}>
        <div className='content-item'>
          <Radio className='content-item_radio' value={POINTS_TYPE.none}>
            {intl?.formatMessage({ id: '6313613a5a1748258b5adbc730ad88b8', defaultMessage: '暂不使用' })}
          </Radio>
        </div>
        <div className='content-item'>
          <Radio className='content-item_radio' value={POINTS_TYPE.maximum}>
            <div>
              {intl?.formatMessage({ id: 'fc9594ade7aa4e85bb7d6f233e9669b4', defaultMessage: '抵扣' })}{' '}
              <span className='content-item_amount'>{intlLiToYuan({ currency, value: maxUsablePointsAmount })}</span>
            </div>
            <span className='content-item_desc'>
              {intl?.formatMessage(
                { id: 'fd11363e3fd34f2e83440ee175449865', defaultMessage: `本单最多使用{points}` },
                {
                  points: getPointNameWithNum({
                    num: maxUsablePoints,
                    names: { singleName, complexName },
                  }),
                },
              )}
            </span>
          </Radio>
        </div>
        <div className='content-item'>
          <Radio className='content-item_radio' value={POINTS_TYPE.customer}>
            {intl?.formatMessage({ id: 'e4a2f29b46cc4908aba55b4084c0b4cd', defaultMessage: '自定义使用' })}
          </Radio>
        </div>
        {currentType === POINTS_TYPE.customer && (
          <div className='content-customer-box'>
            <div className='content-customer-box_input'>
              <NumberInput integer value={currentCustomPoints} onInput={onCustomNumChange} placeholder='0' />
              <Button type='primary' onClick={onConfirmUsePoints} disabled={customPointsError}>
                {intl?.formatMessage({ id: 'common.use', defaultMessage: '使用' })}
              </Button>
            </div>
            {customPointsError ? (
              <div className='content-customer-box_error'>
                {intl?.formatMessage({
                  id: '8a87c55a18b343668ac83ea60380f38a',
                  defaultMessage: '超过最大可使用积分',
                })}
              </div>
            ) : (
              <div className='content-customer-box_tip'>
                {intl?.formatMessage({
                  id: 'fb9651936be446488099bfd86e9f4110',
                  defaultMessage: '本单可抵扣',
                })}
                <span>{intlLiToYuan({ currency, value: currentCustomPointsAmount })}</span>
              </div>
            )}
          </div>
        )}
      </RadioGroup>
      <div className='footer'>
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: '取消',
          })}
        </Button>
        <Button type='primary' onClick={onConfirmUsePoints} disabled={customPointsError}>
          {intl.formatMessage({
            id: 'common_confirm',
            defaultMessage: '确定',
          })}
        </Button>
      </div>
    </Styles>
  );
};

const openUsePointsDialog = (props) => {
  const { theme, intl, currency, pointsInfo = {}, onConfirm = () => {} } = props;
  const dialogId = 'openUsePointsDialog';
  const {
    singleName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
  } = pointsInfo;

  openHtDialog({
    theme,
    dialogId,
    style: { width: 540 },
    title: singleName,
    children: (
      <UsePointsDialogContent
        currency={currency}
        theme={theme}
        intl={intl}
        onClose={() => closeHtDialog(dialogId)}
        pointsInfo={pointsInfo}
        onConfirm={onConfirm}
      />
    ),
    footer: null,
  });
};

export default openUsePointsDialog;
