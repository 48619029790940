import React, { FC, useEffect, memo } from 'react';
import ImageUpload from 'cpn/image-upload';
import { Icon } from 'components/base';
import Style from './style';
import {
  ICustomizedCheckoutProps,
  ITemplateModuleVO,
  ICustomizedCheckoutItemProps,
  IOrderMessageProps,
  GoodsRangeEnum,
  CountryRangeEnum,
} from './type';
import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE } from './const';
import { cloneDeep, set, debounce } from 'lodash';
import cn from 'classnames';
import { Input } from 'zent';
import { DELIVERY_METHOD } from '../../const';

const CustomizedCheckout = (props: ICustomizedCheckoutProps) => {
  const {
    intl,
    $fm,
    checkoutSetting = {},
    customizedCheckoutData = [],
    changeCustomizedCheckoutData,
    orderMessage,
    setOrderMessage,
    orderMessageError,
    setOrderMessageError,
    lineItems = [],
    shippingAddress,
    requiresShipping,
    deliveryInfo = {},
  } = props;

  // 判断地区和商品是否支持
  useEffect(() => {
    if (customizedCheckoutData.length > 0 && lineItems?.length > 0) {
      const findIndex = customizedCheckoutData.findIndex(item => item.type === CHECKOUT_RULE_TYPE.idcard);
      if (findIndex >= 0) {
        const findItem = customizedCheckoutData[findIndex];
        let isExistGoods = true;
        let isExistCountry = true;
        if (findItem?.effectiveRange?.goodsRange === GoodsRangeEnum.products) {
          // 判断是否在商品中
          const ids = findItem?.effectiveRange?.goodsRangeData?.map(item => item.goodsId) || [];
          const isExist = lineItems.some(item => ids?.includes(item.goodsId));
          console.log("🚀 ~ products ~ isExist:", isExist);
          isExistGoods = isExist;
        } else if (findItem?.effectiveRange?.goodsRange === GoodsRangeEnum.collections) {
          // 判断是否在商品分组中
          const ids = findItem?.effectiveRange?.goodsCollectionRangeData?.map(item => item.collectionId) || [];
          const isExist = lineItems.some(item => {
            if (!item.collectionIds) return false;
            const collectionIds = JSON.parse(item.collectionIds);
            try {
              return collectionIds.some(collectionId => ids?.includes(collectionId));
            } catch (err) {
              return false;
            }
          });
          console.log("🚀 ~ collections ~ isExist:", isExist)
          isExistGoods = isExist;
        }
        if (requiresShipping === 1) {
          let countryCode = '';
          if (findItem?.effectiveRange?.countryRange === CountryRangeEnum.countries) {
            if (deliveryInfo?.selectedDeliveryMethod === DELIVERY_METHOD.pickup) {
              const selectedLocation = deliveryInfo?.locationList?.find(item => item.selected === 1);
              if (selectedLocation) {
                countryCode = selectedLocation.countryCode;
              }
            } else {
              countryCode = shippingAddress?.countryCode;
            }
            if (countryCode) {
              const countryCodeList = findItem?.effectiveRange?.countryRangeData?.map(item => item.countryCode) || [];
              const isExist = countryCodeList.includes(countryCode?.toUpperCase());
              console.log("🚀 ~ countryCode ~ isExist:", isExist);
              isExistCountry = isExist;
            } else {
              isExistCountry = false;
            }
          } else {
            isExistCountry = true;
          }
        } else { // 虚拟商品
          const countryRangeData = findItem?.effectiveRange?.countryRangeData || [];
          // 虚拟商品 指定地区也会展示
          isExistCountry = countryRangeData.length === 0;
        }
        // 同时命中限制规则
        const visible = isExistGoods && isExistCountry;
        console.log("🚀 ~ visible ~ isExist:", visible)
        const newCustomizedCheckoutData = cloneDeep(customizedCheckoutData);
        if (newCustomizedCheckoutData[findIndex].visible !== visible) {
          newCustomizedCheckoutData[findIndex].visible = visible;
          changeCustomizedCheckoutData(newCustomizedCheckoutData);
        }
      }
    }
  }, [customizedCheckoutData, lineItems, requiresShipping, shippingAddress, deliveryInfo]);

  const handleChangeCheckoutData = (key, value) => {
    const newData = cloneDeep(customizedCheckoutData);
    set(newData, key, value);
    changeCustomizedCheckoutData(newData);
  };

  return (
    <Style>
      <div className='customized-checkout-title'>{$fm('d258a792ca3b439c9a4247a1f8aa6214', '其他信息')}</div>
      <div className='customized-checkout-list'>
        {
          customizedCheckoutData.map((item, index) => (
            <CustomizedCheckoutItem
              intl={intl}
              $fm={$fm}
              item={item}
              index={index}
              handleChange={(key, value) => handleChangeCheckoutData(key, value)}
            />
          ))
        }
        <OrderMessageItem
          $fm={$fm}
          checkoutSetting={checkoutSetting}
          orderMessage={orderMessage}
          setOrderMessage={setOrderMessage}
          orderMessageError={orderMessageError}
          setOrderMessageError={setOrderMessageError}
        />
      </div>
    </Style>
  )
}

const CustomizedCheckoutItem: FC<ICustomizedCheckoutItemProps> = (props) => {
  const { intl, $fm, item, index, handleChange } = props;
  const { type, items, extra, visible } = item;

  // 商品, 地区不匹配则隐藏
  if (type === CHECKOUT_RULE_TYPE.idcard && !visible) return null;

  const handleChangeImage = (ci, key, data) => {
    const url = data?.[0] || '';
    const newCItem = cloneDeep(items[ci]);
    if (url) {
      newCItem[key] = url;
      newCItem.errorMsg = '';
    } else {
      newCItem[key] = '';
    }
    handleChange(`[${index}].items.[${ci}]`, newCItem);
  }

  return <>
    {
      items.map((cItem, ci) => {
        return (
          <div className="customized-checkout-list-item">
            <div className="list-item-box">
              <div className={cn("list-item__left", { 'label-required': Boolean(item.required) })}>
                <div>{cItem.label}</div>
              </div>
              <div className="list-item__right">
                {
                  cItem.type === TEMPLATE_TYPE.text &&
                  <Input
                    type={extra?.TEXT_IS_MULTI_TEXT === 1 ? 'textarea' : 'text'}
                    placeholder={$fm('common_please_input', '请输入')}
                    value={cItem.value || ''}
                    onChange={(e) => handleChange(`[${index}].items.[${ci}].value`, e.target.value?.substr(0, extra?.TEXT_IS_MULTI_TEXT === 1 ? 300 : 50))}
                    onBlur={() => {
                      if (cItem.value && item.required) {
                        handleChange(`[${index}].items.[${ci}].errorMsg`, '')
                      }
                    }}
                    maxCharacterCount={extra?.TEXT_IS_MULTI_TEXT === 1 ? 300 : 50}
                    showCount={extra?.TEXT_IS_MULTI_TEXT === 1}
                  />
                }
                {
                  cItem.type === TEMPLATE_TYPE.image ?
                    type === CHECKOUT_RULE_TYPE.idcard ?
                      <>
                        {
                          item?.extra?.ID_CARD_IS_FRONT === 1 &&
                          <div className='upload-block'>
                            <ImageUpload
                              multiple={false}
                              intl={intl}
                              maxAmount={1}
                              fileSize={10}
                              accept='.jpg,.png,.bmp,.jpeg'
                              placeholderSlot={
                                <div className="upload-placeholder">
                                  <Icon name='iconshangchuantupian'></Icon>
                                  <span>{$fm('b1585145b9c744b399256cbefe8aa8fe', '正面')}</span>
                                </div>
                              }
                              defaultValue={[cItem?.ID_CARD_IS_FRONT]}
                              onChange={data => handleChangeImage(ci, 'ID_CARD_IS_FRONT', data)}
                            />
                          </div>
                        }
                        {
                          item?.extra?.ID_CARD_IS_REVERSE === 1 &&
                          <div className='upload-block'>
                            <ImageUpload
                              multiple={false}
                              intl={intl}
                              maxAmount={1}
                              fileSize={10}
                              accept='.jpg,.png,.bmp,.jpeg'
                              placeholderSlot={
                                <div className="upload-placeholder">
                                  <Icon name='iconshangchuantupian' size='xSmall'></Icon>
                                  <span>{$fm('407adc54839f4a72a554e7479cfc1491', '反面')}</span>
                                </div>
                              }
                              defaultValue={[cItem?.ID_CARD_IS_REVERSE]}
                              onChange={data => handleChangeImage(ci, 'ID_CARD_IS_REVERSE', data)}
                            />
                          </div>
                        }
                      </> :
                      <div className='upload-block'>
                        <ImageUpload
                          multiple={false}
                          intl={intl}
                          maxAmount={1}
                          fileSize={10}
                          accept='.jpg,.png,.bmp,.jpeg'
                          placeholderSlot={
                            <div className="upload-placeholder">
                              <Icon name='iconshangchuantupian' size='xSmall'></Icon>
                            </div>
                          }
                          defaultValue={[cItem?.value]}
                          onChange={data => handleChangeImage(ci, 'value', data)}
                        />
                      </div> : null
                }
              </div>
              {
                cItem.errorMsg &&
                <div className="item-error-msg">{cItem.errorMsg}</div>
              }
            </div>
          </div>
        )
      })
    }
  </>
}

const OrderMessageItem: FC<IOrderMessageProps> = props => {
  const { $fm, checkoutSetting, orderMessage, setOrderMessage, orderMessageError, setOrderMessageError } = props;

  return (
    <div className="customized-checkout-list-item checkout-order-message">
      <div className="list-item-box">
        <div className={cn("list-item__left", { 'label-required': Boolean(checkoutSetting.requiresMessage) })}>
          <div>{$fm('971ce28231234ccd8f24aca85a29e41e', '订单留言')}</div>
          {
            checkoutSetting.messageDesc &&
            <div className='message-desc'>{checkoutSetting.messageDesc}</div>
          }
        </div>
        <div className="list-item__right">
          <Input
            type='textarea'
            placeholder={$fm('common_please_input', '请输入')}
            value={orderMessage}
            onChange={(e) => setOrderMessage(e.target.value?.substr(0, 300))}
            onBlur={() => {
              if (orderMessage) {
                setOrderMessageError('')
              }
            }}
            maxCharacterCount={300}
            showCount
          />
        </div>
      </div>
      {
        orderMessageError &&
        <div className="item-error-msg">{orderMessageError}</div>
      }
    </div>
  )
}

export default memo(CustomizedCheckout);