import styled from 'styled-components';

export const Style = styled.div`
  border-radius: 50%;
  position: relative;
  .flag-text {
    ${(props) => props.theme.getBaseSize(props.theme.h13)};
    font-weight: 400;
    padding: 2px 4px;
    background: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
    color: #fff;
    border-radius: ${(props) => props.theme.getSpace([12, 8])};
    position: absolute;
    z-index: 2;
    left: 18px;
    word-break: keep-all;
    padding-left: 6px;
  }

  .dot-text {
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      width: 3px;
      height: 3px;
      background: #fff;
      box-sizing: border-box;
    }
    .dot + .dot {
      margin-left: 4px;
    }
  }
  .profile-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-item {
    border: 1px solid #fff;
    &.default-profile-item {
      background: #f7f8fa;
      border: 1px dotted #ebedf0;
    }
  }
  &.big {
    height: ${(props) => props.theme.getSpace([44, 48])};
    width: ${(props) => props.theme.getSpace([44, 48])};
    img,
    .profile-item,
    .profile-box {
      height: ${(props) => props.theme.getSpace([44, 48])};
      width: ${(props) => props.theme.getSpace([44, 48])};
      border-radius: 50%;
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.normal {
    width: ${(props) => props.theme.getSpace([40, 44])};
    height: ${(props) => props.theme.getSpace([40, 44])};
    img,
    .profile-item,
    .profile-box {
      height: ${(props) => props.theme.getSpace([40, 44])};
      width: ${(props) => props.theme.getSpace([40, 44])};
      border-radius: 50%;
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.small {
    img,
    .profile-item,
    .profile-box {
      height: ${(props) => props.theme.getSpace([38, 40])};
      width: ${(props) => props.theme.getSpace([38, 40])};
      border-radius: 50%;
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.mini {
    img,
    .profile-item,
    .profile-box {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
