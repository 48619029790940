import Style from './style';

const EmptyTip = ({ intl }) => {
  const emptyText = intl.formatMessage({
    id: 'no_commission_bill',
    defaultMessage: '暂无佣金账单',
  });

  return (
    <Style>
      <div className='table-empty'>
        {/* {emptyLabel ||
                    intl.formatMessage({
                        id: '32452712d4d2a3d29865bc764a22ecfe',
                        defaultMessage: '没有更多数据了',
                    })} */}
        <img src='https://b.yzcdn.cn/public_files/3c177d9fde5535c3b94e491d0474bf88.png' alt={emptyText} />
        <p className='no_commission_bill'>{emptyText}</p>
      </div>
    </Style>
  );
};
export default EmptyTip;
