import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

const PICKER_TYPE = {
  year: 'year',
  month: 'month',
  date: 'date',
  extend: 'extend',
};

interface IPickerDate {
  year: number;
  month: number | undefined;
  date: number | undefined;
  extend?: string;
}

/**
 * 有两种类型去定义时间选择器的可选值：
 * 1、设置最大值和最小值
 * 2、设置自定义可选时间数据，也就是没有规律的数据，这个主要针对的是同城配送和到店自提，选择时间是需要这个功能
 * isCustom 表示用自定义时间数据
 * customCalendar 表示自定义数据
 *
 */

const usePickerAction = (props) => {
  const { value, extendValue, disabledDate, onChange = () => {} } = props;

  const [calendarPickerVisible, setCalendarVisible] = useState(true);
  const [yearPickerVisible, setYearPickerVisible] = useState(false);
  const [monthPickerVisible, setMonthPickerVisible] = useState(false);
  const [current, setCurrent] = useState<IPickerDate>({
    year: value.year(),
    month: value.month() + 1,
    date: undefined,
    extend: extendValue,
  });

  const dateList = useMemo(() => {
    const lastDate = moment([current.year, current.month], 'YYYY-MM').daysInMonth(); // 这个月最后一天
    const firstDay = moment([current.year, current.month - 1, 1]).day(); // 第一天对应哪周
    const list = Array.from({ length: firstDay }, () => 0).concat(Array.from({ length: lastDate }, (v, i) => ++i));

    return list;
  }, [current]);

  const todayBtnDisabled = disabledDate({
    year: moment().year(),
    month: moment().month() + 1,
    date: moment().date(),
  });

  const preMonthDisabled = useMemo(() => {
    let disabled = false;
    const val = current.month - 1;

    if (val >= 1 && val <= 12) {
      disabled = disabledDate({ year: current.year, month: val });
    } else if (val < 1) {
      disabled = disabledDate({ year: current.year - 1, month: 12 });
    }

    return disabled;
  }, [current]);

  const nextMonthDisabled = useMemo(() => {
    let disabled = false;
    const val = current.month + 1;

    if (val >= 1 && val <= 12) {
      disabled = disabledDate({ year: current.year, month: val });
    } else if (val > 12) {
      disabled = disabledDate({ year: current.year + 1, month: 1 });
    }

    return disabled;
  }, [current]);

  useEffect(() => {
    const year = value.year();
    const month = value.month() + 1;
    const date = value.date();
    const extend = extendValue;

    if (!disabledDate({ year, month, date, extend })) {
      setCurrent({
        year,
        month,
        date,
        extend,
      });
    }
  }, [extendValue]);

  useEffect(() => {
    onChange({ ...current, value: moment([current.year, current.month - 1, current.date]).format('YYYY-MM-DD') });
  }, [current]);

  const onSelect = (type, val) => {
    if (type === PICKER_TYPE.year) {
      setCurrent({ year: val, month: undefined, date: undefined });
      setMonthPickerVisible(true);
      setYearPickerVisible(false);
    } else if (type === PICKER_TYPE.month) {
      if (val < 1) {
        setCurrent({ year: current.year - 1, month: 12, date: undefined });
      } else if (val > 12) {
        setCurrent({ year: current.year + 1, month: 1, date: undefined });
      } else {
        setCurrent({ year: current.year, month: val, date: undefined });
      }
      setCalendarVisible(true);
      setMonthPickerVisible(false);
    } else if (type === PICKER_TYPE.date) {
      setCurrent({ year: current.year, month: current.month, date: val });
    } else if (type === PICKER_TYPE.extend) {
      setCurrent((old) => ({ ...old, extend: val }));
    }
  };

  const openSubPanel = (e, type) => {
    setCalendarVisible(false);
    if (type === PICKER_TYPE.year) {
      setYearPickerVisible(true);
    } else if (type === PICKER_TYPE.month) {
      setMonthPickerVisible(true);
    }
    e.stopPropagation();
  };

  return {
    calendarPickerVisible,
    yearPickerVisible,
    monthPickerVisible,

    openSubPanel,

    current,
    setCurrent,
    onSelect,
    todayBtnDisabled,
    preMonthDisabled,
    nextMonthDisabled,
    dateList,
  };
};

export default usePickerAction;
