import React, { useMemo } from 'react';
import { Style } from './style';
import { useIntl } from 'react-intl';
import { DELIVERY_METHOD, STEP_MAP } from '../../const';
import cn from 'classnames';

const intlMap = {
  [DELIVERY_METHOD.shipping_method]: 'a8d42c43bc8c48908ece39370130d721',
  [DELIVERY_METHOD.local_delivery]: 'a8d42c43bc8c48908ece39370130d721',
  [DELIVERY_METHOD.pickup]: '16618fd6664043e09f239532067dda09',
};
const DeliveryMethodTabs = (props) => {
  const { deliveryInfo = {}, checkoutPreview, step, isSinglePageOrder } = props;
  const deliveryMethods = deliveryInfo.deliveryMethods || [];
  const selectedDeliveryMethod = deliveryInfo?.selectedDeliveryMethod;

  const intl = useIntl();
  const $t = (id, defaultMessage = 'default', params = {}) => {
    if (!id) return '';
    return intl.formatMessage({ id, defaultMessage }, params);
  };

  // 修改配送方式
  const changeDeliveryMethod = (method) => {
    // 商家配送 包含 同城配送
    if (method === DELIVERY_METHOD.shipping_method && selectedDeliveryMethod === DELIVERY_METHOD.local_delivery) return;
    if (method === selectedDeliveryMethod) return;
    checkoutPreview({
      deliveryInfo: {
        selectedDeliveryMethod: method,
      },
    });
  };

  // 只有一种的发货方式情况下, 隐藏 tab
  if (deliveryMethods.length === 1) return null;
  // 分页式, 只在第一步展示
  if (!isSinglePageOrder && step !== STEP_MAP.INFO) return null;

  return (
    <Style>
      <div className='delivery-method-tabs'>
        <div className='delivery-method-tabs-wrap'>
          {deliveryMethods.map((item) => {
            return (
              <div
                onClick={() => changeDeliveryMethod(item)}
                className={cn('delivery-method-tabs-item', {
                  active:
                    item === selectedDeliveryMethod ||
                    (item === DELIVERY_METHOD.shipping_method &&
                      selectedDeliveryMethod === DELIVERY_METHOD.local_delivery),
                })}
              >
                {$t(intlMap[item] || '')}
              </div>
            );
          })}
        </div>
      </div>
    </Style>
  );
};

export default DeliveryMethodTabs;
