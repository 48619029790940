export const auditStatus = {
  pending_audit: 'pending_audit',
  pending_activate: 'pending_activate',
  rejected: 'rejected',
  activated: 'activated',
};

export const affiliateModeStatus = {
  auto: 'auto', // 自动成为分销员
  need_review: 'need_review', // 需要审核
};

export const affiliateStatus = {
  pending_audit: 'pending_audit', // 待审核
  pending_activate: 'pending_activate', // 待激活
  rejected: 'rejected', // 已拒绝
  activated: 'activated', // 已通过
};

export enum AffiliateFissionLevel {
  One = 1,
  Two = 2,
}
