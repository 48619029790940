/**
 * 用户相关的api接口封装
 */
import BaseApi from './base';

class CouponsApi extends BaseApi {
  // 获取优惠券列表
  getCouponsList(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.discount.controller.web.PromoCodeController/searchPromoCodeWithPage',
      params,
    );
  }
  // 根据活动id获取优惠券详细
  getCouponDetailById(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.ump.discount.controller.web.PromoCodeController/getPromoCodeDescription',
      params,
    );
  }

  // 领取优惠券
  takePromoCode(params) {
    return this._post('/api/soa/com.youzan.i18n.ump.discount.controller.web.PromoCodeController/takePromoCode', params);
  }
}

export default new CouponsApi();
