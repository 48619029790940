import styled from 'styled-components';

export default styled.div`
  .recruit-page {
    margin: 0 auto;
    max-width: 680px;
  }

  .recruit-page-container {
    padding: 40px;
    box-shadow: 0px 0px 16px 0px rgba(71, 108, 240, 0.2);
    margin-top: 20px;
    padding-bottom: 74px;
  }

  @media screen and (max-width: 750px) {
    .recruit-page-container {
      margin-top: 0px;
      box-shadow: none;
      padding: 0 16px;
      padding-top: 40px;
    }
  }
`;
