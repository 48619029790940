/* eslint-disable prettier/prettier */
export enum OrderType {
  Member = 'member', // 会员
  Group = 'group', // 商品拼团
  Normal = 'normal', // 普通商品支付
  AppointmentService = 'appointment_service', // 预约订单
  PointsExchange = 'points_exchange', // 积分兑换
}
// 物流包裹状态
export const LogisticsStatus = {
  ready_for_place_order: {
    id: '10ccd3a5637e41a78bfe8cd14b826262',
    defaultMessage: '待下单',
  },
  place_order_failure: {
    id: 'bbc1fcb29b004959bc8b9edb8f7a38a9',
    defaultMessage: '下单失败',
  },
  ready_for_pickup: {
    id: '78d47493e931482b98de6ac6173216db',
    defaultMessage: '待取货',
  },
  picked_up: {
    id: '627f6cbe01314854868f5edd6e15aa1f',
    defaultMessage: '已取货',
  },
  in_transit: {
    id: '519b6faa41174a829d37f6ba3b57cba9',
    defaultMessage: '运输中',
  },
  delivered: {
    id: 'e904d9a2c1244eac9fd71f2e520298fc',
    defaultMessage: '已送达',
  },
  delivery_failure: {
    id: 'd369a53bc61044a3a8cd3d0451589e80',
    defaultMessage: '配送异常',
  },
  cancelling: {
    id: '1a0be3d3a6664956897167adb32b7a42',
    defaultMessage: '取消中',
  },
  cancel_failure: {
    id: '34aea014405b407cb07f486c2c430f50',
    defaultMessage: '取消异常',
  },
  cancel: {
    id: '8537855511464194ac8a2751f4ac55d4',
    defaultMessage: '已取消',
  },
};

// 自提单的一些状态code
export enum OrderSendStatusEnum {
  FULFILLED = 'fulfilled', // 已发货
  NULL = 'none', // 未发货
  PARTIALLY = 'partial', // 部分发货
  NOT_REQUIRES = 'not_requires', // 无需发货
  HOLD = 'hold', // 暂时不发货 -比如待拼团状态
  CONFIRMED = 'confirmed', // 已确认
  WAIT_PREPARE = 'wait_prepare', // 待备货
  WAIT_PICKUP = 'wait_pickup', // 待取货
}

// 自提单页面展示状态和提示语
export const PickupOrderStatusConfig = {
  [OrderSendStatusEnum.WAIT_PREPARE]: {
    id: OrderSendStatusEnum.WAIT_PREPARE,
    name: '待备货',
    key: '8c95288cdd2743d9a5bbc85e4bee429b',
    tipMessage: {
      id: '7d4345667bae44458247e481a7492195',
      defaultMessage: '商家备货中，如果商家长时间未完成备货，建议联系商家咨询情况',
    },
  },
  [OrderSendStatusEnum.WAIT_PICKUP]: {
    id: OrderSendStatusEnum.WAIT_PICKUP,
    name: '待取货',
    key: '78d47493e931482b98de6ac6173216db',
    tipMessage: {
      id: '5c0a9de7befc449d82c25c71a8d351cc',
      defaultMessage: '商家已完成备货，请按预约时间前往自提店，凭提货码取货',
    },
  },
  [OrderSendStatusEnum.CONFIRMED]: {
    id: OrderSendStatusEnum.CONFIRMED,
    key: '279d0d7123ae4163828c0a5ec9a33ab3',
    name: '已完成',
    tipMessage: {
      id: '7cb03dd8005b46999955ca08c8f85626',
      defaultMessage: '订单已完成收货，如果有疑问，建议联系商家咨询情况',
    },
  },
};

export const DELIVERY_METHOD = {
  local_delivery: 'local_delivery',
  shipping_method: 'shipping_method',
  pickup: 'pickup',
};

enum ORDER_STATUS {
  WAIT_PAYMENT = 'wait_payment', // "待付款"
  UNCONFIRMED = 'unconfirmed', //  "待确认"
  PAYING = 'paying', // "支付中"
  WAITING = 'waiting', // "等待中" // 已付款暂不发货的三方支付
  WAIT_FULLFILLMENBT = 'wait_fulfillment', // "待发货, 已经生成履约单"
  CANCELED = 'canceled', // "已取消"
  WAIT_RECEIVED = 'wait_received', // "待收款"
  FULFILLED = 'fulfilled', // "已发货"
  PART_FULFILLED = 'partial_fulfillment', // "已部分发货"
  REFUNDING = 'refunding', // "退款中"
  REFUNDED = 'refunded', // "已退款"
  REFUND_FAILURE = 'refund_failure', // "退款失败"
  PAID = 'paid', // 已支付
  COMPLETED = 'completed', // 已完成
}

// 全部为虚拟商品的状态语
export const VirtualGoodsStatusDesc = {
  [ORDER_STATUS.FULFILLED]: {
    id: 'dbebe2753c124f78a5a29011077f8402',
    defaultMessage: '商品已发货，若未成功收到商品，请与我们联系。',
  },
  [ORDER_STATUS.COMPLETED]: {
    id: '8f91fc37752d478f9e3ba9454e54297c',
    defaultMessage: '订单已完成，如有问题，请与我们联系。',
  },
};

export enum LOGISTICS_CODE {
  UNIUNI = 'uniuni',
  DOORDASH = 'doordash',
}
