import React, { useContext } from 'react';
import { Icon } from 'components/base';
import { getIsOverStandard } from 'helper/shop';
import RootContext from '../../../../pages/context/root';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useMemberInfo } from './hook';
import { MemberStatus } from 'const/member';
import isEmpty from 'lodash/isEmpty';

import { getMemberGlobalStyle } from 'helper/member';
import { Style } from './style';

enum MemberSwitchType {
  Close = 'close',
  Open = 'open',
}

export const MemberInfo = (props) => {
  const intl = useIntl();
  const { history } = props;
  const { initialData } = useContext(RootContext);
  const globalStyle = getMemberGlobalStyle(initialData.memberThemeData); // 会员全局装修数据
  const kdtId = initialData?.kdtId;
  const versionCode = initialData?.packageInfo?.versionCode; // 店铺版本
  const isOverStandard = getIsOverStandard(versionCode);

  /** 全局设置 */
  const { label_bg: labelBg, label_text: labelText } = globalStyle;

  const { userInfo = {}, shopSetting = {} } = initialData;
  const customerId = userInfo?.customerId;
  const { currency } = shopSetting;
  const { memberStatus, textInfo = {} } = useMemberInfo({
    currency,
    kdtId,
    customerId,
    intl,
  });

  const memberPlanSwitch = memberStatus?.memberPlanInfo?.memberPlanSwitch === MemberSwitchType.Open;

  const onJumpToDetail = () => {
    history.push('/member/detail');
  };

  /**如果店铺版本不够 或者商家没有开启 或者开启了没配置 都不显示 */
  if (!isOverStandard || !memberPlanSwitch || isEmpty(memberStatus?.memberPlanInfo?.spreadText)) return null;
  return (
    <Style
      className={cn('member-info-content', {
        expire: memberStatus?.status === MemberStatus.Expire || memberStatus?.status === MemberStatus.Forbid,
      })}
      labelText={labelText}
      labelBg={labelBg}
      onClick={onJumpToDetail}
    >
      <div className='left-item'>
        <Icon className='iconic-icon' name='iconic-huiyuanbiaoqian' size='small' />
        <div className='text'>{textInfo?.text}</div>
      </div>

      <Icon className='iconic-icon' name='iconic-xiala' size='small' />
    </Style>
  );
};
