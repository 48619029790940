import styled from 'styled-components';

export const Style = styled.div`
  margin-top: ${(props) => props.theme.getSpace([10, 16])};
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
  color: #fff;
  padding: ${(props) => props.theme.getSpace([12, 16])};
  background-image: url('https://img01.yzcdn.cn/upload_files/2021/09/07/FlAnnM1mxPg12e8J_t8rNYlqzXVI.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position-x: ${(props) => props.theme.getSpace([160, 460])};
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${(props) => props.theme.getSpace([46, 55])};
    .title {
      display: flex;
      align-items: center;
    }
    .people-content {
      display: inline-flex;
      .participants {
        background: #fff;
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorSaleText, 1)};
        ${(props) => props.theme.getBaseSize(props.theme.h12)};
        padding-right: 2px;
        padding-left: 2px;
        border-radius: 2px;
        display: inline-block;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
    height: ${(props) => props.theme.getSpace([44, 48])};
    .title {
      ${(props) => props.theme.getBaseSize(props.theme.h12)};
      font-weight: 400;
    }
  }

  &.expired {
    align-items: center;
    background-color: #b2b2b2;
    color: #fff;
    .participants {
      color: #b2b2b2 !important;
    }
    .right {
      justify-content: center;
    }
  }
  .underline-price {
    .price-symbol,
    .price-integer,
    .price-decimal {
      ${(props) => props.theme.getBaseSize(props.theme.h11)};
    }
  }
  .cur-price {
    .price-symbol,
    .price-integer,
    .price-decimal {
      ${(props) => props.theme.getBaseSize(props.theme.h7)};
      font-weight: 400;
      color: #fff;
    }
  }
  .origin-price {
    text-decoration: line-through;
    margin-left: ${(props) => props.theme.getSpace([4, 8])};
    .price-symbol,
    .price-integer,
    .price-decimal {
      ${(props) => props.theme.getBaseSize(props.theme.h11)};
      font-weight: 500;
      color: #fff;
    }
  }
`;
