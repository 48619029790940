import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE } from './const';
import { IntlShape } from 'react-intl';
/**
 * com.youzan.i18n.settings.domain.core.valueobject.CustomizedCheckoutModuleEffectiveRange
 * 自定义结账模板组件生效范围
 */
interface ICustomizedCheckoutModuleEffectiveRange {
  /** 国家/区域适用范围列表. */
  countryRangeData?: any[];
  /** 国家/区域适用范围  all: 所有国家/地区  countries：指定国家/地区 */
  countryRange?: string;
  /** 模板组件id */
  moduleId?: number;
  /** 商品适用范围  all：所有商品  products：特定商品  collections：特定商品组 */
  goodsRange?: string;
  goodsRangeData?: any[];
  goodsCollectionRangeData?: any[];
}

/**
 * java.util.Map
 */
interface IMap {
  [key: string]: any;
}

/**
 * com.youzan.i18n.template.domain.valueobject.TemplateItem
 */
interface ITemplateItem {
  id?: number;
  /** 标签名 */
  label?: string;
  /** 占位符文案 */
  placeholder?: string;
  /** 模板组件id */
  moduleId?: number;
  /** 模板项类型 */
  type?: TEMPLATE_TYPE;
  // 前端定义的表单值
  value?: string;
  // 身份证正面照
  ID_CARD_IS_FRONT?: string;
  // 身份证反面照
  ID_CARD_IS_REVERSE?: string;
  errorMsg?: string;
}

/**
 * com.youzan.i18n.settings.admin.vo.CustomizedCheckoutFieldsVO.TemplateModuleVO
 */
export interface ITemplateModuleVO {
  /** 模板组件生效范围 */
  effectiveRange?: ICustomizedCheckoutModuleEffectiveRange;
  /** 模板组件个性化字段放这里，比如身份证正反面勾选 */
  extra?: IMap;
  /** 排序 */
  sort?: number;
  /** 模板组件id */
  moduleId?: number;
  /** 模板组件类型 */
  type?: CHECKOUT_RULE_TYPE;
  /** 模板项列表 */
  items: ITemplateItem[];
  /** 是否必填 */
  required?: number;
  visible?: boolean;
}

/**
 * com.youzan.i18n.settings.admin.vo.CustomizedCheckoutFieldsVO
 */
interface ICustomizedCheckoutFieldsVO {
  /** 模板组件列表 */
  modules?: ITemplateModuleVO[];
}

/**
 * com.youzan.i18n.settings.shared.vo.CheckoutSettingsVO
 */
export interface ICheckoutSettingsVO {
  /** 买家留言是否必填 */
  requiresMessage?: number;
  /** 结账页面类型，single_page：单页式，multi_page：分页式 */
  pageType?: string;
  /** 店铺id */
  kdtId?: number;
  /** 留言信息引导文案 */
  messageDesc?: string;
  /** 下单时是否需要填写联系邮箱 */
  requiresContactEmail?: number;
  /** 订单自动过期周期<br/>  min_15：15分钟;  min_30：30分钟;  day_1：1天;  day_2：2天;  day_5：5天;  mon_1：一个月;  none：不过期； */
  orderExpirePeriod?: string;
  /** 库存扣减时机;  payment:下单支付时  paid":支付成功时 */
  inventoryDeductTiming?: string;
  /** 是否需要邮编， required：需要，optional:可选 */
  requiresPostalCode?: string;
  /** 自定义结账模板数据 */
  customizedCheckoutFields?: ICustomizedCheckoutFieldsVO;
}

// 子组件模板
export interface IRuleTemplateVO {
  label: string;
  labelErrorMsg?: string;
  placeholder: string;
  type: TEMPLATE_TYPE | string;
}

// 自定义结算组件参数
export interface ICustomizedCheckoutProps {
  intl: IntlShape;
  $fm: any;
  token?: string;
  checkoutSetting: ICheckoutSettingsVO;
  customizedCheckoutData?: ITemplateModuleVO[];
  changeCustomizedCheckoutData(args: ITemplateModuleVO[]): void;
  orderMessage: string;
  setOrderMessage(args: string): void;
  orderMessageError: string;
  setOrderMessageError(args: string): void;
  lineItems: any;
  shippingAddress: any;
  requiresShipping: number;
  deliveryInfo: any;
}

// 自定义结算项
export interface ICustomizedCheckoutItemProps {
  intl: any;
  $fm: any;
  item: ITemplateModuleVO;
  index: number;
  handleChange(key: string, value: any): void;
}

// 订单留言
export interface IMessageSettingProps {
  messageDesc?: string;
  setMessageDesc(args: string): void;
  requiresMessage?: number;
  setRequiresMessage(args: number): void;
}

// 订单留言组件
export interface IOrderMessageProps {
  $fm: any;
  checkoutSetting: ICheckoutSettingsVO;
  orderMessage?: string;
  setOrderMessage(args: string): void;
  orderMessageError: string;
  setOrderMessageError(args: string): void;
}

export enum GoodsRangeEnum {
  all = 'all',
  products = 'products',
  collections = 'collections',
}

export enum CountryRangeEnum {
  all = 'all',
  countries = 'countries',
}