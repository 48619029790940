import React from 'react';
import FilterHeader from '../components/filter-header';
import FilterCategory from '../components/filter-category';
import FilterCategorySelect from '../components/filter-category-select';
import { Icon } from 'components/base';
import { useHorizontalFilter, useCollectionFilter } from '../hooks';
import { useIntl } from 'react-intl';
import Style from './style';
import cn from 'classnames';

const CollectionFilterHorizontal = (props) => {
  const { onSortChange, onFilterChange, sortList, settings, data } = props;
  const { style: filterStyle = 'style1' } = settings;
  const { updateCategoryData } = useCollectionFilter({ data, onFilterChange });
  const { horizontalData, isFilterCollapse, handleChangeCollapse } = useHorizontalFilter({ data });
  const intl = useIntl();
  return (
    <Style isHasFilter={horizontalData?.length !== 0}>
      <div className='collection-filter-horizontal'>
        {/* 样式二 */}
        {filterStyle === 'style2' ? (
          <div className='collection-filter-style-2'>
            <FilterHeader sortList={sortList} onSortChange={onSortChange} filterStyle={filterStyle} />
            <div className='filter-category-list'>
              {horizontalData.map((item, index) => {
                return (
                  <div
                    key={item?.filterItemId}
                    style={{
                      display: isFilterCollapse && index >= 4 ? 'none' : 'block',
                    }}
                  >
                    <FilterCategory isShowName data={item} settings={settings} onCategoryChange={updateCategoryData} />
                  </div>
                );
              })}
            </div>
            {horizontalData.length > 4 && (
              <div
                className={cn('collection-filter-horizontal-toggle', !isFilterCollapse ? 'is-filter-open' : '')}
                onClick={handleChangeCollapse}
              >
                {isFilterCollapse ? (
                  <span>
                    {intl.formatMessage({
                      id: '616f183984224a2581ac9649eb923be8',
                      defaultMessage: '展开更多',
                    })}
                    <Icon name='iconic-xiala1' />
                  </span>
                ) : (
                  <span>
                    {intl.formatMessage({
                      id: 'put_away',
                      defaultMessage: '收起',
                    })}
                    <Icon name='iconic-xiala1' />
                  </span>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className='collection-filter-style-1'>
            <FilterHeader sortList={sortList} onSortChange={onSortChange} filterStyle={filterStyle}>
              {horizontalData.map((item, index) => {
                return (
                  // 此处key 的index  用来处理更新后组件不重复渲染的问题
                  <FilterCategorySelect
                    key={index + '_' + item?.filterItemId}
                    data={item}
                    settings={settings}
                    filterStyle={filterStyle}
                    onCategoryChange={updateCategoryData}
                  />
                );
              })}
            </FilterHeader>
          </div>
        )}
      </div>
    </Style>
  );
};

export default CollectionFilterHorizontal;
