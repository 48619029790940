import React from 'react';
import { useIntl } from 'react-intl';
import { HtPrice } from 'cpn/goods-price';
import { ActivityStatusType } from 'const/group-buying';
import { CountDown } from 'components/page/group-buying/count-down';
import cn from 'classnames';
import { Style } from './style';

export const GroupDesc = ({ goodsDetail, currency, cart, onGroupByTimeDone }) => {
  const groupActivityDescription = goodsDetail?.groupActivityDescription ?? {};
  const { participateNum } = groupActivityDescription;
  const activityEndAt = groupActivityDescription?.activityEndAt;
  const activityStatus = goodsDetail?.activityStatus;
  const isExpired = activityStatus === ActivityStatusType.Expired; // 拼团活动是否过期
  const originPrice = cart?.variantId ? cart.compareAtPrice : goodsDetail?.compareAtPrice; // 原价
  const curPrice = cart?.variantId ? cart.price : goodsDetail?.minPrice; // 拼团价
  const intl = useIntl();

  return (
    <Style className={cn('group-buying-desc', { expired: isExpired })}>
      <div className='left'>
        <div className='title'>
          <HtPrice price={curPrice} currency={currency} className='cur-price'></HtPrice>
          <HtPrice price={originPrice} currency={currency} className='origin-price'></HtPrice>
        </div>
        {!!participateNum && (
          <div className='people-content'>
            <span className='participants'>
              {intl.formatMessage(
                {
                  id: 'd39eaca590cd472797d774612dd8a927',
                  defaultMessage: `${participateNum} 人团 `,
                },
                {
                  num: participateNum,
                },
              )}
            </span>
          </div>
        )}
      </div>

      {/* 活动没过期且activityEndAt存在，若activityEndAt不存在说明是长期活动 */}
      {!isExpired && activityEndAt && (
        <div className='right'>
          <span className='title'>
            {intl.formatMessage({
              id: '695af2a94b5e4d06bf22bcb0f6badbc2',
              defaultMessage: 'End in',
            })}
          </span>
          <CountDown
            className='detail-count-down'
            styleType='style3'
            timeEndAt={activityEndAt}
            onTimeDone={onGroupByTimeDone}
          />
        </div>
      )}

      {/* 活动已过期 */}
      {isExpired && (
        <div className='right'>
          <span className='title'>
            {intl.formatMessage({
              id: 'c70b89a1b48c4c9da051788b252d5f31',
              defaultMessage: 'Activity has ended',
            })}
          </span>
        </div>
      )}
    </Style>
  );
};
