import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  .fission-image-wrapper {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #646566;
    position: relative;
    .fission-image {
      display: block;
      width: 197px;
      height: 293px;
    }
    .you {
      position: absolute;
      top: 36px;
      left: 93px;
      transform: translateX(-50%);
    }
    .buddy_1 {
      position: absolute;
      top: 156px;
      left: 93px;
      transform: translateX(-50%);
    }
    .buddy_2 {
      position: absolute;
      top: 275px;
      left: 93px;
      transform: translateX(-50%);
    }
    .circle-block {
      box-sizing: border-box;
      min-width: 23px;
      min-height: 23px;
      position: absolute;
      padding: 4px;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #c87a00;
      background: linear-gradient(135deg, #ffea7a 0%, #ffa959 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transform: translateX(-50%);
      &.oval {
        border-radius: 11.5px;
      }
      &.first-percent {
        top: 78px;
        left: 12px;
      }
      &.sub-first-percent {
        top: 200px;
        left: 150px;
      }
      &.sub-second-percent {
        top: 200px;
        left: 193px;
      }
    }
  }
`;
