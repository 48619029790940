import styled from 'styled-components';

export default styled.section`
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
  height: 100%;
  .add-shopping-car-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 400px;
    box-shadow: 0px 2px 24px 0px rgba(209, 213, 219, 0.4);
    overflow: auto;
  }
  @media screen and (max-width: 750px) {
    .add-shopping-car-container {
      position: fixed;
      align-items: flex-end;
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      left: 0;
    }
  }
`;
