import React from 'react';
import { GoodsTitle } from 'cpn/goods-item';
import { Icon } from 'components/base';
import { HtPrice } from 'cpn/goods-price';
import GoodsImage from '../../goods/goods-image';

import Style, { PriceStyle, TagStyle } from './style';

const Price = ({ price, originPrice, currency, gColorBtnPrimaryBg, gColorText, gLabelText, gLabelBg }) => {
  return (
    <PriceStyle gColorBtnPrimaryBg={gColorBtnPrimaryBg} gColorText={gColorText}>
      <div className='price'>
        <HtPrice price={price} currency={currency} />
        <TagStyle className='tag' gLabelText={gLabelText} gLabelBg={gLabelBg}>
          <Icon className='iconic-icon' name='iconic-huiyuanbiaoqian' size='small' />
        </TagStyle>
      </div>
      {originPrice > price && (
        <div className='origin-price'>
          <HtPrice price={originPrice} currency={currency} />
        </div>
      )}
    </PriceStyle>
  );
};

export const MemberGoodsItem = ({ item, currency, gLabelText, gLabelBg, gColorText, gColorBtnPrimaryBg, history }) => {
  const { imageUrl = '', title = '', price, discountValue, handle } = item || {};
  const imageInfo = {
    goodsInfo: item,
    coverImgUrl: imageUrl,
    hoverImgUrl: imageUrl,
    imageRatio: '1-1',
  };

  return (
    <Style
      gColorText={gColorText}
      onClick={() => {
        window.location.href = `${window.location.origin}/goods/${handle}`;
      }}
    >
      <div className='info'>
        <GoodsImage {...imageInfo} className='member-goods-image' />
        <GoodsTitle title={title} />
      </div>
      <Price
        price={discountValue}
        originPrice={price}
        currency={currency}
        gColorBtnPrimaryBg={gColorBtnPrimaryBg}
        gColorText={gColorText}
        gLabelText={gLabelText}
        gLabelBg={gLabelBg}
      />
    </Style>
  );
};
