import styled, { css } from 'styled-components';

const getFilterStyleCSS = (props) => {
  if (props?.filterStyle === 'style1') {
    return css`
      .filter-header-left {
        .filter-header-name:after {
          border-right: 1px solid ${props.theme.colorBodyTexts?.['20']};
        }
      }
    `;
  }
  if (props?.filterStyle === 'style2') {
    return css`
      border-bottom: 1px solid ${props.theme.colorBodyTexts?.['20']};
      padding-bottom: 24px;
    `;
  }
};

export default styled.div`
  width: 100%;
  .filter-header {
    display: flex;
    justify-content: space-between;
    ${(props) => getFilterStyleCSS(props)}
    align-items: flex-start;
    .filter-header-left {
      display: inline-flex;
      align-items: flex-start;
      .filter-header-name {
        font-size: 16px;
        font-weight: 500;
        color: var(--color_body_text);
        margin-right: 24px;
        display: inline-flex;
        align-items: center;
        word-break: keep-all;
        &:after {
          content: '';
          display: inline-block;
          height: 14px;
          padding-left: 24px;
        }
      }
    }

    .filter-header-right {
      display: inline-flex;
      align-items: center;
      gap: 16px;
      .header-right-sort-title {
        display: inline-flex;
        align-items: center;
      }
      .sort-item-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .sort-item {
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .header-right-sort,
      .header-right-filter {
        display: flex;
        align-items: center;
        word-break: keep-all;
        svg {
          width: 16px;
          height: 16px;
          fill: var(--color_text);
        }
      }
      .header-right-sort svg {
        display: none;
      }
      .header-right-filter {
        display: none;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .filter-header {
      .filter-header-right {
        .header-right-filter {
          display: flex;
        }
        .header-right-sort svg {
          display: inline;
        }
      }
      .header-right-sort .zenticon {
        display: none;
      }
    }
  }
`;
