import React, { useEffect, useState } from 'react';
import distributionApi from '../../../../api/distribution';
import DistributionLogo from '../distribution-logo';
import Back from '../back';
import Style from './style';

const RecruitPage = (props) => {
  const { children, title, history, style, shopName } = props;
  const [logo, setLogo] = useState('');
  useEffect(() => {
    distributionApi
      .getRecruitingPoster({
        kdtId: window?.global_data?.kdtId,
        posterType: 'personal_center',
      })
      .then((res) => {
        const { pcLogo } = res || {};
        setLogo(pcLogo);
      });
  }, []);

  return (
    <Style>
      <div className='recruit-page' style={style}>
        <DistributionLogo shopName={shopName} logo={logo} />
        <div className='recruit-page-container'>
          {title && <Back history={history} title={title} />}
          {children}
        </div>
      </div>
    </Style>
  );
};

export default RecruitPage;
