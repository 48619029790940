import styled from 'styled-components';

export default styled.div`
  /* margin-top: 20px; */

  &.item-block-item {
    cursor: pointer;
    .main-title {
      display: flex;
      align-items: center;

      .promotion-desc-icon {
        width: 16px;
        height: 16px;
        fill: rgba(var(--color_body_text_rgb), 0.8);
        margin-left: 4px;
        transition: all 0.3s;
        &.promotion-desc-icon-active {
          transition: all;
          transform: rotate(180deg);
        }
      }
    }
  }

  .promotion-desc-content {
    margin-top: 12px;
    padding: 12px;
    background: ${(props) => props?.theme?.colorBodyTexts?.['4']};
    .promotion-desc-item {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .promotion-desc-item-left {
        .promotion-desc-item-name {
          color: var(--color_body_text);
        }
        .promotion-desc-item-text {
          margin-top: 4px;
          color: rgba(var(--color_body_text_rgb), 0.4);
        }
      }
      .promotion-desc-item-right {
        font-size: 12px;
        color: var(--color_body_text);
      }
    }
    .promotion-desc-item + .promotion-desc-item {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid var(--color_borders);
    }
  }
`;
