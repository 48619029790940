/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { BlockLoading, Form, FormStrategy, Validators } from 'zent';
import FormFileds from '../form-fileds';
import Footer from '../footer';
import userApi from '../../../../api/user';
import isEmpty from 'lodash/isEmpty';
import UserInfo from 'components/page/account/user-info';
import { buriedPoint } from '../../../../util/log-buried-point';
import AddressItem from '../address-item';
import Style from './style';
// import { useRootContext } from 'client/pages/context/root';
import AddressInput from './address-input';
import LocalStorage from 'utils/local-storage';

import { SAVE_USER_TEMP_ADDRESS } from 'utils/local-storage/config';
import { ContextOrder } from '../../../context/context-order';
import * as cartHelper from 'helper/cart';
import AddressEditDialog from './address-edit-dialog';
import AddressChangeDialog from './address-change-dialog';
import AddressWarnTip from '../address-warn-tip';
import UserForm from '../user-form';

const AddressForm = (props) => {
  const intl = useIntl();
  const { initialData, needShip, setAddressForm, checked, setChecked, openAddressDialog = false } = props;
  const {
    checkoutPreview,
    buyerInfo,
    shippingAddress,
    orderInfo,
    lineItems = [],

    isSinglePageOrder,
    checkoutSetting,
    isExistRadiusLocalDelivery,
    emailReg,

    onNext,
    orderLoading = false,
  } = useContext(ContextOrder);

  const { userInfo = {} } = window?.global_data || {};

  const isLogin = userInfo && !isEmpty(userInfo);
  const hasAddress =
    !!shippingAddress?.phone &&
    !!shippingAddress?.firstName &&
    !!shippingAddress?.lastName &&
    !!shippingAddress?.countryCode &&
    !!shippingAddress?.address1;
  const showAddressWarnTip = isExistRadiusLocalDelivery && (!shippingAddress?.latitude || !shippingAddress?.longitude); // 是否显示输入精准地址的提示，TODO 还需要获取当前店铺配置，只有店铺配置了【同城配送-按距离】才展示
  const showCallingTip = !shippingAddress?.callingCodes || !shippingAddress?.callingArea;
  const showAddressTip = showAddressWarnTip || showCallingTip;

  const form = Form.useForm(FormStrategy.View);
  const { kdtId } = initialData || {};

  const [selectVisible, setSelectVisible] = useState(false);
  const [editAddressVisible, setEditAddressVisible] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const initUserAddress = () => {
    if (!userInfo?.customerId) {
      return;
    }
    return userApi
      .getAddressesByCustomerId({
        customerId: userInfo?.customerId,
        kdtId,
      })
      .then((list) => {
        setAddressList(list);
        if (!hasAddress && list?.length) {
          // 重新计算一下当前选中的地址
          checkoutPreview();
        }
      });
  };

  useEffect(() => {
    setAddressForm && setAddressForm(form);
    if (openAddressDialog && isLogin) {
      setSelectVisible(true);
    } else if (openAddressDialog) {
      setEditAddressVisible(true);
    }
  }, []);

  useEffect(() => {
    if (needShip) {
      // 登录后，并且订单已经初始化后，进行地址的初始化
      if (isLogin && orderInfo?.token) {
        initUserAddress();
      } else if (orderInfo?.token && !hasAddress) {
        // 非登录无地址情况下，尝试去缓存里拿
        const localAddress = LocalStorage.get(SAVE_USER_TEMP_ADDRESS);
        checkoutPreview({ shippingAddress: localAddress });
      }
    } else if (!buyerInfo?.customerId && isLogin && orderInfo?.token) {
      checkoutPreview({ buyerInfo: { customerId: userInfo?.customerId, customerNo: userInfo?.customerNo } });
    }
  }, [isLogin, orderInfo?.token, hasAddress]);

  // useEffect(() => {
  //     // 回填邮箱
  //     if (buyerInfo?.customerNo) {
  //         form.patchValue({ customerNo: buyerInfo?.customerNo });
  //     }
  // }, [buyerInfo?.customerNo]);

  // const onLoginOut = () => {
  //     if (window && window?.gapi) {
  //         const auth2 = window?.gapi.auth2.getAuthInstance();
  //         if (auth2) {
  //             auth2.signOut();
  //         }
  //     }
  //     /** 退出登录，删除不必要的信息 */
  //     userApi.logout().then(() => {
  //         cartHelper.notifyCartCountChange();
  //         window.location.reload();
  //     });
  // };

  const onChoseClick = () => {
    if (isLogin) {
      setSelectVisible(true);
    }
  };

  const filterAddress = (address) => {
    const newAddress = { ...address };
    if (!newAddress.province) {
      delete newAddress.province;
      delete newAddress.provinceCode;
    }
    return newAddress;
  };

  const saveAddress = (shippingAddress) => {
    // 地址保存和缓存逻辑
    const params = {
      ...shippingAddress,
      kdtId,
      customerId: userInfo?.customerId,
      isDefault: Number(checked),
    };
    if (isLogin) {
      // 通过表单获取的地址表示是手写的，直接做新增操作
      userApi.createAddress(params);
    } else {
      if (!checked) LocalStorage.remove(SAVE_USER_TEMP_ADDRESS);
      if (checked) LocalStorage.set(SAVE_USER_TEMP_ADDRESS, shippingAddress, -1);
    }
  };

  const onOrderAddressChange = (params) => {
    checkoutPreview({ shippingAddress: { ...shippingAddress, ...params } });
  };

  const onAddressSubmit = (address) => {
    saveAddress(address);
    onOrderAddressChange(address);
  };

  const onSubmit = () => {
    const data = form.getValue();
    if (data.phone && needShip) {
      const address = filterAddress({
        ...data,
      });
      // 保存地址到订单
      saveAddress(address);
      checkoutPreview({ shippingAddress: { ...shippingAddress, ...address } }).then(() => {
        if (!isSinglePageOrder) {
          // 多页下单跳转下一步
          onNext();
        }
      });
    } else if (!isSinglePageOrder) {
      // 多页下单跳转下一步
      onNext();
    }

    buriedPoint(
      'pay',
      {
        source: '',
      },
      {
        name: '继续付款',
        eventType: 'click',
      },
    );
  };

  const addressProps = {
    intl,

    isLogin,
    setChecked,
    checked,
    needShip,
    checkoutSetting,
    currentAddress: shippingAddress,
    isExistRadiusLocalDelivery,
  };
  // const { setLoginDialogInfo } = useRootContext();

  return (
    <Style>
      <UserForm
        intl={intl}
        form={form}
        userInfo={userInfo}
        orderInfo={orderInfo}
        buyerInfo={buyerInfo}
        checkoutPreview={checkoutPreview}
        emailReg={emailReg}
      />
      {/* <div className='base-login-info-wrapper'>
                {isLogin && (
                    <UserInfo
                        {...userInfo}
                        className='pay-user-info'
                        rightClick={onLoginOut}
                        btnText={intl.formatMessage({ description: '退出', id: 'account.me.logout' })}
                    />
                )}

                <Form form={form} className='address-form'>
                    {!isLogin && (
                        <div>
                            <div className='title'>
                                <FormattedMessage id='order.address.contact' />
                                {!isLogin && (
                                    <div className='login-guide'>
                                        {intl.formatMessage({
                                            defaultMessage: '已有帐号',
                                            id: 'login.has_account',
                                        })}
                                        ? &nbsp;
                                        <span
                                            className='login-link'
                                            onClick={() => {
                                                // TODO
                                                setLoginDialogInfo({
                                                    visible: true,
                                                });
                                            }}
                                        >
                                            {intl.formatMessage({ defaultMessage: '登录', id: 'action.login' })}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <FormFileds
                                name='customerNo'
                                disabled={orderInfo?.isTriggeredPay}
                                onBlur={(e) => {
                                    buriedPoint('checkout_fill_address_email');
                                    const userEmail = e?.target?.value || form?.getValue()?.customerNo;
                                    checkoutPreview({
                                        buyerInfo: { customerNo: userEmail, customerId: null },
                                    });
                                }}
                                validators={[
                                    Validators.required(
                                        intl.formatMessage({
                                            id: 'order.address.input_please_email',
                                            defaultMessage: '请输入电子邮件',
                                        }),
                                    ),
                                    Validators.pattern(
                                        emailReg,
                                        intl.formatMessage({
                                            id: 'order.address.input_right_email',
                                            defaultMessage: '请输入正确的电子邮件',
                                        }),
                                    ),
                                    Validators.maxLength(
                                        80,
                                        intl.formatMessage(
                                            {
                                                id: '5f7b66782d9f4922b95cfbdf444f56a7',
                                                defaultMessage: '超出最大长度80',
                                            },
                                            {
                                                num: 80,
                                            },
                                        ),
                                    ),
                                ]}
                                label={intl.formatMessage({
                                    id: 'order.address.email',
                                    defaultMessage: '电子邮件',
                                    description: '电子邮件',
                                })}
                            />
                        </div>
                    )}
                </Form>
            </div> */}
      {needShip && (
        <BlockLoading icon='circle' loading={!orderInfo?.token}>
          {orderInfo?.token && !hasAddress ? (
            <div className='address-form-wrapper'>
              <div className='title'>
                {intl.formatMessage({
                  defaultMessage: '收货地址',
                  id: 'setting.logistics.receive.goods_address',
                })}
              </div>
              <AddressInput
                {...addressProps}
                showConfirm={isSinglePageOrder}
                form={!isSinglePageOrder ? form : undefined}
                onConfirm={!isSinglePageOrder ? onSubmit : onAddressSubmit}
              />
            </div>
          ) : (
            <div className='address-form-wrapper'>
              <AddressItem
                address={shippingAddress}
                isLogin={isLogin}
                onClick={onChoseClick}
                isCurrent
                intl={intl}
                setEditAddressVisible={setEditAddressVisible}
                icon='#iconic-xiala'
                isNotEdit={orderInfo?.isTriggeredPay}
                extendTip={
                  showAddressTip ? (
                    <AddressWarnTip
                      text={
                        showAddressWarnTip
                          ? showCallingTip
                            ? intl.formatMessage({
                                id: '55b6dfd1492b48969c843257c37b3ffe',
                                defaultMessage: '区号未填写，地址无法精准定位，请修改',
                              })
                            : intl.formatMessage({
                                id: '836100caf99e4b848e39bc5e53846ece',
                                defaultMessage: '地址无法精准定位，请修改地址',
                              })
                          : intl.formatMessage({
                              id: '489d092d981e4ae2a0ce551d473e8a45',
                              defaultMessage: '区号未填写，请补充',
                            })
                      }
                      onClick={(e) => {
                        setEditAddressVisible(true);
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              />
            </div>
          )}
        </BlockLoading>
      )}
      <Form form={form} onSubmit={onSubmit} className='address-form'>
        {!isSinglePageOrder && (
          <Footer
            buttonTxt={intl.formatMessage({ defaultMessage: '继续', id: 'store.theme.continue' })}
            disabled={!lineItems?.length}
            loading={orderLoading}
          />
        )}
      </Form>

      <AddressChangeDialog
        intl={intl}
        visible={selectVisible}
        onClose={() => setSelectVisible(false)}
        onConfirm={onOrderAddressChange}
        addressList={addressList}
        onRefresh={initUserAddress}
        {...addressProps}
      />
      <AddressEditDialog
        intl={intl}
        className='address-select-dialog'
        visible={editAddressVisible}
        onClose={() => {
          setEditAddressVisible(false);
        }}
        addressList={addressList}
        onConfirm={onOrderAddressChange}
        onRefresh={initUserAddress}
        {...addressProps}
      />
    </Style>
  );
};

export default AddressForm;
