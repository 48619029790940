import React, { useState } from 'react';
import { Checkbox } from 'zent';
import ClearSelectedBtn from '../clear-selected-btn';
import Style from './style';

const CategoryCheckbox = ({ direction, settings, data, activeOptionIds, onCategoryChange }) => {
  const { layout } = settings;

  const { filterItemOptions, filterItemTextStyle } = data || {};
  const [checkedList, setCheckedList] = useState(activeOptionIds || []);

  const handleChange = (list = []) => {
    setCheckedList(list);
    const activeOption = filterItemOptions?.filter((item) => list.includes(item?.value));
    console.log('CategoryCheckbox-change', list);
    onCategoryChange && onCategoryChange({ ...data, activeOptionIds: list, activeOption });
  };
  return (
    <Style layout={layout} direction={direction} filterItemTextStyle={filterItemTextStyle}>
      <div className='category-item-checkbox'>
        <Checkbox.Group className='filter-category-items' value={checkedList} onChange={handleChange}>
          {filterItemOptions.map((item) => {
            return (
              <Checkbox className='filter-category-item-name' key={item?.id} value={item?.id}>
                {item?.name}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        {direction === 'column' && !!filterItemOptions?.length && (
          <ClearSelectedBtn
            disabled={!checkedList?.length}
            onClear={() => {
              handleChange([]);
            }}
          />
        )}
      </div>
    </Style>
  );
};

export default CategoryCheckbox;
