import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
  .pick-up-person-dialog {
    .zent-dialog-r-body {
      padding: 0 !important;
    }
  }
`;

export const Style = styled.div`
  .pick-up-person-dialog-container {
    .person-list {
      overflow-y: auto;
      max-height: 60vh;
      .person-item {
        padding: 0 24px;
        cursor: pointer;
        &:hover {
          background-color: rgba(var(--color_body_text_rgb), 0.04);
        }
        + .person-item {
          .person-item-box {
            border-top: 1px solid rgba(var(--color_text_field_border_rgb), 0.08);
          }
        }
        .person-item-box {
          display: flex;
          align-items: center;
          color: var(--color_body_text);
          padding: 24px 0;
        }
        .person-info {
          flex: 1;
          color: var(--color_body_text);
          font-size: 14px;
          line-height: 20px;
          margin-right: 16px;
          &__name {
            display: flex;
            align-items: center;
            font-weight: 500;
            .tag-default {
              flex-shrink: 0;
              padding: 0 6px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              color: var(--color_button);
              background-color: rgba(var(--color_button_rgb), 0.2);
              margin-left: 4px;
              border-radius: 2px;
            }
          }
          &__phone {
            margin-top: 8px;
          }
        }
        .icon-edit {
          width: 16px;
          height: 16px;
          fill: var(--color_button);
        }
      }
    }
    .person-empty {
      text-align: center;
      padding: 100px 0;
    }
    .pick-up-person-dialog-footer {
      border-top: 1px solid var(--color_borders);
      padding: 20px 24px 0;
      display: flex;
      justify-content: space-between;
      button {
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 24px;
        font-size: 14px !important;
        + button {
          margin-left: 12px;
        }
      }
    }
  }
`;
