import styled, { css } from 'styled-components';

export default styled.div`
  .filter-category-select {
    display: flex;
    position: relative;
    .zent-dropdown {
      left: 0 !important;
      top: 26px !important;
    }
    .zent-popover-wrapper {
      .zent-btn.zent-dropdown-button {
        height: auto !important;
        line-height: normal !important;
        background-color: transparent;
        border: none;
        padding: 0;
        color: var(--color_body_text) !important;
        .zenticon-down {
          margin-left: 4px;
        }
      }
    }
    .filter-dropdown-content {
      background-color: #fff;
      padding: 16px;
      min-width: 200px;
      max-width: 400px;
      max-height: 400px;
      overflow: auto;
      box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.08);
      background: var(--color_card) !important;
      color: var(--color_body_text);
      .category-item-checkbox {
        flex-direction: column;
      }
    }
  }
`;
