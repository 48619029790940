import styled from 'styled-components';

export default styled.div`
  .payClover {
    background-color: #fff;
    margin-top: 8px;
  }

  .payClover * {
    font-size: 16px;
    font-weight: 500;
  }

  .payClover .row {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
  }

  .payClover .field {
    position: relative;
    width: 100%;
    min-height: 50px;
    margin: 0 8px;
    border: solid 1px #d1d5db;
    padding: 4px 16px 7px;
    overflow: hidden;
    > div {
      max-height: 28px;
    }
    .input {
      padding-left: 16px;
    }
    .zent-loading--inline {
      float: right;
      margin-right: 24px;
      margin-top: 18px;
    }
  }

  .payClover .field.half-width {
    width: 50%;
  }

  .payClover .field.quarter-width {
    width: calc(25% - 10px);
  }

  .payClover .label {
    font-size: 12px;
    color: #9b9ea4;
  }

  .hidden {
    display: none;
  }

  .payClover input,
  .payClover button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .payClover input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .payClover input,
  .payClover button {
    -webkit-animation: 1ms void-animation-out;
  }
  .payClover .error {
    color: #e25950;
    padding-left: 8px;
  }
  .payClover .submit-wrapper {
    text-align: right;
    padding-right: 12px;
    padding-bottom: 8px;
  }
  .payClover button {
    display: inline-block;
    height: 44px;
    background-color: #041b4a;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
  }
`;
