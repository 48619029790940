import styled from 'styled-components';

export default styled.div`
  cursor: pointer;
  padding: ${(props) => props.theme.getSpace([12, 16])} 0;
  border-top: 1px solid
    ${(props) => {
      return props.theme.hexToRGBA(props.theme.colorText, 0.08);
    }};
  border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  .rule-title-operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .rule-title {
      ${(props) => props.theme.getBaseSize(props.theme.h9)};
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)};
    }
  }
  .rule-content {
    display: flex;
    margin-top: ${(props) => props.theme.getSpace([12, 16])};
    justify-content: space-between;
    .rule-item-wrapper {
      display: flex;
      position: relative;
    }
    .line {
      width: 20%;
      border-top: 2px dotted rgba(51, 51, 51, 0.16);
      margin-left: -${(props) => props.theme.getSpace([12, 20])};
      margin-right: -${(props) => props.theme.getSpace([12, 10])};
      margin-top: ${(props) => props.theme.getSpace([14, 20])};
    }
    .rule-item + .rule-item {
      margin-left: 22px;
    }

    .rule-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: ${(props) => props.theme.getSpace([120, 180])};
      text-align: center;
      .icon-wrapper {
        width: ${(props) => props.theme.getSpace([30, 48])};
        height: ${(props) => props.theme.getSpace([30, 48])};
        background: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.04)};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* svg {
                fill: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
            } */
      }
      .desc {
        font-weight: 400;
        word-break: break-all;
        margin-top: ${(props) => props.theme.getSpace([6, 8])};
        ${(props) => props.theme.getBaseSize(props.theme.h11)};
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
      }
    }
    .icon {
      color: $color-text;
    }
  }

  .group-rule {
    .detail-content {
      width: 30px;
      text-align: right;
    }
  }
`;

export const ContentStyle = styled.div`
  max-width: 592px;
  padding: 0 8px;
  p {
    color: rgba(var(--color_text_rgb), 0.6);
  }

  .rule-detail-item {
    margin-bottom: 10px;
    .title {
      margin-bottom: 10px;
    }
  }
  .rule-item-content {
    margin-bottom: 12px;
  }
`;
