import React, { useContext } from 'react';
import ContactInfo from '../contact-info';
import Style from './style';
import { intlLiToYuan } from 'utils/money';
import { useIntl } from 'react-intl';
import { DELIVERY_METHOD, STEP_EDIT_TYPE, STEP_MAP } from '../../const';
import { ContextOrder } from '../../../context/context-order';
import AddressWarnTip from '../address-warn-tip';
import moment from 'moment';
import { formatLocationText } from 'utils/global';
import { getShopTimeStampByDateTime, formateTimeByTimeZone } from '../../../../util/datetime';
import { getDeliveryFullTime ,getPickupFullTime } from '../../../../helper/order';

const MessageInfo = (props) => {
  const intl = useIntl();
  const { showCount = true } = props;
  const {
    deliveryInfo = {},
    shippingAddress = {},
    currency,
    onPrev,
    localDeliveryInfo = {},
    orderInfo = {},
    isExistRadiusLocalDelivery,
    buyerInfo = {},
    pickupInfo = {},
    isPickup,
  } = useContext(ContextOrder);

  const isLogin = !!window?.global_data?.userInfo?.customerId;

  const { shippingList = [], shippingInfo = {}, selectedDeliveryMethod = '', locationList = [] } = deliveryInfo;
  const shippingItem = shippingList.find((item) => item.shippingRateIds?.[0] === shippingInfo?.shippingRateIds?.[0]);
  const shippingPrice = shippingItem?.price || 0;
  const shippingName = shippingItem?.name || 0;
  const deliveryTime = localDeliveryInfo?.deliveryDate ? getDeliveryFullTime(localDeliveryInfo) : '';
  const needShip = !!orderInfo?.requiresShipping;
  const {
    address1,
    address2,
    province,
    country,
    zip,
    city,
    phone = '',
    firstName = '',
    lastName = '',
    latitude,
    longitude,
    callingCodes = '',
    callingArea = '',
  } = shippingAddress;
  const address = `${address2 ? `${address2}，` : ''}${address1 ? `${address1}，` : ''}${zip ? `${zip}，` : ''}${
    country || ''
  }${province || ''}${country || province ? '，' : ''}${city || ''}`;

  const showAccurateTip = isExistRadiusLocalDelivery && (!latitude || !longitude);
  const showCallingTip = !callingCodes || !callingArea;
  const showAddressTip = showAccurateTip || showCallingTip;

  const intlShippingPrice = intlLiToYuan({
    currency,
    value: shippingPrice,
  });

  const list = [
    {
      title: intl.formatMessage({ description: '联系信息', id: 'order.address.contact' }),
      text: buyerInfo?.customerNo || (buyerInfo?.mobile ? `${buyerInfo?.mobileCountryCode} ${buyerInfo?.mobile}` : ''),
      step: STEP_MAP.INFO,
      canEdit: !isLogin,
    },
  ];

  const needshipList = needShip
    ? [
        {
          title: intl.formatMessage({ description: '收货地址', id: 'order.address.recive_address' }),
          text: (
            <>
              <div>{`${firstName}${lastName} ${phone}`}</div>
              <div>{address}</div>
              {showAddressTip && (
                <AddressWarnTip
                  text={
                    showAccurateTip
                      ? showCallingTip
                        ? intl.formatMessage({
                            id: '55b6dfd1492b48969c843257c37b3ffe',
                            defaultMessage: '区号未填写，地址无法精准定位，请修改',
                          })
                        : intl.formatMessage({
                            id: '836100caf99e4b848e39bc5e53846ece',
                            defaultMessage: '地址无法精准定位，请修改地址',
                          })
                      : intl.formatMessage({
                          id: '489d092d981e4ae2a0ce551d473e8a45',
                          defaultMessage: '区号未填写，请补充',
                        })
                  }
                  onClick={() => onPrev({ step: STEP_MAP.INFO })}
                />
              )}
            </>
          ),
          step: STEP_MAP.INFO,
          editType: STEP_EDIT_TYPE.ADDRESS,
          canEdit: true,
        },
        {
          title: intl.formatMessage({ description: '配送方式', id: 'e40b9656517547da8182b5120d3aaaae' }),
          text: shippingItem ? (
            `${shippingName} ${intlShippingPrice || intl.formatMessage({ id: 'order.send.free' })}`
          ) : (
            <>
              <AddressWarnTip
                color='#EC5B56'
                text={intl.formatMessage({
                  id: 'a99c239f2ba043e38ce69b3cace656c5',
                  defaultMessage: '配送方式已失效，请重新确认配送方式',
                })}
                onClick={() => onPrev({ step: STEP_MAP.SEND })}
              />
            </>
          ),
          step: STEP_MAP.SEND,
          canEdit: true,
        },
      ]
    : [];

  const localDeliveryList =
    selectedDeliveryMethod === DELIVERY_METHOD.local_delivery && deliveryTime
      ? [
          {
            title: intl.formatMessage({ description: '配送时间', id: '717c0b4e40e4487881ce1c0f02dc61db' }),
            text: deliveryTime,
            step: STEP_MAP.SEND,
            canEdit: true,
          },
        ]
      : [];

  const formateList =
    shippingList?.length && showCount
      ? list.concat(needshipList).concat(localDeliveryList)
      : list.concat(needshipList).slice(0, 2);

  // 自提信息展示
  const pickupList = [
    {
      title: intl.formatMessage({ defaultMessage: '提货地点', id: '632a56322a99409fa701b186d2f34513' }),
      text: formatLocationText(locationList.find((item) => item.selected == 1)),
      step: STEP_MAP.SEND,
      canEdit: true,
    },
    {
      title: intl.formatMessage({ defaultMessage: '提货人', id: 'ad7c2500b49540a5b8e8006ae7a7d894' }),
      text: `${pickupInfo?.firstName} ${pickupInfo?.lastName} ${pickupInfo?.phone}`,
      step: STEP_MAP.SEND,
      canEdit: true,
    },
    {
      title: intl.formatMessage({ defaultMessage: '提货时间', id: 'c1ac87fe64af42b38f7d76da57fe597f' }),
      text: getPickupFullTime(pickupInfo),
      step: STEP_MAP.SEND,
      canEdit: true,
    },
  ];
  return (
    <Style>
      {isPickup ? (
        <p className='shipping-title'>
          {intl.formatMessage({ defaultMessage: '提货信息', id: 'b37867009ebe49fd9baa169f6ece8490' })}
        </p>
      ) : (
        <p className='shipping-title'>
          {intl.formatMessage({ defaultMessage: '收货信息', id: '50bf935384bb475796426f4348745574' })}
        </p>
      )}
      <ContactInfo
        list={isPickup ? pickupList : formateList}
        onEdit={({ step, editType }) => {
          onPrev({ step, editType });
        }}
        isNotEdit={orderInfo?.isTriggeredPay}
      />
    </Style>
  );
};

export default MessageInfo;
