import Big from 'big.js';
import SessionStorage from '../../../util/session-storage';
import { DISTRIBUTION_SESSIONS } from '../../../util/session-storage/config';
import {
  DISTRIBUTION_COOKIES,
  GOODS_INFO,
  GOODS_BUY_ONCE,
  SHOP_CART,
  AUTO_DISCOUNT_CART,
  WISH_COUNT_CHANGE,
} from '../../../util/local-storage/config';
import { notifyCartCountChange } from 'helper/cart';
import { isEmpty } from 'lodash';
import { getLogUtmParams } from 'utils/log-buried-point/util';
import LocalStorage from 'utils/local-storage';
import { GOODS_STATUS } from '../const';

export const getUnitDiscount = (discount, quantity) => {
  if (quantity === 0) return 0;
  return Number(new Big(discount).div(quantity).toFixed(2));
};

// 清楚和支付相关的缓存
export const removeStorageWithPay = (needFilterGoods, refer) => {
  const storeKeyNeedRemoveAfterPay = [GOODS_INFO, GOODS_BUY_ONCE, AUTO_DISCOUNT_CART, WISH_COUNT_CHANGE];
  // const cartList = LocalStorage.get(SHOP_CART) || [];
  // if (cartList.some((item) => item?.hasSelected === 0) && needFilterGoods) {
  //     const newList = cartList.filter((item) => item?.hasSelected === 0);
  //     LocalStorage.set(SHOP_CART, newList, -1);
  // } else {
  //     if (refer !== 'shopCart') return;
  //     storeKeyNeedRemoveAfterPay.push(SHOP_CART);
  // }
  storeKeyNeedRemoveAfterPay.map((storeKey) => LocalStorage.remove(storeKey));
  notifyCartCountChange();
};


export const getLocationQuery = (search) => {
  const urlSearchParams = new URLSearchParams(search);
  const orderToken = urlSearchParams.get('orderToken');
  const needFilterGoods = urlSearchParams.get('filterExpired');
  const refer = urlSearchParams.get('refer'); //  数据来源 购物车/立即购买
  const step = urlSearchParams.get('step'); // 当前处于哪一步
  const addressQuery = urlSearchParams.get('addressQuery'); // 从paypal 获取的地址
  const cartId = urlSearchParams.get('cartId'); // 从paypal 获取的地址

  const paramsObj = {};
  for (const [key, value] of urlSearchParams) {
    paramsObj[key] = value;
  }

  return {
    urlSearchParams,
    ...paramsObj,
    orderToken,
    needFilterGoods,
    refer,
    step,
    addressQuery,
    cartId,
  };
};

export const initGoodsLits = ({ cartId, refer, needFilterGoods }) => {
  if (cartId) {
    return [];
  }
  const goodsList = LocalStorage.get(refer) || [];
  const filterList = goodsList.filter((item) => item?.hasSelected || item?.giftGoodsFlag);
  const newList = needFilterGoods ? filterList : goodsList;
  const isReferShopCar = refer?.indexOf('shopCart') > -1;
  return isReferShopCar ? newList?.filter((item) => item?.goodsStatus?.indexOf(GOODS_STATUS.ok) > -1) : newList;
};
