import React from 'react';
import { Dialog } from 'zent';
import DistributionRule from '../distribution-rule';

const { openDialog } = Dialog;
const DistributionRuleDialog = 'distribution_rule_dialog';

const openRuleDialog = (params) => {
  const { affiliateId, intl, currency } = params;
  return openDialog({
    dialogId: DistributionRuleDialog,
    title: intl.formatMessage({
      id: 'rule',
      description: `规则`,
    }),
    style: {
      width: '640px',
    },
    children: <DistributionRule affiliateId={affiliateId} currency={currency} intl={intl} />,
  });
};

export default openRuleDialog;
