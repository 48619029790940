import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: 2;
  padding-bottom: 16px;
  align-items: flex-start;
  position: relative;
  .image-box {
    width: 102px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: pink;
    margin-right: 14px;
    background: ${(props) => props.gLabelBg};
  }
  .package-title {
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
  }
  .info {
    flex: 1;
    color: #333333;
    min-height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item-line {
    display: flex;
    justify-content: space-between;
  }

  .discount-info-wrapper {
    font-size: 12px !important;
    text-align: right;
    & > div {
      margin-top: 5px !important;
    }
  }
  .mt10 {
    margin-top: 10px;
  }
`;
