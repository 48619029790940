import React from 'react';
import Style from './style';
import cn from 'classnames';
import { Dropdown, DropdownButton, DropdownPosition, DropdownClickTrigger, DropdownContent } from 'zent';

/**
 * https://youzan.github.io/zent/zh/component/popover#position%20api
 * visible	手动控制弹层的显示隐藏, 必须和 onVisibleChange 一起使用	bool
 * onVisibleChange	手动控制时的回调函数, 必须和 visible 一起使用, 只有用户手动触发的打开／关闭操作才会调用	func
 * */

const DropdownSelect = (props) => {
  const { title, visible = false, onVisibleChange, children, selectorClassName = 'dropdown-select-wrapper-1' } = props;
  return (
    <Style>
      <div className={cn('dropdown-select-wrapper', selectorClassName)}>
        <Dropdown
          position={DropdownPosition.AutoBottomLeft}
          containerSelector={`.${selectorClassName}`}
          visible={visible}
          onVisibleChange={(value) => {
            onVisibleChange && onVisibleChange(value);
          }}
        >
          <DropdownClickTrigger>
            <DropdownButton>{title}</DropdownButton>
          </DropdownClickTrigger>
          <DropdownContent>
            <div className='dropdown-select-content'>{children}</div>
          </DropdownContent>
        </Dropdown>
      </div>
    </Style>
  );
};

export default DropdownSelect;
