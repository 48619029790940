import { Rate } from 'zent';
import { intlMessage } from 'utils/intl/formate';
import { useIntl } from 'react-intl';
import { formateGoodsSale } from 'helper/goods';
import React from 'react';

export const ReviewsBar = ({ reviewOverview = {}, reviewTotalCount, soldNum, showSales, showReview }) => {
  const intl = useIntl();
  if (!showReview && !showSales && !showReview) {
    return null;
  }
  return (
    <div className='rate-area'>
      {!!showReview && (
        <>
          <Rate allowHalf value={reviewOverview?.avgScore || 5} readOnly />
          {reviewOverview?.avgScore || null}
          <span className='line' />
          {`(${reviewTotalCount}) ${intlMessage(intl, 'product_comments')}`}
        </>
      )}

      {!!showReview && showSales && <span className='seperate-dot'>·</span>}

      {showSales ? `${formateGoodsSale(soldNum)} ${intlMessage(intl, 'sales_volume')}` : null}
    </div>
  );
};
