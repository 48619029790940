import { isNull, isFunction } from 'lodash';
import React, { useRef } from 'react';
import { Form, FormControl, FormError, Input, validateOption } from 'zent';
import Style from './style';

const FormFileds = (props) => {
  const {
    name = '',
    defaultValue = '',
    validators = [],
    label = '',
    placeholder = '',
    style = {},
    onBlur,
    onFiledsChange,
    maxLength,
    disabled = false,
    isTrim = true, // 是否允许两端有空格
    isRemoveMidSpaces = false, // 是否允许中间有空格
    onFocus: onParentFocus,
    inputClass = '',
  } = props;
  const model = Form.useField(name, defaultValue, [...(validators || [])]);
  model.destroyOnUnmount = true;
  const inputNode = useRef(null);

  const onChange = React.useCallback(
    (e) => {
      model.isTouched = true;
      const preValue = model.value;
      model.value = e.target.value;
      onFiledsChange && onFiledsChange(e.target.value, preValue);
    },
    [model],
  );

  const validate = React.useCallback(
    (e) => {
      const val = e.target.value;
      if (isTrim) {
        model.value = val.trim();
      }
      if (isRemoveMidSpaces) {
        model.value = val.replace(/\s*/g, '');
      }
      model.validate(validateOption).then((res) => {
        if (onBlur && isNull(res)) onBlur(e);
      });
    },
    [model, validateOption, isRemoveMidSpaces, isTrim],
  );

  const onFocus = () => {
    inputNode.current.input.focus();
  };

  return (
    <Style style={style}>
      <div onClick={onFocus} className='filed-item'>
        <FormControl className={model.error ? 'has-error' : ''}>
          <p className='filed-name'>{label}</p>
          <div className='input-warp'>
            <Input
              ref={inputNode}
              size='small'
              className={inputClass}
              style={{ height: '56px', paddingBottom: '0px' }}
              value={model.value}
              onChange={onChange}
              onBlur={validate}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              onFocus={() => {
                if (isFunction(onParentFocus)) {
                  onParentFocus();
                }
              }}
            />
          </div>
        </FormControl>
        {model.error && <FormError>{model.error.message}</FormError>}
      </div>
    </Style>
  );
};

export default FormFileds;
