import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { intlLiToYuan } from 'utils/money';
import { getDiscountDesc } from 'helper/discount';
import Style from './style';

const PromotionDesc = ({ currency, language, promotionDescriptions = [] }) => {
  const intl = useIntl();

  const discountInfos = promotionDescriptions.filter((item) => item.type === 'discount');

  const [isShow, setIsShow] = useState(false);

  const getDiscountDescText = (discountInfo) => {
    return getDiscountDesc({ discountInfo, currency, intl, language });
  };

  if (!discountInfos || !discountInfos.length) {
    return null;
  }
  const totalDiscount = discountInfos.reduce((total, item) => {
    let promotionAmount = item?.promotionAmount || 0;
    return total + promotionAmount;
  }, 0);

  return (
    <Style className='item-block-item'>
      <div
        className='f-js-ac item-block-item-title'
        onClick={() => {
          setIsShow(!isShow);
        }}
      >
        <span className='main-title'>
          <FormattedMessage id='discount_title' defaultMessage='折扣' />
          <svg className={`promotion-desc-icon ${isShow && 'promotion-desc-icon-active'}`} aria-hidden='true'>
            <use xlinkHref='#iconic-xiala1' />
          </svg>
        </span>
        <div className='layout-cell-right font-light shipping-price'>
          -{intlLiToYuan({ currency, value: totalDiscount })}
        </div>
      </div>
      {isShow && (
        <div className='promotion-desc-content'>
          {discountInfos.map((item) => {
            return (
              <div className='promotion-desc-item' key={item.promotionId}>
                <div className='promotion-desc-item-left'>
                  <div className='promotion-desc-item-name'>{item.title}</div>
                  <div className='promotion-desc-item-text'>{getDiscountDescText(item)}</div>
                </div>
                <div className='promotion-desc-item-right'>
                  -{intlLiToYuan({ currency, value: item?.promotionAmount })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Style>
  );
};

export default PromotionDesc;
