import styled from 'styled-components';

export default styled.div`
  --border: 1px solid #ebedf0;
  .simple-table {
    width: 100%;
  }
  .simple-table-thead {
    display: flex;
    align-items: center;
    height: 44px;
    &.has-border {
      border-bottom: var(--border);
    }
    .simple-column-title {
      &.auto-width {
        flex: 1;
      }
      td {
        color: #9b9ea4;
        font-size: 12px;
        display: inline-flex;
      }
    }
  }
  .simple-table-tbody {
    .simple-table-row {
      display: flex;
      align-items: center;
      height: 88px;
      &.has-border {
        border-bottom: var(--border);
      }
      .simple-table-cell {
        &.auto-width {
          flex: 1;
        }
      }
    }
  }
  /* .table-empty {
        border-bottom: var(--border);
        text-align: center;
        height: 50px;
        line-height: 50px;
    } */
`;
