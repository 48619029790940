import styled, { css } from 'styled-components';

export default styled.div`
  position: relative;
  .take-success-close-btn {
    position: absolute;
    right: 24px;
    top: 24px;
    font-size: 20px;
    color: #1a1a1b;
    cursor: pointer;
  }
  .take-success-section {
    padding: 40px;
  }
  .take-success-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    margin-bottom: 24px;
  }
  .take-success-footer {
    text-align: center;
    margin-top: 24px;
  }
  .take-success-card {
    margin: 0 auto;
    position: relative;
    .take-success-card-body {
      text-align: center;
      background-color: ${(props) => {
        if (!props?.theme?.colorButtons) return;
        return props?.theme?.colorButtons[8];
      }};
      width: 100%;
      text-align: center;
      padding-bottom: 16px;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 8px;
        background-color: var(--color_button);
      }
      .take-success-card-line {
        width: 100%;
        position: relative;
        margin: 16px 0;
        text-align: center;
        padding: 0 8px;
        box-sizing: border-box;
        &:before {
          content: '';
          display: block;
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          top: -8px;
          left: -8px;
        }
        &:after {
          content: '';
          display: block;
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          top: -8px;
          right: -8px;
        }
        .take-success-card-line-body {
          margin: 0 auto;
          width: calc(100% - 20px);
          border-bottom: 1px dashed #1f2433;
        }
      }
    }
    .take-code {
      display: inline-block;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 4px;
      padding: 8px 24px;
      margin: 40px auto 8px;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: var(--color_button);
    }
    .take-code-desc {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: var(--color_button);
      margin-bottom: 24px;
      margin-top: 8px;
    }
    .take-code-limit {
      margin-top: 16px;
      text-align: left;
      padding: 0 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: rgba(51, 51, 51, 0.6);
      li + li {
        margin-top: 16px;
      }
      .take-code-limit-name {
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        margin-bottom: 8px;
      }
    }
  }
  .take-success-footer {
    .zent-btn {
      background: var(--color_button);
      border-radius: 29px;
      width: 100% !important;
    }
  }
  @media screen and (max-width: 750px) {
    .take-success-close-btn {
      font-size: 28px;
    }
    .take-success-section {
      padding: 24px;
      .take-success-title {
        font-size: 18px;
      }
      .take-success-card-bg {
        height: 224px;
      }
      .take-success-card-body {
        .take-success-card-line {
          width: 100%;
          position: relative;
          margin: 16px 0;
          text-align: center;
          padding: 0 8px;
          box-sizing: border-box;
          &:before {
            content: '';
            display: block;
            background-color: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            left: -5px;
          }
          &:after {
            content: '';
            display: block;
            background-color: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: -5px;
          }
          .take-success-card-line-body {
            margin: 0 auto;
            width: calc(100% - 16px);
            border-bottom: 1px dashed #1f2433;
          }
        }
      }
      .take-success-card {
        .take-code {
          font-size: 14px;
          padding: 8px;
          margin: 20px auto 8px;
          line-height: 20px;
        }
        .take-code-desc {
          margin-top: 0;
          font-size: 12px;
          margin-bottom: 0;
        }
      }
      .take-code-limit {
        margin-top: 0;
        font-size: 12px;
        line-height: 17px;
        .take-code-limit-name {
          margin-bottom: 4px;
          font-size: 12px;
        }
        li + li {
          margin-top: 8px;
        }
      }
    }
    .take-success-footer {
      margin-top: 16px;
    }
  }
`;
