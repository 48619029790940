import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { Icon } from 'components/base';
import { Dialog } from 'zent';
import Style, { ContentStyle } from './style';
// import { HtCard, HtIcon } from 'taro-hunt';
const { openDialog, closeDialog } = Dialog;

export const GroupRuleContent = ({ className = '' }) => {
  const [visibleDetail, setVisibleDetail] = useState(false);
  const intl = useIntl();
  const handleClose = () => {
    setVisibleDetail(false);
  };
  const ruleList = [
    {
      icon: 'iconjiagou',
      text: intl.formatMessage({
        id: '9b5f3049237b47508dc224f5c9cece07',
        defaultMessage: 'Order&pay',
      }),
      //
    },
    {
      icon: 'iconfenxianghaoyou',
      text: intl.formatMessage({
        id: '4f61c530afa44753ac7b8871e7e6daa3',
        defaultMessage: 'Share&invite',
      }),
    },
    {
      icon: 'iconchengtuan',
      text: intl.formatMessage({
        id: '2365ec74718b42b59ae4358ccb09a193',
        defaultMessage: 'Buy as group',
      }),
    },

    {
      icon: 'iconpintuanchenggong',
      text: intl.formatMessage({
        id: '58bd140bc30446a3a2e89177bf41ac4d',
        defaultMessage: 'Complete',
      }),
    },
  ];

  return (
    <div className={cn(className)}>
      <div>
        <div className={cn('rule-content', 'group-rule-content')}>
          {ruleList.map((item, index) => {
            const isLast = index === ruleList?.length - 1;
            return (
              <Fragment key={index}>
                <div className='rule-item'>
                  <div className='icon-wrapper'>
                    <Icon name={item.icon} size='xSmall'></Icon>
                  </div>
                  <span className='desc'>{item.text}</span>
                </div>
                {!isLast && (
                  <div className='line'>
                    <div className='line-inner'></div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const GroupRule = () => {
  const intl = useIntl();

  const onOpenRuleDetail = () => {
    openDialog({
      title: intl.formatMessage({
        id: 'e43d32418b494ad786305e603335e51f',
        defaultMessage: 'Group Buy Rules',
      }),
      children: (
        <ContentStyle>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: '6f4e61c9eb964aa99184d58dc32a2a3b',
                defaultMessage: '拼团规则',
              }),
            }}
          />
        </ContentStyle>
      ),
    });
  };

  return (
    <Style onClick={onOpenRuleDetail}>
      <div className='rule-title-operation'>
        <span className='rule-title'>
          {intl.formatMessage({ defaultMessage: '拼团玩法说明', id: 'a2da9aad89cb4bf5a0d18d9b7f1b2444' })}
        </span>
        <span className='icon'>
          {' '}
          <Icon name='iconxiayige' size='small'></Icon>
        </span>
      </div>
      <GroupRuleContent />
    </Style>
  );
};
