import styled from 'styled-components';

export default styled.div`
  position: absolute;
  right: 0;
  .more-logo {
    color: #2654ff;
    margin-left: 16px;
    line-height: 26px;
    cursor: pointer;
  }
  .logo-wrapper {
    background: #fff;
    min-width: 120px;
    min-height: 40px;
    max-width: 400px;
    background: #ffffff;
    border: 1px solid #ebedf0;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    display: flex;
    padding: 8px 16px 16px 8px;
    flex-wrap: wrap;
    position: absolute;
    right: -18px;
    top: -16px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 38px;
    .logo {
      border: solid 1px #dcdee0;
      width: 40px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      margin-top: 8px;
      img {
        max-height: 20px;
        max-width: 36px;
      }
    }
  }

  .top-zero {
    top: 0;
  }
  @media screen and (max-width: 750px) {
    &.wrapper-level-1 {
      top: 38% !important;
      right: -12px;
    }
    &.wrapper-level-2 {
      top: 54% !important;
      right: -12px;
    }
  }
`;
