import React from 'react';
import CategoryCheckbox from './category-checkbox';
import CategoryRadio from './category-radio';
import CategorySlider from './category-slider';

import { FILTER_ITEM_STYLE, LAYOUT } from '../../const';
import Style from './style';

/**
 * 三种筛选器类型： 多选 单选 滑块
 * 单选 滑块 只对纵向排序有效（目前只有价格有使用到）
 *
 * */
const FilterCategory = (props) => {
  const { settings = {}, direction = 'row', isShowName = false, data, activeOptionIds, onCategoryChange } = props;
  const { layout = 'horizontal' } = settings;
  const { filterItemName, filterItemStyle } = data || {};

  return (
    <Style layout={layout} direction={direction}>
      <div className='filter-category'>
        {/* title 横向样式风格二 用  */}
        {isShowName && (
          <div className='filter-category-name' title={filterItemName}>
            <span className='filter-category-name-text'>{filterItemName}</span>:
          </div>
        )}
        {/* 滑块 */}
        {filterItemStyle === FILTER_ITEM_STYLE?.SLIDER && layout === LAYOUT?.VERTICAL && (
          <CategorySlider settings={settings} data={data} onCategoryChange={onCategoryChange} />
        )}
        {/* 单选 */}
        {/* layout === LAYOUT?.VERTICAL && */}
        {filterItemStyle === FILTER_ITEM_STYLE?.RADIO && (
          <CategoryRadio
            settings={settings}
            direction={direction}
            data={data}
            activeOptionIds={activeOptionIds}
            onCategoryChange={onCategoryChange}
          />
        )}

        {/* 多选框 */}
        {filterItemStyle === FILTER_ITEM_STYLE?.CHECKBOX && (
          <CategoryCheckbox
            settings={settings}
            direction={direction}
            data={data}
            activeOptionIds={activeOptionIds}
            onCategoryChange={onCategoryChange}
          />
        )}
      </div>
    </Style>
  );
};

export default FilterCategory;
