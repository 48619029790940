import { useState, useEffect } from 'react';
import { auditStatus } from '../constant/config';
import distributionApi from '../../../api/distribution';

function useDistributorList(params = {}) {
  const { history, design, refresh = false } = params;
  const [needLogin, setNeedLogin] = useState(false);
  const [distributorInfo, setDistributorInfo] = useState({
    status: auditStatus.activated,
  });

  const goRecruitPage = () => {
    window.location.href = '/affiliate/recruit';
  };

  const toLogin = (affiliateId) => {
    !affiliateId && goRecruitPage();
  };

  const getDistributionInfo = () => {
    if (!design) {
      distributionApi
        .isLogin()
        .then((res) => {
          const { isLogin } = res || {};
          if (!isLogin) {
            setNeedLogin(true);
            return Promise.reject()
          };
          return Promise.resolve();
        })
        .then(() => {
          return distributionApi.getPersonalInfo();
        })
        .then((res) => {
          const { affiliateId } = res || {};
          setDistributorInfo(res);
          toLogin(affiliateId);
          window.cacheDistributorInfo = res;
        })
        .catch(() => {
          history && goRecruitPage();
        });
    }
  };

  useEffect(() => {
    const { cacheDistributorInfo } = window;
    if (cacheDistributorInfo && !refresh) {
      const { affiliateId } = window.cacheDistributorInfo || {};
      !design && toLogin(affiliateId);
      setDistributorInfo(window.cacheDistributorInfo);
      return;
    }
    getDistributionInfo();
  }, [refresh]);

  return {
    distributorInfo,
    needLogin,
  };
}

export const delDistributorInfo = () => {
  window.cacheDistributorInfo = null;
};

export default useDistributorList;
