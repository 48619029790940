import React, { useEffect, useState } from 'react';
import groupBuyingApi from 'api/group-buying';
import { GroupCard } from 'components/page/group-buying/group-card';
import SectionTitle from 'cpn/section-title';
import { useIntl } from 'react-intl';
import { Style } from './style';

export const GroupBuyingGoods = ({ language, currency }) => {
  const intl = useIntl();
  const [groupBuyingGoods, setGroupBuyingGoods] = useState([]);

  useEffect(() => {
    const params = {
      page: 1,
      pageSize: 12,
    };
    groupBuyingApi.searchGroupActivityGoodsWithPage(params).then((res) => {
      setGroupBuyingGoods(res?.items ?? []);
    });
  }, []);

  return (
    <Style>
      <SectionTitle
        title={intl.formatMessage({
          id: 'fbfc4e1064b240b1bfc97495507b36e6',
          defaultMessage: '拼团商品',
        })}
      />
      <div className='goods-list'>
        {groupBuyingGoods.map((item) => (
          <div className='card-wrapper' key={item.goodsId}>
            <GroupCard goodsInfo={item} currency={currency} language={language} showWish={true} />
          </div>
        ))}
      </div>
    </Style>
  );
};
