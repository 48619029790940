import styled, { css } from 'styled-components';

const getContainerWidthCSS = (props) => {
  const { theme, bgColor, layout } = props;
  if (layout === 'vertical') {
    return css`
      padding: 0 40px 0 16px;
    `;
  } else {
    return css`
      margin-top: 24px;
      margin-bottom: 24px;
      /* padding: 32px 80px; */
      padding: 24px;
      background-color: ${bgColor};
      .collection-filter-body {
        /* padding: ${theme.getSpace([16, 32])} 0; */
      }
    `;
  }
};

export default styled.div`
  .collection-filter {
    border-radius: var(--border_radius_other);
    ${(props) => getContainerWidthCSS(props)};
    .collection-filter-body {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 750px) {
    display: none;
    .filter-box-select .content {
      width: 180px !important;
    }
  }
`;
