import { UN_NEED_BILLING_ADDRESS_PAY_PROVIDERS } from 'const/pay';

/** 新打开一个页面 @刘静怡 */
export const jumpPayUrlAddLongParams = ({ url, params = {}, httpMethod = 'get' }) => {
  if (httpMethod?.toLowerCase() === 'get') {
    window.location.href = url;
    return;
  }
  // 创建form表单
  const tempForm = document.createElement('form');
  tempForm.action = url;
  // 如需打开新窗口，form的target属性要设置为'_blank'
  tempForm.target = '_self';
  tempForm.method = 'post';
  tempForm.style.display = 'none';
  // 添加参数
  Object.keys(params).forEach((key) => {
    const element = document.createElement('textarea');
    element.name = key;
    element.value = params[key];
    tempForm.appendChild(element);
  });
  document.body.appendChild(tempForm);
  // 提交数据
  tempForm.submit();
  document.body.removeChild(tempForm);
};

export const openWindowWithHtmlStr = (htmlStr) => {
  const newWindow = window.open('', '_self');
  if (newWindow) {
    newWindow.document.write(htmlStr);
    newWindow.focus();
  }
};

export const isCodAndCustomerOrder = (providerType) => {
  return UN_NEED_BILLING_ADDRESS_PAY_PROVIDERS.includes(providerType);
};
