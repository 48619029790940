import React, { useEffect, useState } from 'react';
import shopApi from 'api/shop';
import {
  AdditionalFeeSettingTypeEnum,
  IAdditionalFeeSettingVO,
} from './types';
import { intlLiToYuan } from 'utils/money';
import { Icon } from 'components/base';
import Style, { DescContentStyle, DescDetailStyle, GlobalStyle } from './style';
import HtDialog from 'components/standard/dialog';
import { useRootContext } from '../../context/root';
import DefaultImg from 'cpn/default-img';

export enum TotalItemPageType {
  ORDER_DETAIL = 'order_detail',
}

enum EnumDrawerContentType {
  discount = 'discount', // 折扣明细
  tax = 'tax', // 税费明细
  feeDesc = 'feeDesc',  // 费用说明
  feeDetail = 'feeDetail',  // 费用明细
}

const { openHtDialog } = HtDialog;

export const AdditionalFeeTotal = (props) => {
  const { theme } = useRootContext();
  
  const { additionalFees = [], currency, $fm, pageType } = props;
  const isOrderDetail = pageType === TotalItemPageType.ORDER_DETAIL;

  const [additionalFeesSettings, setAdditionalFeesSettings] = useState<IAdditionalFeeSettingVO[]>([]);

  useEffect(() => {
    shopApi.getAdditionalFeeSetting().then((res) => {
      setAdditionalFeesSettings(res || []);
    });
  }, []);

  const openAdditionalFeeDialog = (type, data) => {
    let Content: any = null;
    const dialogInfo: any = {
      title: '',
      descInfo: {},
      contentInfo: {},
    }
    switch (type) {
      case EnumDrawerContentType.feeDesc: {
        Content = FeeDescContent;
        dialogInfo.title = $fm('34e95b87339240b8801bc6057344b77f', '费用说明');
        dialogInfo.descInfo = additionalFeesSettings.find(item => item.type === data.type);
        break;
      }
      case EnumDrawerContentType.feeDetail: {
        dialogInfo.title = $fm('fe0f07a5ed294b869c52f48242eb8b80', '费用明细');
        dialogInfo.contentInfo = data;
        Content = FeeDetailContent;
        break;
      }
    }
    
    openHtDialog({
      className: 'additional-fee-dialog',
      theme,
      title: dialogInfo.title,
      children: (
        <>
          <GlobalStyle />
          <Content dialogInfo={dialogInfo} $fm={$fm} currency={currency} />
        </>
      ),
    });
  }

  if (additionalFees?.length === 0) return null;

  return additionalFees.map((item) => {
    const { totalPrice } = item;
    if (!totalPrice) return null;
    return (
      <Style className='f-js-ac item-block-item'>
        <div
          className='main-title'
          onClick={() => {
            if (isOrderDetail) return;
            openAdditionalFeeDialog(EnumDrawerContentType.feeDesc, item);
          }}
        >
          <span>{item.name}</span>
          {!isOrderDetail && <span><Icon name='iconjichu-mingcijieshi-wenhao' size='small' /></span>}
        </div>
        <div
          className='sub-title total-goods-price'
          onClick={() => {
            if (item.type === AdditionalFeeSettingTypeEnum.ORDER) return;
            openAdditionalFeeDialog(EnumDrawerContentType.feeDetail, item);
          }}
        >
          <span>
            {intlLiToYuan({
              currency,
              value: totalPrice,
            })}
          </span>
          {item.type !== AdditionalFeeSettingTypeEnum.ORDER && <span><Icon name='iconic-xiala' /></span>}
        </div>
      </Style>
    );
  });
};

// 费用说明
const FeeDescContent = props => {
  const { dialogInfo } = props;
  return (
    <DescContentStyle className='fee-desc-content'>
      <div className='fee-desc'>
        <div className="fee-desc__title">
          {dialogInfo?.descInfo?.name || ''}
        </div>
        <div className="fee-desc__value">
          {dialogInfo?.descInfo?.description || ''}
        </div>
      </div>
    </DescContentStyle>
  )
}

// 费用明细
const FeeDetailContent = props => {
  const { $fm, currency, dialogInfo } = props;
  const items = dialogInfo?.contentInfo?.items || [];
  return (
    <DescDetailStyle className='fee-detail-content'>
      <div className='fee-detail'>
        <div className="fee-detail__table-th">
          <div className='fee-detail__table-td'>
            {$fm('9897d88453b13e07568ca63588d75fa6', '商品')}
          </div>
          <div className='fee-detail__table-td'>
            {$fm('56e0df9a5a434ff8b015efa77d0182fc', '每份收取')}
          </div>
          <div className='fee-detail__table-td'>
            {$fm('goods.item _total', '小计')}
          </div>
        </div>
        <div className="fee-detail__table-body">
          {
            items.map(item => (
              <div className='fee-detail__table-tr'>
                <div className='fee-detail__table-td goods-cover-td'>
                  {
                    item.imageUrl ? 
                    <img className='goods-cover' src={item.imageUrl} /> :
                    <DefaultImg />
                  }
                  <div className='num-tag'>x{item.quantity}</div>
                </div>
                <div className='fee-detail__table-td'>
                  {intlLiToYuan({ currency, value: item.unitPrice, showZero: true })}
                </div>
                <div className='fee-detail__table-td'>
                  {intlLiToYuan({ currency, value: item.unitPrice * item.quantity, showZero: true })}
                </div>
              </div>
            ))
          }
          <div className='fee-detail__table-tr detail-total'>
            <div className='fee-detail__table-td'>
              {$fm('goods.cart.total_count', '合计')}
            </div>
            <div className='fee-detail__table-td'>
              {intlLiToYuan({ currency, value: dialogInfo?.contentInfo?.totalPrice || 0, showZero: true })}
            </div>
          </div>
        </div>
      </div>
    </DescDetailStyle>
  )
}


