import styled from 'styled-components';

export default styled.div`
    .user-info {
        padding: 24px 0;
        box-sizing: border-box;
        position: relative;
        word-break: break-word;
        min-height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .user-content {
            flex: 1;
            display: flex;
            align-items: center;
        }
        .img-wrap {
            width: ${(props) => props.theme.getSpace([36, 36, 64])};
            height: ${(props) => props.theme.getSpace([36, 36, 64])};
            border-radius: 50% !important;
            margin-right: var(--pm-16-10);
            .default-touxiang {
                width: 100%;
                height: 100%;
            }
        }
        .name {
            font-weight: 500;
            ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
            color: var(--color_body_text);
        }
        .des {
            font-size: var(--base-font-14-12);
            font-weight: 400;
            color: rgba(var(--color_body_text_rgb), 0.6)
            margin-top: 7px;
        }
        .right-btn {
            vertical-align: middle;
            color: var(--color_button);
            cursor: pointer;
            user-select: none;
        }

        .distributor {
            min-width: 60px;
            padding: 0 4px;
            height: 18px;
            border-radius: 9px;
            cursor: pointer;
            font-size: 11px;
            font-weight: 500;
            color: #292f31;
            line-height: 18px;
            margin-left: 4px;
            text-align: center;
            display: inline-block;
            background: linear-gradient(142deg, #f1d9af 0%, #d9b57d 100%);
            .distributor-xiala {
                width: 12px;
                height: 12px;
                margin-top: -1px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
`;
