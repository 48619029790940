import styled, { createGlobalStyle } from 'styled-components';

export const Style = styled.div`
  display: flex;
  width: 100%;
  .zent-input-wrapper {
    border-left-width: 0;
  }
  .hunt-select-trigger {
    width: 110px !important;
  }
  .phone-input-fileds {
    width: calc(100% - 110px);
  }
  > div {
    margin-top: 0;
    &:last-child {
      flex: 1;
    }
  }
`;
export const GlobalStyle = createGlobalStyle`
  .hunt-select-options {
    width: 240px;
  }
`;
