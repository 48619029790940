import React from 'react';
import Style from './style';
import { useIntl } from 'react-intl';

const UserInfo = (props) => {
  const intl = useIntl();
  const {
    imgUrl = '',
    btnText = '',
    rightClick,
    firstName = '',
    lastName = '',
    customerNo = '',
    mobileCountryCode = '',
    mobile = '',
    className = '',
    isActivated = false,
    history,
  } = props;

  const toDistributorCenter = () => {
    history.push('/affiliate/dashboard');
  };
  return (
    <Style>
      <div className={`user-info ${className}`}>
        <div className='user-content'>
          <div className='img-wrap'>
            {imgUrl ? (
              <img src={imgUrl} alt={`${firstName} ${lastName}`} />
            ) : (
              <svg className='default-touxiang' aria-hidden='true' height='36px' width='36px'>
                <use xlinkHref='#iconic-touxiang' />
              </svg>
            )}
          </div>

          <div>
            {lastName && (
              <p className='name'>
                {firstName}
                {lastName}
                {isActivated && (
                  <span onClick={toDistributorCenter} className='distributor'>
                    {intl.formatMessage({ defaultMessage: '分销员', id: 'distributor' })}
                    <svg className='distributor-xiala' aria-hidden='true' height='24px' width='24px'>
                      <use xlinkHref='#iconic-xiala' />
                    </svg>
                  </span>
                )}
              </p>
            )}
            {(customerNo || mobile) && <p className='des'>{customerNo || `${mobileCountryCode} ${mobile}`}</p>}
          </div>
        </div>

        <span className='right-btn' onClick={rightClick}>
          {btnText}
        </span>
      </div>
    </Style>
  );
};

export default UserInfo;
