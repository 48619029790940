import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, Radio, RadioGroup } from 'zent';
import AddressItem from '../address-item';
import AddressEditDialog from './address-edit-dialog';
import userApi from '../../../../api/user';
import { DialogStyle } from './style';
import AddressWarnTip from '../address-warn-tip';

const AddressChangeDialog = (props) => {
  const {
    intl,
    visible = false,
    onClose = () => {},
    onConfirm = () => {},
    addressList = [],
    onRefresh = () => {},
    currentAddress = {},
    canEdit = true,
    notFullDisabled = false, // 未填写完整的地址不可选
    ...addressProps
  } = props;
  const { userInfo = {} } = window?.global_data || {};

  const [tempUniqueId, setTempUniqueId] = useState('');
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  const selectedAddress = useMemo(() => {
    return addressList?.find((item) => item.uniqueId === tempUniqueId) || {};
  }, [tempUniqueId, addressList]);

  const initUniqueId = useMemo(() => {
    const current = addressList?.find(
      (item) =>
        item?.country === currentAddress?.country &&
        item?.countryCode === currentAddress?.countryCode &&
        item?.province === currentAddress?.province &&
        item?.provinceCode === currentAddress?.provinceCode &&
        item?.city === currentAddress?.city &&
        item?.address1 === currentAddress?.address1 &&
        item?.address2 === currentAddress?.address2 &&
        item?.zip === currentAddress?.zip &&
        item?.firstName === currentAddress?.firstName &&
        item?.lastName === currentAddress?.lastName &&
        item?.phone === currentAddress?.phone &&
        item?.latitude === currentAddress?.latitude &&
        item?.longitude === currentAddress?.longitude,
    );

    return current?.uniqueId || '';
  }, [currentAddress, addressList]);

  useEffect(() => {
    if (!visible) {
      setTempUniqueId('');
    }
  }, [visible]);

  const onEditUserAddress = (id) => {
    setTempUniqueId(id);
    setEditDialogVisible(true);
  };

  const updateUserAddress = (params) => {
    userApi.updateAddress(params).then(() => {
      onRefresh();
    });
  };

  const addUserAddress = (params) => {
    userApi.createAddress(params).then(() => {
      onRefresh();
    });
  };

  const onEditConfirm = (address) => {
    const params = {
      customerId: userInfo?.customerId,
      ...address,
      uniqueId: tempUniqueId,
    };
    if (tempUniqueId) {
      updateUserAddress(params);
    } else {
      addUserAddress(params);
    }
    setEditDialogVisible(false);
  };

  return (
    <>
      <Dialog
        className='address-select-dialog'
        visible={visible}
        onClose={onClose}
        maskClosable={false}
        title={intl?.formatMessage({
          description: '更换收货地址',
          id: 'customer.change_receive_address',
        })}
      >
        <div className='whole-address-wrapper'>
          {addressList.map((item) => {
            const icon = '#iconic_edit';
            const showCallingTip = !item?.callingCodes || !item?.callingArea;
            return (
              <React.Fragment key={item?.uniqueId}>
                <div
                  className='address-wrapper'
                  onClick={() => {
                    if (!notFullDisabled || !showCallingTip) setTempUniqueId(item?.uniqueId);
                  }}
                >
                  <RadioGroup value={tempUniqueId || initUniqueId}>
                    <Radio value={item?.uniqueId} disabled={notFullDisabled && showCallingTip} />
                  </RadioGroup>
                  <div className='address-item-wrapper'>
                    <AddressItem
                      address={item}
                      icon={canEdit ? icon : ''}
                      intl={intl}
                      iconCallBack={() => {
                        if (canEdit) {
                          onEditUserAddress(item?.uniqueId);
                        }
                      }}
                      extendTip={
                        showCallingTip ? (
                          <AddressWarnTip
                            text={intl.formatMessage({
                              id: '489d092d981e4ae2a0ce551d473e8a45',
                              defaultMessage: '区号未填写，请补充',
                            })}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            showRight={false}
                          />
                        ) : (
                          ''
                        )
                      }
                    />
                  </div>
                </div>
                <div className='address-item-line' />
              </React.Fragment>
            );
          })}
        </div>
        <div className='action-wrapper'>
          <div>
            {canEdit && (
              <Button
                onClick={() => {
                  onEditUserAddress('');
                }}
              >
                <span>
                  {intl?.formatMessage({
                    defaultMessage: '添加收货地址',
                    id: 'f5effc8e4ef44215a415ef4629c47ed4',
                  })}
                </span>
              </Button>
            )}
          </div>
          <div className='confirm-btn'>
            <Button onClick={onClose} className='cancel'>
              {intl?.formatMessage({ defaultMessage: '取消', id: 'general.cancel' })}
            </Button>
            <Button
              type='primary'
              onClick={() => {
                onConfirm(selectedAddress);
                onClose();
              }}
            >
              {intl?.formatMessage({ defaultMessage: '确定', id: 'common_confirm' })}
            </Button>
          </div>
        </div>
        <DialogStyle />
      </Dialog>
      <AddressEditDialog
        {...addressProps}
        intl={intl}
        visible={editDialogVisible}
        onClose={() => setEditDialogVisible(false)}
        onConfirm={() => setEditDialogVisible(false)}
        onRefresh={onRefresh}
        currentAddress={selectedAddress}
        addressList={addressList}
      />
    </>
  );
};

export default AddressChangeDialog;
