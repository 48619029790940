import React, { useState } from 'react';
import Style from './style';
import FilterCategory from '../filter-category';
import { Dropdown, DropdownButton, DropdownPosition, DropdownClickTrigger, DropdownContent } from 'zent';
import cn from 'classnames';

const FilterCategorySelect = (props) => {
  const { data, onCategoryChange, settings } = props;
  const [activeOptionIds, setActiveOptionIds] = useState([]);
  const filterItemId = data?.filterItemId;

  return (
    <Style>
      <div className={cn(`filter-category-select`, `filter-category-select-${filterItemId}`)}>
        <Dropdown
          position={DropdownPosition.AutoBottomLeft}
          containerSelector={`.filter-category-select-${filterItemId}`}
        >
          <DropdownClickTrigger>
            <DropdownButton>{data?.filterItemName}</DropdownButton>
          </DropdownClickTrigger>
          <DropdownContent>
            <div className='filter-dropdown-content'>
              <FilterCategory
                direction='column'
                settings={settings}
                data={data}
                activeOptionIds={activeOptionIds}
                key={data?.filterItemId}
                onCategoryChange={(activeData) => {
                  setActiveOptionIds(activeData?.activeOptionIds);
                  onCategoryChange(activeData);
                }}
              />
            </div>
          </DropdownContent>
        </Dropdown>
      </div>
    </Style>
  );
};

export default FilterCategorySelect;
