interface getPointNameWithNumProps {
  intl?: any;
  num: number;
  names?: {
    singleName?: string;
    complexName?: string;
  };
}

export const getPointNameWithNum = ({
  intl,
  num = 0,
  names = { singleName: '', complexName: '' },
}: getPointNameWithNumProps) => {
  const {
    singleName = intl?.formatMessage({ id: '267b5b363bbd494783f70402f93697d5', defaultMessage: '积分' }),
    complexName = intl?.formatMessage({ id: '267b5b363bbd494783f70402f93697d5', defaultMessage: '积分' }),
  } = names;

  if (num > 1) {
    return `${num}${complexName}`;
  } else {
    return `${num}${singleName}`;
  }
};
