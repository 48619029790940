import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Style from './style';
import { Icon } from 'components/base';
import DropdownSelect from '../../../../components/dropdown-select';

const FilterHeader = (props) => {
  const { filterStyle, sortList = [], onSortChange, onClickFilter, sortSelectorClassName } = props;
  const intl = useIntl();
  const [activeSortData, setActiveSortData] = useState();

  const handleSelectChange = (data) => {
    setActiveSortData(data);
    onSortChange && onSortChange(data?.value);
  };

  const sortTitle = useMemo(() => {
    if (activeSortData) {
      return activeSortData?.name;
    }
    return intl.formatMessage({
      id: 'general.default',
      defaultMessage: '默认',
    });
  }, [activeSortData]);

  // 初始化默认显示最后一个
  useEffect(() => {
    if (sortList.length) {
      handleSelectChange(sortList?.[sortList.length - 1]);
    }
  }, [sortList]);

  const [visible, setVisible] = useState(false);

  return (
    <Style filterStyle={filterStyle}>
      <div className='filter-header'>
        <div className='filter-header-left'>
          <div className='filter-header-name'>
            {intl.formatMessage({
              id: 'goods.filter.by',
              defaultMessage: '筛选',
            })}
          </div>
          <div className='filter-header-body'>{props?.children}</div>
        </div>
        <div className='filter-header-right'>
          <div className='header-right-sort'>
            <DropdownSelect
              selectorClassName={sortSelectorClassName}
              title={
                <span className='header-right-sort-title'>
                  {sortTitle} <Icon name='iconpaixu' />
                </span>
              }
              visible={visible}
              onVisibleChange={(data) => {
                setVisible(data);
              }}
            >
              <div className='sort-item-list'>
                {sortList.map((item, index) => {
                  return (
                    <div
                      key={`${index}-${item.id}`}
                      className='sort-item skiptranslate'
                      onClick={() => {
                        handleSelectChange(item);
                        setVisible(false);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </DropdownSelect>
          </div>
          <div className='header-right-filter' onClick={onClickFilter}>
            <span>
              {intl.formatMessage({
                id: 'goods.filter.by',
                defaultMessage: '筛选',
              })}
            </span>
            <Icon name='iconshaixuan' />
          </div>
        </div>
      </div>
    </Style>
  );
};

export default FilterHeader;
