import { useState, useEffect } from 'react';
import distributionApi from '../../../api/distribution';

function useDistributorPoster() {
  const { cacheDistributorPoster } = window;
  const initConfig = cacheDistributorPoster || {};
  const [config, setConfig] = useState(initConfig);
  const [design, setDesign] = useState(false);
  const defaultConfig = {
    pcLogo: null,
    bgColor: '#476CF0',
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isDesignModel = Number(urlParams.get('design')) === 1;
    if (isDesignModel) {
      setDesign(isDesignModel);
      return;
    }
    if (!cacheDistributorPoster) {
      distributionApi
        .getRecruitingPoster({
          kdtId: window?.global_data?.kdtId,
          posterType: 'personal_center',
        })
        .then((res) => {
          setConfig(res);
          window.cacheDistributorPoster = res;
        });
    }
  }, []);
  return {
    design,
    config,
    setConfig,
    defaultConfig,
  };
}
export default useDistributorPoster;
