import { useIntl } from 'react-intl';
import cn from 'classnames';
import EmptyTip from '../empty-tip';
import Style from './style';

const SimpleTable = ({
  className = {},
  columns = [],
  dataSource = [],
  rowKey = 'id',
  hasBorder = true,
  emptyLabel,
}) => {
  const intl = useIntl();
  return (
    <Style className={className}>
      <table className='simple-table'>
        {dataSource.length !== 0 && (
          <thead className={cn('simple-table-thead', { 'has-border': hasBorder })}>
            {columns.map((item) => {
              return (
                <tr
                  key={item.title}
                  className={cn('simple-column-title', { 'auto-width': !item.width && !item.flex })}
                  style={{
                    textAlign: item.align,
                    width: item.width ? `${item.width}px` : '',
                    flex: item.flex || '',
                  }}
                >
                  <td>{item.title}</td>
                </tr>
              );
            })}
          </thead>
        )}
        {dataSource.length !== 0 && (
          <tbody className='simple-table-tbody'>
            {dataSource.map((data, index) => {
              return (
                <tr
                  key={data[rowKey]}
                  className={cn('simple-table-row', {
                    'has-border': hasBorder,
                  })}
                >
                  {columns.map((item) => {
                    return (
                      <td
                        // eslint-disable-next-line react/no-array-index-key
                        key={item.title + index}
                        className={cn('simple-table-cell', {
                          'auto-width': !item.width && !item.flex,
                        })}
                        style={{
                          textAlign: item.align,
                          width: item.width ? `${item.width}px` : '',
                          flex: item.flex || '',
                        }}
                      >
                        {item.render ? item.render(data) : data[item.name]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {dataSource.length === 0 && <EmptyTip intl={intl} emptyLabel={emptyLabel} />}
    </Style>
  );
};
export default SimpleTable;
