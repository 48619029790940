import styled from 'styled-components';

export default styled.div`
  margin-top: var(--pm-16-10);
  .filed-item {
    // padding: 10px 0;
    // border: 1px solid #d1d5db;
    // text-align: left;
    position: relative;
  }

  .filed-name {
    color: #9b9ea4;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 12px;
    padding-left: 8px;
    position: absolute;
    top: 10px;
    z-index: 2;
  }
  .zent-form-control {
    margin-bottom: 0px;
  }
  .input-warp {
    border: none;
    // padding-top: 8px;
  }
  .zent-input {
    padding-top: 15px !important;
  }
  /* .zent-input--has-focus {
        box-shadow: 0 0 0 0;
        border-color: #041B4A !important;
    } */
`;
