import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  position: relative;
  /* max-height: 75vh; 包卫，为啥加这个？？*/

  .zent-btn {
    padding: 0 32px;
  }

  .mt-40 {
    margin-top: var(--pm-60-40);
  }
  .icon-switch {
    height: 100% !important;
  }
  /* .pay-user-info {
        &.user-info {
            padding: 0 !important;
            min-height: var(--pm-64-40);
        }
    }
    .login-guide {
        text-align: right;
        font-size: var(--base-font-16-14);
        font-weight: 400;
        color: #36383b;

        .login-link {
            font-size: var(--base-font-16-14);
            font-weight: 600;
            color: #041b4a;
            color: var(--color_button);
            line-height: 16px;
            cursor: pointer;
            text-decoration: underline;
            word-break: keep-all;
            white-space: nowrap;
        }
    } */
  /* div {
        background: #fff;
    } */
  /* .base-login-info-wrapper {
        padding: var(--pm-24-16);
        background: var(--color_card, #fff);
        border-radius: var(--border_radius_card);
    } */
  .order-form-container {
    padding: var(--pm-24-16) var(--pm-24-16) 0;
  }
  .address-edit {
    padding-bottom: var(--pm-24-16);
  }
  .address-form-wrapper {
    background: var(--color_card, #fff);
    margin-top: 16px;
    border-radius: var(--border_radius_card);
    overflow: hidden;
    .order-form-container {
      padding-top: 0;
    }
    .title {
      padding: var(--pm-24-16) var(--pm-24-16) var(--pm-16-10);
    }
    /* padding: var(--pm-24-16); */
  }

  .no-ship-address {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    color: rgba(var(--color_text_rgb), 0.4);
  }

  .dialog-address-form {
    max-height: 74vh;
    overflow: auto;
  }
  .col-wrapper-1 {
    padding: var(--pm-40-34);
    background: #fff;
    box-sizing: border-box;
    height: 100%;
  }
  .col-wrapper-2 {
    padding: var(--pm-24-18);
    margin-left: var(--pm-16-10);
    background: #fff;
    height: 100%;
  }

  /* .title {
        margin-top: 0 !important;
        margin-bottom: 0px;
        color: #1a1a1b;
        font-size: 20px !important;
        font-family: var(--font-stack-header);
        font-style: var(--font-style-header);
        font-weight: var(--font-weight-header);
        font-weight: 600;
        display: flex;
        justify-content: space-between;
    } */
  .flex-container {
    display: flex;
  }
  .mr-24 {
    margin-right: var(--pm-24-18);
  }
  .flex-item {
    flex: 1;
  }
  .mt-16 {
    margin-top: var(--pm-16-10);
  }
  .save {
    margin-top: var(--pm-16-10);
  }
  .zent-select {
    margin-right: 0 !important;
  }
  .shop-car {
    background: #999;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  .auto-address-list-container {
    position: relative;
    width: 100%;
    .auto-address-list {
      width: 100%;
      max-height: 320px;
      position: absolute;
      background: var(--color_card);
      top: 4px;
      left: 0;
      padding: 4px;
      box-sizing: border-box;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      z-index: var(--zindex-picker);
      overflow: hidden;
      overflow-y: scroll;
      .auto-address-item {
        padding: 8px;
        cursor: pointer;
        &_title {
          color: var(--color_text_field_text);
          font-size: 14px;
          line-height: 20px;
        }
        &_desc {
          color: rgba(var(--color_text_field_text_rgb), 0.6);
          font-size: 12px;
          line-height: 17px;
        }
        &_title + &_desc {
          margin-top: 4px;
        }
      }
      .auto-address-item + .auto-address-item {
        margin-top: 4px;
      }
    }
  }
  .auto-address-warn-tip {
    font-size: 12px;
    line-height: 18px;
    color: #ec903a;
    margin-top: 8px;
  }
  .address-edit-action-wrapper-boder {
    content: '';
    display: block;
    width: 200%;
    height: 1px;
    transform: scale(0.5);
    transform-origin: left;
    background-color: var(--color_borders);
  }
  .address-edit-action-wrapper {
    text-align: right;
    padding: 16px 24px 8px;
  }

  .total {
    text-align: right;
  }
  .pay-login {
    .zent-input-wrapper {
      width: 100% !important;
    }
    .submit-btn {
      width: 100% !important;
    }
  }
  .address-action-wrapper {
    text-align: right;
    padding: 0 24px 24px;
    button {
      min-width: 92px;
    }
  }
  @media screen and (max-width: 750px) {
    .flex-container {
      flex-direction: column;
    }
    .mr-24 {
      margin-right: 0px;
    }
  }

  .address-form {
    .zent-form-control {
      margin-bottom: 0px !important;
    }

    .current-address-info {
      margin-top: 5px;
    }

    .address-header {
      display: flex;
      justify-content: space-between;
    }

    .add-new-btn {
      color: var(--color_button);
    }
  }

  .hunt-select-trigger {
    height: 56px !important;
    line-height: 56px !important;
    box-sizing: border-box;
    .hunt-select-switch {
      line-height: 61px !important;
    }
    input {
      outline: none !important;
      height: 50% !important;
      margin-top: 16px !important;
      width: 100%;
    }
  }
`;

export const DialogStyle = createGlobalStyle`
        .zent-btn {
            padding: 0 32px !important;
        }
       .whole-address-wrapper{
           max-height: 560px;
           overflow-y: auto;
           width: 628px;
           max-width: 100%;
           max-height: 70vh;
       }
       /* .order-form-container{
           width: 580px;
           max-width: 70vw;
       } */
       .address-wrapper{
        display: flex;
        align-items: center;
        padding-left: 16px;
        /* border-bottom: solid 1px var(--color_borders); */

            /* &:hover{
                background: #F7F9FC;
            } */
            .zent-radio-group{
                position: relative;
                left: 8px;
            }
            .address-item-wrapper{
                width: 100%;
            }
        }
        .address-item-line {
            content: '';
            display: none;
            width:calc((100% - 48px) * 2);
            height:1px;
            background-color: var(--color_borders);
            transform: scale(0.5);
            transform-origin: left;
            margin-left: 24px;
        }
        .address-wrapper + .address-item-line:not(:last-child) {
            display:block;
            
        }
        
    .zent-dialog-r-body{
        padding: 0!important;
    }
    .action-wrapper{
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04);
        align-items: center;
        @media screen and (max-width:750px){
           
            .cancel{
                display: none;
            }
        }
        .add-icon{
            margin-right: 8px;
            position: relative;
            bottom:-3px;
            fill: var(--color_small_button_text_border);
        }

    }
    .address-select-dialog {
        max-width: 90vw;
        width: 640px;
        &.zent-dialog-r {
            padding-top:24px;
            .zent-dialog-r-has-title {
                top: 24px;
                right:24px;
            }
            .zent-dialog-r-header {
                .zent-dialog-r-title {
                    border-bottom: none;
                    padding:0 24px 16px;
                    font-size: 18px;
                    line-height:24px;
                }
            }
        }
       
        .zent-dialog-r-body {
            padding: 0 !important;
            max-height: 80vh;
            overflow: auto;
        }
        .zent-dialog-r-body::-webkit-scrollbar  {
            width: 2px;
        }
    }
    .zent-radio-group{
        &>div:first-child{
            display: flex;
            align-items: center;
        }
    }
    .hunt-select-option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
   

    @media screen and (max-width: 750px) {
        .address-item-line {
            width:calc((100% - 32px) * 2);
            margin-left:16px;
        }
        
        .action-wrapper{
            padding: 16px 16px 0;
        }
        .address-select-dialog {
            min-width: calc(100% - 32px) !important;
            max-width: calc(100% - 32px) !important;

            .zent-dialog-r-body {
                max-height: 80vh;
            }
        }
    }
`;
