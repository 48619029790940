import styled from 'styled-components';

export default styled.div`
  margin-top: var(--pm-16-10);
  .filed-item {
    position: relative;
  }
  .filed-name {
    color: #9b9ea4;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 12px;
    padding-left: 8px;
    position: absolute;
    top: 10px;
    z-index: 2;
  }
  .zent-form-control {
    margin-bottom: 0px;
  }
  .input-warp {
    border: none;
  }
  .zent-input {
    padding-top: 15px !important;
  }
`;
