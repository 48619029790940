/**
 * @name 搜索hooks
 * @applyPage  应用的页面: 分组页面、全部商品、搜索页、所有分组页面
 *
 * 注意：如果是自定义排序是不支持分页的,pageSize 最大300
 *
 * */
import { useEffect, useCallback, useState, useMemo } from 'react';
import goodsApi from 'api/goods';
import debounce from 'lodash/debounce';
import { formatGoodsFieldName } from 'helper/goods';
import { getCookie } from 'utils/cookie';
import { getMultiLanguageCookieName } from '../../../helper/multi-language';

const CUSTOM_SORT_MAX_PAGESIZE = 300;

export const fetchData = (filter = {}, { locale, language }) => {
  // 如果这两个字段是空着不传递，传控字段 后端没有默认值处理
  const { sort, sortBy, customerId } = filter;
  if (!sort && !sortBy) {
    delete filter?.sort;
    delete filter?.sortBy;
  }
  // 没有客户ID 也不传递
  if (!customerId) {
    delete filter?.customerId;
  }

  // 分组数据
  return goodsApi.searchGoodsSmartFilter2({ ...filter }, { locale, language });
};

/**
 * @params currentPage: allGoodsPage（所有商品）、 allCollection（所有分组）、 searchPage（搜索页面）、 页面id（其它页面）
 * @isFirstLoad 首次是否加载 主要给搜索页main使用
 * */
export const useSearchList = (params) => {
  const { initData, currentPage, pageSize = CUSTOM_SORT_MAX_PAGESIZE, isFirstLoad = true } = params;
  // 保证显示四行
  const { searchData = {}, kdtId, collectionId, shopSetting = {}, userInfo } = initData;
  const { language, currency } = shopSetting;
  const locale = getCookie(getMultiLanguageCookieName(kdtId));
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    kdtId,
    customerId: userInfo?.customerId || '',
    currentPage: currentPage || (collectionId ? `${collectionId}` : 'allGoodsPage'),
    page: 1,
    pageSize,
    collectionQuery: [], // 分组
    vendorQuery: [], // 供应商
    goodsTagsQuery: [], // 标签
    goodsTypeQuery: [], // 商品类型
    // skuQuery: {}, // 不做
    // priceQuery: { // 价格类型
    //     maxPrice: -96636358,
    //     minPrice: -40419622,
    // },
    // sort: ' ',
    // sortBy: '',
    // keyWord:''
  });
  let { filterItem = [], simpleGoods = [], paginator = { page: 1000, pageSize: 1, totalCount: 0 } } = searchData;
  simpleGoods = formatGoodsFieldName(simpleGoods);
  const [totalCount, setTotalCount] = useState(paginator?.totalCount);
  const [renderList, setRenderList] = useState(simpleGoods);
  const [filterList, setFilterList] = useState(filterItem);

  const [data, setData] = useState({
    simpleGoods,
    paginator,
    filterItem,
  });

  const getData = (filter) => {
    const isDefaultSortCustom = filter?.sort === 'manually' && filter?.sortBy === 'manually';
    setLoading(true);
    if (typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }
    fetchData(
      {
        ...filter,
        pageSize: isDefaultSortCustom ? CUSTOM_SORT_MAX_PAGESIZE : filter?.pageSize,
      },
      { locale, language },
    )
      .then((res) => {
        if (res) {
          setData(res);
          const { paginator, simpleGoods = [], filterItem } = res || {};
          setRenderList(formatGoodsFieldName(simpleGoods));
          setFilterList(filterItem);
          console.log('res_filterItem___', filterItem);
          setTotalCount(paginator?.totalCount || 0);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('____err', err);
        setLoading(false);
      });
  };

  const onKeyWordChange = (value) => {
    window.location.hash = encodeURI(value);
    setFilter((prevFilter) => {
      if (prevFilter?.priceQuery) {
        delete prevFilter?.priceQuery;
      }
      return {
        ...prevFilter,
        keyWord: value || '',
        page: 1,
        collectionQuery: [], // 分组
        vendorQuery: [], // 供应商
        goodsTagsQuery: [], // 标签
        goodsTypeQuery: [], // 商品类型
      };
    });
  };

  const onPageChange = (page) => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        page,
      };
    });
  };

  const onPageSizeChange = (pageSize = CUSTOM_SORT_MAX_PAGESIZE) => {
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        pageSize,
        page: 1,
      };
    });
  };

  const onSortChange = (data) => {
    const { sort = '', sortBy = '' } = data;
    const isDefaultSortCustom = sort === 'manually' && sortBy === 'manually';

    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        ...data,
        page: 1,
        pageSize: isDefaultSortCustom ? CUSTOM_SORT_MAX_PAGESIZE : prevFilter?.pageSize,
      };
    });
  };

  const isActiveCustomSort = useMemo(() => {
    return filter?.sort === 'manually' && filter?.sortBy === 'manually';
  }, [filter?.sort, filter?.sortBy]);

  const onFilterChange = (data) => {
    setFilter((prevFilter) => {
      // 没有价格删除
      if (!data.priceQuery && prevFilter.priceQuery) {
        delete prevFilter.priceQuery;
      }
      return {
        ...prevFilter,
        ...data,
        page: 1,
      };
    });
  };

  // 此处添加一个防抖
  const debounceGetData = useCallback(
    debounce((q) => getData(q), 500),
    [],
  );

  // 初始化是否加载，主要用到搜索页main
  const [isInitLoad, setIsInitLoad] = useState(isFirstLoad);

  useEffect(() => {
    if (!isInitLoad) {
      setIsInitLoad(true);
      return;
    }
    debounceGetData(filter);
  }, [filter]);

  return {
    loading,
    data,
    page: filter?.page,
    pageSize: filter?.pageSize,
    keyWord: filter?.keyWord || '',
    totalCount,
    renderList,
    filterItem: filterList,
    language,
    currency,
    isActiveCustomSort,
    onPageChange,
    onSortChange,
    onFilterChange,
    onKeyWordChange,
    onPageSizeChange,
  };
};

export default useSearchList;
