import React, { useState, useEffect } from 'react';
import { Slider } from 'zent';
import Style from './style';
import { intlLiToYuan } from 'utils/money';

// 滑块
const CategorySlider = ({ settings, data, onCategoryChange }) => {
  const { currency } = settings;
  const { filterItemOptions = [] } = data || {};
  const { minPrice = 0, maxPrice = 1 } = filterItemOptions?.[0]?.priceRange || {};
  const ratio = (maxPrice - minPrice) / 100;

  const [priceRange, setPriceRange] = useState([0, 100]);
  const [value, setValue] = useState([1, 100]);

  const getRealMoneyRang = (min, max) => {
    const realMinMoney = intlLiToYuan({ currency, value: min });
    const realMaxMoney = intlLiToYuan({ currency, value: max });
    return [realMinMoney, realMaxMoney];
  };

  const handleChange = (rangeData) => {
    const min = rangeData[0];
    const max = rangeData[1];
    setValue(rangeData);

    const realMin = min === 0 ? 0 : parseInt(min * ratio);
    const realMax = max === 100 ? maxPrice : parseInt(max * ratio);

    const realMoneyRange = getRealMoneyRang(realMin, realMax);
    setPriceRange(realMoneyRange);

    console.log('CategorySlider-change_realMoneyRange', realMoneyRange);
    onCategoryChange &&
      onCategoryChange({
        ...data,
        priceQuery: {
          minPrice: realMin,
          maxPrice: realMax,
        },
      });
  };

  useEffect(() => {
    const realMoneyRange = getRealMoneyRang(minPrice, maxPrice);
    setPriceRange(realMoneyRange);
  }, [minPrice, maxPrice]);

  return (
    <Style>
      <div className='category-item-slider'>
        <div className='category-item-slider-values'>
          <span>{priceRange?.[0]}</span>
          <span>{priceRange?.[1]}</span>
        </div>
        <Slider range withInput={false} value={value} min={minPrice ? 1 : 0} max={100} onChange={handleChange} />
      </div>
    </Style>
  );
};

export default CategorySlider;
