import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;
  .sold-out {
    position: absolute;
    width: 88px;
    height: 88px;
    margin: auto;
    background: #000000;
    opacity: 0.75;
    color: #fff;
    font-size: var(--base-font-14-12);
    border-radius: 50%;
    text-align: center;
    line-height: 88px;
    top: 0;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    z-index: 10;
  }
  &.customer-ratio {
    height: 0;
    padding-bottom: ${(props) => props.heightPercent};
    .goods-img {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: ${(props) => (props.isCut ? 'cover' : 'contain')};
      object-position: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .hover-img {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s ease-in-out;
  }
  .goods-img {
    max-width: 100%;
    display: block;
    transition: all 0.5s ease-in-out;
    opacity: 1;
  }
  &.hover:hover {
    .hover-img {
      opacity: 1;
      z-index: 2;
      display: block;
    }
    .goods-img {
      opacity: 0;
    }
  }
`;
