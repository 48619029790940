import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  &.tiled {
    .profile-item-wrapper + .profile-item-wrapper {
      margin-left: 8px;
    }
  }
  &.impulse {
    .profile-item-inner {
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .profile-item-wrapper + .profile-item-wrapper {
      margin-left: -8px;
      position: relative;
    }
    .profile-item-wrapper {
      position: relative;
    }
  }
  &.normal {
    .profile-item-wrapper {
      height: 44px;
      width: 44px;
    }
  }
  &.small {
    .profile-item-wrapper {
      height: 40px;
      width: 40px;
    }
  }
`;
