import React, { useMemo } from 'react';
import { Dialog } from 'zent';
import { DialogStyle } from './style';
import userApi from '../../../../api/user';
import AddressInput from './address-input';
import LocalStorage from 'utils/local-storage';
import { SAVE_USER_TEMP_ADDRESS } from 'utils/local-storage/config';

const AddressEditDialog = (props) => {
  const {
    intl,
    visible = false,
    isLogin = false,
    onClose = () => {},
    onConfirm = () => {},
    currentAddress = {},
    addressList = [],
    onRefresh = () => {},
    ...addressProps
  } = props;
  const userInfo = window?.global_data?.userInfo || {};

  const address = useMemo(() => {
    if (!isLogin) return currentAddress;
    const current = addressList?.find(
      (item) =>
        item?.country === currentAddress?.country &&
        item?.countryCode === currentAddress?.countryCode &&
        item?.province === currentAddress?.province &&
        item?.provinceCode === currentAddress?.provinceCode &&
        item?.city === currentAddress?.city &&
        item?.address1 === currentAddress?.address1 &&
        item?.address2 === currentAddress?.address2 &&
        item?.zip === currentAddress?.zip &&
        item?.firstName === currentAddress?.firstName &&
        item?.lastName === currentAddress?.lastName &&
        item?.phone === currentAddress?.phone &&
        item?.latitude === currentAddress?.latitude &&
        item?.longitude === currentAddress?.longitude,
    );

    return current;
  }, [currentAddress, addressList]);

  const initUniqueId = address?.uniqueId || '';

  const updateUserAddress = (params) => {
    userApi.updateAddress(params).then(() => {
      onRefresh();
    });
  };

  const addUserAddress = (params) => {
    userApi.createAddress(params).then(() => {
      onRefresh();
    });
  };

  const onInputSubmit = (params) => {
    console.log('???params', params);
    if (userInfo?.customerId) {
      const newParams = { ...params, customerId: userInfo?.customerId };
      if (initUniqueId) {
        updateUserAddress({ ...newParams, uniqueId: initUniqueId });
      } else {
        addUserAddress(newParams);
      }
    } else {
      if (!params.isDefault) LocalStorage.remove(SAVE_USER_TEMP_ADDRESS);
      if (params.isDefault) LocalStorage.set(SAVE_USER_TEMP_ADDRESS, params, -1);
    }
    // 保存并关闭弹窗
    onConfirm({ ...params, uniqueId: initUniqueId });
    onClose();
  };
  return (
    <Dialog
      className='address-select-dialog'
      visible={visible}
      maskClosable={false}
      onClose={onClose}
      title={intl?.formatMessage({
        defaultMessage: '填写地址',
        id: '5d39b256ede94e7a8531257eb18d2e53',
      })}
    >
      <AddressInput
        dialogModal
        intl={intl}
        onConfirm={onInputSubmit}
        onCancel={onClose}
        showConfirm
        showCancel
        currentAddress={address}
        {...addressProps}
      />
      <DialogStyle />
    </Dialog>
  );
};

export default AddressEditDialog;
