import styled from 'styled-components';

export const Style = styled.div`
  .delivery-method-tabs {
    margin-bottom: 24px;
    .delivery-method-tabs-wrap {
      display: inline-flex;
      border: 1px solid var(--color_button);
      border-radius: 50px;
      background-color: var(--color_button_text);
    }
    .delivery-method-tabs-item {
      padding: 0 32px;
      height: 46px;
      line-height: 46px;
      color: var(--color_button);
      cursor: pointer;
      &.active {
        border-radius: 50px;
        background-color: var(--color_button);
        color: var(--color_button_text);
      }
    }
  }
`;
