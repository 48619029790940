import BaseApi from './base';

const baseApi = new BaseApi();

class AppointmentApi extends BaseApi {
  // 根据日期查询可预约的时间段
  getAvailableInterval = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentTimeController/getAvailableInterval',
      params,
    );
  };
  
  // 根据日期范围查询可预约的日期
  getAvailableDate = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentTimeController/getAvailableDate',
      params,
    );
  };
  
  // 预订单计价
  checkoutAppointment = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/confirm',
      params,
    );
  };
  
  // 重复支付，进入结算页，查询确认详情
  getConfirmDetail = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/getConfirmDetail',
      params,
    );
  };
  
  // 首次支付创建预订单
  createAppointment = (params) => {
    return baseApi._post('/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/create', params);
  };
  
  // 二次支付预订单
  rePayAppointment = (params) => {
    return baseApi._post('/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/pay', params);
  };
  
  getAppointmentByToken = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/getByToken',
      params,
    );
  };
  
  searchAppointmentWithPage = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/searchWithPage',
      params,
    );
  };
  // 查询店铺是否开启了预订服务
  getByKdtId = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentCommonRuleController/getByKdtId',
      params,
    );
  };
  
  // 查询预约策略规则
  getByGoodsId = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentStrategyRuleController/getByGoodsId',
      params,
    );
  }
  ;
  // 查询预约策略规则
  getStrategyRuleByToken = (params) => {
    return baseApi._post(
      '/api/soa/com.youzan.i18n.appointment.controller.web.AppointmentOrderController/getStrategyRuleByToken',
      params,
    );
  };
}

export default new AppointmentApi();

