import React from 'react';
import { Button } from 'zent';
import HtDialog from '../../standard/dialog';
import { getPointNameWithNum } from 'utils/points';
import { intlLiToYuan } from 'utils/money';

const { openHtDialog, closeHtDialog } = HtDialog;

const openPointsRulesDialog = (props) => {
  const { theme, intl, currency, pointsSettings = {} } = props;
  const dialogId = 'openPointsRulesDialog';
  const { globalRule = {}, orderDeductRule = {}, placeOrderRule = {}, userRegisterRule = {} } = pointsSettings;

  const {
    singleName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
    complexName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
  } = globalRule;

  const SceneMap = {
    paid: intl.formatMessage({
      id: 'fd3035d8436e418da19054e6744df786',
      defaultMessage: '下单后',
    }),
    shipped: intl.formatMessage({
      id: 'a97cca425b28453fb9ab84673177c0bf',
      defaultMessage: '发货后',
    }),
  };

  const validTimeUnitMap = {
    permanent: intl.formatMessage({ id: 'long.time.effective', defaultMessage: '长期有效' }),
    day: intl.formatMessage(
      { id: 'af59450aef824abe940576957e178143', defaultMessage: `${globalRule?.validTime}天` },
      { day: globalRule?.validTime },
    ),
    year: intl.formatMessage(
      { id: '9a623397e4b94d22818b426a40b7e2f5', defaultMessage: `${globalRule?.validTime}年` },
      { year: globalRule?.validTime },
    ),
  };

  const getRulesText = () => {
    const getStatus = !!placeOrderRule?.isActivated;
    const useStatus = !!orderDeductRule?.isActivated;
    const registerStatus = !!userRegisterRule?.isActivated;
    // 规则弹窗文案
    const getTitle = `${intl?.formatMessage(
      { id: '610b0b16917f41a192879e7bfc02a8ee', defaultMessage: '积分获取规则' },
      { name: singleName },
    )}：`;
    const getContent: string[] = []; // 用数组是因为需要序号，文案有顺序
    const useTitle = `${intl?.formatMessage(
      { id: '8306e302314d4c1c8c3ea81668f50b91', defaultMessage: '积分使用规则' },
      { name: singleName },
    )}：`;
    const useContent = intl?.formatMessage(
      {
        id: 'f37cba53facd464aae020f5e8e0441d0',
        defaultMessage: `积分在购物中可抵扣商品金额：每{100}积分兑换{1元}，最高可抵扣订单商品实付金额的{100%}；若订单退款，积分将在订单完全退款后返还。`,
      },
      {
        name: singleName,
        points: getPointNameWithNum({ num: orderDeductRule?.points, names: { complexName, singleName } }),
        amount: `${intlLiToYuan({
          currency,
          value: orderDeductRule?.amount,
        })}`,
        maxPercent: `${orderDeductRule?.maxPercent}%`,
      },
    );
    const specialTitle = `${intl?.formatMessage({
      id: '98c607b21faa48e08e3c8934c06e0369',
      defaultMessage: `特殊说明`,
    })}：`;
    const specialContent: string[] = [];
    if (getStatus) {
      getContent.push(
        intl?.formatMessage(
          {
            id: 'cd9b4d3fcde24218ba7d48ae2c035c52',
            defaultMessage: `购物获得积分：每购买{amount}，获得{points}。{node}发送至你的账户。若订单退款，将扣除所得积分。`,
          },
          {
            name: singleName,
            amount: intlLiToYuan({ value: placeOrderRule?.amount, currency }),
            points: getPointNameWithNum({
              num: placeOrderRule?.points,
              names: { complexName, singleName },
            }),
            node:
              SceneMap?.[placeOrderRule?.scene] ||
              intl.formatMessage({
                id: 'fd3035d8436e418da19054e6744df786',
                defaultMessage: '下单后',
              }),
          },
        ),
      );
    }
    if (registerStatus) {
      getContent.push(
        intl?.formatMessage(
          { id: '89bc01eac28f492e81b3553d15b71c1e', defaultMessage: `注册获得积分：注册获得{100}积分。` },
          {
            name: singleName,
            points: getPointNameWithNum({
              num: userRegisterRule?.points,
              names: { singleName, complexName },
            }),
          },
        ),
      );
    }
    if (useStatus) {
      specialContent.push(
        intl?.formatMessage(
          {
            id: 'e0417729b83543e09f5ba36594ef18da',
            defaultMessage: `积分仅可抵扣商品金额，不可抵扣运费、税费。`,
          },
          { name: singleName },
        ),
      );
    }
    specialContent.push(
      intl?.formatMessage(
        {
          id: 'c1f192ae552c441686aaa18cf82c509c',
          defaultMessage:
            '我们将为你自动使用最早产生的积分，在发生积分退回时（如购物退款），积分有效期将保持原有有效期。',
        },
        { name: singleName },
      ),
    );
    getContent.push(
      `${intl?.formatMessage(
        {
          id: '6e3c28c56e42415ba8621113be426546',
          defaultMessage: '积分有效期',
        },
        { name: singleName },
      )}：${validTimeUnitMap[globalRule?.validTimeUnit]}`,
    );
    const text = (
      <>
        {getStatus && (
          <>
            <div>{getTitle}</div>
            {getContent.map((item, index) => (
              <div>{`${index + 1}.${item}`}</div>
            ))}
            <br />
          </>
        )}
        {useStatus && (
          <>
            <div>{useTitle}</div>
            <div>{useContent}</div>
            <br />
          </>
        )}
        <div>{specialTitle}</div>
        {specialContent.map((item, index) => (
          <div>{`${index + 1}.${item}`}</div>
        ))}
      </>
    );
    return text;
  };
  openHtDialog({
    theme,
    dialogId,
    style: { width: 540 },
    title: intl?.formatMessage({ id: '73e46bb799d44ed7af17068e2948e9b2', defaultMessage: '使用规则' }),
    children: <div>{getRulesText()}</div>,
    footer: (
      <Button type='primary' onClick={() => closeHtDialog(dialogId)}>
        {intl?.formatMessage({ id: 'me.knew_has', defaultMessage: '我知道了' })}
      </Button>
    ),
  });
};

export default openPointsRulesDialog;
