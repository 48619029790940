import React from 'react';
import { useIntl } from 'react-intl';
import Style from './style';

const Back = (props) => {
  const {
    title = intl.formatMessage({
      id: 'c8a34ec4116e469aa8305e733e3b97b8',
      description: '申请成为分销员',
    }),
    history,
    className,
    hideMobileHeader = false,
  } = props;
  const intl = useIntl();
  const goBack = () => {
    history.goBack();
  };
  return (
    <Style>
      <div className={className}>
        <div className='pc-header'>
          <span onClick={goBack} className='back-tip'>
            <svg className='back-icon' aria-hidden='true' height='24px' width='24px'>
              <use xlinkHref='#iconic-zuoyouqiehuan' />
            </svg>
            <i className='back-text'>
              {intl.formatMessage({
                id: 'general.return',
                description: '返回',
              })}
              /
            </i>
          </span>

          <span className='title'>{title}</span>
        </div>
        {!hideMobileHeader && (
          <div className='mobile-header'>
            <svg className='back-icon' onClick={goBack} aria-hidden='true' height='24px' width='24px'>
              <use xlinkHref='#iconic-zuoyouqiehuan' />
            </svg>
            <span className='title'>{title}</span>
          </div>
        )}
      </div>
    </Style>
  );
};

export default Back;
