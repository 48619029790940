import { LitePagination } from 'zent';
import Style from './style';

const Pagination = ({ pagination, setPagination, className = {} }) => {
  return (
    <Style className={className}>
      {pagination.total > pagination.pageSize && (
        <div className='page-info'>
          <LitePagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={setPagination}
          />
        </div>
      )}
    </Style>
  );
};
export default Pagination;
