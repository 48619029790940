import React from 'react';
import Style from './style';

const AddressWarnTip = (props) => {
  const { text = '', onClick = () => {}, color = '', showRight = true } = props;
  return (
    <Style style={{ color }} onClick={onClick}>
      <svg className='tip_icon' aria-hidden='true'>
        <use xlinkHref='#iconic-jinggao' />
      </svg>
      {text}
      {showRight && (
        <svg className='tip_icon' aria-hidden='true' style={{ fill: color }}>
          <use xlinkHref='#iconic-xiala' />
        </svg>
      )}
    </Style>
  );
};

export default AddressWarnTip;
