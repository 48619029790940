// import { , Image, } from '@tarojs/components';
import React, { useEffect, useState } from 'react';
import Image from 'components/base/image';
import { Icon } from 'components/base';
import { Style } from './style';
import cn from 'classnames';

export const NormalProfile = ({
  url,
  lastName,
  index = 0, // 用数组下标 可以保证一个人的头像颜色是一致的，不会变
}) => {
  /** 随机背景色 */
  const profileColor = ['#FE7449', '#363BAD', '#34C09F', '#334258', '#4985FE', '#F5B231'];
  const lastNameChar = lastName.charAt(0); // 姓首字母
  const profileStyle = profileColor[index % profileColor.length];

  return (
    <div className='profile-box'>
      {!!url && <Image className='profile-item' src={url} style={{ height: '100%', width: '100%' }} />}

      {!url && !!lastNameChar && (
        <div className='profile-item' style={{ background: profileStyle }}>
          {lastNameChar}
        </div>
      )}
      {!url && !lastNameChar && (
        <div className='profile-item default-profile-item'>
          <Icon name='icondaijiarutouxiang' size='xSmall' />
        </div>
      )}
    </div>
  );
};

enum ProfileType {
  Normal = 'normal',
  Omit = 'omit',
  Default = 'default',
}

/**
 * 用户头像
 * type :头像类型
 *  normal 正常展示头像
 *  omit   带省略符号的头像
 *  default 默认头像
 * 1. 存在头像展示头像
 * 2. 不存在头像展示姓首字母，背景色随机
 */
export const UserProfile = ({
  lastName = '',
  url = '',
  flagText = '',
  size = 'normal',
  type = ProfileType.Normal,
  index = 0,
}) => {
  return (
    <Style className={cn('profile', size)}>
      {/* 有用户头像或者名字 */}
      {type === ProfileType.Normal && (
        <>
          {flagText && <div className='flag-text'>{flagText}</div>}
          <NormalProfile lastName={lastName} url={url} index={index} />
        </>
      )}
      {/* 有用户头像 */}
      {type === ProfileType.Omit && (
        <>
          <div className='dot-text'>
            <div className='dot' />
            <div className='dot' />
            <div className='dot' />
          </div>
          <NormalProfile lastName={lastName} url={url} index={index} />
        </>
      )}
    </Style>
  );
};
