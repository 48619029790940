import React, { useEffect, useState } from 'react';
import GroupRule from '../group-rule';
import GoodsRule from '../goods-rule';
import distributionApi from '../../../../api/distribution';
import Style from './style';

const DistributionRule = (props) => {
  const { affiliateId, intl, currency } = props;
  const [commissionValues, setCommissionValues] = useState([]);
  const [settlementDate, setSettlementDate] = useState('');
  useEffect(() => {
    distributionApi.getAffiliateCommissionRate({ affiliateId: Number(affiliateId) }).then((res) => {
      const { commissionValues: values = [] } = res || {};
      setCommissionValues(values || []);
    });
    distributionApi.getAdvancedSetting({ kdtId: window?.global_data?.kdtId }).then((res) => {
      const { settlementDate: lastDay } = res || {};
      setSettlementDate(lastDay);
    });
  }, []);

  // 分组模式
  const isGroupsModel = Array.isArray(commissionValues) && commissionValues.length > 0;
  return (
    <Style>
      {isGroupsModel && <GroupRule settlementDate={settlementDate} intl={intl} commissionValues={commissionValues} />}
      {!isGroupsModel && <GoodsRule settlementDate={settlementDate} currency={currency} intl={intl} />}
    </Style>
  );
};

export default DistributionRule;
