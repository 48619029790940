import styled from 'styled-components';

export default styled.div`
  .dropdown-select-wrapper {
    display: flex;
    position: relative;
    .zent-popover-wrapper {
      .zent-btn.zent-dropdown-button {
        height: auto !important;
        line-height: normal !important;
        background-color: transparent;
        border: none;
        padding: 0;
        color: var(--color_body_text) !important;
        display: flex;
        align-items: center;
        .zenticon-down {
          margin-left: 4px;
        }
      }
    }
    .dropdown-select-content {
      background-color: var(--color_card, #fff);
      color: var(--color_body_text);
      padding: 16px;
      min-width: 200px;
      max-width: 400px;
      max-height: 400px;
      overflow: auto;
      box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.08);
      border-radius: var(--border_radius_other);
    }
  }
`;
