import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Style } from './style';
import { useIntl } from 'react-intl';
import DeliveryTimePicker from '../deliver-time-picker';
import { Form, FormStrategy } from 'zent';
import { ContextOrder } from '../../../context/context-order';
import { DELIVERY_METHOD } from '../../const';
import PickupLocationDialog from 'components/page/pick-up-location-dialog';
import PickupPersonDialog from 'components/page/pick-up-person-dialog';
import { openPersonFormDialog } from 'components/page/pick-up-person-dialog/person-form-dialog';
import { formatLocationText } from 'utils/global';
import moment from 'moment';
import cn from 'classnames';
import UserForm from '../user-form';
import Footer from '../footer';
import { useRootContext } from 'client/pages/context/root';
import { getShopTimeStampByDateTime, formateTimeByTimeZone } from 'utils/datetime';

const PickupForm = (props) => {
  const { pickupValidator } = props;
  const userInfo = window?.global_data?.userInfo;
  const intl = useIntl();
  const $t = (id, defaultMessage = 'default', params = {}) => {
    if (!id) return '';
    return intl.formatMessage({ id, defaultMessage }, params);
  };

  const [visibleLocationDialog, setVisibleLocationDialog] = useState(false);
  const [visiblePersonDialog, setVisiblePersonDialog] = useState(false);
  const [pickupStatus, setPickupStatus] = useState({
    location: true,
    person: true,
    time: true,
  });

  const { theme } = useRootContext();

  const {
    orderInfo = {},
    buyerInfo = {},
    deliveryInfo = {},
    pickupInfo = {},
    checkoutPreview,
    emailReg,
    onNext,
    isSinglePageOrder,
  } = useContext(ContextOrder);

  // 是否有提货点
  const hasPickupLocation = useMemo(() => {
    return deliveryInfo?.locationList?.length > 0;
  }, [deliveryInfo?.locationList]);
  // 是否有提货人
  const hasPickupPerson = useMemo(() => {
    return pickupInfo.firstName && pickupInfo.lastName && pickupInfo.phone;
  }, [pickupInfo]);
  // 勾选的提货点
  const selectLocationItem = useMemo(() => {
    return deliveryInfo?.locationList?.find((item) => item.selected === 1);
  }, [deliveryInfo?.locationList]);

  const pickupForm = Form.useForm(FormStrategy.View);

  // 确认提货点
  const confirmPickupLocation = (item) => {
    if (item?.locationId && item?.locationId != selectLocationItem?.locationId) {
      checkoutPreview({
        deliveryInfo: {
          selectedDeliveryMethod: DELIVERY_METHOD.pickup,
          locationInfo: {
            locationId: item.locationId,
          },
        },
        pickupInfo: {
          pickupDate: 0,
          pickupEndTime: '',
          pickupStartTime: '',
          pickupFullTime: '',
        },
      });
      setPickupStatus({
        ...pickupStatus,
        location: true,
      });
      pickupForm.patchValue({
        shipTime: {
          date: 0,
          start: '',
          end: '',
          full: '',
        },
      });
    }
    setVisibleLocationDialog(false);
  };
  // 确认提货人
  const confirmPickupPerson = (item) => {
    if (item) {
      checkoutPreview({
        pickupInfo: {
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
        },
      });
      setVisiblePersonDialog(false);
      setPickupStatus({
        ...pickupStatus,
        person: true,
      });
    }
  };

  // 提货时间处理, 枚举到组件字段
  const {
    pickupTimeSettings = {},
    pickupTimeResult = {},
    pickupDate = 0,
    pickupStartTime = '',
    pickupEndTime = '',
    pickupFullTime = '',
  } = pickupInfo;
  
  // 初始化提货时间
  useEffect(() => {
    pickupForm.patchValue({
      shipTime: {
        date: pickupDate ? formateTimeByTimeZone(pickupDate, 'YYYY-MM-DD') : 0,
        start: pickupStartTime,
        end: pickupEndTime,
        full: pickupFullTime,
      },
    });
  }, []);

  const pickupTimeValidator = () => {
    if (pickupTimeResult.success === 0) {
      return {
        name: 'apiError',
        message: pickupTimeResult.errorMsg,
      };
    }
    return null;
  };

  // 提货信息校验
  const validator = () => {
    // 提货点校验
    if (!selectLocationItem) {
      setPickupStatus({
        ...pickupStatus,
        location: false,
      });
      handleScrollPickupEle();
      return false;
    }
    // 提货人校验
    if (!hasPickupPerson) {
      setPickupStatus({
        ...pickupStatus,
        person: false,
      });
      handleScrollPickupEle();
      return false;
    }
    // 提货人时间校验
    const {
      pickupTimeSettings = {},
      pickupDate = 0,
      pickupStartTime = '',
      pickupEndTime = '',
      pickupFullTime = '',
    } = pickupInfo;
    let isSuccess = true;
    if (pickupTimeSettings.isEnablePickupTime == 1) {
      if (
        pickupTimeSettings?.pickupTimeInterval === 'all_day' &&
        pickupTimeSettings?.pickupTimeIntervalDetail === 'same_as_custom_details'
      ) {
        if (!pickupDate || !pickupFullTime) {
          isSuccess = false;
        }
      } else if (!pickupDate || !pickupStartTime || !pickupEndTime || !pickupFullTime) {
        isSuccess = false;
      }
    }
    if (!isSuccess) {
      setPickupStatus({
        ...pickupStatus,
        time: false,
      });
      handleScrollPickupEle();
      return false;
    }
    setPickupStatus({
      location: true,
      person: true,
      time: true,
    });
    return true;
  };

  useEffect(() => {
    if (pickupValidator) {
      pickupValidator.current = validator;
    }
  }, [pickupInfo, hasPickupPerson, selectLocationItem]);

  // 滚动至表单处
  const handleScrollPickupEle = () => {
    const ele = document.querySelector('.pick-up-form');
    ele.scrollIntoView({ behavior: 'smooth' });
  };

  // 添加提货人
  const handlePickupPersonDialog = () => {
    if (userInfo?.customerId) {
      setVisiblePersonDialog(true);
    } else {
      const dialogParams = {
        theme,
        intl,
        isEdit: hasPickupPerson,
        defaultValue: hasPickupPerson ? pickupInfo : null,
        onSuccess(res) {
          confirmPickupPerson(res);
        },
      };
      openPersonFormDialog(dialogParams);
    }
  };

  const commonProps = {
    intl,
    $t,
    userInfo,
    isLogin: userInfo?.customerId,
  };

  // 没有可用提货点
  if (!hasPickupLocation) {
    return (
      <Style>
        <UserForm
          intl={intl}
          form={pickupForm}
          userInfo={userInfo}
          orderInfo={orderInfo}
          buyerInfo={buyerInfo}
          checkoutPreview={checkoutPreview}
          emailReg={emailReg}
        />
        <div className='pick-up-form pick-up-empty'>
          <div className='pick-up-form-title'>{$t('b37867009ebe49fd9baa169f6ece8490', '提货信息')}</div>
          <div className='empty-box'>
            <svg className='icon' aria-hidden='true' width={120} height={120}>
              <use xlinkHref='#iconwuwuliufangshi' />
            </svg>
            <span>{$t('921322149339497095bfa7d668fd7fed', '当前无可选提货点')}</span>
          </div>
        </div>
      </Style>
    );
  }

  return (
    <Style>
      <UserForm
        intl={intl}
        form={pickupForm}
        userInfo={userInfo}
        orderInfo={orderInfo}
        buyerInfo={buyerInfo}
        checkoutPreview={checkoutPreview}
        emailReg={emailReg}
      />
      <Form form={pickupForm}>
        <div className='pick-up-form'>
          <div className='pick-up-form-title'>{$t('b37867009ebe49fd9baa169f6ece8490', '提货信息')}</div>
          <div className='pick-up-form-inline'>
            <div className={cn('pick-up-form-item', { 'form-item-error': !pickupStatus.location })}>
              <div className='pick-up-form-label'>{$t('7a3e395eee1d410c854f85176cf81d44', '提货点')}</div>
              <div className='pick-up-form-content' onClick={() => setVisibleLocationDialog(true)}>
                <div className='pick-up-form-content-inner'>
                  <div className='form-content-title'>
                    <svg className='icon' aria-hidden='true'>
                      <use xlinkHref='#icondizhi' />
                    </svg>
                    {selectLocationItem ? (
                      <span>{selectLocationItem?.name}</span>
                    ) : (
                      <span>{$t('464e1371419149e0a8dadc612485c3c6', '添加提货点')}</span>
                    )}
                  </div>
                  {selectLocationItem && (
                    <div className='form-content-desc hover' title={formatLocationText(selectLocationItem)}>
                      <span>{formatLocationText(selectLocationItem)}</span>
                    </div>
                  )}
                  {!pickupStatus.location && (
                    <div className='form-error-text'>{$t('43a571df3c6e43988da24a240b64569a', '请添加提货点')}</div>
                  )}
                </div>
                <svg className='icon icon-arrow-right' aria-hidden='true'>
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </div>
            </div>
            <div className={cn('pick-up-form-item', { 'form-item-error': !pickupStatus.person })}>
              <div className='pick-up-form-label'>{$t('ad7c2500b49540a5b8e8006ae7a7d894', '提货人')}</div>
              <div className='pick-up-form-content' onClick={handlePickupPersonDialog}>
                <div className='pick-up-form-content-inner'>
                  <div className='form-content-title'>
                    <svg className='icon' aria-hidden='true'>
                      <use xlinkHref='#iconic-gerenzhanghao' />
                    </svg>
                    {hasPickupPerson ? (
                      <span>{`${pickupInfo.firstName} ${pickupInfo.lastName}`}</span>
                    ) : (
                      <span>{$t('8facd2ab88234b7395cedf1894011bc2', '添加提货人')}</span>
                    )}
                  </div>
                  {hasPickupPerson && (
                    <div className='form-content-desc'>
                      <span>{pickupInfo.phone}</span>
                    </div>
                  )}
                  {!pickupStatus.person && (
                    <div className='form-error-text'>{$t('f678676342b04731bc632bfed2fa935f', '请添加提货人')}</div>
                  )}
                </div>
                <svg className='icon icon-arrow-right' aria-hidden='true'>
                  <use xlinkHref='#iconic-xiala' />
                </svg>
              </div>
            </div>
          </div>
          {pickupTimeSettings?.isEnablePickupTime === 1 && (
            <div className={cn('pick-up-form-item form-item-date-picker', { 'form-item-error': !pickupStatus.time })}>
              <div className='pick-up-form-label'>{$t('c1ac87fe64af42b38f7d76da57fe597f', '提货时间')}</div>
              <DeliveryTimePicker
                name='shipTime'
                settings={{
                  deliveryTimeCustomDetails: pickupTimeSettings?.pickupTimeCustomDetails,
                  deliveryTimeInterval: pickupTimeSettings?.pickupTimeInterval,
                  expectedDeliveryTimeUnit: pickupTimeSettings?.expectedPickupTimeUnit,
                  expectedDeliveryTimeValue: pickupTimeSettings?.expectedPickupTimeValue,
                  isEnableDeliveryTime: pickupTimeSettings?.isEnablePickupTime,
                  isEnableExpectedDeliveryTime: pickupTimeSettings?.isEnableExpectedPickupTime,
                  longestExpectedTime: pickupTimeSettings?.longestExpectedTime,
                  deliveryTimeIntervalDetail: pickupTimeSettings?.pickupTimeIntervalDetail,
                }}
                getSelectValue={({ date, start, end, full }) => {
                  checkoutPreview({
                    pickupInfo: {
                      pickupDate: getShopTimeStampByDateTime(date),
                      pickupStartTime: start,
                      pickupEndTime: end,
                      pickupFullTime: full,
                    },
                  });
                  setPickupStatus({
                    ...pickupStatus,
                    time: true,
                  });
                }}
                validators={[pickupTimeValidator]}
                disabled={orderInfo?.isTriggeredPay}
                isPickup={true}
              />
              {!pickupStatus.time && (
                <div className='form-error-text'>{$t('0e0cd304dc594512ba18682ec69b36a3', '请选择提货时间')}</div>
              )}
            </div>
          )}
        </div>
      </Form>
      {!isSinglePageOrder && (
        <Footer
          buttonTxt={intl.formatMessage({ description: '继续', id: 'store.theme.continue' })}
          onSubmit={() => {
            if (validator()) {
              onNext();
            }
          }}
        />
      )}
      <PickupLocationDialog
        {...commonProps}
        visibleDialog={visibleLocationDialog}
        onClose={() => setVisibleLocationDialog(false)}
        locationList={deliveryInfo?.locationList}
        locationId={selectLocationItem?.locationId}
        isSelect={true}
        onConfirm={confirmPickupLocation}
      />
      <PickupPersonDialog
        {...commonProps}
        visibleDialog={visiblePersonDialog}
        onClose={() => setVisiblePersonDialog(false)}
        defaultPerson={pickupInfo}
        onConfirm={confirmPickupPerson}
      />
    </Style>
  );
};

export default PickupForm;
