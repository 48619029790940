import { useState, useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { filterEmptyObj } from '../util';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

const useDeepCompare = (value) => {
  // useRef 有两种用法
  // 一种用来操作dom时使用
  // 还有一种不常用的用法就是可以直接赋值，在一些不改变的函数里面当变量使用
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }

  return ref.current;
};

const useDeepEffect = (callback, deps) => {
  useEffect(callback, useDeepCompare(deps));
};

const PAGE_SIZE = 20;

/**
 *
 * @param {q} url 数据请求地址
 * @param {*} params 初始化参数
 */
export default function useListData(fetchCallback, params = {}, getParams = null, onResHandle, needAuth = false) {
  const { pageSize = PAGE_SIZE } = params;
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    ...params,
    current: 1,
    pageSize,
  });
  const [total, setTotal] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const { current } = filter;

  const onPageChange = (pagination) => {
    setFilter({
      ...filter,
      current: pagination.current,
    });
  };
  /**
   * 设置筛选条件，保留之前的选择
   * @param {筛选条件} params
   */
  const onFilterChange = (params) => {
    setFilter({
      ...filter,
      ...params,
      current: 1,
      pageSize,
    });
  };

  const fetchData = async () => {
    const params = filterEmptyObj(getParams ? getParams(filter) : { ...filter, page: filter?.current });
    setLoading(true);
    delete params?.current;
    fetchCallback(params).then((res = {}) => {
      const { items = [], paginator = {} } = onResHandle ? onResHandle(res) : res;
      const { totalCount } = paginator;
      unstable_batchedUpdates(() => {
        setTotal(totalCount);
        setDataSource(items);
        setLoading(false);
      });
    });
  };

  useDeepEffect(() => {
    if (needAuth) {
      // 客户登录后才能调
      // customerId会延迟获取，需要等有值才做查询
      if (filter?.customerId) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [filter]);

  return {
    loading,
    dataSource,
    filter,
    setFilter,
    total,
    onFilterChange,
    onPageChange,
    refreshListData: () => fetchData(true),
  };
}
