import styled from 'styled-components';

export default styled.div`
  .link-title-item {
    color: #969799;
  }
  .current {
    color: #323233;
  }
  .title-item-gap {
    margin: 0 8px;
  }
`;
