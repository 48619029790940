import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  .member-goods-image {
  }
  .default-img.icon {
    opacity: 0.5 !important;
  }

  @media screen and (max-width: 750px) {
    .member-img {
      width: 157px !important;
      height: 157px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .member-img {
      width: 202px !important;
      height: 202px !important;
    }
  }
  .info {
    .goods-name {
      margin-top: 8px;
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      color: ${(props) => props.gColorText} !important;
    }
  }
`;

export const TagStyle = styled.div`
  margin-left: 4px;
  background: ${(props) => props.gLabelText};
  color: ${(props) => props.gLabelBg};
  padding: 2px 2px;
  font-size: 12px;

  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  .iconic-icon {
    fill: ${(props) => props.gLabelBg} !important;
  }
`;

export const PriceStyle = styled.div`
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  align-items: flex-start;
  .price {
    color: ${(props) => props.gColorBtnPrimaryBg} !important;
    display: flex;
    align-items: center;
    .price-sale {
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
      color: ${(props) => props.gColorBtnPrimaryBg} !important;
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h10)};
        color: ${(props) => props.gColorBtnPrimaryBg} !important;
      }
    }
  }

  .origin-price {
    margin-top: 4px;
    text-decoration: line-through;
    text-decoration-color: ${(props) => props.theme.hexToRGBA(props.gColorText, 0.4)} !important;
    .price-sale {
      ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
      color: ${(props) => props.theme.hexToRGBA(props.gColorText, 0.4)} !important;
      .price-symbol,
      .price-integer,
      .price-decimal {
        ${(props) => props.theme.getSalePriceFontSize(props.theme.h12)};
        color: ${(props) => props.theme.hexToRGBA(props.gColorText, 0.4)} !important;
      }
    }
  }
`;
