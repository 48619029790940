import React from 'react';
import { LayoutRow as Row, LayoutCol as Col, LayoutGrid as Grid, Button } from 'zent';
import Style from './style';

const Footer = (props) => {
  const { text = '', buttonTxt, onSubmit, loading = false, disabled = false, slotLeft, lastStep = false } = props;
  return (
    <Style lastStep={lastStep}>
      {slotLeft && slotLeft}
      <div className='action-btn-wrapper'>
        <Button
          loading={loading}
          type='primary'
          htmlType='submit'
          className='btn'
          onClick={onSubmit}
          disabled={disabled}
        >
          {buttonTxt}
        </Button>
      </div>
    </Style>
  );
};

export default Footer;
