import React from 'react';
import { Radio, LayoutRow as Row, LayoutCol as Col, LayoutGrid as Grid } from 'zent';
import Style from './style';
import { useIntl } from 'react-intl';
import { ExtraLogoShow } from './extra-logo-show';
import PayMethodItem from './pay-method-item';
import cn from 'classnames';
import { PAY_METHODS_TRANSLATE } from 'const/pay';

const RadioGroup = Radio.Group;

const PaySelect = (props) => {
  const intl = useIntl();
  const {
    list = [],
    value,
    subValue,
    onMenuChange,
    onSubMenuChange,
    emptyText = '',
    applePayStatus,
    googlePayStatus,
  } = props;

  const PAY_LOGO_NUM = window?.innerWidth > 750 ? 6 : 4;

  if (!list?.length) {
    return (
      <div className='no-payment-wrapper'>
        <svg width={60} height={60}>
          <use xlinkHref='#iconwuzhifufangshi' />
        </svg>
        <div className='tips'>
          {emptyText ||
            intl.formatMessage({
              defaultMessage: '抱歉，暂无可选的支付方式',
              id: '1232e61ae7cb4508a30b608939b8681b',
            })}
        </div>
      </div>
    );
  }
  return (
    <Style>
      <div className='select-container'>
        <Grid>
          {list.map((item) => {
            // Google Pay 和 Apple Pay 需要判断是否支持
            if (item?.paymentCategory === PAY_METHODS_TRANSLATE.APPLE_PAY && !applePayStatus) return null;
            if (item?.paymentCategory === PAY_METHODS_TRANSLATE.GOOGLE_PAY && !googlePayStatus) return null;
            return (
              <RadioGroup
                className='pay-way-radio'
                onChange={(event) => {
                  onMenuChange(event);
                }}
                value={value}
                key={item.value}
              >
                <Row className='send-row'>
                  <Col span={18}>
                    <div
                      className={cn('payment-line', {
                        'logo-position-fix': item?.allPaymentMethods?.length > 0,
                      })}
                    >
                      {item?.allPaymentMethods?.length > PAY_LOGO_NUM && (
                        <ExtraLogoShow style={{ top: '30%' }} paymentMethods={item?.allPaymentMethods} />
                      )}
                      <Radio className='pay-radio' value={item.value}>
                        <div className={cn({ 'item-wrapper': false })}>
                          <PayMethodItem
                            item={item}
                            PAY_LOGO_NUM={PAY_LOGO_NUM}
                            paymentCategory={item.paymentCategory}
                          />
                        </div>
                      </Radio>
                    </div>
                    {item?.paymentProviders?.length > 1 && value === item.value && (
                      <div className='provider-wrapper'>
                        <RadioGroup
                          onChange={(e) => {
                            onSubMenuChange(e, item);
                          }}
                          value={subValue}
                        >
                          {item?.paymentProviders.map((provider) => {
                            return (
                              <div
                                key={provider.gatewayId}
                                className={cn('payment-line', 'provider-radio-wrapper', {
                                  'logo-position-fix': provider?.paymentMethods.length > 0,
                                })}
                              >
                                {provider?.paymentMethods.length > PAY_LOGO_NUM && (
                                  <ExtraLogoShow
                                    paymentMethods={provider?.paymentMethods}
                                    style={{ top: '30%' }}
                                    level={2}
                                  />
                                )}
                                <Radio value={provider.gatewayId}>
                                  <PayMethodItem
                                    item={provider}
                                    PAY_LOGO_NUM={PAY_LOGO_NUM}
                                    paymentCategory={item.paymentCategory}
                                  />
                                </Radio>
                              </div>
                            );
                          })}
                        </RadioGroup>
                      </div>
                    )}
                  </Col>
                </Row>
              </RadioGroup>
            );
          })}
        </Grid>
      </div>
    </Style>
  );
};

export default PaySelect;
