import styled, { css } from 'styled-components';

const getStyle2CSS = (props) => {
  if (!props?.isHasFilter) {
    return css`
      .filter-header {
        padding-bottom: 0;
        border-bottom: none !important;
      }
    `;
  }
};

export default styled.div`
  .collection-filter-style-1 {
    .filter-header-body {
      display: flex;
      flex-wrap: wrap;
      gap: 28px 23px;
      padding-right: 200px;
    }
  }
  .collection-filter-style-2 {
    ${(props) => getStyle2CSS(props)}
  }

  .collection-filter-horizontal-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color_button);
    cursor: pointer;
    &.is-filter-open {
      svg {
        transform: rotate(180deg);
      }
    }
    span {
      display: flex;
      align-items: center;
      svg {
        fill: var(--color_button);
        width: 16px;
        height: 16px;
        transition: all 0.3s;
      }
    }
  }
`;
