import React, { useEffect, useContext, useMemo, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, FormStrategy } from 'zent';
import MessageInfo from '../message-info';
import Footer from '../footer';
import DeliverySelect from '../radio-select/delivery-select';
import DeliveryTimePicker from '../deliver-time-picker';
import Style from './style';
import { buriedPoint } from '../../../../util/log-buried-point';
import { ContextOrder } from '../../../context/context-order';
import moment from 'moment';
import { DELIVERY_METHOD } from '../../const';
import { getShopTimeStampByDateTime, formateTimeByTimeZone } from '../../../../util/datetime';

const SendFrom = (props) => {
  const intl = useIntl();
  const { customForm } = props;
  const sendForm = Form.useForm(FormStrategy.View);
  const form = customForm || sendForm;

  const {
    deliveryInfo = {},
    shippingAddress = {},
    checkoutPreview,
    orderInfo = {},
    lineItems = [],
    isSinglePageOrder,
    currency,
    onPrev,
    onNext,
    localDeliveryInfo = {},
    orderLoading,
  } = useContext(ContextOrder);
  const { shippingList: shippingOriginList = [], shippingInfo = {}, selectedDeliveryMethod = '' } = deliveryInfo;
  const hasShipping = !!shippingInfo?.shippingRateIds?.[0];
  const {
    deliveryTimeSettings = {},
    deliveryTimeResult = {},
    deliveryEndTime = '',
    deliveryStartTime = '',
    deliveryDate = 0,
    deliveryFullTime = '',
  } = localDeliveryInfo;

  const formRef = useRef(null);

  const shippingList = useMemo(() => {
    return shippingOriginList.map((item) => ({
      ...item,
      value: item?.shippingRateIds?.[0],
      text: item?.name,
      cost: item?.price,
      description: item?.description || item?.disabledMsg || '',
    }));
  }, [shippingOriginList]);

  const onChange = (value) => {
    const shipInfo = shippingList.find((item) => item.value === value);
    const { shippingRateIds = null, locationId = null } = shipInfo;
    buriedPoint('checkout_seleteddelivery');
    checkoutPreview({
      deliveryInfo: {
        shippingInfo: { shippingRateIds, locationId },
        selectedDeliveryMethod: locationId ? DELIVERY_METHOD.local_delivery : DELIVERY_METHOD.shipping_method,
      },
    });
  };

  useEffect(() => {
    if (!orderInfo?.token) {
      return;
    }
    if (!shippingInfo?.shippingRateIds?.[0] && shippingAddress?.countryCode) {
      // 如果订单初始化拿到的配送方式是不可选的状态，需要重新计算一下订单信息，刷新一下当前选中的配送方式
      // 多页式下单不会默认选择配送方式
      checkoutPreview();
    }
  }, [orderInfo?.token]);

  useEffect(() => {
    if (!orderInfo?.token) {
      return;
    }

    form.patchValue({
      shipTime: {
        date: deliveryDate ? formateTimeByTimeZone(deliveryDate, 'YYYY-MM-DD') : 0,
        start: deliveryStartTime || '',
        end: deliveryEndTime || '',
        full: deliveryFullTime || '',
      },
    });
    form.patchValue({ shipMethod: shippingInfo?.shippingRateIds?.[0] });
  }, [orderInfo?.token, deliveryDate, shippingInfo?.shippingRateIds]);

  const deliveryTimeValidator = () => {
    if (deliveryTimeResult.success === 0) {
      return {
        name: 'apiError',
        message: deliveryTimeResult.errorMsg,
      };
    }
    return null;
  };
  const deliveryMethodValidator = (value) => {
    if (!value) {
      return {
        name: 'required',
        message: intl.formatMessage({
          id: 'ff018a12d1604d1297213f5f0d31ecde',
          defaultMessage: '请选择配送方式',
        }),
      };
    }
    return null;
  };

  return (
    <Style>
      {!isSinglePageOrder && (
        <div className='container'>
          <MessageInfo showCount={false} />
        </div>
      )}
      <Form
        ref={formRef}
        form={form}
        layout='horizontal'
        onSubmit={() => {
          onNext();
        }}
        onSubmitFail={(err) => {
          // 不知道为啥报错不滚动定位到错误位置，设置了scrollToError也不行，自己写吧，哎
          formRef.current?.formRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
          });
        }}
        scrollToError
      >
        <div className='deliver-wrapper'>
          <div className='deliver-label'>
            {intl.formatMessage({ defaultMessage: '配送方式', id: 'e40b9656517547da8182b5120d3aaaae' })}
          </div>

          {/* 选择发货方式 */}
          {!!shippingList.length && !!shippingAddress?.countryCode ? (
            <DeliverySelect
              name='shipMethod'
              list={shippingList}
              getSelectValue={onChange}
              currency={currency}
              showCost
              validators={[deliveryMethodValidator]}
              disabled={!!orderInfo?.isTriggeredPay}
            />
          ) : (
            <div className='no-shipping-wrapper'>
              <div className='has-no-way'>
                <svg className='icon search-icon' aria-hidden='true' width={120} height={120}>
                  <use xlinkHref='#iconwuwuliufangshi' />
                </svg>
                <p>
                  <FormattedMessage
                    id={
                      shippingAddress?.countryCode
                        ? '0c4a1f96b38e43ada79f70b724e8919e'
                        : '805a1802f43d41e38948193422aece8e'
                    }
                  />
                </p>
              </div>
            </div>
          )}
        </div>
        {/* 配送时间 */}
        {hasShipping &&
          selectedDeliveryMethod === DELIVERY_METHOD.local_delivery &&
          !!deliveryTimeSettings?.isEnableDeliveryTime && (
            <div className='deliver-wrapper'>
              <div className='deliver-label'>
                {intl.formatMessage({
                  defaultMessage: '配送时间',
                  id: '717c0b4e40e4487881ce1c0f02dc61db',
                })}
              </div>
              <DeliveryTimePicker
                className='deliver-content'
                name='shipTime'
                settings={deliveryTimeSettings}
                getSelectValue={({ date, start, end, full }) => {
                  checkoutPreview({
                    localDeliveryInfo: {
                      deliveryEndTime: end,
                      deliveryStartTime: start,
                      deliveryDate: getShopTimeStampByDateTime(date),
                      deliveryFullTime: full,
                    },
                  });
                }}
                validators={[deliveryTimeValidator]}
                disabled={orderInfo?.isTriggeredPay}
              />
            </div>
          )}
        {!isSinglePageOrder && (
          <Footer
            disabled={!lineItems?.length || !shippingList.length}
            buttonTxt={intl.formatMessage({ description: '继续', id: 'store.theme.continue' })}
            loading={orderLoading}
          />
        )}
      </Form>
    </Style>
  );
};

export default SendFrom;
