import React from 'react';
import { Collapse } from 'zent';
import { Icon } from 'components/base';
import FilterCategory from '../components/filter-category';
import { useVerticalFilter, useCollectionFilter } from '../hooks';
import Style from './style';

const PanelTitle = (props) => {
  const { activeKey = [], item = {} } = props;
  const { filterItemId, filterItemName } = item;
  const isOpen = activeKey?.includes(`${filterItemId}`);
  return (
    <div className='panel-title'>
      {filterItemName} <Icon className='title-icon' name={isOpen ? 'iconguanbi1' : 'icondakai'} />
    </div>
  );
};

const CollectionFilterVertical = (props) => {
  const { onFilterChange, settings, data } = props;
  const { layout } = settings;

  const { verticalData, collapseActiveKey, setCollapseActiveKey } = useVerticalFilter({ data });
  const { updateCategoryData } = useCollectionFilter({ onFilterChange });

  return (
    <Style>
      <div className='collection-filter-vertical'>
        <Collapse activeKey={collapseActiveKey} onChange={setCollapseActiveKey}>
          {verticalData.map((item) => {
            return (
              <Collapse.Panel
                key={item?.filterItemId}
                showArrow={false}
                title={<PanelTitle item={item} activeKey={collapseActiveKey} />}
              >
                <FilterCategory
                  settings={settings}
                  direction='column'
                  data={item}
                  onCategoryChange={updateCategoryData}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>
    </Style>
  );
};

export default CollectionFilterVertical;
