/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormStrategy, Notify, Checkbox } from 'zent';
import { usePayOrder, usePaySelect, useOrderInfo, useAppleGooglePay, useNewPayOrder } from 'hook/pay';
import Footer from '../footer';
import PaySelect from 'components/page/pay/pay-select';
import MessageInfo from '../message-info';
import { buriedPoint } from '../../../../util/log-buried-point';
import { addUrlTrackInfo } from 'utils/log-buried-point/util';
import Style from './style';
import LocalStorage from 'utils/local-storage';
import { removeStorageOfAutoDiscount, SAVE_USER_BILL_ADDRESS, SHOP_CART } from 'utils/local-storage/config';
import useCountrySelect from 'hook/use-country-select';
import BillAddress from '../bill-address';
import cn from 'classnames';
import { ContextOrder } from '../../../context/context-order';
// eslint-disable-next-line lean-imports/import
import { isEmpty } from 'lodash';
import {
  PAY_METHODS_TRANSLATE,
  UN_NEED_BILLING_ADDRESS_PAY_PROVIDERS,
  UN_NEED_BILLING_ADDRESS_PAYMENT_CATEGORY,
} from 'const/pay';
import { DELIVERY_METHOD } from '../../const';
import * as cartHelper from 'helper/cart';
import { ORDER_TOKEN_FROM_CART } from '../../../../const/cart';
import CustomizedCheckout from '../customized-checkout';

const PayForm = (props) => {
  const intl = useIntl();
  const { needShip, setCompleteForm, addressForm, sendForm, isPickup, pickupValidator, $fm } = props;

  // const [sameAsShippingAddress, setSameAsShippingAddress] = useState(1); // 账单地址类型
  const [billAddressType, setBillAddressType] = useState(1); // 账单地址类型
  const [isSaveBillAddress, setIsSaveBillAddress] = useState(true);
  const [currentBillingAddress, setCurrentBillingAddress] = useState(null);
  const [billingAddressStatus, setBillingAddressStatus] = useState(true);
  // Apple Google Pay 临时变量
  const appleGoogleFormTempData = useRef({
    formValidate: false,
    payOrderParams: null,
  });

  const form = Form.useForm(FormStrategy.View);
  const { kdtId, multiCurrencyInfo, shopSetting, userInfo = {} } = props.initialData || {};
  const {
    orderDetail,
    orderToken,
    lineItems = [],
    orderInfo: previweOrderInfo,
    isSinglePageOrder,
    onPrev,
    checkoutSetting,
    getCheckoutSettingsV2,
    emailReg,
    deliveryInfo,
    localDeliveryInfo,
    getCheckoutPreviewDetail,
    priceInfo,
    setIsAppleGooglePay,
    appleGoogleBtnRef,
    setAppleGoogleBtnRef,
    refer = '',
    shippingAddress = {},
    groupedPaymentGateways,

    customizedCheckoutData,
    changeCustomizedCheckoutData,
    customizedCheckoutValidator,
    orderMessage,
    orderMessageError,
    setOrderMessage,
    setOrderMessageError,
    needAgreement,
    agreementEle,
    agreementText,
    isAgree,
    setIsAgree,
  } = useContext(ContextOrder);

  // 是否 0 元订单
  const isZeroPrice = priceInfo.totalPrice === 0;

  // Apple Google Pay DOM
  const nativeAppleGoogleBtnRef = useRef(null);
  useEffect(() => {
    if (nativeAppleGoogleBtnRef.current) {
      setAppleGoogleBtnRef(nativeAppleGoogleBtnRef.current);
    }
  }, [nativeAppleGoogleBtnRef]);

  const { selectedDeliveryMethod = '', shippingInfo = {} } = deliveryInfo;
  const { deliveryTimeSettings = {} } = localDeliveryInfo;
  const { isTriggeredPay = 0 } = previweOrderInfo;
  const isLogin = userInfo && !isEmpty(userInfo);
  const hasShipping = !!shippingInfo?.shippingRateIds?.[0]; // 是否有物流

  const backToSend = intl.formatMessage({
    id: 'order.pay.return_address',
    defaultMessage: '返回到发货',
    description: '返回到发货',
  });

  const backToMessage = intl.formatMessage({
    id: 'order.pay.return_info',
    defaultMessage: '返回到信息',
    description: '返回到信息',
  });

  const footerText = needShip ? backToSend : backToMessage;
  const { getCountryAndProvinceName } = useCountrySelect({ locale: props?.locale });

  const { orderInfo } = useOrderInfo({
    kdtId,
    token: orderToken,
  });

  /**
   * 获取支付方式的逻辑
   */
  const {
    paymentGateways,
    selectedPaymentInfo,
    onMenuChange,
    onSubMenuChange,
    selectedProvider,
    stripeComplete,
  } = usePaySelect({
    token: orderToken,
    intl,
    kdtId,
    multiCurrencyInfo,
    currency: shopSetting.currency,
    groupedPaymentGateways,
  });

  // 提交订单按钮是否可点
  const submitBtnDisabled = (!paymentGateways?.length && !isZeroPrice) || !lineItems?.length || (needShip && !hasShipping);

  const isShowBillingAddress = useMemo(() => {
    return !(
      UN_NEED_BILLING_ADDRESS_PAY_PROVIDERS.includes(selectedProvider?.providerType) ||
      UN_NEED_BILLING_ADDRESS_PAYMENT_CATEGORY.includes(selectedPaymentInfo?.paymentCategory)
    ) && !isZeroPrice && paymentGateways?.length > 0;
  }, [selectedPaymentInfo, selectedProvider, isZeroPrice, paymentGateways]);

  // const !selectedProvider?.gatewayId
  /**
   * 点击按钮进行支付的逻辑
   */
  const { onPayGoodsOrder, payLoading } = usePayOrder({
    paymentGateways,
    selectedProvider,
    selectedPaymentInfo,
    kdtId,
    intl,
    orderInfo,
    token: orderToken,
    redirectUrl: `${window.location.origin}/order/complete?orderToken=${orderToken}${addUrlTrackInfo()}`, // 支付完成的页面地址,
    language: 'en', // 刘静怡
    customizedCheckoutData,
    message: orderMessage,
    cancelUrl: orderToken ? `${window.location.origin}/me/order/${orderToken}?cancelUrl=1` : window.location.href,
    callBack: () => {
      // removeStorageWithPay(needFilterGoods, refer);
      // 和m端名字保存一致吧
      const paymentType = selectedPaymentInfo?.paymentProviders?.[0]?.paymentMethods?.[0]?.value;
      buriedPoint('add_payment_info', {
        payment_type: paymentType,
      });
    },
    errorCallBack() {
      getCheckoutPreviewDetail();
      getCheckoutSettingsV2();
    },
  });

  const { newPayOrder, newPayLoading } = useNewPayOrder({
    kdtId,
    intl,
    history: props.history,
    orderDetail,
    orderToken,
    selectedProvider,
    errorCallBack() {
      getCheckoutPreviewDetail();
      getCheckoutSettingsV2();
    },
  });

  const btnText = useMemo(() => {
    if (orderDetail?.isPointsExchange && isZeroPrice) {
      return $fm('fe3190ea29ae42f1b55f6089b82d4b97', '立即兑换');
    }
    return $fm('82255fdf2a344a43b51150dff6b6593a', '安全支付');
  }, [orderDetail, isZeroPrice]);

  /**
   * 表单校验状态, 黑科技处理方式
   * @param {boolean} formValidate - 校验通过
   * @param {Object} payOrderParams - 支付表单数据
   */
  const validator = useCallback(async () => {
    return new Promise((resolve) => {
      try {
        form.submit();
        setTimeout(() => {
          resolve(appleGoogleFormTempData.current);
        }, 200);
      } catch (err) {
        console.log(err);
        return resolve({
          formValidate: false,
          payOrderParams: null,
        });
      }
    });
  }, [form]);

  const { applePayStatus, googlePayStatus } = useAppleGooglePay({
    token: orderToken,
    priceInfo,
    paymentGateways,
    selectedProvider,
    shopSetting,
    stripeComplete,
    validator,
    appleGoogleBtnRef,
  });

  // 是否为 Apple Pay 或者 Google Pay
  const isAppleGooglePay = useMemo(() => {
    const paymentCategory = selectedPaymentInfo && selectedPaymentInfo?.paymentCategory;
    return paymentCategory === PAY_METHODS_TRANSLATE.APPLE_PAY || paymentCategory === PAY_METHODS_TRANSLATE.GOOGLE_PAY;
  }, [selectedPaymentInfo]);

  useEffect(() => {
    setIsAppleGooglePay(isAppleGooglePay);
  }, [isAppleGooglePay]);

  // 页面卸载执行 清空定时器
  useEffect(() => {
    if (setCompleteForm) setCompleteForm(form);
  }, []);

  useEffect(() => {
    if (isPickup) {
      if (isLogin) {
        setBillAddressType(2);
      } else {
        setBillAddressType(0);
      }
    } else {
      if (needShip) {
        setBillAddressType(1);
      } else {
        setBillAddressType(0);
      }
    }
  }, [isPickup, needShip]);

  // useEffect(() => {
  //     if (!needShip) {
  //         setSameAsShippingAddress(0);
  //     } else {
  //         setSameAsShippingAddress(1);
  //     }
  // }, [needShip]);

  const onSubmitOrder = () => {
    const formPayData = form.getValue();
    buriedPoint('checkout_finish_order', {
      payment: `${selectedPaymentInfo?.paymentCategory}-${selectedProvider?.name}`,
      lineItems,
      order_no: orderToken,
    });

    // 传统网页之前地址没有区号，现在要加上，没区号校验不通过
    if (needShip && shippingAddress.countryCode && (!shippingAddress?.callingCodes || !shippingAddress?.callingArea)) {
      Notify.error(
        intl.formatMessage({
          defaultMessage: '请补充“收货/账单地址”的电话区号',
          id: '238575a4e81a4c86a73df78191dc59a0',
        }),
        1500,
      );
      return;
    }

    /** 1. 支付方式 校验 */
    if (!selectedProvider?.gatewayId && !isZeroPrice) {
      Notify.error(
        intl.formatMessage({ defaultMessage: '请选择支付方式', id: '66d131f7f3d84aa488d02c8acc0b7062' }),
        1500,
      );
      appleGoogleFormTempData.current.formValidate = false;
      return;
    }

    /** 2. 账单地址 校验 */
    let billingAddress = {};
    let sameAsShippingAddress = 0;
    if (isShowBillingAddress) {
      // 需要账单地址
      if (billAddressType == 0) {
        // 使用其他地址
        billingAddress = { ...formPayData, ...getCountryAndProvinceName(formPayData) };
      } else if (billAddressType == 1) {
        // 同收货地址
        sameAsShippingAddress = needShip ? 1 : 0;
      } else {
        // 选择已有地址
        if (!currentBillingAddress) {
          setBillingAddressStatus(false);
          scrollBillingAddressEle();
          appleGoogleFormTempData.current.formValidate = false;
          return;
        } else {
          setBillingAddressStatus(true);
        }
        billingAddress = currentBillingAddress;
      }
      if (billingAddress?.countryCode && (!billingAddress?.callingCodes || !billingAddress?.callingArea)) {
        Notify.error(
          intl.formatMessage({
            defaultMessage: '请补充“收货/账单地址”的电话区号',
            id: '238575a4e81a4c86a73df78191dc59a0',
          }),
          1500,
        );
        return;
      }
    }
    // const billingAddress = isShowBillingAddress
    //     ? { ...formPayData, ...getCountryAndProvinceName(formPayData) }
    //     : {};

    if (isSaveBillAddress) {
      // 这是啥意思？不懂
      if (billingAddress?.paymentGatewayId) delete billingAddress.paymentGatewayId;
      LocalStorage.set(SAVE_USER_BILL_ADDRESS, billingAddress);
    } else {
      LocalStorage.remove(SAVE_USER_BILL_ADDRESS);
    }

    // 清空从购物车过来的token
    if (refer === SHOP_CART) {
      cartHelper.removeStorage(ORDER_TOKEN_FROM_CART);
    }
    const paymentMethodsArray = selectedProvider?.paymentMethods?.filter?.((item) => {
      return !!item?.enabled;
    });
    const paymentMethods = paymentMethodsArray?.map?.((item) => item?.value);
    if (isAppleGooglePay) {
      appleGoogleFormTempData.current = {
        formValidate: true,
        payOrderParams: {
          kdtId,
          token: orderToken,
          // billingAddress: sameAsShippingAddress && isShowBillingAddress ? { ...formPayData, ...getCountryAndProvinceName(formPayData) } : billingAddress,
          billingAddress,
          paymentGatewayId: selectedProvider?.gatewayId, // 选择的支付网关
          paymentMethods: selectedProvider?.paymentMethods?.filter((item) => !!item.enabled).map((i) => i.value),
          sameAsShippingAddress,
          message: orderMessage,
          redirectUrl: `${window.location.origin}/order/complete?orderToken=${orderToken}${addUrlTrackInfo()}`,
        },
      };
      return;
    }
    try {
      if (orderDetail?.isPointsExchange) {
        newPayOrder({
          billingAddress,
          sameAsShippingAddress,
          message: orderMessage,
          paymentMethods,
        })
      } else {
        onPayGoodsOrder({
          // billingAddress:
          //     sameAsShippingAddress && isShowBillingAddress
          //         ? { ...formPayData, ...getCountryAndProvinceName(formPayData) }
          //         : billingAddress,
          billingAddress,
          sameAsShippingAddress,
          message: orderMessage,
          paymentMethods,
        });
      }
    } catch (err) {
      const DISCOUNT_INVALID_STATUS = ['order.discount.modify'];
      if (DISCOUNT_INVALID_STATUS.includes(err?.message)) {
        // 折扣失效删除缓存中的折扣信息
        removeStorageOfAutoDiscount();
        Notify.error(
          intl.formatMessage({
            id: 'order.discount.modify',
            defaultMessage: '折扣已失效',
            description: '折扣已失效',
          }),
        );
        return;
      }
      console.log(err);
      Notify.error(err?.message);
      // 支付报错，重新计算一下订单信息
      getCheckoutPreviewDetail();
    }
  };
  const customizedCheckoutRef = useRef();
  const onSubmit = () => {
    let isFail = false;
    if (previweOrderInfo?.salesChannel === 'xhs_mp') {
      Notify.warn(intl.formatMessage({ id: '5dfb5f190d0740c9951e5a4b51cb94f0', defaultMessage: '请在小红书小程序进行支付' }));
      return false;
    }
    if (isSinglePageOrder && needShip) {
      // 单页模式下，校验其他表单
      if (!isLogin) {
        // 单页下单模式下，校验用户信息,用户信息和后端不同步，所以提交之前需要先校验一下
        const customerNo = addressForm?.getValue()?.customerNo || '';
        if (!customerNo || !emailReg.test(customerNo)) {
          isFail = true;
        }
      }
      const { shipTime, shipMethod } = sendForm.getValue();
      if (!shipMethod) {
        isFail = true;
      }
      if (selectedDeliveryMethod === DELIVERY_METHOD.local_delivery && !!deliveryTimeSettings?.isEnableDeliveryTime) {
        // 手动触发校验
        sendForm.submit();
        // 下面这段代码其实是没办法的写法，因为上面sendForm表单的校验结果没法拿到，zent的文档没有写，
        // 所以上面触发校验只是让页面有报错提示，下面还需要逻辑校验一遍，阻断提交订单操作
        if (
          deliveryTimeSettings?.deliveryTimeInterval === 'all_day' &&
          deliveryTimeSettings?.deliveryTimeIntervalDetail === 'same_as_custom_details'
        ) {
          // 全天24小时并且分段方式是按时间段，不需要开始时间和结束时间
          if (!shipTime.date || !shipTime.full) {
            isFail = true;
          }
        } else if (!shipTime.date || !shipTime.start || !shipTime.end || !shipTime.full) {
          isFail = true;
        }
      }
    }
    // 自提触发表单校验
    if (isSinglePageOrder && isPickup) {
      if (!pickupValidator()) return false;
    }
    // 自定义结账信息判断
    if (!customizedCheckoutValidator()) {
      return false;
    }
    if (needAgreement && !isAgree) {
      Notify.warn($fm('c01e37eb5aa94329a313c61e35f4050f', '请同意{text}', {text: agreementText}));
      return false;
    }
    if (isFail) {
      appleGoogleFormTempData.current.formValidate = false;
      return;
    }
    onSubmitOrder();
  };

  const onSubmitFail = (err) => {
    console.log("🚀 ~ onSubmitFail ~ err:", err)
    // 当账单地址同收货地址 账单地址没有校验通过也让onSubmit
    // if (isShowBillingAddress && sameAsShippingAddress === 0) {
    //     Notify.error(
    //         intl.formatMessage({ defaultMessage: '请输入正确的账单地址', id: '2d4dc19a8d3840a593c35889272e5371' }),
    //     );
    //     appleGoogleFormTempData.current.formValidate = false;
    //     return;
    // }
    // onSubmit();
    scrollBillingAddressEle();
  };

  const scrollBillingAddressEle = () => {
    const ele = document.querySelector('.bill-address-input-wrapper');
    ele &&
      ele.scrollIntoView({
        behavior: 'smooth',
      });
  };

  return (
    <Style>
      <Form form={form} onSubmit={onSubmit} onSubmitFail={onSubmitFail}>
        {!isSinglePageOrder && (
          <div className='container'>
            <MessageInfo />
          </div>
        )}

        {
          !isZeroPrice &&
          <div className='pay-methods-wrapper'>
            <div className='send-way'>
              {intl.formatMessage({ defaultMessage: '选择付款方式', id: 'f0a2d1fbec4a4acb9b5bebdf9c839682' })}
            </div>

            {!paymentGateways?.length ? (
              <div className='no-payment-wrapper'>
                <svg width={60} height={60}>
                  <use xlinkHref='#iconwuzhifufangshi' />
                </svg>
                <div className='tips'>
                  {intl.formatMessage({
                    defaultMessage: orderToken
                      ? '抱歉，暂无可选的支付方式'
                      : '请先填写收货地址，才能给你提供最优的支付选择',
                    id: orderToken ? '1232e61ae7cb4508a30b608939b8681b' : 'ff29390eb3544ed5b4515b1e6fb342f0',
                  })}
                </div>
              </div>
            ) : (
              <PaySelect
                value={selectedPaymentInfo?.value}
                subValue={selectedProvider?.gatewayId}
                list={paymentGateways}
                onMenuChange={onMenuChange}
                onSubMenuChange={onSubMenuChange}
                emptyText={
                  orderToken &&
                  intl.formatMessage({
                    defaultMessage: '请先填写收货地址，才能给你提供最优的支付选择',
                    id: 'ff29390eb3544ed5b4515b1e6fb342f0',
                  })
                }
                applePayStatus={applePayStatus}
                googlePayStatus={googlePayStatus}
              />
            )}
          </div>
        }
        {isShowBillingAddress && (
          <div
            className={cn('container', 'bill-address-input-wrapper', {
              'is-single-page': isSinglePageOrder,
            })}
          >
            <BillAddress
              intl={intl}
              locale={props?.locale}
              needShip={needShip}
              billAddressForm={form}
              billAddressType={billAddressType}
              setBillAddressType={setBillAddressType}
              currentBillingAddress={currentBillingAddress}
              setCurrentBillingAddress={setCurrentBillingAddress}
              setIsSaveBillAddress={setIsSaveBillAddress}
              billingAddressStatus={billingAddressStatus}
              // setBillAddressType={setSameAsShippingAddress}
              // sameAsShippingAddress={sameAsShippingAddress}
              // hasShippingAddress={needShip}
            />
          </div>
        )}
        {/* 其他信息 */}
        <div
          ref={customizedCheckoutRef}
          className={cn('container', {'is-single-page': isSinglePageOrder})}
          style={{ marginTop: 12, paddingBottom: 24 }}
        >
          <CustomizedCheckout
            intl={intl}
            $fm={$fm}
            token={orderToken}
            checkoutSetting={checkoutSetting}
            customizedCheckoutData={customizedCheckoutData}
            changeCustomizedCheckoutData={changeCustomizedCheckoutData}
            lineItems={lineItems}
            shippingAddress={shippingAddress}
            deliveryInfo={deliveryInfo}
            requiresShipping={previweOrderInfo?.requiresShipping}
            orderMessage={orderMessage}
            orderMessageError={orderMessageError}
            setOrderMessage={setOrderMessage}
            setOrderMessageError={setOrderMessageError}
          />
        </div>
        {!isSinglePageOrder && (
          <>
            {!isAppleGooglePay && (
              <Footer
                disabled={submitBtnDisabled}
                text={footerText}
                lastStep={true}
                buttonTxt={btnText}
                loading={payLoading || newPayLoading}
                onChangeSameAsShippingAddress
                slotLeft={
                  needAgreement ?
                    <Checkbox
                      className='agreement-checkbox-wrap'
                      checked={isAgree}
                      onChange={() => setIsAgree(!isAgree)}
                    >
                      <div className='agreement-content'>
                        <span>{$fm('c6d82602e3c547a5a048230c3cb61d02', '已经阅读并同意')}</span>&nbsp;
                        <span className='agreement-text'>{agreementEle}</span>
                      </div>
                    </Checkbox> :
                    null
                }
              />
            )}
            {/* 单页支付 Apple Google Pay 支付按钮 */}
            <div
              ref={nativeAppleGoogleBtnRef}
              className={cn('payment-request-button-wrap', submitBtnDisabled && 'payment-request-button-disabled')}
              style={{ display: isAppleGooglePay ? 'block' : 'none' }}
            >
              {
                needAgreement ?
                  <Checkbox
                    className='agreement-checkbox-wrap'
                    checked={isAgree}
                    onChange={() => setIsAgree(!isAgree)}
                  >
                    <div className='agreement-content'>
                      <span>{$fm('c6d82602e3c547a5a048230c3cb61d02', '已经阅读并同意')}</span>&nbsp;
                      <span className='agreement-text'>{agreementEle}</span>
                    </div>
                  </Checkbox> :
                  null
              }
              <div id='payment-request-button'></div>
            </div>
          </>
        )}
      </Form>
    </Style>
  );
};

export default PayForm;
