import React from 'react';
import { intlLiToYuan } from 'utils/money';
import openUsePointsDialog from '../../../use-points-dialog';
import { getPointNameWithNum } from 'utils/points';
import cn from 'classnames';
import Styles from './style';

const PointsSelector = (props) => {
  const { intl, currency, theme, pointsInfo = {}, checkoutPreview, canEdit = true } = props;
  const {
    singleName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
    complexName = intl.formatMessage({
      id: '267b5b363bbd494783f70402f93697d5',
      defaultMessage: '积分',
    }),
    usablePoints = 0,
    selectedPoints = 0,
    pointsDeductAmount = 0,
    pointsDeductRule,
  } = pointsInfo;

  const hasUseable = usablePoints > 0 && selectedPoints <= 0; // 有可用，但未使用
  const hasUsed = selectedPoints > 0; // 已使用
  const hasNull = usablePoints <= 0; // 无可用

  const rightSlot = () => {
    if (hasNull) {
      return intl.formatMessage({ id: '31c331a157de4e6f91623bb10693bfd9', defaultMessage: '暂无可用' });
    }
    if (hasUseable) {
      return (
        <>
          <img src='https://intl-image.yzcdn.cn/images/public_files/89f5107142d72c18f404e6ca92cf7051.png' />
          {intl.formatMessage(
            { id: '3abac6b7701c4e1f9911af7a5c914729', defaultMessage: `${usablePoints}积分可用` },
            { points: getPointNameWithNum({ num: usablePoints, names: { complexName, singleName } }) },
          )}
        </>
      );
    }
    return `-
      ${intlLiToYuan({
        currency,
        value: pointsDeductAmount,
      })}`;
  };

  const onUsedPointsChange = (val) => {
    checkoutPreview({ pointsInfo: val });
  };

  if (!pointsDeductRule) {
    return null;
  }

  return (
    <Styles>
      <span className='main-title'>{singleName}</span>
      <div
        className={cn('sub-title', 'points-amount', {
          'points-none': hasNull,
          'points-used': hasUsed,
          'points-uasabled': hasUseable,
        })}
        onClick={() => {
          if (!hasNull && canEdit)
            openUsePointsDialog({ theme, intl, currency, pointsInfo, onConfirm: onUsedPointsChange });
        }}
      >
        {rightSlot()}
        {canEdit && (
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#iconic-xiala' />
          </svg>
        )}
      </div>
    </Styles>
  );
};

export default PointsSelector;
