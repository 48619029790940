import styled from 'styled-components';

export default styled.div`
  .back-icon {
    width: 16px;
    height: 16px;
    fill: #1a1a1b;
    vertical-align: bottom;
  }

  .back-tip {
    cursor: pointer;
    i {
      font-style: normal;
      font-size: 14px;
      font-weight: 300;
      color: #1a1a1b;
      line-height: 14px;
    }
  }

  .back-text {
    font-size: 14px;
    font-weight: 300;
    color: #1a1a1b;
    line-height: 14px;
    display: inline-block;
    margin-left: 2px;
  }

  .pc-header {
    display: block;
    .title {
      margin-left: 8px;
      font-size: 26px;
      font-weight: 500;
      color: #1a1a1b;
      line-height: 26px;
    }
  }

  .mobile-header {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .pc-header {
      display: none;
    }

    .mobile-header {
      display: block;
      text-align: center;
      padding: 17px 16px;
      position: relative;
      background: #fff;
    }

    .back-icon {
      vertical-align: middle;
      position: absolute;
      left: 16px;
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 18px;
    }
  }
`;
