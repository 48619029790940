import React, { useState } from 'react';
import { Radio } from 'zent';
import cn from 'classnames';
import Style from './style';
import { FILTER_ITEM_TYPE } from '../../../const';
import { formateLiToYuan } from 'utils/money';
import ClearSelectedBtn from '../clear-selected-btn';

const RadioGroup = Radio.Group;

/**
 * 单选这里要区分下 横向和竖向的展示
 *
 * */

const CategoryRadio = ({ direction, settings, data, activeOptionIds, onCategoryChange }) => {
  const { currency, layout = 'horizontal', style: filterStyle = 'style1' } = settings || {};

  const defaultCheckedId = data?.filterItemOptions?.filter((item) => item?.isSelected === 1) || [];
  const { filterItemOptions, filterItemType, filterItemTextStyle } = data || {};
  const [checkedId, setCheckedId] = useState(defaultCheckedId?.length ? defaultCheckedId?.[0]?.id : '');

  const handleChange = (item) => {
    const { id, priceRange } = item || {};
    const activeOptionIds = [];
    const activeOption = [];
    let newPriceRange = null;
    if (id === checkedId || !id) {
      // 取消
      setCheckedId('');
    } else {
      // 添加
      newPriceRange = priceRange;
      setCheckedId(id);
      activeOptionIds.push(id);
      activeOption.push(item);
    }
    if (filterItemType === FILTER_ITEM_TYPE.MIN_PRICE) {
      onCategoryChange && onCategoryChange({ ...data, activeOptionIds, priceQuery: newPriceRange });
    } else {
      onCategoryChange && onCategoryChange({ ...data, activeOptionIds, activeOption });
    }
  };

  const getName = (item) => {
    const { name, priceRange = {} } = item;
    if (filterItemType === FILTER_ITEM_TYPE.MIN_PRICE) {
      let { maxPrice, minPrice } = priceRange;
      // 价格换算
      minPrice = formateLiToYuan({ value: minPrice, currency });
      maxPrice = formateLiToYuan({ value: maxPrice, currency });

      return `${minPrice}-${maxPrice}`;
    }
    return name;
  };

  return (
    <Style layout={layout} filterStyle={filterStyle} filterItemTextStyle={filterItemTextStyle}>
      <div className='category-radio'>
        <RadioGroup value={checkedId}>
          {filterItemOptions.map((item) => {
            return (
              <Radio
                key={item?.id}
                className={cn('category-radio-item', checkedId === item.id ? 'category-radio-item-active' : '')}
                value={item?.id}
                onClick={() => {
                  handleChange(item);
                }}
              >
                {getName(item)}
              </Radio>
            );
          })}
        </RadioGroup>

        {/* <ul className="category-radio-list">
                        {filterItemOptions.map((item) => {
                            return (
                                <li
                                    className={cn(
                                        'category-radio-item',
                                        checkedId === item.id ? 'category-radio-item-active' : '',
                                    )}
                                    key={item?.id}
                                    value={item?.id}
                                    onClick={() => {
                                        handleChange(item);
                                    }}
                                    data-count={item.count}
                                >
                                    {getName(item)}
                                </li>
                            );
                        })}
                    </ul> */}
        {direction === 'column' && !!filterItemOptions?.length && (
          <ClearSelectedBtn
            disabled={!checkedId}
            onClear={() => {
              handleChange({});
            }}
          />
        )}
      </div>
    </Style>
  );
};

export default CategoryRadio;
