import styled, { css } from 'styled-components';

const getLayoutCSS = (props) => {
  const { layout = 'horizontal', direction } = props;
  if (layout === 'vertical') {
    return css`
      .filter-category {
        flex-direction: column;
      }
    `;
  } else if (direction !== 'column') {
    return css`
      margin: 26px 0;
    `;
  }
};

export default styled.div`
  ${(props) => getLayoutCSS(props)}
  .filter-category {
    display: flex;
    .filter-category-name {
      font-size: 14px;
      color: ${(props) => props.theme.colorBodyText};
      width: 80px;
      min-width: 80px;
      margin-right: 24px;
      overflow: hidden;
      display: flex;
      .filter-category-name-text {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
