import React, { useEffect, useState } from 'react';
import { intlLiToYuan } from 'utils/money';
import distributionApi from '../../../../api/distribution';
import Style from './style';

const GoodsRule = (props) => {
  const { intl, settlementDate, currency } = props;
  const { host } = window.location;
  const [limit, setLimit] = useState(0);
  const getAdvancedSetting = () => {
    distributionApi.getAdvancedSetting().then((res) => {
      const { settlementLimit = 0 } = res || {};
      setLimit(Number(settlementLimit || 0));
    });
  };

  useEffect(() => {
    getAdvancedSetting();
  }, []);

  return (
    <Style>
      <div>
        <p className='rule-title'>
          <span>
            {intl.formatMessage({
              id: 'income_rules',
              description: `收益规则`,
            })}
          </span>
        </p>
        <p className='rule-item'>
          1.
          {intl.formatMessage({
            id: 'different_commodities_commission',
            description: `不同商品，可获取的佣金比例不同，具体以商品推荐佣金比例为准。`,
          })}
        </p>
      </div>
      <p className='rule-title'>
        <span>
          {intl.formatMessage({
            id: 'settlement_instructions',
            description: `结算说明`,
          })}
        </span>
      </p>
      <p className='rule-item'>
        1.
        {intl.formatMessage({
          id: 'completely_shipped_commission',
          description: `当订单商品完全发货后，订单佣金进入待结算。`,
        })}
      </p>
      <p className='rule-item'>
        2.
        {intl.formatMessage(
          {
            id: 'commission_limit_before_settlement_date',
          },
          {
            settlementDate,
            limit: intlLiToYuan({
              currency,
              value: limit,
            }),
          },
        )}
      </p>
      {/* 先屏蔽 */}
      {/* <p className="rule-item">
                3.
                {intl.formatMessage({
                    id: 'yourself_without_commission',
                    description: `自己购买时，该订单不产生佣金。`,
                })}
            </p> */}
      <p className='rule-item'>
        3.
        {intl.formatMessage({
          id: 'refunded_without_commission',
          description: `当订单发生退款时，则该订单不产生佣金。`,
        })}
      </p>
      <p className='rule-item rule-explanation'>
        {intl.formatMessage(
          {
            id: 'host_final_right',
          },
          {
            host,
          },
        )}
      </p>
    </Style>
  );
};

export default GoodsRule;
