import React from 'react';
import { GoodsTitle, PriceItem } from 'cpn/goods-item';
import cn from 'classnames';
import { Icon } from 'components/base';
import { useMemberPlan } from 'hook/member';
import Style from './style';

export const PackageItem = (props) => {
  const { item, currency, globalStyle = {}, kdtId } = props;
  const { label_bg: gLabelBg } = globalStyle;
  const { memberPlan = {} } = useMemberPlan({ kdtId });
  const { quantity = 1, title = '', unitPrice = 0 } = item || {};

  return (
    <Style className='goods-item-one' gLabelBg={gLabelBg}>
      <div className='image-box'>
        <Icon name='iconic-huiyuanbiaoqian' size='large'></Icon>
      </div>
      <div className={cn('info')}>
        <GoodsTitle title={memberPlan.title} />
        <div className='mt10 package-title'>{title}</div>
        <div className='item-line'>
          <p className='count mt10'>x{quantity}</p>
          <PriceItem price={unitPrice} discount={0} currency={currency} className='mt10' type='row-reverse' />
        </div>
      </div>
    </Style>
  );
};
