import React, { useState, useEffect, useMemo } from 'react';
import { Dialog } from 'zent';
import Style from './style';
import TakeSuccess from './components/take-success';
import CouponBody from './components/coupon-body';
import discountApi from 'api/discount';
import { getQueryString } from 'utils';

const ACTIVITY_ID_CODE_NAME = 'activityId_';

const DiscountModal = ({ intl, initData, onChangeDiscountInfo }) => {
  const { activityId, type, shopSetting, kdtId, userInfo, search } = initData;
  // 是否需要优惠券弹窗，某些情景下跳转过来是不需要弹窗的，如：抽奖
  const { noCouponsModal = false } = getQueryString(search);
  const currency = shopSetting?.currency;
  const customerId = userInfo?.customerId;

  const [visible, setVisible] = useState(false);
  const [discountInfo, setDiscountInfo] = useState();
  const [takeCode, setTakeCode] = useState();

  const getPromoCodeDescription = () => {
    if (!kdtId || !activityId) return;
    let params = {
      activityId,
      kdtId,
    };
    if (customerId) {
      params = { ...params, customerId };
    }
    discountApi.getPromoCodeDescription(params).then((res) => {
      setDiscountInfo(res);
      onChangeDiscountInfo && onChangeDiscountInfo(res);
      setVisible(true);
    });
  };

  useEffect(() => {
    // 如果是抽奖页面过来，则不需要弹窗
    if (!noCouponsModal || noCouponsModal === 'false') {
      getPromoCodeDescription();
    }
  }, [activityId, kdtId, customerId, noCouponsModal]);

  const handleClose = () => {
    window.localStorage.setItem(`${ACTIVITY_ID_CODE_NAME}${activityId}`, `${+new Date()}`);
    setVisible(false);
  };

  return (
    <Dialog
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      closeBtn={false}
      maskClosable={false}
      footer={null}
      title=''
      style={{
        width: '460px',
        padding: 0,
      }}
    >
      <Style>
        <div className='coupon-modal-body'>
          {takeCode ? (
            <TakeSuccess
              intl={intl}
              currency={currency}
              discountInfo={discountInfo}
              takeCode={takeCode}
              onClose={handleClose}
            />
          ) : (
            <CouponBody
              intl={intl}
              initData={initData}
              discountInfo={discountInfo}
              onClose={handleClose}
              onTakeSuccess={(data) => {
                window.localStorage.setItem(`${ACTIVITY_ID_CODE_NAME}${activityId}`, `${+new Date()}`);
                setTakeCode(data);
              }}
            />
          )}
        </div>
      </Style>
    </Dialog>
  );
};

export default DiscountModal;
