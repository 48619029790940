import styled from 'styled-components';

export const Style = styled.div`
  .goods-buy-button {
    display: flex;
  }
  .operate-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.theme.getSpace([40, 56])} !important;
    &.only-one-button {
      width: 100%;
    }
    .guide-text {
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      font-weight: 400;
    }
    .price-sale {
      ${(props) => props.theme.getBaseSize(props.theme.h11)};
      .price-symbol,
      .price-integer,
      .price-decimal {
        font-weight: 400;
        ${(props) => props.theme.getBaseSize(props.theme.h11)};
      }
    }
    &.theme-sub {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)} !important;
      }
    }
    &.theme-primary {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: #fff;
      }
    }
  }
  .goods-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: ${(props) => props.theme.getSpace(props.theme.hCards)};
    grid-column-gap: ${(props) => props.theme.getSpace(props.theme.hGoods)};
  }
  @media screen and (max-width: 750px) {
    .goods-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: ${(props) => props.theme.getSpace(props.theme.hCards)};
      grid-column-gap: ${(props) => props.theme.getSpace(props.theme.hGoods)};
    }
  }
`;
