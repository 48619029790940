import React from 'react';
import Style from './style';
import cn from 'classnames';

const AddressBox = (props) => {
  const {
    firstName = '',
    lastName = '',
    address2 = '',
    address1 = '',
    zip = '',
    country = '',
    province = '',
    city = '',
    className = '',
    phone = '',
    isComplete = false,
    callingCodes = '',
    callingArea = '',
  } = props;
  return (
    <Style className={cn(className, { 'is-complete': isComplete })}>
      <div className='name-info-wrapper'>
        <span>
          {firstName}
          {lastName}
        </span>
        {!isComplete && <span className='phone'>{`${callingArea} ${callingCodes} ${phone}`}</span>}
      </div>
      <div className='other-info-wrapper'>
        {address2 && <div>{address2}</div>}
        <div>{address1}</div>
        <div>
          {zip && <span>{zip}&nbsp;</span>}
          {country && (
            <span>
              {country}
              &nbsp;
            </span>
          )}
          {province && (
            <span>
              {province}
              &nbsp;
            </span>
          )}
          {city}
        </div>
        {isComplete && <span className='phone'>{phone}</span>}
      </div>
    </Style>
  );
};

export default AddressBox;
