import React, { useState } from 'react';
import { pick } from 'lodash';
import orderApi from 'api/order';
import { Notify } from 'zent';
import moment from 'moment';
import { jumpPayUrlAddLongParams } from 'helper/pay';

/**
 * 支付的下一步行为-调用payOrder返回的
 */
export const PayNextAction = {
  ThreeDSecure: 'three_d_secure',
  RedirectToUrl: 'redirect_to_url',
  DisplayQrCode: 'display_qr_code',
  useStripeSdk: 'use_stripe_sdk',
  displayHtmlQrcode: 'display_html_qr_code',
};

export const useCardForm = ({ form, intl, payOrderParams = {} }) => {
  const [loading, setLoading] = useState(false);

  const getMonth = (value) => {
    const curValueLen = value.length;
    const str = value.substring(0, 2);
    if (isNaN(str)) return str;

    if (curValueLen < 2) {
      return Number(str) > 1 ? `0${str}` : str; // 补0
    } else {
      return Number(str) > 12 ? '12' : str; // 不能超过12
    }
  };

  const getYear = (value) => {
    const arr = value?.trim()?.split('/');
    const str = arr?.[1] && !isNaN(Number(arr?.[1])) ? Number(arr?.[1]) : '';
    return str;
  };

  const checkTime = (value) => {
    if (!value) {
      return {
        name: 'idLength',
        message: intl.formatMessage({
          id: '2bf160ce7f6f428e99853b1ad95c338f',
          defaultMessage: '请输入有效期',
        }),
      };
    }
    if (value?.length > 7) {
      return {
        name: 'idLength',
        message: intl.formatMessage({
          id: '2bf160ce7f6f428e99853b1ad95c338f',
          defaultMessage: '输入正常的有效期位数',
        }),
      };
    }

    /**
     * slice 2是只截取年的后两位比较。因为填的时候就只填了后两位
     * dayjs的月比真实数据少1 所以要加1
     */
    const curTime = `${moment().get('year')}`.slice(2) + (moment().get('month') + 1);

    // 计算当前填的时间
    const arr = value?.trim()?.split('/');
    const month = arr?.[0];
    const year = arr?.[1];
    const inputTime = Number(`${year}${month}`);

    if (isNaN(Number(month)) || isNaN(Number(year))) {
      return {
        name: 'idLength',
        message: intl.formatMessage({
          id: 'f89f7544fac247089cf189eb8be7f2d2',
          defaultMessage: '有效期要输入数字',
        }),
      };
    }

    if (Number(inputTime) < Number(curTime)) {
      return {
        name: 'idLength',
        message: intl.formatMessage({
          id: '25de3b6a675a4f34be04e9b4ebac7529',
          defaultMessage: '有效期应大于当前时间',
        }),
      };
    }
  };
  /**
   * 要维护这个函数，先看下实际交互哦
   * @param value
   */
  const onTimeFiledsChange = (value, preValue) => {
    enum OperateType {
      Delete = 'delete',
      Add = 'add',
    }
    const preValueLen = preValue?.length ?? 0;
    const curValueLen = value?.length ?? 0;
    const operateType = curValueLen < preValueLen ? OperateType.Delete : OperateType.Add; // 增加还是删除
    let newValue = value;
    const month = getMonth(value);
    const year = getYear(value);

    if (!newValue) newValue = '';
    if (operateType === OperateType.Add) {
      newValue = month.length >= 2 ? `${month} / ${year}` : value;
    }
    if (operateType === OperateType.Delete) {
      newValue = '';
    }
    form.patchValue({ time: newValue });
  };

  const onSubmit = React.useCallback((form) => {
    setLoading(true);
    const formData = form.getValue();
    const arr = formData?.time?.trim()?.split('/');
    const expiryMonth = String(Number(arr?.[0]));
    const expiryYear = String(Number(arr?.[1]));
    const orderParams = {
      ...payOrderParams,
      paymentMethodOptions: {
        type: 'card',
        card: {
          expiryMonth,
          expiryYear,
          ...pick(formData, ['cvc', 'number']),
        },
      },
    };

    return orderApi
      .payOrder(orderParams)
      .then((res) => {
        const { nextAction = {} } = res;
        const { type } = nextAction;
        /** 如果是三方认证 */
        if (PayNextAction && type === PayNextAction?.ThreeDSecure) {
          const { httpMethod, url, params } = nextAction?.threeDSecure ?? {};
          jumpPayUrlAddLongParams({
            url,
            params,
            httpMethod,
          });
        }
        if (PayNextAction && type === PayNextAction?.RedirectToUrl) {
          const { httpMethod, url, params } = nextAction?.redirectToUrl ?? {};
          jumpPayUrlAddLongParams({
            url,
            params,
            httpMethod,
          });
        }
      })
      .catch((err) => {
        console.log('报错拉', err);
        Notify.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    onSubmit,
    checkTime,
    onTimeFiledsChange,
  };
};
