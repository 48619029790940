import styled from 'styled-components';

export const Style = styled.div`
  .bill-address-title {
    font-size: 20px;
    color: var(--color_body_text);
    font-weight: 600;
  }
  .hunt-select-trigger {
    /* border-color: rgba(51, 51, 51, 0.08);*/
    border-color: var(--color_text_field_border);
  }
  .select-wrapper {
    margin-top: var(--pm-24-16);
  }
  .select-wrapper-body {
    position: relative;
    .zent-select {
      width: 100%;
      .zent-select-text {
        height: 52px;
        line-height: 40px;
      }
    }
    .zent-select-text:after {
      display: none;
    }
    .hunt-select-switch {
      width: 30px;
      height: 100%;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      line-height: 46px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 10px;
      .icon-switch {
        fill: var(--color_text_field_text, #fff);
      }
      &.hunt-select-switch-is-open-true {
        .icon-switch {
          transition: transform 0.1s linear;
          transform: rotate(180deg);
        }
      }
    }
  }

  .billing-address-input {
    background: rgba(var(--color_body_text_rgb), 0.04);
  }

  .save-as-default-wrapper {
    background: rgba(var(--color_body_text_rgb), 0.04);
    padding: 0 var(--pm-32-16) 24px;
  }
  .pay-address-wrapper {
    .bill-address-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .bill-address-card {
      margin-top: 24px;
      border: 1px solid var(--color_text_field_border);
    }
    .choose-address-btn {
      cursor: pointer;
      margin-top: 24px;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      color: var(--color_button);
      .icon-arrow-right {
        width: 16px;
        height: 16px;
        fill: var(--color_button);
        margin-left: 4px;
      }
      .error-status {
        color: rgba(236, 91, 86, 1);
      }
    }
    .footer {
      background: rgba(var(--color_body_text_rgb), 0.04);
      margin: var(--pm-24-16) 0 0;
      padding: 24px 24px 0;
      .zent-form-control {
        margin-bottom: 16px;
      }
      .zent-form-control-content {
        margin-left: 0;
      }
      .zent-input-wrapper {
        height: 52px !important;
      }
      .hunt-select-trigger {
        height: 52px;
      }
      .hunt-select-switch {
        line-height: 60px;
      }
      .zent-form-error {
        text-align: left;
      }
    }
    @media screen and (max-width: 750px) {
      .save-as-default-wrapper {
        background: #fff;
        padding: 0;
        padding: relative;
        margin-top: -12px;
      }
      .bill-address-wrapper {
        margin-top: 0;
      }
      .footer {
        background: #fff;
        padding: 16px 0;
        margin: 0;
        .address-field-wrapper {
          padding: 0;
        }
      }
    }
  }
`;
