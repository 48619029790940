import styled from 'styled-components';

export default styled.div`
  --border: 1px solid #ebedf0;
  width: 100%;
  .table-empty {
    text-align: center;
    height: 252px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 80px;
      height: 80px;
    }
  }

  .no_commission_bill {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969799;
    line-height: 20px;
    margin-top: 16px;
    text-align: center;
  }
  @media screen and (max-width: 750px) {
    /* .table-empty {
            border: none;
        } */
  }
`;
