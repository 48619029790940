import { UserProfile } from '../profile-item';
import cn from 'classnames';
import { Style } from './style';

/**
 * 用户头像列表
 *
 * type: 展示方式，impulse 重叠 tiled 平铺展示
 */
export const UserProfileList = ({
  list = [
    {},
    {
      lastName: 'liu',
    },
  ],
  size = 'normal',
  className = '',
  styleType = '',
  index = 0, // 作为生成头像随机颜色的索引
}) => {
  const isOverFive = list?.length > 5;
  const type = styleType || (isOverFive ? 'impulse' : 'tiled'); // 超过5个折叠展示
  /**
   * type 分类
   * // 大于6的人不显示
   */
  const formateList = list.slice(0, 6).map((item, index) => {
    /** 超过5个 头像要特殊处理 */
    const type = index + 1 === 5 ? 'omit' : item?.type || 'normal';
    return {
      ...item,
      type,
    };
  });

  return (
    <Style className={cn(className, type, size)}>
      {formateList?.map((item, itemIndex) => {
        /**注意此处flag主要用于没头像时，生成不同颜色的头像，但是又要做到刷新时头像颜色不变
         * 如果只有一排头像，则直接用索引 就可保证刷新时头像颜色不变
         * 如果有两排头像的时候，要保证两排头像不一样 就要取外界数组的索引了
         * 反正比较hack，但是需求又如此。。。
         */
        const flag = index || itemIndex;
        return (
          <div className='profile-item-wrapper' key={itemIndex} style={{ zIndex: formateList.length - itemIndex }}>
            <UserProfile {...item} flagText={item?.flagText} size={size} index={flag} />
          </div>
        );
      })}
    </Style>
  );
};
