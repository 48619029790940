import React from 'react';
import View from 'components/base/view';
import { VendorStyle } from './style';

export const GoodsVendor = ({ vendor }) => {
  if (!vendor) return null;
  return (
    <VendorStyle className='vendor-wrapper'>
      <div className='vendor-wrapper'>{vendor}</div>
    </VendorStyle>
  );
};
