import styled, { css } from 'styled-components';

const getFilterItemTextStyleCSS = (props) => {
  const { filterItemTextStyle = 'none' } = props;
  const textStyleList = ['none', 'lowercase', 'uppercase', 'capitalized']; // capitalized --> capitalize

  if (textStyleList.includes(filterItemTextStyle)) {
    return css`
      text-transform: ${filterItemTextStyle === 'capitalized' ? 'capitalize' : filterItemTextStyle};
    `;
  }
};

const getLayoutStyleCSS = (props) => {
  const { filterStyle, layout = 'horizontal' } = props;
  if (layout !== 'horizontal') {
    return css`
      flex-direction: column;
      gap: 12px 0;
    `;
  }
  if (filterStyle === 'style1') {
    // 下拉
    return css`
      flex-direction: column;
      gap: 16px;
    `;
  } else {
    return css`
      gap: 16px;
    `;
  }
};

export default styled.div`
  .category-radio {
    color: rgba(var(--color_body_text_rgb), 0.6);
    /* 单选横向 */
    .zent-radio-group {
      display: flex;
      flex-wrap: wrap;
      ${(props) => getLayoutStyleCSS(props)}
      .zent-radio-inner {
        background-color: transparent;
      }
      .category-radio-item-active {
        .zent-radio-inner:after {
          background-color: ${(props) => props.theme.colorButton} !important;
        }
      }
    }

    .category-radio-list {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .category-radio-item {
        display: inline-flex;
        align-items: center;
        min-height: 30px;
        box-sizing: border-box;
        padding: 6px 16px;
        border-radius: 50px;
        color: ${(props) => props.theme.colorBodyText};
        background-color: ${(props) => props.theme.colorTextField};
        cursor: pointer;
        border: 1px solid transparent;
        ${(props) => getFilterItemTextStyleCSS(props)}
        &.category-radio-item-active {
          color: var(--color_button);
          border-color: var(--color_button);
        }
      }
    }
  }
`;
