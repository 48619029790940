import styled from 'styled-components';

export default styled.div`
  margin-bottom: var(--pm-30-0);
  .pay-methods-wrapper {
    padding: 16px var(--pm-24-16) 0;
    background: #fff;
    margin-top: 16px;
    .zent-form-control-content {
      width: 100% !important;
    }
  }
  .no-payment-wrapper {
    padding-bottom: var(--pm-24-16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--pm-24-16);
    .tips {
      color: #8c8c8c;
      margin-top: var(--pm-24-16);
    }
  }
  .container {
    background: #fff;
    padding: var(--pm-24-16) var(--pm-24-16) 0;
  }
  .send-way,
  .mark-address-title {
    font-weight: 600;
    margin-bottom: 12px;
    color: #333333;
    font-size: 20px;
  }
  .tip {
    margin-bottom: var(--pm-16-10);
    color: #9b9ea4;
    font-size: var(--base-font-14-12);
    line-height: 14px;
  }
  .scan-wrapper {
    margin-top: 40px;
    text-align: center;
    img {
      width: 160px;
    }
  }
  .scan-pay-tips {
    margin-top: 16px;
    text-align: center;
    margin-bottom: 44px;
  }
  .bill-address-input-wrapper,
  .bill-mark-input-wrapper {
    margin-top: 16px;
    padding-bottom: var(--pm-24-16);
    font-weight: normal;
  }
  @media screen and (max-width: 750px) {
    .bill-address-input-wrapper {
      &.is-single-page {
        margin-bottom: 16px;
      }
    }
  }
`;
