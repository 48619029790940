import styled, { css } from 'styled-components';

export default styled.div`
  .coupon-body-wrap {
    position: relative;
  }
  .coupon-close-btn {
    position: absolute;
    right: 24px;
    top: 24px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
  }
  .coupon-body {
    background-color: var(--color_button);
    background-image: url('https://img01.yzcdn.cn/upload_files/2021/10/26/FmL-CmMOV0P_3dekJib9pjUF_U_H.png');
    background-size: cover;
    padding: 40px 36px 48px;
    .coupon-body-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .coupon-card {
      margin: 0 auto;
      .coupon-card-body {
        width: 388px;
        width: 100%;
        max-width: 100%;
        background: linear-gradient(180deg, #fffcf0 0%, #fff5cc 100%);
        /* background-image: url('https://img01.yzcdn.cn/upload_files/2021/10/26/FuGaNR2FwTkMdeSqLOrDZMnFdmhi.png'); */
        -webkit-mask: radial-gradient(circle at 10px, #0000 6px, red 0);
        -webkit-mask-position: -10px;
        -webkit-mask-size: 100% 27px;
        background-size: 100% 100%;
        opacity: 0.98;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        position: relative;
        padding: 24px 16px;
        box-sizing: border-box;
      }
      .coupon-card-decorate-1 {
        position: absolute;
        width: 62px;
        height: 62px;
        top: -27px;
        left: 11px;
        background-image: url('https://img01.yzcdn.cn/upload_files/2021/10/26/FnBDd2sWxYbH2bQG_hJb73fHEmlw.png');
        background-size: cover;
      }
      .coupon-card-decorate-2 {
        position: absolute;
        width: 62px;
        height: 62px;
        bottom: -31px;
        right: 100px;
        background-image: url('https://img01.yzcdn.cn/upload_files/2021/10/26/FoRRr2fRGhDzqBo7-OQfLJZ1mVsM.png');
        background-size: cover;
      }
      .coupon-card-name {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: #33290a;
        text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
      }
      .coupon-card-desc {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #33290a;
      }
      .coupon-card-line {
        width: 100%;
        border-bottom: 1px dashed rgba(51, 41, 10, 0.2);
        margin: 24px 0;
      }
      .coupon-card-goods-range {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #33290a;
      }
    }
  }
  .coupon-footer {
    text-align: center;
    background-color: #fff;
    padding: 24px;
    .coupon-footer-data {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #333333;
      margin-bottom: 16px;
    }
    .coupon-footer-button {
      box-sizing: border-box;
      width: 380px;
      max-width: 100%;
      height: 56px;
      border-radius: 28px;
      color: #fff !important;
      text-transform: capitalize;
      &.coupon-footer-button-disabled {
        opacity: 0.4;
      }
      &.coupon-footer-button-end {
        background: linear-gradient(315deg, #707587 0%, #bec0c7 100%), #1f2433 !important;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .coupon-body {
      padding: 24px 16px;
      .coupon-body-title {
        font-size: 18px;
        margin-bottom: 16px;
      }
      .coupon-card-body {
        padding: 16px;
        .coupon-card-name {
          font-size: 28px;
          line-height: 1.5;
        }
        .coupon-card-desc {
          font-size: 15px;
        }
        .coupon-card-goods-range {
          font-size: 12px;
        }
      }
    }
    .coupon-footer .coupon-footer-button.coupon-footer-button-end {
      font-size: 13px;
      height: 39px;
    }
  }
`;
