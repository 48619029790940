import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
  
`;

export const Style = styled.div`
  .pick-up-form {
    padding: 16px var(--pm-24-16);
    background: var(--color_card, #fff);
    border-radius: var(--border_radius_card);
    margin-top: 16px;
    &-title {
      font-weight: 600;
      margin-bottom: 24px;
      color: var(--color_body_text);
      font-size: 20px;
    }
    &-inline {
      display: flex;
      align-items: stretch;
      gap: 16px;
      margin-bottom: 16px;
    }
    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: var(--color_text_field_text);
      margin-bottom: 12px;
    }
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 24px 16px;
      border: 1px solid var(--color_text_field_border);
      cursor: pointer;
      &:hover {
        background-color: rgba(var(--color_body_text_rgb), 0.04);
      }
    }
    .form-item-error {
      .pick-up-form-content {
        border-color: rgba(236, 91, 86, 1);
      }
      &.form-item-date-picker {
        .zent-input-wrapper {
          border-color: rgba(236, 91, 86, 1) !important;
        }
      }
    }
    .form-error-text {
      color: rgba(236, 91, 86, 1);
      font-size: 14px;
      line-height: 20px;
    }
    .pick-up-form-content-inner {
      flex: 1;
      width: 0;
      .form-content-title {
        display: flex;
        align-items: center;
        color: var(--color_body_text);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
        .icon {
          width: 16px;
          height: 16px;
          fill: var(--color_button);
          margin-right: 2px;
          flex-shrink: 0;
        }
      }
      .form-content-desc {
        font-size: 14px;
        line-height: 20px;
        color: rgba(var(--color_body_text_rgb), 0.8);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.hover {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .icon-arrow-right {
      width: 16px;
      height: 16px;
      fill: var(--color_text_field_text);
    }
  }
  .pick-up-empty {
    .empty-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 40px 0;
      .icon {
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .pick-up-form {
      .pick-up-form-inline {
        display: block;
        .pick-up-form-item {
          .pick-up-form-content {
            padding: 16px;
          }
          + .pick-up-form-item {
            margin-top: 16px;
          }
        }
      }
    }
  }
`;
