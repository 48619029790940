import styled from 'styled-components';

export default styled.section`
  .float-action:hover {
    opacity: 1;
  }
  @keyframes skuMove {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .float-commit {
    position: absolute;
    bottom: 6px;
    left: 6px;
    right: 6px;
    max-height: 80%;
    height: auto;
    overflow: auto;
    background-color: #fff;
    animation: skuMove 0.5s;
    .goods-list-sku-wrapper {
      padding: 0 16px 24px;
    }
    .commit-action-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-bottom: 16px;
      & > div {
        width: 120px;
        height: 36px;
        line-height: 36px;
        flex-shrink: 1;
        text-align: center;
      }
      .sku-cancel {
        border: solid 1px #041b4a;
        color: #041b4a;
        margin-left: 16px;
      }
      .sku-commit {
        background-color: #041b4a;
        color: #fff;
        margin: 0 16px;
      }
    }
  }
  .float-action {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 60%;
    min-height: 130px;
    opacity: 0;
    transition: all 0.5s;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    .action-buy {
      background-color: #041b4a;
      color: #fff;
      transition: all 0.2s;
      margin: 0 30px 16px !important;
    }
    .action-add {
      background-color: #fff;
      color: #041b4a;
      transition: all 0.2s;
      margin: 0 30px 24px;
    }
    & > div {
      height: 44px;
      font-size: 16px;
      text-align: center;
      line-height: 44px;
      width: 80%;
      margin-left: 10%;
    }
  }
  .goods-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: relative;
    /* color: #1a1a1b; */
    cursor: pointer;
  }

  img {
    width: 100%;
  }
  .title {
    font-size: var(--base-header-font-18-14);
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    color: var(--color_body_text);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .goods-list-icon {
    width: 20px;
    height: 20px;
  }
  .price-and-wish-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }
  @media screen and (max-width: 750px) {
    .price-and-wish-wrapper {
      flex-direction: column;
      align-items: flex-start;
      .wish-wrapper {
        margin-top: 10px;
      }
    }
  }

  .price {
    font-size: var(--base-font-16-14);
    color: var(--color_sale_text);
  }
  .title {
    margin-top: 12px;
  }
  .sold-out-image {
  }
  .sold-out {
    position: absolute;
    width: 88px;
    height: 88px;
    margin: auto;
    background: #000000;
    opacity: 0.75;
    color: #fff;
    font-size: var(--base-font-14-12);
    border-radius: 50%;
    text-align: center;
    line-height: 88px;
    top: 0;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    @media screen and (max-width: 750px) {
      width: 80px;
      height: 80px;
      left: 50%;
      top: 50%;
      line-height: 80px;
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .goods-item-image {
    position: relative;
    height: 100%;
  }

  .goods-item-image-inner.customer-ratio {
    height: 0;
    padding-top: ${(props) => `calc(${props.heightPercent} / 2)`};
    padding-bottom: ${(props) => `calc(${props.heightPercent} / 2)`};
    .goods-img {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: ${(props) => (props.isCut ? 'cover' : 'contain')};
      object-position: center;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .sku-all-wrapper {
    animation: skuMove 0.5s;
    background-color: #fff;
    position: absolute;
    z-index: 500;
    bottom: 24px;
    left: -16px;
    right: -16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    max-height: 320px;
    height: auto;
    .goods-list-sku-wrapper {
      padding: 0px 8px;
    }
    .action-wrapper {
      padding: 16px 8px 0px;
    }
    box-shadow: 0px 2px 24px 0px rgba(209, 213, 219, 0.4);
    .goods-list-sku-wrapper {
      overflow: auto;
    }
    .close-wrapper {
      display: flex;
      padding-left: 8px;
      padding-top: 8px;
      justify-content: space-between;
      position: relative;
      .close-icon-wrapper {
        cursor: point;
        top: -8px;
        position: relative;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .float-action {
      display: none;
      opacity: 0;
      .action-buy {
        display: block;
        margin: 16px auto 0 !important;
      }
      .action-add {
        display: block;
        margin: 0 auto !important;
      }
      &.visible-action {
        display: block;
        opacity: 1;
      }
    }
  }
`;

export const PriceStyle = styled.div`
  .discount-text {
    margin-left: 12px !important;
  }
  .discount-price .price-symbol,
  .discount-price .price-integer,
  .discount-price .price-decimal {
    font-size: ${(props) => props.theme.getBaseFontSize([16, 22])};
  }
`;
