import { useMemo } from 'react';
import { sortList } from './const/index';
import { useIntl } from 'react-intl';

export const useSortList = ({ initData }) => {
  const intl = useIntl();
  const { sort = '', sortBy = '' } = initData?.group || {};

  // 是否默认排序自定义
  const isDefaultSortCustom = sort === 'manually' && sortBy === 'manually';

  const newSortList = useMemo(() => {
    const list = [
      ...sortList,
      {
        id: 4,
        value: {
          sortBy,
          sort,
        },
        formatMessage: {
          id: 'general.default',
          defaultMessage: '默认',
        },
      },
    ];

    return list.map((item) => {
      return {
        ...item,
        name: intl.formatMessage({
          ...item?.formatMessage,
        }),
      };
    });
  }, [sortList, sort, sortBy]);

  return {
    sortList: newSortList,
    isDefaultSortCustom,
  };
};
