/** 下单相关常量 @静怡 */

export const PAYMENT_TYPE = {
  MANUAL: 3,
  THIRD_PARTY: 1,
};

export const PAY_METHODS_TRANSLATE = {
  PAYPAL: 'PayPal',
  CREDIT_CARD: 'CREDIT_CARD',
  ALI_PAY: 'ALI_PAY',
  WECHAT_PAY: 'WECHAT_PAY',
  CRYPTOCURRENCY: 'CRYPTOCURRENCY',
  OTHER: 'OTHER',
  MANUAL: 'MANUAL',
  COD: 'cash-on-delivery',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
};

/**
 * 信用卡支付模式
 * 托管-点击信用卡我们调用第三方的弹窗
 * 直连-点击信用卡我们调用
 */
export const CardMode = {
  Host: 'hosted', // 托管
  Direct: 'direct', // 直连
};

export const UN_NEED_BILLING_ADDRESS_PAY_PROVIDERS = [5, 8];

export const UN_NEED_BILLING_ADDRESS_PAYMENT_CATEGORY = [
  PAY_METHODS_TRANSLATE.ALI_PAY,
  PAY_METHODS_TRANSLATE.COD,
  PAY_METHODS_TRANSLATE.WECHAT_PAY,
];

export enum PAY_METHODS_TYPE {
  APPLE_PAY = 22,
  GOOGLE_PAY = 23,
}
