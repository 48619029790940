import React from 'react';
import { LayoutRow as Row, LayoutCol as Col, LayoutGrid as Grid } from 'zent';
import Style from './style';
import { useIntl } from 'react-intl';

const ContactInfo = (props) => {
  const { list = [], onEdit, isNotEdit = false } = props;
  const intl = useIntl();
  return (
    <Style>
      <Grid>
        {list.map((item) => (
          <Row className='border-b' key={item.title}>
            <Col span={18}>
              <div className='main-title'>
                <p>{item.title}</p>
                <div className='detail'>
                  {item?.text || intl.formatMessage({ defaultMessage: '无', id: 'order.tips_none' })}
                </div>
              </div>
            </Col>
            {!isNotEdit && item?.canEdit && (
              <Col span={6}>
                <div className='layout-cell-right'>
                  <svg
                    onClick={() => {
                      onEdit(item);
                    }}
                    className='edit-icon'
                    aria-hidden='true'
                  >
                    <use xlinkHref='#iconic_edit' />
                  </svg>
                </div>
              </Col>
            )}
          </Row>
        ))}
      </Grid>
    </Style>
  );
};

export default ContactInfo;
