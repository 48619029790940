export const NEXT_ACTION_TYPE = {
  redirect_to_url: 'redirect_to_url',
  display_qr_code: 'display_qr_code',
  display_html_qr_code: 'display_html_qr_code',
  wechat_pay_in_mini_program: 'wechat_pay_in_mini_program',
  use_stripe_sdk: 'use_stripe_sdk',
  three_d_secure: 'three_d_secure',
};

export const PAY_ORDER_ERROR_CODE = {
  ORDER_HAS_PAID: 270102015,
  ORDER_CANCELED: 270102041,
  ORDER_REFUNDED: 270102042,
};
