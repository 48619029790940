// 可装修页面的layout
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import ShopApi from 'api/shop';
import { Message } from 'utils/postmessage';
import isFunction from 'lodash/isFunction';
import { OPEN_MODE, MessageType, ThemeDataType } from 'const';
import { enterpage } from 'utils/log-buried-point/ambush-log';
import { sendTimlingLog } from 'utils/log-buried-point/ambush-log/performance-timing';
import { globalConfigOfSectionMap } from '../../../pages/design/config/sections_map';
import { initGlobalStyle } from 'utils/set-style';
import { getQueryString } from 'utils';
import { triggerFbMessage } from 'utils/sdk/facebook-message';
import useGlobalAnimation from 'hook/use-global-animation';
import { useDecorationActiveMap } from 'hook/decoration-active-map';
import { LayoutWrapper } from 'cpn/layout-wrapper';

import { memoedWrappedSectionsMapV2, memoedWrappedSectionsMap } from '../../../pages/components/decoration-wrapper';
import cn from 'classnames';
import Style from './style';

function getBoundingClientRect(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

const useDecorationActive = ({ sectionsIndex, current }) => {
  /** 存储所有组件的高亮状态 */
  const { decorationActiveMap, changeDecorationActiveFun } = useDecorationActiveMap(sectionsIndex);
  /** 这个是啥 希望有个注释
   *
   *  我的理解，设置当前选中的组件-高亮，并且页面滚动到指定位置
   */
  useEffect(() => {
    if (current && typeof current !== 'undefined') {
      changeDecorationActiveFun(current, false);
    }
  }, [current]);

  return {
    decorationActiveMap,
    changeDecorationActiveFun,
  };
};

/** 滚动逻辑 */
const useScroll = ({ current }) => {
  /** 滚动页面到指定距离 */
  const scrollSectionInView = () => {
    const sectionEl = document.querySelector(`.section-${current}`);
    if (sectionEl) {
      sectionEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (current && typeof current !== 'undefined') {
      scrollSectionInView();
    }
  }, [current]);
};

const useLog = ({ pageType, initialData }) => {
  // 埋点-这个是不是可以换位置？放到app.ts
  useEffect(() => {
    const { goods = {}, goodsId } = initialData;
    let goodsParams = {};
    if (goodsId && goods) {
      goodsParams = {
        ...goods,
        goods_id: goodsId,
        num: goods.quantity,
        price: goods.maxPrice,
        variantId: goods.variantId,
      };
    }

    enterpage({
      params: {
        path: window.location.pathname,
        pageType,
        ...goodsParams,
      },
      initialData,
    });
    sendTimlingLog({ initialData });
  }, [pageType]);
};

/**
 * 可装修的页面
 * @param props k z
 */
const Layout = (props) => {
  const { mode, tid } = getQueryString(props.location.search);
  const {
    themeKey = ThemeDataType.ThemeData, // 代表当前需要用到的装修数据是什么-目前有很多装修json，比如会员有自己的json
    initialData = {},
    canNotEditLayout,
    history,
    pageType,
    name,
    checkoutHeader,
    showFbMsg,
    sectionsIndex: pageSectionsIndex = [], // 当前页需要的组件-如果是首页就来源于接口
    canNotDesignKey = [], // 不可以装修的组件
    pageSectionsMap = [],
    defaultActiveKey = '', // 默认高亮的组件
    onThemeChange, // 装修时数据更改，主要是为了在页面内可以拿到最新的装修数据
    pageData = {}, // 页面装修
  } = props;
  const fixSectionsIndex = ['announcement-bar']; // 固定会存在的组件
  const sectionsIndex = [...fixSectionsIndex, ...pageSectionsIndex];
  const { kdtId } = initialData;
  const isEdit = Number(mode) === OPEN_MODE.EDIT_NORMAL;
  const domain = get(initialData, 'shopInfo.primaryDomain', '');
  const [theme, setThemeData] = useState(initialData[themeKey] || {});
  const sections = get(theme, 'current.sections', {});
  const [current, setCurrent] = useState(defaultActiveKey); // 当前在装修的组件，hashId
  const [init, setInit] = useState(false);
  const canDesignIndex = sectionsIndex.filter((item) => {
    return !canNotDesignKey.includes(item);
  });

  /** 高亮正在装修的组件 */
  const { decorationActiveMap, changeDecorationActiveFun } = useDecorationActive({
    sectionsIndex: canDesignIndex,
    current,
  });
  /** 滚动页面到当前装修的组件 */
  useScroll({ current });
  /* 上报埋点* */
  useLog({
    pageType,
    initialData,
  });

  const { animationSelectorClass } = useGlobalAnimation(theme);
  const [sectionOrderInfo, setSectionOrderInfo] = useState(null);

  /** 得到预览数据 tid 预览id*/
  const getTemplateInitData = async () => {
    const getPreviewTemplate = (templateId) => {
      return ShopApi.getSystemTemplateById({
        kdtId,
        templateId,
      });
    };

    const template = tid && (await getPreviewTemplate(tid));

    return {
      template,
    };
  };

  const handleNewMessage = (data, type) => {
    window.isEdit = true;
    if (!data && MessageType.CURRENT_SECTION !== type) return;
    switch (type) {
      case MessageType.CURRENT_SECTION:
        setCurrent(data);
        break;

      case 'StorefrontMessage::SetSectionOrder':
        setThemeData(data.themeData);
        onThemeChange && onThemeChange(data.themeData);
        setSectionOrderInfo(data.payload);

        break;
      case MessageType.EDIT_INFO:
        window.EDIT_INFO = JSON.parse(data);
        break;
      case 'decoration_b_lang':
        break;
      case MessageType.ADMIN_CHANGE:
      default:
        initGlobalStyle(data);
        setThemeData(data);
        onThemeChange && onThemeChange(data);
        break;
    }
  };

  useEffect(() => {
    if (sectionOrderInfo) {
      // 这里存在数据滞后性问题 暂时采用这样的hack解决
      setTimeout(() => {
        const target = document.getElementsByClassName(`section-${sectionOrderInfo.selectedSectionGid}`)[0];
        if (target) {
          target.scrollIntoView({ block: 'start' });
          window.scrollBy(0, -280);
          // 这里需要延时不然获取的位置不准确 需要等滚动完毕 不然getBoundingClientRect获取的数据不准确
          setTimeout(() => {
            window.parent.postMessage(
              {
                type: 'StorefrontMessage::SetSectionOrder',
                payload: getBoundingClientRect(target),
              },
              '*',
            );
          }, 200);
        }
      }, 0);
    }
  }, [sectionOrderInfo]);

  /** 希望能有个注释 */
  useEffect(() => {
    if (window.self !== window.parent) {
      const message = new Message(props, handleNewMessage);
      // /** 主动取数据 */
      return () => {
        message.removeListenMessage();
      };
    }
  }, []);

  /** 希望能有个注释 */
  useEffect(() => {
    if (window.self == window.parent) {
      // 获取预览数据
      getTemplateInitData().then(({}) => {
        initGlobalStyle(theme);
      });
    }
  }, [initialData[themeKey]]);

  useEffect(() => {
    initGlobalStyle(theme);
    // 这里为什么需要把这个事件发送回去 因为从不可装修页面到首页需要更新需要更新装修左侧的数据
    window.parent.postMessage(
      {
        type: MessageType.HASH_CHANGE,
        content: {
          pathname: props.location.pathname,
        },
      },
      '*',
    );
    triggerFbMessage(showFbMsg);
  }, []);

  /**
   * 如果是装修模式，先设置下组件map
   */
  useEffect(() => {
    if (!isEdit) return;
    memoedWrappedSectionsMapV2(pageSectionsMap);
    setInit(true); // memoedWrappedSectionsMap初始化完成，重新渲染页面
  }, [isEdit]);

  /** 生成指定组件 */
  const generateEle = (hashId, index) => {
    /**全局数据-业务数据 不应该耦合在这里 */
    let { getValueAfterFormate } = globalConfigOfSectionMap.footer || {};
    const socialLinkArrFooter = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];
    const sectionSetting = sections[hashId];
    const type = sectionSetting?.type ? sectionSetting.type : hashId;

    const Elem = isEdit ? memoedWrappedSectionsMap[type] : pageSectionsMap[type]?.component;

    const elemProps = {
      key: hashId,
      isEdit, // 是否是装修模式
      history,
      initialData, // 初始化数据
      theme, // 所有的装修配置
      hashId,
      domain,
      sectionSetting, // 当前组件的装修配置
      ...sectionSetting, // 之前历史遗留，之前是解构的，但是不好维护
      checkoutHeader, // 结账的头？目测不应该出现在这里
      socialLinkArr: socialLinkArrFooter, // 底部的业务数据？目测也不应该出现在这里 这里逻辑就没有很统一导致商详的footer并没有拿到这个数据
      socialLinkArrFooter, // 底部的业务数据？目测也不应该出现在这里
      elemClass: `section-${hashId} ${animationSelectorClass}`,
      pageData,
    };
    if (!Elem) {
      return null;
    }
    const ElemWithProps = isEdit ? (
      <Elem
        {...elemProps}
        isFirst={index === 0}
        decorationActive={decorationActiveMap[hashId]}
        changeDecorationActiveFun={changeDecorationActiveFun}
      />
    ) : (
      <Elem {...elemProps} />
    );
    return !sectionSetting?.disabled && Elem ? ElemWithProps : null;
  };

  return (
    <Style>
      <div className={cn('h5-preview-container')}>
        <div className={cn('h5-preview-content')}>
          {canNotEditLayout &&
            sectionsIndex.map((hashId, index) => {
              return generateEle(hashId, index);
            })}
          {!canNotEditLayout && (
            <LayoutWrapper containerWidth={theme?.current?.container_width}>
              {sectionsIndex.map((hashId, index) => {
                return generateEle(hashId, index);
              })}
            </LayoutWrapper>
          )}
        </div>
      </div>
    </Style>
  );
};

export default Layout;
