import styled from 'styled-components';

export default styled.div`
  margin-bottom: var(--pm-30-0);
  .pay-methods-wrapper {
    padding: 16px var(--pm-24-16) 0;
    background: var(--color_card, #fff);
    margin-top: 16px;
    border-radius: var(--border_radius_card);

    .zent-form-control-content {
      width: 100% !important;
    }
  }
  .no-payment-wrapper {
    padding-bottom: var(--pm-24-16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--pm-24-16);
    .tips {
      color: #8c8c8c;
      margin-top: var(--pm-24-16);
    }
  }
  .container {
    background: var(--color_card, #fff);
    padding: var(--pm-24-16) var(--pm-24-16) 0;
    border-radius: var(--border_radius_card);
  }
  .send-way,
  .mark-address-title {
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--color_body_text);
    font-size: 20px;
  }
  .zent-textarea {
    color: var(--color_text_field_text) !important;
    background-color: var(--color_text_field) !important;
  }
  .tip {
    margin-bottom: var(--pm-16-10);
    color: #9b9ea4;
    font-size: var(--base-font-14-12);
    line-height: 14px;
  }
  .scan-wrapper {
    margin-top: 40px;
    text-align: center;
    img {
      width: 160px;
    }
  }
  .scan-pay-tips {
    margin-top: 16px;
    text-align: center;
    margin-bottom: 44px;
  }
  .bill-address-input-wrapper,
  .bill-mark-input-wrapper {
    margin-top: 16px;
    padding-bottom: var(--pm-24-16);
    font-weight: normal;
  }
  .message-tips {
    font-size: 14px;
    line-height: 20px;
    color: rgba(51, 51, 51, 0.6);
    font-weight: 400;
  }
  .consumer-message-error {
    font-size: 14px;
    line-height: 20px;
    color: #ec5b56;
    margin-top: 10px;
  }
  .payment-request-button-wrap {
    position: relative;
    &.payment-request-button-disabled::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: not-allowed;
      z-index: 2;
      opacity: 0.3;
      background-color: #fff;
    }
    iframe {
      border-radius: var(--border_radius_btn) !important;
      height: 40px !important;
    }
  }
  .agreement-checkbox-wrap {
    padding-right: var(--pm-24-16);
  }
  .agreement-content {
    font-size: 12px;
    line-height: 20px;
    color: rgb(var(--color_body_text_rgb), .6);
    text-align: left;
    .agreement-text {
      margin-left: 2px;
      overflow: hidden;
      .agreement-title {
        color: var(--color_body_text);
      }
      >* {
        margin-left: 2px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .bill-address-input-wrapper {
      &.is-single-page {
        margin-bottom: 16px;
      }
    }
    .payment-request-button-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      padding: 12px 16px;
      z-index: 1000;
      box-shadow: 0px -1px 12px rgba(26, 26, 27, 0.04);
      iframe {
        border-radius: var(--border_radius_btn) !important;
        height: 40px !important;
      }
    }
    .agreement-checkbox-wrap {
      margin-bottom: 12px;
    }
  }
`;
