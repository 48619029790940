import styled from 'styled-components';

export default styled.div`
  .info-card {
    width: 100%;
    background: #ffffff;
    padding: var(--pm-24-18);
    box-sizing: border-box;
    border-radius: 4px;
    .info-card-title {
      height: 16px;
      font-size: var(--base-header-font-16-14);
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      font-weight: 500;
      color: var(--color_body_text);
      line-height: 16px;
    }
  }

  @media screen and (max-width: 750px) {
    .info-card {
      padding: 16px;
    }
  }
`;
