import React, { useState, useEffect, useMemo } from 'react';
import { Dialog } from 'zent';
import { useIntl } from 'react-intl';
import { UserProfileList } from 'components/page/group-buying/profile-list';
import { ActivityStatus } from 'const/group-buying';
import { ShareButtons } from 'components/page/share-buttons';
import { CountDown } from 'components/page/group-buying/count-down';
import cn from 'classnames';
import { Style, GroupStatusStyle, DialogStyle } from './style';

export const InviteDialog = (props) => {
  const {
    groupBuyingInfo = {},
    goodsId,
    activityId,
    token,
    shareLinkUrl,
    showInviteDialog,
    onClose,
    handleBuriedPoint,
  } = props;
  const { participateCustomers = [], differNum, status, groupEndAt } = groupBuyingInfo;
  const [visible, setVisible] = useState(showInviteDialog);
  const [peoples, setPeople] = useState([]);
  const intl = useIntl();

  const getDiffPeople = () => {
    const list = [];
    for (let i = 0; i < differNum; i++) {
      list.push({
        type: 'normal',
      });
    }
    return list;
  };

  useEffect(() => {
    const groupPeoples =
      participateCustomers.map((item) => {
        return {
          url: item?.imgUrl,
          lastName: item?.name,
          flagText: item?.isHeader
            ? intl.formatMessage({
                id: '10defe0be4434692bd5970f468dff09a',
                defaultMessage: '团长',
              })
            : '',
        };
      }) || [];
    const diffPeople = getDiffPeople();

    setPeople([...groupPeoples, ...diffPeople]);
  }, [groupBuyingInfo]);

  useEffect(() => {
    setVisible(showInviteDialog);
  }, [showInviteDialog]);

  return (
    <div>
      <DialogStyle />
      <Dialog visible={visible} onClose={onClose} className='group-buying-invite-dialog'>
        <Style>
          <GroupStatusStyle className={cn('group-status', { 'is-success': status === ActivityStatus.GroupSuccess })}>
            <div className='group-status-left'>
              {
                <div className='status-desc'>
                  <span>
                    {intl.formatMessage(
                      {
                        id: '01e623e8c3294613bd4e7d9d0e79f7de',
                        defaultMessage: `还差 ${differNum} 人, 赶快邀请好友来拼单`,
                      },
                      {
                        differNum,
                      },
                    )}
                  </span>
                </div>
              }

              <div className='group-rest-time'>
                <span className='group-rest-time-text'>
                  {intl.formatMessage({
                    id: 'ecb122a3e41a47ae892ecceab3dc7d89',
                    defaultMessage: '剩余时间',
                  })}
                </span>
                &nbsp; <CountDown className='group-time' timeEndAt={groupEndAt} />
              </div>

              <UserProfileList className='group-card-profile' size='normal' list={peoples} />
            </div>
          </GroupStatusStyle>

          <div className='share-way'>
            <div className='share-title'>
              {intl.formatMessage({
                id: '7144174e3ed84789a49e0dda6cca7ad3',
                defaultMessage: '分享方式',
              })}
            </div>
            <ShareButtons
              // 生成海报的参数
              postInfo={{
                goodsId,
                activityId,
              }}
              showWeChat={false}
              shareThirdUrl={`group-buying/product?goodsId=${goodsId}&activityId=${activityId}&token=${token}`}
              copyUrl={shareLinkUrl}
              customClass='goods-detail-share-btns'
              socialShareData={{}}
              handleBuriedPoint={handleBuriedPoint}
            />
          </div>
        </Style>
      </Dialog>
    </div>
  );
};
