import React from 'react';
import cn from 'classnames';
import DistributionHeader from '../distribution-header';
import Style from './style';

const DistributionLayout = (props) => {
  const {
    hideMobileHeader = false,
    hidePcHeader = false,
    title,
    history,
    className,
    affiliateId,
    bgColor,
    children,
    pcLogo,
    initialData,
    currency,
    isActive = false,
    noBackground = false,
    style = {},
  } = props;

  return (
    <Style>
      <DistributionHeader
        currency={currency}
        history={history}
        bgColor={bgColor}
        pcLogo={pcLogo}
        title={title}
        affiliateId={affiliateId}
        initialData={initialData}
        hideMobileHeader={hideMobileHeader}
        hidePcHeader={hidePcHeader}
        isActive={isActive}
        noBackground={noBackground}
        style={style}
      />
      <div className={cn('page-container', className)}>{children}</div>
    </Style>
  );
};

export default DistributionLayout;
