import React, { useRef } from 'react';
import { Form, FormControl, FormError, Input, validateOption } from 'zent';
import Style from './style';

const FormFileds = (props) => {
  const {
    name = '',
    defaultValue = '',
    validators = [],
    label = '',
    placeholder = '',
    style = {},
    onFiledsChange,
    maxLength,
    className = '',
  } = props;
  const model = Form.useField(name, defaultValue, [...(validators || [])]);
  const inputNode = useRef(null);

  const onChange = React.useCallback(
    (e) => {
      model.isTouched = true;
      const preValue = model.value;
      model.value = e.target.value;
      onFiledsChange && onFiledsChange(e.target.value, preValue);
    },
    [model],
  );

  const validate = React.useCallback(() => {
    model.validate(validateOption);
  }, [model, validateOption]);

  const onFocus = () => {
    inputNode.current.input.focus();
  };

  return (
    <Style style={style} className={className}>
      <div onClick={onFocus} className='filed-item'>
        <FormControl className={model.error ? 'has-error' : ''}>
          <p className='filed-name'>{label}</p>
          <div className='input-warp'>
            <Input
              ref={inputNode}
              size='small'
              style={{ height: '56px', paddingBottom: '0px' }}
              value={model.value}
              onChange={onChange}
              onBlur={validate}
              placeholder={placeholder}
              maxLength={maxLength}
            />
          </div>
        </FormControl>
        {model.error && <FormError>{model.error.message}</FormError>}
      </div>
    </Style>
  );
};

export default FormFileds;
