import React from 'react';

/**
 * @description 口语化的日期格式化工具绝对时间
 * @example 10分钟前 | 下午2:10 | 星期二 下午2:10 | 9月27日 下午2:10
 * @param timestamp
 */
export const AbsTime = ({ date, timeZone, locale, formate = {} }) => {
  let result = '';
  const params = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...formate,
  };
  if (timeZone) {
    params.timeZone = timeZone;
  }

  if (typeof Intl !== 'undefined') {
    const df = new Intl.DateTimeFormat(locale, params);
    result = df.format(date);
  } else {
    const dateObj = new Date(date);
    result = dateObj.toLocaleDateString(locale, params);
  }

  return <span>{result}</span>;
};
