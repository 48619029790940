import React, { useState, useEffect } from 'react';
import { Dialog } from 'zent';
import Style from './style';
import QRCode from 'qrcode';

const { openDialog } = Dialog;

export const openPayScanDialog = (params) => {
  const { name = '', intl } = params;
  const way = name || intl.formatMessage({ id: '1d6e9d5c24c14dae927291c89a4bf777', defaultMessage: '手机' });
  openDialog({
    title: intl.formatMessage({ defaultMessage: '扫码支付', id: 'tong.client.scan_pay' }),
    children: <PayScanContent {...params} way={way} />,
    maskClosable: false,
  });
};

const PayScanContent = props => {
  const {
    intl,
    qrCode,
    url,
    way,
  } = props;
  const [qrcodeUrl, setQrcodeUrl] = useState();

  useEffect(() => {
    if (url) {
      QRCode.toDataURL(url, {
        width: 200,
        height: 200,
        margin: 1,
      }).then((res) => {
        setQrcodeUrl(res);
      }).catch(() => {
        setQrcodeUrl(qrCode);
      });
    } else {
      setQrcodeUrl(qrCode);
    }
  }, []);

  return (
    <Style>
      <div className='scan-wrapper'>
        <img src={qrcodeUrl} alt={way} />
      </div>
      <div className='scan-pay-tips'>
        {intl.formatMessage({ defaultMessage: '请打开{way},扫码支付', id: 'scan.qrcode.for_pay' }, { way })}
      </div>
    </Style>
  )
}
