import styled, { css } from 'styled-components';

export default styled.div`
  width: 272px;
  .zent-collapse {
    border: none;
    .zent-collapse-panel {
      margin-bottom: 16px;
    }
    .zent-collapse-panel:first-child {
      .zent-collapse-panel__title {
        padding-top: 0;
      }
    }
    .zent-collapse-panel__title {
      background-color: transparent;
      border: none;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 0;
      .panel-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: var(--color_body_text) !important;
        .title-icon {
          min-width: 14px;
          width: 14px;
          height: 14px;
          fill: var(--color_body_text);
        }
      }
    }
    .zent-collapse-panel__content-box {
      padding: 0 14px;
      border-bottom: none;
      background-color: transparent;
      .zent-collapse-panel__content {
        padding-bottom: 0;
      }
      .filter-category {
        max-height: 400px;
        overflow: auto !important;
      }
      .zent-checkbox {
        padding-top: 2px;
      }
    }
  }
`;
