// 可装修页面的layout
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import ShopApi from 'api/shop';
import isFunction from 'lodash/isFunction';
import { baseSectionsMap as pageSectionsMap } from 'config/design-section';
import { OPEN_MODE } from 'const';
import { enterpage } from 'utils/log-buried-point/ambush-log';
import { sendTimlingLog } from 'utils/log-buried-point/ambush-log/performance-timing';
import { globalConfigOfSectionMap } from '../../../pages/design/config/sections_map';
import { initGlobalStyle } from 'utils/set-style';
import { getQueryString } from 'utils';
import { triggerFbMessage } from 'utils/sdk/facebook-message';
import useGlobalAnimation from 'hook/use-global-animation';
import { LayoutWrapper } from 'cpn/layout-wrapper';
import cn from 'classnames';
import Style from './style';

const useLog = ({ pageType, initialData }) => {
  // 埋点-这个是不是可以换位置？放到app.ts
  useEffect(() => {
    enterpage({
      params: {
        path: window.location.pathname,
        pageType,
      },
      initialData,
    });
    sendTimlingLog({ initialData });
  }, [pageType]);
};

/** 无需装修的页面  */
const Layout = (props) => {
  const { mode, tid } = getQueryString(props.location.search);
  const {
    initialData = {},
    history,
    pageType,
    name,
    hideHeader,
    hideFooter,
    checkoutHeader,
    showFbMsg,
    canNotEditLayout,
    sectionsIndex = ['header, footer'], // 当前页需要的组件-如果是首页就来源于接口
  } = props;
  const { kdtId } = initialData;
  const isEdit = Number(mode) === OPEN_MODE.EDIT_NORMAL;
  const domain = get(initialData, 'shopInfo.primaryDomain', '');
  const [theme, setThemeData] = useState(initialData.themeData || {});
  const sections = get(theme, 'current.sections', {});

  useLog({
    pageType,
    initialData,
  });

  const { animationSelectorClass } = useGlobalAnimation(theme);

  const getTemplateInitData = async () => {
    const getPreviewTemplate = (templateId) => {
      return ShopApi.getSystemTemplateById({
        kdtId,
        templateId,
      });
    };

    const template = tid && (await getPreviewTemplate(tid));

    return {
      template,
    };
  };

  /** 希望能有个注释 */
  useEffect(() => {
    getTemplateInitData().then(({}) => {
      initGlobalStyle(theme);
    });
  }, [initialData.themeData]);

  useEffect(() => {
    initGlobalStyle(theme);
    triggerFbMessage(showFbMsg);
  }, []);

  /** 生成指定组件 */
  const generateEle = (hashId) => {
    /**全局数据-业务数据 不应该耦合在这里 */
    let { getValueAfterFormate } = globalConfigOfSectionMap.footer || {};
    const socialLinkArrFooter = isFunction(getValueAfterFormate) ? getValueAfterFormate(theme) : [];

    const sectionSetting = sections[hashId];
    const type = sectionSetting?.type ? sectionSetting.type : hashId;

    const Elem = pageSectionsMap[type]?.component;

    const elemProps = {
      key: hashId,
      isEdit, // 是否是装修模式
      history,
      initialData, // 初始化数据
      theme, // 所有的装修配置
      hashId,
      domain,
      sectionSetting, // 当前组件的装修配置
      ...sectionSetting,
      checkoutHeader, // 结账的头？目测不应该出现在这里
      socialLinkArrFooter, // 底部的业务数据？目测也不应该出现在这里
      elemClass: `section-${hashId} ${animationSelectorClass}`,
    };
    return !sectionSetting?.disabled && Elem ? <Elem {...elemProps} /> : null;
  };

  const TopList = ['announcement-bar', 'header'];

  const children = () => {
    return (
      <div className={cn('h5-preview-content')}>
        {!hideHeader &&
          TopList.map((hashId) => {
            return generateEle(hashId);
          })}

        {canNotEditLayout ? props.children : <LayoutWrapper>{props.children}</LayoutWrapper>}
        {!hideFooter && generateEle('footer')}
      </div>
    );
  };

  return (
    <Style>
      <div className={cn('h5-preview-container')}>
        <div className={cn('h5-preview-content')}>{children()}</div>
      </div>
    </Style>
  );
};

export default Layout;
