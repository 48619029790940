import { useState, useEffect, useMemo } from 'react';
import { intlLiToYuan } from 'utils/money';
import { MemberStatus, OrderStatus } from 'const/member';
import memberApi from 'api/member';

export const useMemberInfo = ({ currency, kdtId, customerId, intl }) => {
  const [memberStatus, setMemberStatus] = useState({});
  useEffect(() => {
    if (!kdtId || !customerId) return;
    memberApi
      .getMemberStatus({
        kdtId,
        customerId,
      })
      .then((res) => {
        setMemberStatus(res);
      });
  }, []);

  const textInfo = useMemo(() => {
    if (!memberStatus.status) return {};
    const { status = MemberStatus.None, hadSavingAmount, memberPlanInfo, orderStatus } = memberStatus;
    const money = intlLiToYuan({
      currency,
      value: hadSavingAmount,
    });
    if (status === MemberStatus.None || orderStatus === OrderStatus.Auditing) {
      return {
        text: memberPlanInfo?.spreadText,
      };
    }

    if (status === MemberStatus.Done || status === MemberStatus.Test) {
      return {
        text: intl.formatMessage(
          {
            id: '7ce491fc01b94224a8eb546f63f2bf3a',
            defaultMessage: `尊贵的${memberPlanInfo?.title}, 已为您节省${money}`,
          },
          {
            title: memberPlanInfo?.title,
            money,
          },
        ),
      };
    }
    if (status === MemberStatus.Expire || status === MemberStatus.Forbid) {
      return {
        text: intl.formatMessage(
          {
            id: '92785b5ab1c246d2a5b6587eda774cf9',
            defaultMessage: `${memberPlanInfo?.title}已过期`,
          },
          {
            title: memberPlanInfo?.title,
          },
        ),
      };
    }
  }, [memberStatus]);

  return {
    memberStatus,
    textInfo,
  };
};
