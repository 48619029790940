/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Form, Validators, Dialog, FormStrategy, Button } from 'zent';
import FormFileds from 'components/base/form-fileds';
import { useCardForm } from './hook';
import Style, { DialogStyle } from './style';

const { openDialog } = Dialog;
const CardForm = ({ intl, payOrderParams }) => {
  const form = Form.useForm(FormStrategy.View);
  const { loading, onSubmit, checkTime, onTimeFiledsChange } = useCardForm({ form, intl, payOrderParams });

  return (
    <Style>
      <DialogStyle />
      <Form form={form} className='address-form' onSubmit={onSubmit}>
        <FormFileds
          name='number'
          maxLength={19}
          validators={[
            Validators.required(
              intl.formatMessage({
                id: 'cf7f088ddcc14cdf9a381adfffef12c1',
                defaultMessage: '请输入卡号',
                description: '请输入卡号',
              }),
            ),
            Validators.minLength(
              12,
              intl.formatMessage({
                id: '8a573fa0d2fe4e7ca879d7809269b61c',
                defaultMessage: '卡号位数应在12-19',
                description: '卡号位数应在12-19',
              }),
            ),
          ]}
          label={intl.formatMessage({
            id: 'card-num',
            defaultMessage: '卡号',
            description: '卡号',
          })}
        />
        <div className='form-row'>
          <div className='form-item-row'>
            <FormFileds
              maxLength={7}
              name='time'
              validators={[checkTime]}
              label={intl.formatMessage({
                id: '2bf160ce7f6f428e99853b1ad95c338f',
                defaultMessage: '有效期（MM/YY）',
                description: '有效期（MM/YY）',
              })}
              onFiledsChange={onTimeFiledsChange}
            />
          </div>
          <div className='form-item-row'>
            <FormFileds
              name='cvc'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: '4bfc0f017cc847afb2e965bdef308c32',
                    defaultMessage: '请输入CVC',
                    description: '请输入CVC',
                  }),
                ),
              ]}
              label='CVC'
            />
          </div>
        </div>
        <div className='zent-form-actions'>
          <Button type='primary' htmlType='submit' loading={loading}>
            {intl.formatMessage({
              id: 'dfb2e81001ba44c79ee8451d85a5286b',
              defaultMessage: '验证并支付',
            })}
          </Button>
        </div>
      </Form>
    </Style>
  );
};

export const OpenCardFormDialog = (props) => {
  const { intl } = props;
  const DialogId = 'card-from-dialog';
  openDialog({
    title: intl.formatMessage({
      id: 'dfb2e81001ba44c79ee8451d85a5286b',
      defaultMessage: '信用卡验证',
    }),
    style: {
      maxWidth: '460px',
    },
    className: DialogId,
    dialogId: DialogId,
    children: <CardForm {...props} />,
    maskClosable: false,
  });
};
