import styled from 'styled-components';

export default styled.div`
  .filter-category-clear-btn {
    margin-top: 12px;
    font-size: 14px;
    color: var(--color_button);
    cursor: pointer;
    &.filter-category-clear-btn-disabled {
      cursor: default;
      color: rgba(var(--color_body_text_rgb), 0.4);
    }
  }
`;
