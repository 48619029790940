import React from 'react';
import { Dialog, Button, CopyButton, Notify } from 'zent';
import { formateTimeByTimeZone } from 'utils/datetime';
import { useCoupons } from 'hook/discount/use-coupons';
import Style from './style';

const TakeSuccess = ({ intl, currency, discountInfo, takeCode, onClose }) => {
  const { useCondition, useRange } = useCoupons({ intl, currency, data: discountInfo });

  const handleCopyAndUse = () => {
    Notify.success(
      intl.formatMessage({
        id: 'copy_successfully',
        defaultMessage: '复制成功',
      }),
    );
    onClose && onClose();
  };

  return (
    <Style>
      <div className='take-success'>
        <div className='take-success-close-btn' onClick={onClose}>
          &times;
        </div>
        <div className='take-success-section'>
          <div className='take-success-title'>
            {intl.formatMessage({
              id: '56d43743edb44ab0a16cbbe7cff955b6',
              defaultMessage: '获得一张优惠码',
            })}
          </div>
          <div className='take-success-card'>
            <div className='take-success-card-body'>
              <div className='take-code'>{takeCode}</div>
              <div className='take-code-desc'>{useCondition}</div>
              <div className='take-success-card-line'>
                <div className='take-success-card-line-body'></div>
              </div>
              <ul className='take-code-limit'>
                <li>
                  <div className='take-code-limit-name'>
                    {intl.formatMessage({
                      id: 'bf9fd3ee97b9470dbfdc3924dc3437ef',
                      defaultMessage: '有效时间',
                    })}
                  </div>
                  {formateTimeByTimeZone(discountInfo?.activityStartAt, 'YYYY.MM.DD')}-
                  {formateTimeByTimeZone(discountInfo?.activityEndAt, 'YYYY.MM.DD')}
                </li>
                <li>
                  <div className='take-code-limit-name'>
                    {intl.formatMessage({
                      id: 'apply_range',
                      defaultMessage: '适用范围',
                    })}
                  </div>
                  {useRange}
                </li>
              </ul>
            </div>
          </div>
          <div className='take-success-footer'>
            <CopyButton text={takeCode} onCopySuccess={handleCopyAndUse}>
              <Button type='primary'>
                {intl.formatMessage({
                  id: '230b020218a34dbfa2d0dba4f5031460',
                  defaultMessage: '复制去使用',
                })}
              </Button>
            </CopyButton>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default TakeSuccess;
