import React, { useEffect, useState, useContext, useMemo } from 'react';
import Style from './style';
import { BlockLoading, Form, FormStrategy, Validators } from 'zent';
import { useIntl, FormattedMessage } from 'react-intl';
import FormFileds from '../form-fileds';
import userApi from 'api/user';
import isEmpty from 'lodash/isEmpty';
import UserInfo from 'components/page/account/user-info';
import { buriedPoint } from '../../../../util/log-buried-point';
import * as cartHelper from 'helper/cart';
import { useRootContext } from 'client/pages/context/root';

const UserForm = (props) => {
  const { intl, form, userInfo, orderInfo, buyerInfo, checkoutPreview, emailReg } = props;
  const { setLoginDialogInfo } = useRootContext();

  const isLogin = useMemo(() => {
    return userInfo && !isEmpty(userInfo);
  }, [userInfo]);

  const onLoginOut = () => {
    if (window && window?.gapi) {
      const auth2 = window?.gapi.auth2.getAuthInstance();
      if (auth2) {
        auth2.signOut();
      }
    }
    /** 退出登录，删除不必要的信息 */
    userApi.logout().then(() => {
      cartHelper.notifyCartCountChange();
      window.location.reload();
    });
  };

  useEffect(() => {
    // 回填邮箱
    if (buyerInfo?.customerNo) {
      form.patchValue({ customerNo: buyerInfo?.customerNo });
    }
  }, [buyerInfo?.customerNo]);

  return (
    <Style>
      <div className='base-login-info-wrapper'>
        {isLogin && (
          <UserInfo
            {...userInfo}
            className='pay-user-info'
            rightClick={onLoginOut}
            btnText={intl.formatMessage({ description: '退出', id: 'account.me.logout' })}
          />
        )}

        <Form form={form}>
          {!isLogin && (
            <div>
              <div className='title'>
                <FormattedMessage id='order.address.contact' />
                {!isLogin && (
                  <div className='login-guide'>
                    {intl.formatMessage({
                      defaultMessage: '已有帐号',
                      id: 'login.has_account',
                    })}
                    ? &nbsp;
                    <span
                      className='login-link'
                      onClick={() => {
                        // TODO
                        setLoginDialogInfo({
                          visible: true,
                        });
                      }}
                    >
                      {intl.formatMessage({ defaultMessage: '登录', id: 'action.login' })}
                    </span>
                  </div>
                )}
              </div>
              <FormFileds
                name='customerNo'
                disabled={orderInfo?.isTriggeredPay}
                onBlur={(e) => {
                  buriedPoint('checkout_fill_address_email');
                  const userEmail = e?.target?.value || form?.getValue()?.customerNo;
                  checkoutPreview({
                    buyerInfo: { customerNo: userEmail, customerId: null },
                  });
                }}
                validators={[
                  Validators.required(
                    intl.formatMessage({
                      id: 'order.address.input_please_email',
                      defaultMessage: '请输入电子邮件',
                    }),
                  ),
                  Validators.pattern(
                    emailReg,
                    intl.formatMessage({
                      id: 'order.address.input_right_email',
                      defaultMessage: '请输入正确的电子邮件',
                    }),
                  ),
                  Validators.maxLength(
                    80,
                    intl.formatMessage(
                      {
                        id: '5f7b66782d9f4922b95cfbdf444f56a7',
                        defaultMessage: '超出最大长度80',
                      },
                      {
                        num: 80,
                      },
                    ),
                  ),
                ]}
                label={intl.formatMessage({
                  id: 'order.address.email',
                  defaultMessage: '电子邮件',
                  description: '电子邮件',
                })}
              />
            </div>
          )}
        </Form>
      </div>
    </Style>
  );
};

export default UserForm;
