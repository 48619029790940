import styled from 'styled-components';

export default styled.div`
  .info-card {
    width: 100%;
    /* background: #ffffff; */
    background-color: var(--color_card, #fff);
    padding: var(--pm-24-18);
    box-sizing: border-box;
    border-radius: var(--border_radius_card);
    .info-card-title {
      ${(props) => props.theme.getBaseSize(props.theme.h10)}
      font-family: var(--font-stack-header);
      font-style: var(--font-style-header);
      font-weight: var(--font-weight-header);
      font-weight: 500;
      color: var(--color_body_text);
    }
  }

  @media screen and (max-width: 750px) {
    .info-card {
      padding: 16px;
    }
  }
`;
