import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  .main-title {
    display: flex;
    align-items: center;
    word-break: break-word;
  }
  .sub-title {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      fill: rgba(var(--color_body_text_rgb), 0.8);;
    }
  }
  svg {
    cursor: pointer;
    fill: rgba(var(--color_body_text_rgb), 0.6);
    margin-left: 4px;
  }
`;

export const DescContentStyle = styled.div`
  .fee-desc {
    &__title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: var(--color_body_text);
    }
    &__value {
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-text-secondary);
    }
  }
`;

export const DescDetailStyle = styled.div`
  .fee-detail {
    color: var(--color_body_text);
    &__table-th {
      display: flex;
      align-items: center;
      gap: 4px;
      text-align: right;
      margin-bottom: 12px;
      .fee-detail__table-td {
        flex: 1;
        font-size: 12px;
        line-height: 17px;
        color: rgba(var(--color_body_text_rgb), 0.4) !important;
      }
    }
    &__table-tr {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      gap: 4px;
    }
    &__table-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &__table-td {
      position: relative;
      flex: 1;
      word-break: break-word;
      text-align: right;
      color: rgba(var(--color_body_text_rgb), 0.6);
      &.goods-cover-td {
        width: 32px;
        height: 32px;
        .goods-cover {
          display: block;
          width: 32px;
          max-height: 100%;
          object-fit: cover;
          border-radius: 2px;
        }
      }
      &:first-child {
        flex: 0 0 48px;
        text-align: left;
      }
      &:last-child {
        color: var(--color_body_text);
        font-weight: 500;
      }
      .default-img-box {
        width: 32px;
        height: 32px;
        border-radius: 2px;
      }
    }
    .detail-total {
      font-size: 20px;
      line-height: 28px;
      padding-top: 12px;
      border-top: 1px solid rgba(var(--color_body_text_rgb), 0.08);
      .fee-detail__table-td {
        flex: 1;
      }
    }
    .num-tag {
      position: absolute;
      top: 0;
      left: 16;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 0 2px 0 8px;
      background-color: var(--color-mask-bg);
      color: #fff;
      font-size: 10px;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .additional-fee-dialog {
    .zent-dialog-r-header .zent-dialog-r-title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`;
