import React from 'react';
import { useIntl } from 'react-intl';
import { Style } from './style';
import { STEP_MAP } from '../../const';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';

const StepHeader = (props) => {
  const { stepList, step, onPrev, orderToken, isSinglePageOrder, isNotEdit, isTraditionPlaceOrder = false } = props;
  const intl = useIntl();
  const quitCallback = (goBackCount = -1) => {
    props.history.go(goBackCount);
  };
  const onBack = () => {
    // 没有生成订单直接退出
    if (!orderToken && !isTraditionPlaceOrder) {
      quitCallback();
      return;
    }
    // 生成订单 且回到了第一步  确认后退出
    if (!isSinglePageOrder) {
      if (step === STEP_MAP.PAY || step === STEP_MAP.SEND) {
        onPrev && onPrev();
      } else {
        // onQuitConfirm(intl, () => {
        quitCallback(-1);
        // });
      }
      return;
    }
    // 生成了订单  确认后退出
    if (isSinglePageOrder) {
      // onQuitConfirm(intl, () => {
      quitCallback(-1);
      // });
      return;
    }
    quitCallback();
  };

  const backText =
    step === STEP_MAP.PAY && stepList?.length > 2 ? (
      <span>{intl.formatMessage({ defaultMessage: '配送', id: '4ee158b1ea1143a7a74be9473addf2b7' })}</span>
    ) : (
      <span>{intl.formatMessage({ defaultMessage: '信息', id: 'order.pay.step_info' })}</span>
    );

  return (
    <Style
      className={cn(
        'step-header-wrapper',
        { 'is-multi-page': !isSinglePageOrder },
        { 'is-single-page': isSinglePageOrder },
      )}
    >
      <div
        className={cn('back')}
        onClick={() => {
          if (isNotEdit) {
            quitCallback();
          } else {
            onBack();
          }
        }}
      >
        <svg width={16} height={16}>
          <use xlinkHref='#iconic-zuoyouqiehuan' />
        </svg>
        <p>
          {intl.formatMessage({ defaultMessage: '返回', id: 'general.return' })}
          {'\u00A0'}
          {step !== STEP_MAP.INFO && !isSinglePageOrder && !isNotEdit && backText}
        </p>
      </div>
      {!isSinglePageOrder && (
        <div className='step'>
          <span className={cn('step1', { current: step === STEP_MAP.INFO })}>
            {intl.formatMessage({ defaultMessage: '信息', id: 'order.pay.step_info' })}
          </span>
          <div className={cn('step-line', { 'current-line': step === STEP_MAP.INFO })} />
          {stepList?.length > 2 && (
            <>
              <span className={cn('step2', { current: step === STEP_MAP.SEND })}>
                {intl.formatMessage({ defaultMessage: '配送', id: '4ee158b1ea1143a7a74be9473addf2b7' })}
              </span>
              <div className={cn('step-line', { 'current-line': step === STEP_MAP.SEND })} />
            </>
          )}

          <span className={cn('step3', { current: step === STEP_MAP.PAY })}>
            {intl.formatMessage({ defaultMessage: '支付', id: '94a0f6f6473b46fd9a97a29a66b308fd' })}
          </span>
        </div>
      )}
    </Style>
  );
};

export default withRouter(StepHeader);
