import React, { useEffect, useState } from 'react';
import HasFission from './has-fission';
import NoFission from './no-fission';
import { AffiliateFissionLevel } from '../../constant/config';
import distributionApi from '../../../../api/distribution';
import Style from './style';

const GroupRule = (props) => {
  const { commissionValues = [], intl, settlementDate } = props;
  const { host } = window.location;
  const [affiliateFission, setAffiliateFission] = useState(1);
  // 裂变模式
  const isFission = affiliateFission === AffiliateFissionLevel.Two && commissionValues?.[1];
  // 非裂变模式
  const isNotFission = affiliateFission === AffiliateFissionLevel.One || !commissionValues?.[1];
  // 分组模式
  const getAffiliateFission = () => {
    distributionApi.getAffiliateFissionSetting().then((res) => {
      setAffiliateFission(res?.affiliateFissionLevel);
    });
  };
  useEffect(() => {
    getAffiliateFission();
  }, []);
  return (
    <Style>
      <p className='welcome-message'>
        {intl.formatMessage({
          id: 'welcome_participate_affiliate',
          description: `欢迎参与联盟计划～！`,
        })}
      </p>
      <p className='welcome-message'>
        {intl.formatMessage({
          id: 'share_your_link',
          description: `分享您的链接给您的好友，好友购买时，您可获得收益。`,
        })}
      </p>
      {isFission && (
        <div>
          <p className='rule-title'>
            <span>
              {intl.formatMessage({
                id: 'income_rules',
                description: `收益规则`,
              })}
            </span>
          </p>
          <p className='rule-item'>
            1.
            {intl.formatMessage(
              {
                id: 'get_commission_product',
                description: `当商家确认发货后，您可获得产品实付金额的${commissionValues[0] / 10}%收益。`,
              },
              {
                commissionValues: commissionValues[0] / 10,
              },
            )}
          </p>
          <p className='rule-item'>
            2.
            {intl.formatMessage(
              {
                id: 'c640849b0b0e404f887a0855c079eee4',
                description: ` 当您的好友通过您的链接成为推广员，继续分享给他的好友时，他的好友购买时，您的好友可获得${
                  commissionValues[0] / 10
                } %收益，您可额外获得${commissionValues[1] / 10}%收益。（如下图所示）`,
              },
              {
                commissionValues0: commissionValues[0] / 10,
                commissionValues1: commissionValues[1] / 10,
              },
            )}
          </p>

          <div className='rule-img-bg'>
            <HasFission commissionValues={commissionValues} intl={intl} />
          </div>
        </div>
      )}
      {isNotFission && (
        <div>
          <p className='rule-title'>
            <span>
              {intl.formatMessage({
                id: 'income_rules',
                description: `收益规则`,
              })}
            </span>
          </p>
          <p className='rule-item'>
            1.
            {intl.formatMessage(
              {
                id: 'get_commission_product',
                description: `当商家确认发货后，您可获得产品实付金额的${commissionValues[0] / 10}%收益。`,
              },
              {
                commissionValues: commissionValues[0] / 10,
              },
            )}
            （
            {intl.formatMessage({
              id: 'income_rules',
              description: `as_shown_below`,
            })}
            ）
          </p>
          <div className='rule-img-bg'>
            <div className='rule-img-container no-fission'>
              <NoFission commissionValues={commissionValues} intl={intl} />
            </div>
          </div>
        </div>
      )}

      <p className='rule-item'>
        {isNotFission ? 2 : 3}.
        {intl.formatMessage({
          id: 'sharing_prohibited',
          description: '平台禁止恶意分享，若发生劫持，造假等问题，经商家确定后，将取消您的佣金。',
        })}
      </p>
      <p className='rule-item'>
        {isNotFission ? 3 : 4}.
        {intl.formatMessage({
          id: 'order_is_refunded',
          description: '当订单产生退款时，该订单无法获得佣金。',
        })}
      </p>
      <p className='rule-title'>
        <span>
          {intl.formatMessage({
            id: 'settlement_instructions',
            description: '结算说明',
          })}
        </span>
      </p>
      {/* <p className="rule-item">1. 发货7天后，佣金进入待结算。</p> */}
      <p className='rule-item'>
        1.
        {intl.formatMessage(
          {
            id: 'commission_after_will_review',
            description: `每月${settlementDate}日平台将对佣金进行审核，审核后发放佣金。`,
          },
          {
            settlementDate,
          },
        )}
      </p>
      <p className='rule-item rule-explanation'>
        {intl.formatMessage(
          {
            id: 'host_final_right',
          },
          {
            host,
          },
        )}
      </p>
    </Style>
  );
};

export default GroupRule;
