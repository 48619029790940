import Style from './style';
import { useIntl } from 'react-intl';

const COD_METHOD_NAME = 'cod';

const HIDDEN_CHILDREN_LOGO_PAYMENT_CATEGORYS = ['PAYPAL', 'ALI_PAY', 'WECHAT_PAY'];

const PayMethodItem = (props) => {
  const intl = useIntl();
  const { item = {}, PAY_LOGO_NUM, paymentCategory } = props;
  const paymentMethods = item?.allPaymentMethods || item?.paymentMethods || [];
  const logo = item?.logoUrl;
  const additionalDetail = item?.paymentProviders?.[0]?.additionalDetail;
  return (
    <Style>
      <div className='radio-content-wrapper'>
        <div className='pay-wrapper'>
          {logo && (
            <span className='pay-logo'>
              <img src={logo} alt={item.text || item.name} />
            </span>
          )}

          <div className='pay-text-wrapper'>
            <span className='pay-text skiptranslate'> {item.text || item.name}</span>
            {additionalDetail && <span className='pay-description'>{additionalDetail}</span>}
          </div>
        </div>
        {paymentMethods?.length > 0 && (
          <div className='logo-wrapper'>
            {paymentMethods.map((method, methodIndex) => {
              /** cod只会有一个图标，显示出来不美观 */
              if (
                methodIndex > PAY_LOGO_NUM - 1 ||
                method.paymentMethodName === COD_METHOD_NAME ||
                HIDDEN_CHILDREN_LOGO_PAYMENT_CATEGORYS.includes(paymentCategory)
              ) {
                return null;
              }
              return (
                <span className='logo' key={methodIndex}>
                  <img src={method.logoUrl || method.logo} alt={method.name || method.paymentMethodName} />
                </span>
              );
            })}
            {paymentMethods.length > 6 && (
              <span className='empty'>
                {intl.formatMessage({
                  defaultMessage: '更多',
                  id: 'general.more',
                })}
                {`(${paymentMethods.length - PAY_LOGO_NUM})`}
              </span>
            )}
          </div>
        )}
      </div>
    </Style>
  );
};

export default PayMethodItem;
