import React from 'react';
import Style from './style';
import CollectionFilterHorizontal from './collection-filter-horizontal';
import CollectionFilterVertical from './collection-filter-vertical';

const CollectionFilter = (props) => {
  const { sortList, settings, filterItem, onSortChange, onFilterChange } = props;
  const { style: filterStyle, bg_color: bgColor = '#EAEEF5', layout = 'horizontal' } = settings;

  return (
    <Style layout={layout} bgColor={bgColor}>
      <div className='collection-filter'>
        {layout === 'horizontal' ? (
          <CollectionFilterHorizontal
            filterStyle={filterStyle}
            data={filterItem}
            settings={settings}
            sortList={sortList}
            onSortChange={onSortChange}
            onFilterChange={onFilterChange}
          />
        ) : (
          <CollectionFilterVertical
            data={filterItem}
            settings={settings}
            sortList={sortList}
            onSortChange={onSortChange}
            onFilterChange={onFilterChange}
          />
        )}
      </div>
    </Style>
  );
};

export default CollectionFilter;
