import styled from 'styled-components';

export default styled.div`
  .base-login-info-wrapper {
    padding: var(--pm-24-16);
    background: var(--color_card, #fff);
    border-radius: var(--border_radius_card);
  }
  .pay-user-info {
    &.user-info {
      padding: 0 !important;
      min-height: var(--pm-64-40);
    }
  }
  .title {
    margin-top: 0 !important;
    margin-bottom: 0px;
    /* color: #1a1a1b; */
    font-size: 20px !important;
    font-family: var(--font-stack-header);
    font-style: var(--font-style-header);
    font-weight: var(--font-weight-header);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .login-guide {
    text-align: right;
    font-size: var(--base-font-16-14);
    font-weight: 400;
    /* color: #36383b; */

    .login-link {
      font-size: var(--base-font-16-14);
      font-weight: 600;
      /* color: #041b4a; */
      color: var(--color_button);
      line-height: 16px;
      cursor: pointer;
      text-decoration: underline;
      word-break: keep-all;
      white-space: nowrap;
    }
  }
`;
