import styled from 'styled-components';

export default styled.div`
  .container {
    border: 1px solid #d1d5db;
    padding: 0 var(--pm-16-10);
  }
  .mt-40 {
    margin-top: 40px;
  }
  .send-way {
    font-weight: 500;
    /* color: #1a1a1b; */
    margin: var(--pm-40-34) 0 12px;
  }
  .tip {
    margin-bottom: var(--pm-16-10);
    /* color: #9b9ea4; */
    font-size: var(--base-font-14-12);
    line-height: 14px;
  }
  .shipping-title {
    font-size: 20px;
    color: var(--color_body_text);
    font-weight: 600;
  }
`;
