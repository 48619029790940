import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
    .group-buying-invite-dialog {
        min-width: 460px !important ;
        .zent-dialog-r-close {
            top: 24px !important;
            right: 24px !important;
            font-size: 25px !important;
        }
    }
    @media screen and (max-width: 765px) {
        .group-buying-invite-dialog {
            min-width: 320px !important ;
            .zent-dialog-r-close {
                top: 20px !important;
                right: 20px !important;
                font-size: 24px !important;
            }
        }
    }

`;

export const Style = styled.div`
  .share-way {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 24px;
    margin-bottom: 24px;
    .share-buttons-container {
      display: flex;
      margin-top: ${(props) => props.theme.getSpace([12, 12])} !important;
    }
  }
`;

export const GroupStatusStyle = styled.div`
  padding: ${(props) => props.theme.getSpace([12, 24])} 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.08)};
  .status-desc {
    display: flex;
    align-items: center;
    ${(props) => props.theme.getBaseSize(props.theme.h10)};
  }
  .group-rest-time {
    display: flex;
    align-items: center;
    margin-top: 12px;
    .group-time {
      .time-item {
        color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 1)} !important;
      }
      .time-item.time-value {
        color: #fff !important;
      }
    }
    .group-rest-time-text {
      color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.6)};
    }
  }

  .group-card-profile {
    margin-top: ${(props) => props.theme.getSpace([12, 16])};
  }

  .group-status-left {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    .group-status-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .group-time {
      display: inline-flex;
    }
    .status-desc {
      display: inline;
    }
  }
`;
