import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Menu } from 'zent';
import Style from './style';
import { SlideLeft } from 'cpn/slide-left';
import HeaderSetting from '../header-setting';

const { MenuItem } = Menu;
export const DistributionMobileHeader = (props) => {
  const { initialData, jumpToOtherPage, history, bgColor, pcLogo, affiliateId, isActive, noBackground, style } = props;
  const { shopSetting = {} } = initialData;
  const initialDataOfDistribution = {
    ...initialData,
    shopSetting: {
      ...shopSetting,
      name: `${shopSetting.name} | 分销联盟`,
    },
  };
  const settings = {
    logo: pcLogo,
    // logoMaxWidth: 160,
  };
  const intl = useIntl();

  return (
    <Style bgColor={bgColor}>
      {!noBackground && <div className='page-bg' />}
      <div className='sub-header-container'>
        <div className='wrapper'>
          <SlideLeft
            customHeaderClass='menu-back-header'
            customClass='mobile-header-menu'
            expendIconName='iconic-zhucaidan'
            iconSize='24'
            settings={settings}
            initialData={initialDataOfDistribution}
            jumpToOtherPage={jumpToOtherPage}
            showWish={false}
            style={style}
          >
            <Menu mode='inline'>
              <MenuItem key='1-0'>
                <NavLink activeClassName='is-active' to='/affiliate/dashboard'>
                  {intl.formatMessage({
                    id: 'store.edit.homepage',
                    defaultMessage: '首页',
                    description: '首页',
                  })}
                </NavLink>
              </MenuItem>
              {/* 数据下一期做 */}
              {/* <MenuItem key="1-1">
                                <NavLink to="/affiliate/dashboard">数据报告</NavLink>
                            </MenuItem> */}
              {isActive && (
                <MenuItem key='1-2'>
                  <NavLink activeClassName='is-active' to='/affiliate/commission-bill'>
                    {intl.formatMessage({
                      id: '55edd09f4e9145ea9319bb17e68844e5',
                      defaultMessage: '佣金账单',
                      description: '佣金账单',
                    })}
                  </NavLink>
                </MenuItem>
              )}
            </Menu>
          </SlideLeft>
          <HeaderSetting style={style} history={history} affiliateId={affiliateId} />
        </div>
      </div>
      {/* </div> */}
    </Style>
  );
};
