import React, { useEffect, useState, useMemo } from 'react';
import { FILTER_ITEM_STYLE, FILTER_ITEM_TYPE } from './const';
import { useDidUpdate } from 'hook/life-cycle';

// 竖向 过滤器 折叠展开的处理
export const useVerticalFilter = ({ data }) => {
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  // 初始化展开收起
  useEffect(() => {
    // 不更新已有的展开收起状态
    if (collapseActiveKey?.length !== 0) {
      return;
    }
    const defaultActiveKey = [];
    data?.forEach((item) => {
      //  0：折叠 1 展开
      if (item?.isCollapse === 1) {
        defaultActiveKey.push(`${item?.filterItemId}`);
      }
    });
    setCollapseActiveKey(defaultActiveKey);
  }, [data]);

  // 此处把没有数据的过滤掉
  const verticalData = useMemo(() => {
    const list = data.filter((item) => {
      if (item?.filterItemStyle === FILTER_ITEM_STYLE.CHECKBOX || item?.filterItemStyle === FILTER_ITEM_STYLE.RADIO) {
        if (item?.filterItemOptions?.length) {
          return item;
        }
      } else if (item?.filterItemStyle === FILTER_ITEM_STYLE.SLIDER) {
        return item;
      }
    });
    return list;
  }, [data]);

  return {
    verticalData,
    collapseActiveKey,
    setCollapseActiveKey,
  };
};

// 横向 过滤器
// 过滤选项 和 展开折叠的处理
export const useHorizontalFilter = ({ data }) => {
  const [isFilterCollapse, setFilterCollapse] = useState(true);
  const handleChangeCollapse = () => {
    setFilterCollapse(!isFilterCollapse);
  };

  // 横向不显示滑块
  const horizontalData = useMemo(() => {
    const list = data.filter((item) => {
      if (
        // item?.filterItemStyle === FILTER_ITEM_STYLE.CHECKBOX &&
        item?.filterItemStyle !== FILTER_ITEM_STYLE.SLIDER &&
        item?.filterItemOptions?.length
      ) {
        return item;
      }
    });
    return list;
  }, [data]);

  return {
    horizontalData,
    isFilterCollapse,
    handleChangeCollapse,
  };
};

// 过滤器 数据处理
// 脱水
export const useCollectionFilter = (props) => {
  const { onFilterChange } = props;

  // const [goodsTypeQuery, setGoodsTypeQuery] = useState([]);
  // const [goodsTagsQuery, setGoodsTagsQuery] = useState([]);
  // const [priceQuery, setPriceQuery] = useState();

  const [activeFilter, setActiveFilter] = useState({});
  const [categoryData, setCategoryData] = useState({});

  const updateCategoryData = (newData) => {
    setCategoryData((prev = {}) => {
      // 商品集合：collection_ids
      // 供应商： vendor
      // 商品类型：goods_type
      // 商品价格：min_price
      // 商品标签：goods_tags
      // 商品sku：goods_sku
      // FILTER_ITEM_TYPE

      const { filterItemStyle, filterItemType } = newData;

      // 价格独立于类型处理
      // if(filterItemType === FILTER_ITEM_TYPE.MIN_PRICE){
      //     return {
      //         ...prev,
      //         priceQuery: newData?.priceQuery,
      //     };
      // }

      // 滑动
      if (filterItemStyle === FILTER_ITEM_STYLE.SLIDER) {
        return {
          ...prev,
          [newData?.filterItemId]: newData,
        };
      }
      // 单选
      if (filterItemStyle === FILTER_ITEM_STYLE.RADIO) {
        return {
          ...prev,
          [newData?.filterItemId]: newData,
        };
      }

      // 多选
      if (filterItemStyle === FILTER_ITEM_STYLE.CHECKBOX) {
        if (newData?.activeOptionIds?.length) {
          return {
            ...prev,
            [newData?.filterItemId]: newData,
          };
        }
        if (prev?.[newData?.filterItemId]) {
          delete prev?.[newData?.filterItemId];
        }
      }

      return {
        ...prev,
      };
    });
  };

  // 第一次不执行 使用静怡大姐的 useDidUpdate
  // -----
  const filterMap = {
    collection_ids: 'collectionQuery', // 商品集合：
    vendor: 'vendorQuery',
    goods_type: 'goodsTypeQuery',
    goods_tags: 'goodsTagsQuery',
    // priceQuery: 'minPrice, maxPrice'
    // NamedNodeMap: 'skuQuery',
  };

  useDidUpdate(() => {
    const filterData = {
      collectionQuery: [],
      vendorQuery: [],
      goodsTypeQuery: [],
      goodsTagsQuery: [],
    };
    // 更新出最新的数据
    if (categoryData) {
      Object.keys(categoryData)?.forEach((item) => {
        const { activeOptionIds, priceQuery, filterItemType, filterItemStyle } = categoryData?.[item] || {};
        if (filterItemStyle === FILTER_ITEM_STYLE.CHECKBOX || filterItemStyle === FILTER_ITEM_STYLE.RADIO) {
          // 属性名映射处理
          const filterKey = filterMap?.[filterItemType] || filterItemType;

          if (filterData?.[filterKey]) {
            filterData[filterKey] = [...filterData?.[filterKey], ...activeOptionIds];
          } else {
            if (activeOptionIds) {
              filterData[filterKey] = activeOptionIds;
            }
          }
        }
        if (filterItemType === FILTER_ITEM_TYPE.MIN_PRICE && priceQuery) {
          filterData.priceQuery = priceQuery;
        }
      });
      setActiveFilter(filterData);
    }
    onFilterChange && onFilterChange(filterData);
  }, [categoryData]);

  return {
    categoryData,
    activeFilter,
    updateCategoryData,
  };
};
