import styled from 'styled-components';

export default styled.div`
  min-height: 128px;
  &.address-cannot-edit-true {
    cursor: not-allowed;
    .current-address-info {
      pointer-events: none;
    }
    .select-address-btn {
      pointer-events: none;
    }
  }
  .click-able {
    cursor: pointer;
  }
  .address-img-wrapper {
    height: 6px;
    background: var(--color_button);
    background-image: url(https://img01.yzcdn.cn/upload_files/2022/01/10/FgBfwJrPflqzGp2uVV8DzlulFoDA.png);
    background-size: auto 100%;
  }

  .current-address-info {
    min-height: 122px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: var(--pm-24-16);
    align-items: center;
  }

  .default-address-title {
    margin-bottom: 8px;
    line-height: 1;

    font-size: 14px;
    font-weight: 500;
    color: var(--color_body_text);
  }
  .default-address-tag {
    border-radius: 2px;
    padding: 2px 6px;
    color: var(--color_button);
    font-size: 12px;
    line-height: 17px;
    background: rgba(var(--color_button_rgb), 0.1);
    margin-top: 12px;
    display: inline-block;
  }

  .current-address-detail {
    line-height: 22px;

    font-size: 16px;
    font-weight: 400;
    color: #1a1a1b;
  }
  .select-address-btn {
    color: var(--color_button);
    display: flex;
    align-items: center;
    .icon-select-address {
      fill: var(--color_button);
    }
    .change-text {
      margin-right: 8px;
    }
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    .address-svg-wrapper {
      margin-right: 20px;
      fill: var(--color_body_text);
    }
  }
  @media screen and (max-width: 750px) {
    .change-text {
      display: none;
    }
  }
`;
