export const setTitle = (title) => {
  window.document.title = title;
};

export const dealRichText = (str = '') => {
  try {
    /** 处理空格 */
    const textAfterDealTab = str.replace(/&nbsp;/g, ' ');
    /** 处理空格&amp, 这玩意是啥，我也不知道 */
    const textAfterDealAmp = textAfterDealTab.replace(/&amp;nbsp;/g, ' ');

    return textAfterDealAmp;
  } catch (error) {
    return str;
  }
};
