import React, { useState, useEffect } from 'react';
import { Style } from './style';
import { Dialog } from 'zent';
import shopApi from '../../../api/shop';
import { useIntl } from 'react-intl';
import cn from 'classnames';

const { openDialog } = Dialog;

const PolicyBottomLine = () => {
  const intl = useIntl();
  const [ruleData, setRuleData] = useState({ refundIntro: '', shippingIntro: '' });
  const [isLittleHeight, setIsLittleHeight] = useState(false);
  const [hasDetailRule, setHasDetailRule] = useState([]);
  useEffect(() => {
    shopApi.getRuleIntro({ kdtId: window && window?.global_data?.kdtId }).then((data) => {
      setRuleData(data);
    });
    if (window && window.innerHeight < 928) {
      setIsLittleHeight(true);
    }
    shopApi.getAvailableRuleDetail({ kdtId: window?.global_data?.kdtId }).then((data) => {
      const { availableRules = [] } = data;
      setHasDetailRule(availableRules);
    });
  }, []);

  const onClickPolicy = (title, text, path, showDetail) => {
    const redirect = () => {
      window.open(`/policy/${path}`);
    };
    openDialog({
      title,
      children: (
        <Style className='content'>
          <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
          {showDetail && (
            <div className='view-detail'>
              <span onClick={redirect}>
                {intl.formatMessage({
                  description: '查看详情',
                  id: 'general.preview_detail',
                })}
              </span>
            </div>
          )}
        </Style>
      ),
    });
  };

  return (
    <Style>
      <div className={cn('policy-wrapper', { 'little-height': isLittleHeight })}>
        {ruleData?.refundIntro && (
          <span
            onClick={() => {
              onClickPolicy(
                intl.formatMessage({
                  id: 'e996ed42c6ec4052b2c99a76755d2733',
                  defaultMessage: '退款政策',
                }),
                ruleData?.refundIntro,
                'Return-Policy',
                hasDetailRule.includes('refund'),
              );
            }}
          >
            {intl.formatMessage({ id: 'e996ed42c6ec4052b2c99a76755d2733', defaultMessage: '退款政策' })}
          </span>
        )}
        {ruleData?.shippingIntro && (
          <span
            className={cn({ refund: ruleData?.refundIntro })}
            onClick={() => {
              onClickPolicy(
                intl.formatMessage({
                  id: '61f2fe9d60154c9d98bed3106b2a8c4a',
                  defaultMessage: '物流政策',
                }),
                ruleData?.shippingIntro,
                'Shipping-Policy',
                hasDetailRule.includes('shipping'),
              );
            }}
          >
            {intl.formatMessage({ id: '61f2fe9d60154c9d98bed3106b2a8c4a', defaultMessage: '物流政策' })}
          </span>
        )}
      </div>
    </Style>
  );
};

export default PolicyBottomLine;
