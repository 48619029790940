import React, { useState, useEffect, useMemo } from 'react';
import { GlobalStyle, Style } from './style';
import SelectFileds from 'cpn/select-fileds';
import FormFileds from 'components/base/form-fileds';
import useCountrySelect from 'hook/use-country-select';
import { getClientLanguage } from '../../../../../../helper/multi-language';
import userApi from 'api/user';
import { Validators } from 'zent';
import { isValidMobile, checkLetter } from 'helper/verify';

const FormInputAreaPhone = (props) => {
  const { form, intl, onChange, defaultValue = {}, isEdit } = props;

  const locale = getClientLanguage(window?.global_data);
  const { countryList } = useCountrySelect({ locale });
  const countryListData = useMemo(() => {
    return countryList.map((item, index) => {
      return {
        value: index,
        text: `${item.name}(+${item.phoneNumberPrefix})`,
        inputText: `${item.code} +${item.phoneNumberPrefix}`,
        code: item.code,
        phoneNumberPrefix: item.phoneNumberPrefix,
      };
    });
  }, [countryList]);

  useEffect(() => {
    if (countryListData?.length) {
      if (isEdit) {
        if (defaultValue?.code) {
          const item = countryListData.find((item) => item.code === defaultValue?.code);
          form.patchValue({
            code: item?.code || '',
          });
        }
      } else {
        userApi.getUserLocation().then((res) => {
          let { countryCode } = res?.ipData ?? {};
          countryCode = countryCode || 'US';
          const item = countryListData.find((item) => item.code === countryCode);
          if (item) {
            form.patchValue({
              code: item?.code || '',
            });
            onChange(item);
          }
        });
      }
    }
  }, [countryListData, defaultValue]);

  return (
    <Style>
      <GlobalStyle />
      <SelectFileds
        name='code'
        labelType='inner'
        label={intl.formatMessage({
          id: 'general.phone.area.code',
          defaultMessage: '区号',
        })}
        data={countryListData}
        optionText='text'
        optionValue='code'
        validators={[
          Validators.required(
            intl.formatMessage({
              id: '86b64037592f47689e1c2fa32771958d',
              defaultMessage: '请输入正确的区号和手机号',
            }),
          ),
        ]}
        getSelectValue={(value) => {
          const item = countryListData.find((item) => item.code === value);
          onChange && onChange(item);
        }}
      />
      <FormFileds
        className='phone-input-fileds'
        name='phone'
        label={intl.formatMessage({
          id: 'login_phone_number',
          defaultMessage: '手机号',
        })}
        validators={[
          Validators.required(
            intl.formatMessage({
              id: '1b3c254437d149c8a84c8b95c62867c1',
              defaultMessage: '请输入手机号',
            }),
          ),
          Validators.maxLength(
            32,
            intl.formatMessage(
              {
                id: '5f7b66782d9f4922b95cfbdf444f56a7',
                defaultMessage: '超出最大长度{num}',
              },
              {
                num: 21,
              },
            ),
          ),
          isValidMobile(
            intl.formatMessage({
              id: '232cf690b4504611b22e8fa3315b483b',
              defaultMessage: '手机号格式不正确',
            }),
          ),
          checkLetter(
            intl.formatMessage({
              id: '232cf690b4504611b22e8fa3315b483b',
              defaultMessage: '手机号格式不正确',
            }),
          ),
        ]}
      />
    </Style>
  );
};

export default FormInputAreaPhone;
