import styled from 'styled-components';

export default styled.div`
  .distribution-logo {
    text-align: center;
    /* height: 25px; */
    font-size: 24px;
    color: #476cf0;
    line-height: 24px;
    letter-spacing: 3px;
    padding: 68px 0px 32px 0;
    span {
      display: inline-block;
      border-right: 1px solid #476cf0;
      padding-right: 2px;
      margin-right: 2px;
    }
    img {
      max-width: 160px;
      max-height: 40px;
    }
  }

  @media screen and (max-width: 750px) {
    .distribution-logo {
      display: none;
    }
  }
`;
