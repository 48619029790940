import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.labelText};
  background: ${(props) => props.labelBg};
  ${(props) => props.theme.getBaseSize(props.theme.h12)};
  padding: 14px 12px;
  cursor: pointer;
  border-radius: 0 0 var(--border_radius_card) var(--border_radius_card);
  &.expire {
    color: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.4)};
    background: rgba(0, 0, 0, 0.1);
    svg {
      fill: ${(props) => props.theme.hexToRGBA(props.theme.colorText, 0.4)} !important;
    }
  }
  .left-item {
    display: flex;
    align-items: center;
    .text {
      margin-left: 4px;
    }
  }
`;
