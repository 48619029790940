import Style from './style';
import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Validators, Button, FormStrategy } from 'zent';
import FormFileds from '../form-fileds';
import SelectFileds from '../../../components/select-fileds';
import deliveryApi from '../../../../api/delivery';
import AutoAddressSelectFiled from './auto-address-select-filed';
import userApi from 'api/user';
import cn from 'classnames';
import { checkPhone, getCustomerAddressSettings } from 'helper/verify';
import { debounce, set } from 'lodash';
import useCountrySelect from 'hook/use-country-select';
import HtFormInputPhone from 'components/standard/form-input-phone';
import EmptyFormFileds from 'components/standard/empty-form-fileds';
import { useI18n } from 'hook/intl';

const language = window?.global_data?.multiLanguageInfo?.multiLanguage || 'en';

const checkIsEmpty = (value, message) => {
  if (value && !value.trim()) {
    return {
      name: 'error',
      message,
    };
  }
  return null;
};

const getCanadaZipValue = (value) => {
  return (
    value
      .toLocaleUpperCase()
      .replace(/\s/g, '')
      .replace(/^(.{3})/g, '$1 ') || ''
  );
};

export const checkZipVerify = ({ errorText, value, countryCode }) => {
  const canadaZipPattern = /^[ABCEFGHJ-NPQRSTVXY][0-9][ABCEFGHJ-NPRSTV-Z] ?[0-9][ABCEFGHJ-NPRSTV-Z][0-9]$/;
  if (countryCode === 'CA' && !canadaZipPattern.test(getCanadaZipValue(value))) {
    return {
      name: 'error',
      message: errorText,
    };
  }
};

const {
  customerAddressSettings,
  AddressSettingsEnum,
  isRequiredFirstName,
  isRequiredLastName,
  isRequiredAddress2,
  isRequiredZip,
} = getCustomerAddressSettings();

const AddressInput = (props) => {
  const {
    /** 分割线-下面这块是外面统一传进来的字段 */
    intl,
    isLogin,
    setChecked,
    checked,
    needShip = false,
    checkoutSetting,
    currentAddress: shippingAddress = {},
    className = '',
    /** 分割线 */

    form: customForm,
    dialogModal = false,

    /** 控制按钮是否展示 */
    showConfirm = false,
    showCancel = false,
    /** action */
    onCancel = () => {},
    onConfirm = () => {}, // 自定义保存事件
    isExistRadiusLocalDelivery = false,
    fromType,
    locale,
  } = props;
  const { $fm } = useI18n(intl);
  const addressForm = Form.useForm(FormStrategy.View);

  const form = customForm || addressForm;

  const [deliveryZones, setDeliveryZones] = useState([]);
  const [openZipNess, setOpenZipNess] = useState(true);
  const [autoAddressList, setAutoAddressList] = useState([]);
  const [showAutoAddress, setShowAutoAddress] = useState(false);

  const {
    countryList,
    provinceList,
    getCountryAndProvinceName,
    onCountryChange,
    // setAddress,
    labels = {},
  } = useCountrySelect({ form, shippingAddress, locale });

  const shippableCountry = needShip ? countryList.filter((item) => deliveryZones?.includes(item?.code)) : countryList;
  const hasShipCountry = Boolean(shippableCountry?.length);
  const hasAddress1 = form?.getValue()?.address1;
  const Address1InputRef = 'Address1InputRef';

  const getDeliveryZones = (userCountryCode) => {
    // 获取店铺可选的国家列表
    deliveryApi.getDeliveryZones().then((deliveryZonesData) => {
      const { countryCodes = [] } = deliveryZonesData || {};
      setDeliveryZones(countryCodes || []);

      // 国家默认选择优先级 用户ip信息的国家>商家店铺的国家>可配置的国家第一个
      const matchCountryCode =
        userCountryCode && countryCodes.includes(userCountryCode) ? userCountryCode : shippingAddress?.countryCode;
      const targetCountryCode = countryCodes.includes(matchCountryCode) ? matchCountryCode : countryCodes[0];
      if (fromType === 'bill-address') {
        form.patchValue({ countryCode: userCountryCode || 'US' });
        onCountryChange(userCountryCode || 'US');
      }
      if (countryCodes?.length > 0) {
        const country = getCountryAndProvinceName({ countryCode: targetCountryCode })?.country;
        form.patchValue({ countryCode: targetCountryCode, country });
        onCountryChange(targetCountryCode);
      }
      if (countryCodes?.length === 0 && needShip) {
        form.patchValue({ countryCode: '' });
        onCountryChange('');
      }
    });
  };

  // 无地址情况下，获取当前ip定位，自动选择国家
  const getInitUserLocation = () => {
    userApi
      .getUserLocation()
      .then((res) => {
        const userCountryCode = res?.ipData?.countryCode || '';
        getDeliveryZones(userCountryCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const globalCountryCode = window?.global_data?.ipData?.countryCode;
    if (!countryList?.length) return;
    if (shippingAddress?.countryCode || globalCountryCode) {
      getDeliveryZones(shippingAddress?.countryCode || globalCountryCode);
    } else if (!shippingAddress?.countryCode && countryList?.length) {
      // 无地址情况下，从ip获取国家
      // 拿到国家列表后,再去根据ip匹配国家，不然会匹配不到，加载不出省输入框
      getInitUserLocation();
    }
  }, [countryList?.length]);

  useEffect(() => {
    setOpenZipNess(checkoutSetting?.requiresPostalCode === 'required');
  }, []);

  useEffect(() => {
    if (hasShipCountry) {
      form.initialize(shippingAddress);
      if (shippingAddress?.uniqueId) {
        if (shippingAddress?.isDefault) setChecked(true);
        else setChecked(false);
      }
    }
  }, [shippingAddress, hasShipCountry]);

  const asyncZipValidator = Form.createAsyncValidator((postalCode) => {
    return new Promise((resolve, reject) => {
      if (form.getValue()?.countryCode !== undefined) {
        userApi.validatePostcode({
          postalCode,
          countryCode: form.getValue()?.countryCode,
        }).then((res) => {
          if (!res.result) {
            // eslint-disable-next-line no-console
            console.log('validatePostcode', res?.message);
            resolve({
              name: 'regError',
              message: intl.formatMessage({
                id: 'b2ab65d8e2f5481596e213786ee6d902',
                defaultMessage: '请填写正确邮政编码',
              }),
            });
          }
          resolve(null);
        });
      } else {
        resolve(null);
      }
    })
  });

  const zipValidators = isRequiredZip
    ? [
        Validators.required($fm('common_please_input_TEXT', '请输入{text}', {text: labels?.postalCode})),
        (value) => {
          return checkZipVerify({
            value,
            errorText: intl.formatMessage({
              id: 'order.address.please_input_zip',
              defaultMessage: '请填写邮政编码',
            }),
            countryCode: form?.getValue()?.countryCode,
          });
        },
        // asyncZipValidator,
      ]
    : [];

  useEffect(() => {
    if (form?.getValue()?.countryCode === 'CA') {
      form.patchValue({
        zip: getCanadaZipValue(form?.getValue()?.zip),
      });
    }
  }, [form?.getValue()?.countryCode, form?.getValue()?.zip]);

  const onAddressInputSubmit = () => {
    // 如果是外部自定义表单就不走这个方法
    if (customForm) {
      // onConfirm();
      return;
    }

    // 弹窗编辑保存走这里
    const data = form.getValue();

    if (data.province === undefined && data.provinceCode === undefined) {
      // 无省份国家
      data.province = '';
      data.provinceCode = '';
    }
    const params = {
      ...data,
      isDefault: Number(checked),
    };

    onConfirm(params);
  };

  const onAddress1Change = debounce(async (val) => {
    if (!val || !isExistRadiusLocalDelivery) return;
    form.patchValue({ latitude: '', longitude: '' });
    const list = await userApi.getAutoCompleteAddress({
      language,
      country: form?.getValue().countryCode || '',
      input: val,
    });

    const autoList = list.map((item) => ({
      place_id: item?.place_id,
      main_text: item?.structured_formatting?.main_text,
      secondary_text: item?.structured_formatting?.secondary_text,
    }));

    setAutoAddressList(autoList);
    setShowAutoAddress(true);
  }, 800);

  const onSelectAutoAddress = async (place = {}) => {
    setShowAutoAddress(false);
    const { place_id = '', main_text = '' } = place;
    const { address_components = [], geometry = {} } = await userApi.getAddressPlaceDetails({ place_id, language });
    const { lat = 0, lng = 0 } = geometry?.location || {};
    const VALUE_MAP = {
      country: 'country',
      province: 'administrative_area_level_1',
      city: 'locality',
      zip: 'postal_code',
      area: 'sublocality_level_1',
      route: 'route',
      detail: 'point_of_interest',
    };
    const addressDetail = {
      latitude: `${lat}`,
      longitude: `${lng}`,
      address1: [main_text, ''],
      province: '',
      provinceCode: '',
      country: '',
      countryCode: '',
      city: '',
      zip: '',
    };
    address_components.map((item) => {
      const key = item?.types?.[0];
      switch (key) {
        case VALUE_MAP.country:
          addressDetail.country = item?.long_name;
          addressDetail.countryCode = item?.short_name;
          break;
        case VALUE_MAP.province:
          addressDetail.province = item?.long_name || '';
          addressDetail.provinceCode = provinceList?.find((province) => province.name === item?.long_name)?.code || '';
          break;
        case VALUE_MAP.city:
          addressDetail.city = item?.long_name;
          break;
        case VALUE_MAP.zip:
          addressDetail.zip = item?.long_name;
          break;
        case VALUE_MAP.area:
          addressDetail.address1[1] = item?.long_name;
          break;
        // TODO: 地址拼凑问题模糊，先暂时注释
        // case VALUE_MAP.route:
        //     addressDetail.address1[1] = item?.long_name;
        //     break;
        // case VALUE_MAP.detail:
        //     addressDetail.address1[2] = item?.long_name;
        //     break;
        default:
          break;
      }
    });
    set(addressDetail, 'address1', addressDetail.address1.join(', '));

    form.patchValue(addressDetail);
    setAutoAddressList([]);
  };

  const bindBodyClick = (e) => {
    // 失去焦点，必须如此，不能用onBlur
    if (!e.target.parentNode?.classList.contains(Address1InputRef)) {
      setShowAutoAddress(false);
    }
  };

  useEffect(() => {
    if (showAutoAddress) {
      document.addEventListener('click', bindBodyClick, false);
    } else {
      document.removeEventListener('click', bindBodyClick, false);
    }
    return () => {
      document.removeEventListener('click', bindBodyClick, false);
    };
  }, [showAutoAddress]);

  // 特殊需求 国家单独搜索逻辑, 二字码
  const countryFilter = (list, keyword) => {
    return list?.filter((item) => {
      return (
        item?.name?.toLowerCase().indexOf(keyword?.toLowerCase()) !== -1 ||
        item?.code.toLowerCase() === keyword?.toLowerCase()
      );
    });
  };

  return (
    <Style>
      <div className={cn(dialogModal ? 'dialog-address-form' : '', className)}>
        <Form form={form} className='address-form' onSubmit={onAddressInputSubmit}>
          <div className={cn('address-edit', 'order-form-container')}>
            <div className='flex-container'>
              <div className='flex-item  mr-24'>
                <FormFileds
                  name='firstName'
                  label={`${labels.firstName || ''}${isRequiredFirstName ? '*' : ''}`}
                  validators={
                    isRequiredFirstName ?
                    [Validators.required($fm('common_please_input_TEXT', '请输入{text}', {text: labels.firstName}))] :
                    []
                  }
                  style={{ marginTop: 0 }}
                />
              </div>
              <div className='flex-item'>
                <FormFileds
                  name='lastName'
                  label={`${labels.lastName || ''}${isRequiredLastName ? '*' : ''}`}
                  validators={[
                    ...(
                      isRequiredLastName ?
                      [Validators.required($fm('common_please_input_TEXT', '请输入{text}', {text: labels.lastName}))] :
                      []
                    ),
                    Validators.maxLength(
                      32,
                      intl.formatMessage(
                        {
                          id: '5f7b66782d9f4922b95cfbdf444f56a7',
                          defaultMessage: '超出最大长度32',
                        },
                        {
                          num: 32,
                        },
                      ),
                    ),
                  ]}
                  style={window.innerWidth > 750 ? { marginTop: 0 } : {}}
                />
              </div>
            </div>
            <div className='flex-item mt-16'>
              <HtFormInputPhone
                intl={intl}
                width='100%'
                form={form}
                phoneName='phone'
                mobileCodeName='callingCodes'
                mobileAreaName='callingArea'
                onMobileCountryCodeChange={(callingCodes, countryCode) => {
                  form.patchValue({
                    callingCodes,
                    callingArea: countryCode,
                  });
                }}
              />
            </div>
            {/* <FormFileds
                            name='phone'
                            validators={[
                                Validators.required(
                                    intl.formatMessage({
                                        id: 'order.address.please_input_phone',
                                        defaultMessage: '请填写电话',
                                    }),
                                ),
                                (value) => {
                                    return checkIsEmpty(
                                        value,
                                        intl.formatMessage({
                                            id: 'order.address.please_input_phone',
                                            defaultMessage: '请填写电话',
                                        }),
                                    );
                                },
                                (value) => {
                                    return checkPhone(value, intl);
                                },
                            ]}
                            label={intl.formatMessage({
                                id: 'order.address.phone',
                                defaultMessage: '电话',
                                description: '电话',
                            })}
                        /> */}
            <FormFileds
              name='address1'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'please_fill_in_the_full_address',
                    defaultMessage: '请填写完整地址',
                    description: '请填写完整地址',
                  }),
                ),
              ]}
              label={`${labels.address1 || ''}*`}
              onFiledsChange={onAddress1Change}
              onFocus={() => setShowAutoAddress(autoAddressList.length !== 0)}
              inputClass={Address1InputRef}
            />
            {isExistRadiusLocalDelivery && (
              <AutoAddressSelectFiled
                autoAddressList={autoAddressList}
                showOptions={showAutoAddress}
                onFiledChange={onSelectAutoAddress}
                hasAddress1={hasAddress1}
              />
            )}
            {
              customerAddressSettings.address2 !== AddressSettingsEnum.none &&
              <FormFileds
                name='address2'
                label={`${labels.address2 || ''}${isRequiredAddress2 ? '*' : ''}`}
                validators={
                  isRequiredAddress2 ?
                  [Validators.required($fm('common_please_input_TEXT', '请输入{text}', {text: labels.address2}))] :
                  []
                }
                placeholder={intl.formatMessage({
                  id: 'general.option_input',
                })}
              />
            }
            <FormFileds
              name='city'
              validators={[
                Validators.required(
                  intl.formatMessage({
                    id: 'please_fill_in_the_city',
                    defaultMessage: '请填写城市',
                    description: '请填写城市',
                  }),
                ),
              ]}
              label={`${labels.city || ''}*`}
            />
            <div className='flex-container'>
              <div className='flex-item mr-24'>
                <SelectFileds
                  position='up'
                  name='countryCode'
                  data={shippableCountry}
                  optionText='name'
                  optionValue='code'
                  label={`${labels?.country}*`}
                  getSelectValue={(countryCode) => {
                    onCountryChange(countryCode);
                    // 取provinceCode主要是为了切换无省份国家时校验一下，无省份需要清空省份相关信息
                    const provinceCode = form.getValue().provinceCode || '';
                    const { country = '', province = '' } =
                      getCountryAndProvinceName({ countryCode, provinceCode }) || {};
                    form.patchValue({
                      country,
                      province,
                      provinceCode: province ? provinceCode : '',
                    });
                  }}
                  disabled={!hasShipCountry}
                  labelType='inner'
                  validators={[
                    Validators.required(
                      intl.formatMessage({
                        id: 'order.address.please_input_city',
                        defaultMessage: '请输入',
                        description: '请输入',
                      }),
                    ),
                  ]}
                  canEmpty
                  onFilter={countryFilter}
                />
                <EmptyFormFileds name='country' />
                {!hasShipCountry && (
                  <p className='no-ship-address'>
                    {intl.formatMessage({
                      id: '65c1f9a42a4a45cb8f6b50fb3879b9e9',
                      defaultMessage: '暂无配送区域',
                      description: '暂无配送区域',
                    })}
                  </p>
                )}
              </div>
              {provinceList?.length > 0 && shippableCountry?.length > 0 && (
                <div className='flex-item mr-24'>
                  <SelectFileds
                    position='up'
                    name='provinceCode'
                    data={provinceList}
                    getSelectValue={() => {
                      const data = form.getValue();
                      const countryData = getCountryAndProvinceName(data);
                      form.patchValue(countryData);
                    }}
                    optionText='name'
                    optionValue='code'
                    validators={[
                      Validators.required(
                        intl.formatMessage({
                          id: 'order.address.please_input_province',
                          defaultMessage: '请填写区域',
                        }),
                      ),
                    ]}
                    labelType='inner'
                    label={`${labels?.zone}*`}
                    canEmpty
                  />
                  <EmptyFormFileds name='province' />
                </div>
              )}

              <div className='flex-item'>
                <FormFileds
                  name='zip'
                  label={`${labels.postalCode || ''}${isRequiredZip ? '*' : ''}`}
                  onFiledsChange={(value, preValue) => {
                    if (form?.getValue()?.countryCode === 'CA' && preValue?.length < value?.length) {
                      form.patchValue({
                        zip: getCanadaZipValue(value),
                      });
                    }
                  }}
                  validators={zipValidators}
                />
              </div>
            </div>

            <div className='save'>
              <Checkbox
                onChange={(event) => {
                  setChecked(event.target.checked);
                }}
                checked={checked}
              >
                {intl.formatMessage({
                  id: isLogin ? 'account.form.set_as_the_default_address' : '46a62baa20bd4a5f8c5d6f63f2cc2af6',
                  defaultMessage: '设为默认地址',
                })}
              </Checkbox>
            </div>
          </div>
          {dialogModal && <span className='address-edit-action-wrapper-boder' />}
          <div
            className={cn({
              'address-edit-action-wrapper': showConfirm && showCancel,
              'address-action-wrapper': showConfirm && !showCancel,
            })}
          >
            {showCancel && (
              <Button onClick={onCancel}>{intl.formatMessage({ id: 'general.cancel', defaultMessage: '取消' })}</Button>
            )}
            {showConfirm && (
              <Button type='primary' htmlType='submit'>
                {intl.formatMessage({ id: 'general.save', defaultMessage: '保存' })}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Style>
  );
};

export default AddressInput;
