import React, { useState } from 'react';
import { Button } from 'zent';
import Style from './style';
import { formateTimeByTimeZone } from 'utils/datetime';
import { useCoupons } from 'hook/discount/use-coupons';
import discountApi from 'api/discount';
import cn from 'classnames';
import { useRootContext } from 'client/pages/context/root';

enum CouponStatus {
  success = 'success', // 可领取
  activity_waiting = 'activity_waiting', // 活动待开始：activity_waiting
  activity_expired = 'activity_expired', // 活动已结束
  take_out = 'take_out', // 码已抢完
  take_exceed = 'take_exceed', // 码已达到领取次数
}

const CouponBody = ({ intl, initData, discountInfo, onTakeSuccess, onClose }) => {
  const { activityId, shopSetting, kdtId, userInfo, multiCurrencyInfo } = initData;
  const currency = shopSetting?.currency;
  const customerId = userInfo?.customerId;
  const { title, useCondition, useRange } = useCoupons({ intl, currency, data: discountInfo, multiCurrencyInfo });
  const { setLoginDialogInfo } = useRootContext();

  const [activeStatus, setActiveStatus] = useState(discountInfo?.status);

  const [loading, setLoading] = useState(false);

  const takePromoCode = () => {
    if (discountInfo?.status !== CouponStatus.success) {
      return;
    }
    if (customerId) {
      setLoading(true);
      discountApi
        .takePromoCode({
          activityId,
          customerId,
          kdtId,
        })
        .then((res) => {
          if (res.status) {
            setActiveStatus(res.status);
          }
          res?.code && onTakeSuccess && onTakeSuccess(res?.code);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // 去登录
      setLoginDialogInfo({
        visible: true,
      });
    }
  };

  const btnTextMap = {
    [CouponStatus.success]: {
      text: intl.formatMessage({ id: 'f608b4ff64cf47f0bda5df5c08a71986', defaultMessage: '立即领取' }),
      status: 'ok',
    },
    [CouponStatus.activity_waiting]: {
      text: intl.formatMessage({ id: '225b6d67f60c4b5f841a3e2cb5e2691d', defaultMessage: '活动待开始' }),
      status: 'disabled',
    },
    [CouponStatus.activity_expired]: {
      text: intl.formatMessage({ id: '202256f79cad4021b1d7c77e81b698d6', defaultMessage: '活动已结束' }),
      status: 'end',
    },
    [CouponStatus.take_out]: {
      text: intl.formatMessage({ id: '67c030114c6d49db8f40458b95c77836', defaultMessage: '优惠码已被抢完' }),
      status: 'end',
    },
    [CouponStatus.take_exceed]: {
      text: intl.formatMessage({ id: '8ef9bcdb9bc8408e80cf7c7994b7b7a9', defaultMessage: '你已领取完' }),
      status: 'end',
    },
  };

  return (
    <Style>
      <div className='coupon-body-wrap'>
        <div className='coupon-close-btn' onClick={onClose}>
          <svg className='return-icon' aria-hidden='true' height='30px' width='30px'>
            <use xlinkHref='#iconguanbidanchuang' />
          </svg>
        </div>
        <div className='coupon-body'>
          <div className='coupon-body-title'>
            {intl.formatMessage({
              id: 'be903c5940944a61a0ddaeb143838262',
              defaultMessage: '送你一张优惠码',
            })}
          </div>
          <div className='coupon-card'>
            <div className='coupon-card-body'>
              <div className='coupon-card-decorate-1' />
              <div className='coupon-card-decorate-2' />
              <div className='coupon-card-name'>{title}</div>
              <div className='coupon-card-desc'>{useCondition}</div>
              <div className='coupon-card-line' />
              <div className='coupon-card-goods-range'>{useRange}</div>
            </div>
          </div>
        </div>
        <div className='coupon-footer'>
          <div className='coupon-footer-data'>
            <span className='coupon-footer-data-name'>
              {intl.formatMessage({
                id: '70382e433521489593a7c25b8e5548fa',
                defaultMessage: '有效期',
              })}
              ：
            </span>
            {formateTimeByTimeZone(discountInfo?.activityStartAt, 'YYYY.MM.DD')}
            {discountInfo?.activityEndAt
              ? ` - ${formateTimeByTimeZone(discountInfo?.activityEndAt, 'YYYY.MM.DD')}`
              : `(${intl.formatMessage({
                  id: 'long.time.effective',
                  defaultMessage: '长期有效',
                })})`}
          </div>
          <Button
            loading={loading}
            type='primary'
            className={cn('coupon-footer-button', `coupon-footer-button-${btnTextMap?.[activeStatus]?.status}`)}
            onClick={takePromoCode}
          >
            {btnTextMap?.[activeStatus]?.text || '--'}
          </Button>
        </div>
      </div>
    </Style>
  );
};

export default CouponBody;
