import React, { useState, useEffect, useCallback } from 'react';
import { Style, DialogStyle } from './style';
import { Dialog, Radio } from 'zent';
import HtButton from '../../standard/button';
import HtDialog from '../../standard/dialog';
import deliveryApi from 'api/delivery';
import { openPersonFormDialog } from './person-form-dialog';
import { useRootContext } from 'client/pages/context/root';
interface IPersonProps {
  customerId: number;
  firstName: string;
  isDefault: number;
  lastName: string;
  phone: string;
  uniqueId?: number;
}

const PickupPersonDialog = (props) => {
  const { intl, $t, userInfo, isLogin, visibleDialog, onClose, defaultPerson, onConfirm } = props;

  const [personList, setPersonList] = useState<IPersonProps[]>([]);
  const [selectUniqueId, setSelectUniqueId] = useState(0);
  const [selectPersonItem, setSelectPersonItem] = useState({});
  const { theme } = useRootContext();

  // 已登录查询 提货人列表
  useEffect(() => {
    if (isLogin) {
      getPickupPerson().then((res) => {
        // 后端没办法记录 uniqueId, 使用字符串匹配
        const { firstName, lastName, phone } = defaultPerson;
        const item = res?.find(
          (item) => item.firstName === firstName || item.lastName === lastName || item.phone === phone,
        );
        setSelectUniqueId(item.uniqueId);
      });
    }
  }, []);

  // 未登录 打开表单弹窗
  useEffect(() => {
    if (visibleDialog) {
      if (isLogin) {
        personList.length === 0 && addPerson();
      } else {
        unLoginHandlePerson();
      }
    }
  }, [visibleDialog, personList]);

  // 获取提货人列表
  const getPickupPerson = () => {
    return new Promise((resovle, reject) => {
      deliveryApi
        .getPickUpInfoByCustomerId({
          kdtId: userInfo.kdtId,
          customerId: userInfo.customerId,
        })
        .then((res) => {
          if (res.length > 0) {
            setPersonList(res);
          }
          resovle(res);
        });
    });
  };

  // 未登录提货人处理
  const unLoginHandlePerson = useCallback(() => {
    if (selectPersonItem.phone) {
      editPerson(selectPersonItem);
    } else {
      addPerson();
    }
  }, [selectPersonItem]);

  // 提货人 添加编辑成功
  const handlePersonSuccess = (params) => {
    if (isLogin) {
      getPickupPerson().then((res) => {
        if (selectUniqueId) {
          const item = res?.find((item) => item.uniqueId == selectUniqueId);
          item && setSelectPersonItem(item);
        }
      });
    } else {
      onConfirm(params);
    }
  };
  // 添加提货人
  const addPerson = () => {
    openPersonFormDialog({
      intl,
      isEdit: false,
      defaultValue: null,
      onSuccess: handlePersonSuccess,
      theme,
    });
  };
  // 编辑提货人
  const editPerson = (item) => {
    openPersonFormDialog({
      intl,
      isEdit: true,
      defaultValue: item,
      onSuccess: handlePersonSuccess,
      theme,
    });
  };
  // 选择提货人
  const selectPerson = (item) => {
    setSelectUniqueId(item.uniqueId);
    setSelectPersonItem(item);
  };
  // 确认
  const confirm = () => {
    if (selectPersonItem.uniqueId) {
      onConfirm(selectPersonItem);
    } else {
      onClose();
    }
  };

  return (
    <HtDialog
      theme={theme}
      className='pick-up-person-dialog'
      visible={visibleDialog}
      title={$t('ebe6c22cfacb406197598809f0625e5e', '选择提货人')}
      onClose={onClose}
    >
      <DialogStyle />
      <Style>
        <div className='pick-up-person-dialog-container'>
          <div className='person-list-wrap'>
            {personList.length > 0 ? (
              <ul className='person-list'>
                {personList.map((item) => (
                  <li className='person-item' key={item.uniqueId}>
                    <div className='person-item-box'>
                      <Radio
                        checked={item.uniqueId == selectUniqueId}
                        onChange={(e) => {
                          if (e.target.checked) {
                            selectPerson(item);
                          }
                        }}
                      ></Radio>
                      <div className='person-info' onClick={() => selectPerson(item)}>
                        <div className='person-info__name'>
                          <span>{`${item.firstName} ${item.lastName}`}</span>
                          {Boolean(item.isDefault) && (
                            <span className='tag-default'>{$t('general.default', '默认')}</span>
                          )}
                        </div>
                        <div className='person-info__phone'>
                          <span>{item.phone}</span>
                        </div>
                      </div>
                      <svg onClick={() => editPerson(item)} className='icon icon-edit' aria-hidden='true'>
                        <use xlinkHref='#iconbianji' />
                      </svg>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='person-empty'>{$t('7c74a55192cc4e25bcd709ee0d25e488', '暂无提货人信息')}</div>
            )}
          </div>
          <div className='pick-up-person-dialog-footer'>
            <HtButton onClick={addPerson} type='secondary' theme={theme}>
              {$t('8facd2ab88234b7395cedf1894011bc2', '添加提货人')}
            </HtButton>
            <div className='dialog-footer-btn'>
              <HtButton onClick={onClose} type='secondary' theme={theme}>
                {$t('common_cancel', '取消')}
              </HtButton>
              <HtButton onClick={confirm} theme={theme}>
                {$t('common_confirm', '确定')}
              </HtButton>
            </div>
          </div>
        </div>
      </Style>
    </HtDialog>
  );
};

export default PickupPersonDialog;
