import styled from 'styled-components';

export default styled.div`
  flex: 1;
  .zent-form-control {
    margin-bottom: 0px !important;
  }
  .zent-form-control-content {
    margin-left: 0 !important;
  }
  .pay-radio {
    min-height: 68px;
    box-sizing: border-box;
  }
  .select-container {
    font-weight: normal;
  }
  .zent-col {
    width: 100% !important;
  }
  .zent-radio-label {
    width: 100%;
  }
  .item-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .item-wrapper_disabled {
    color: rgba(var(--color_body_text_rgb), 0.4);
    .delivery-description {
      color: rgba(var(--color_body_text_rgb), 0.4) !important;
    }
  }
  .send-row {
    min-height: 68px;
    display: flex;
    align-items: center;
  }
  .zent-radio-wrap {
    display: flex;
    padding: 16px 0;
    align-items: center;
  }
  .payment-line {
    position: relative;
  }
  .empty {
    margin-left: 16px;
    visibility: hidden;
  }
  .send-row {
    border-bottom: 1px solid rgba(51, 51, 51, 0.08);
    line-height: 68px !important;
    &:last-child {
      border: none;
    }
  }
  .pay-wrapper {
    display: flex;
    align-items: center;
    padding-right: 8px;
    .pay-logo {
      img {
        max-width: 24px;
        max-height: 24px;
        margin: 0 10px 0 16px;
      }
    }
  }
  .pay-text-wrapper,
  .delivery-text-wrapper {
    display: inline-flex;
    flex-direction: column;
    width: 90%;
    padding-left: 8px;
    flex-grow: 1;
    word-break: break-all;
    .pay-text,
    .delivery-text {
      flex-shrink: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .pay-description,
    .delivery-description {
      color: #9b9ea4;
      font-size: 12px;
      line-height: 12px;
      margin-top: 4px;
      line-height: 16px;
    }
  }
  .delivery-text-wrapper {
    padding-left: 0;
    flex: 1;
    .delivery-description {
      color: rgba(var(--color_body_text_rgb), 0.6);
    }
  }
  .radio-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-wrapper {
    display: inline-flex;
    min-width: 362px;
    .logo {
      border: 1px solid rgba(var(--color_body_text_rgb), 0.08);
      width: 40px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      img {
        max-height: 20px;
        max-width: 36px;
      }
    }
  }
  .pay-way-radio .send-row {
    line-height: 68px;
  }
  .pay-way-radio {
    display: block !important;
    line-height: 68px !important;
    .delivery-show-more {
      color: var(--color_button);
      margin: 10px 0 24px;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 4px;
      svg {
        fill: var(--color_button);
      }
      .icon-up {
        transform: rotateX(180deg);
      }
    }
  }
  .layout-cell-right {
    text-align: right;
    margin-right: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .provider-wrapper {
    .zent-radio-group {
      width: 100%;
    }
    .zent-radio-wrap {
      width: 100%;
    }
    padding-left: 48px;
    .provider-radio-wrapper {
      min-height: 67px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .provider-radio-wrapper {
      border-top: solid 1px rgba(51, 51, 51, 0.08);
    }
    .provider-name {
      padding-left: 16px;
    }
  }
  .delivery-content {
    flex: 1;
  }

  @media screen and (max-width: 750px) {
    .radio-content-wrapper {
      display: block;
      .logo-wrapper {
        margin-top: var(--pm-24-16);
      }
    }
    .logo-position-fix .zent-radio {
      top: -24px;
    }
  }
`;
