import styled from 'styled-components';

export const Style = styled.div`
  .policy-wrapper {
    position: absolute;
    width: 100%;
    max-width: 1280px;
    text-align: center;
    color: rgba(var(--color_body_text_rgb), 0.4);
    padding: 24px 0;
    margin-top: -64px;
    .refund {
      padding-left: 12px;
      margin-left: 12px;
      border-left: solid 1px #333333;
    }
    span {
      cursor: pointer;
    }
  }
  .view-detail {
    color: #304ffe;
    padding-bottom: 24px;
    padding-top: 16px;
    span {
      cursor: pointer;
    }
  }
  &.content {
    min-height: 150px;
    max-height: 390px;
    max-width: 100%;
    width: 524px;
    overflow: auto;
  }
  .item-wrapper {
    border-bottom: 1px solid #f5f5f5;
  }

  @media screen and (min-width: 750px) {
    .policy-wrapper {
      &.little-height {
        position: static;
        margin-top: 80px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .content {
      min-height: 80px;
    }
    .policy-wrapper {
      position: static;
      margin-top: 0px;
      width: 100%;
      text-align: center;
      color: rgba(51, 51, 51, 0.8);
      font-size: 14px;
      padding-bottom: 92px;
      .refund {
        padding-left: 12px;
        margin-left: 12px;
        border-left: solid 1px #333333;
      }
    }
  }
`;
