import orderApi from 'api/order';
import userApi from 'api/user';
import appointmentApi from 'api/appointment';
import { buriedPoint } from 'utils/log-buried-point';
import { openWindowWithHtmlStr, jumpPayUrlAddLongParams } from 'helper/pay';
import { getOrderUtmParams } from 'utils/log-buried-point/util';
import { set, isEmpty, pick, omit, merge, isArray } from 'lodash';
import { NEXT_ACTION_TYPE, PAY_ORDER_ERROR_CODE } from './const';
import type { IPreviewDetail, IPayOrderParam, IPayOrderVO, ICustomizedFields } from './types';
import { isSpecialMultiCurrency } from 'utils/multi-currency';
import { getDistributionCookies } from 'helper/affiliate';
import { OrderType } from 'const/order';
import { openPayScanDialog } from 'components/page/pay/pay-scan';
import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE } from 'packages/client/pages/order/component/customized-checkout/const';
import { ORDER_STATUS } from 'const/index';

export enum PaymentFactoryTypeEnum {
  default = 'default', // 默认弃单模式
  member = 'member', // 弃单模式 - 会员购买弃单模式
  goods = 'goods', // 非弃单模式 - 商品购买
  service = 'service', // 非弃单模式 - 服务购买
}

export enum PaymentTypeEnum {
  PAYPAL = 1,
  STRIPE = 2,
  ALLINPAY = 3,
  ALPHA_PAY = 7,
  NIHAO_PAY = 12,
  CLOVER = 22,
};

export enum PAY {
  COMPLETE_ONLY_PATH = '/order/complete', // 有些需求token必须放在最后面，以供后端拼参数
  ORDER_DETAIL_PATH = '/me/order',
  APPOINTMENT_COMPLETE_ONLY_PATH = '/order/complete', // 有些需求token必须放在最后面，以供后端拼参数
  APPOINTMENT_ORDER_DETAIL_PATH = '/me/order',
}

class Payment {
  /** 结账类型 */
  public paymentType: PaymentFactoryTypeEnum;
  /** 结账预览 */
  public previewDetail: IPreviewDetail;
  /** 下单参数 */
  public payOrderParam: IPayOrderParam;
  /** 下单响应 */
  public payOrderRes: IPayOrderVO;
  /** 是否自动唤醒支付 */
  public isAutoEvokePayment: boolean = true;
  /** 是否会员商品订单 */
  public isMemberOrder: boolean = false;
  /** 支付完成链接 */
  public payCompletePath: string = PAY.COMPLETE_ONLY_PATH;
  /** 订单详情链接 */
  public orderDetailPath: string = PAY.ORDER_DETAIL_PATH;
  /** 多币种信息 */
  public multiCurrency: string | null;
  /** 二维码轮询定时器 */
  public qrcodeTimer;
  /** 国际化 */
  public intl;


  constructor(props) {
    this.intl = props.intl;
    this.paymentType = props.paymentType;
    this.previewDetail = props.orderDetail;
    this.isAutoEvokePayment = props.isAutoEvokePayment ?? true;
    this.multiCurrency = isSpecialMultiCurrency(window?.global_data?.multiCurrencyInfo)
      ? window?.global_data?.multiCurrencyInfo?.currency
      : null;
  }

  /** 订单支付参数预处理 */
  beforePayOrder(payOrderParam: IPayOrderParam): Promise<IPayOrderParam> {
    return new Promise(async (resolve, reject) => {
      this.payOrderParam = payOrderParam;
      this.userTrack();
      const cancelUrl = this.getCancelUrl();
      const redirectUrl = this.getRedirectUrl();
      // loading
      resolve({
        ...pick(payOrderParam, [
          'token',
          'billingAddress',
          'sameAsShippingAddress',
          'paymentMethods',
          'paymentCategory',
          'paymentGatewayId',
          'paymentMethodOptions',
          'identifier',
          'extra',
          'message',
        ]),
        cancelUrl,
        redirectUrl,
        client: {
          userAgent: navigator?.userAgent || '',
        },
      });
    });
  }

  /** 订单支付 */
  payOrder(payOrderParam: IPayOrderParam) {
    return new Promise((resolve, reject) => {
      this.beforePayOrder(payOrderParam).then((requestParams) => {
        orderApi
          .payOrder(requestParams)
          .then((res: IPayOrderVO) => {
            this.payOrderRes = res;
            this.payOrderSuccessCallback();
            resolve(res);
          })
          .catch((err) => {
            this.payOrderErrorCallback(err);
            reject(err);
          })
          .finally(() => {
            // loading
          });
      });
    });
  }

  /**
   * 下单成功回调
   */
  payOrderSuccessCallback() {
    const { payOrderRes, isAutoEvokePayment } = this;
    const { token, orderConfirmed } = payOrderRes;
    // 删除已支付的订单自定义信息 TODO:
    // 订单已确认, 无需支付则直接重定向
    if (orderConfirmed === 1) {
      this.toPayComplete();
      return;
    }
    // 默认自动唤醒
    if (isAutoEvokePayment) {
      this.evokePayment(); // 唤醒支付
    }
  }

  /**
   * 下单失败回调
   */
  payOrderErrorCallback(err) {
    const errorCode = [
      PAY_ORDER_ERROR_CODE.ORDER_HAS_PAID,
      PAY_ORDER_ERROR_CODE.ORDER_CANCELED,
      PAY_ORDER_ERROR_CODE.ORDER_REFUNDED,
    ];
    if (errorCode.includes(err?.code)) {
      // 已支付、已取消、已退款订单重定向到详情页, 报错提示完进行重定向
      const cancelUrl = this.getCancelUrl();
      setTimeout(() => {
        location.replace(cancelUrl);
      }, 1000);
    }
  }

  /**
   * 唤醒支付
   * 都根据 nextAction 做下一步操作，不需要考虑 providerType
   */
  evokePayment() {
    const { payOrderRes, orderDetailPath } = this;
    const { token, nextAction, httpMethod, paymentUrl, paymentParams } = payOrderRes;
    switch (nextAction?.type) {
      // 使用 stripe sdk, 外部回调处理
      case NEXT_ACTION_TYPE.use_stripe_sdk: {
        break;
      }
      // html 二维码
      case NEXT_ACTION_TYPE.display_qr_code: {
        const displayQrCode = nextAction?.displayQrCode ?? {};
        this.showScanPay(displayQrCode);
        break;
      }
      // html 二维码
      case NEXT_ACTION_TYPE.display_html_qr_code: {
        const { htmlQrCode } = nextAction?.htmlQrCode ?? {};
        openWindowWithHtmlStr(htmlQrCode);
        break;
      }
      default: {
        if (httpMethod?.toLowerCase() === 'get') {
          if (paymentUrl.startsWith(`${window.location.origin}`)) { // 内部跳转
            const [_, path] = paymentUrl?.split(`${window.location.origin}`);
            location.replace(path || '/');
          } else {
            window.history.replaceState(null, '' , `${orderDetailPath}/${token}`); // 替换结算页
            window.location.href = paymentUrl;
          }
          return;
        }
        // 表单提交唤醒支付
        window.history.replaceState(null, '' , `${orderDetailPath}/${token}`); // 替换结算页
        jumpPayUrlAddLongParams({
          url: paymentUrl,
          params: paymentParams,
          httpMethod,
        });
      }
    }
  }

  // 扫码支付
  showScanPay(params) {
    const { intl, qrcodeTimer, getOrderStatus } = this;
    const { qrCode } = params || {};
    if (qrCode) {
      if (qrcodeTimer) {
        clearInterval(qrcodeTimer);
      }
      this.qrcodeTimer = setInterval(() => {
        getOrderStatus();
      }, 2000);
      openPayScanDialog({
        intl,
        ...params,
      });
    }
  };

  // 查询订单支付状态
  getOrderStatus() {
    const { previewDetail, payOrderRes, toPayComplete } = this;
    const { token } = previewDetail;
    const { token: orderToken } = payOrderRes || {};
    orderApi.getOrderStatusByToken({ token: token || orderToken }).then((res) => {
      const { financialStatus } = res;
      if (financialStatus === ORDER_STATUS.PAID) {
        clearInterval(this.qrcodeTimer);
        toPayComplete();
      }
    });
  };

  /** 埋点 */
  userTrack() {
    if (this.isMemberOrder) return; // 购买会员不埋点
    const { paymentCategory } = this.payOrderParam;
    buriedPoint('add_payment_info', {
      payment_type: paymentCategory,
    });
  }

  /**
   * 支付完成重定向
   */
  toPayComplete() {
    let url = this.getRedirectUrl();
    location.replace(url);
  }

  /**
   * 获取支付完成, 重定向地址
   */
  getRedirectUrl() {
    const { payCompletePath } = this;
    const { token } = this.previewDetail;
    const { token: orderToken } = this.payOrderRes || {};
    const { origin } = window.location;
    return `${origin}${payCompletePath}?orderToken=${token || orderToken || ''}`
  }

  /**
   * 获取支付取消地址
   * 正常是为当前页面的地址，但是在没地址情况下，商品页的下单页会把地址也带过来【其实不合理】，所以取消地址就只取了token
   */
  getCancelUrl() {
    const { orderDetailPath } = this;
    const { token } = this.previewDetail;
    const { token: orderToken } = this.payOrderRes || {};
    const { origin } = window.location;
    return `${origin}${orderDetailPath}/${token || orderToken || ''}`
  }

  /**
   * 销售渠道参数
   */
  getChannelParams() {
    const { cookies: metafields = {} } = getDistributionCookies() || {};
    const utm = getOrderUtmParams();
    const salesChannel = 'online_shop';
    return {
      utm,
      metafields: isArray(metafields) ? metafields.filter(item => !isEmpty(item)) : metafields,
      salesChannel,
    };
  }
}

/**
 * 会员商品 Payment
 */
class MemberPayment extends Payment {
  constructor(props) {
    super(props);
    this.isMemberOrder = true;
  }
}

/**
 * 非弃单 普通商品 Payment
 */
class TraditionGoodsPayment extends Payment {
  payOrder(payOrderParam: IPayOrderParam) {
    return new Promise((resolve, reject) => {
      this.beforePayOrder(payOrderParam).then((requestParams) => {
        const { previewDetail, multiCurrency } = this;
        const basicParams = {
          ...pick(previewDetail, [
            'basicInfo',
            'buyerInfo',
            'lineItems',
            'localDeliveryInfo',
            'pickupInfo',
            'pointsInfo',
            'shippingAddress',
            'contactEmail',
            'requestId',
            'encryptRequestId',
          ]),
          ...pick(requestParams, [
            'token',
            'billingAddress',
            'message',
          ]),
          autoConfirm: 0, // 固定传0，统一走0元单自动确认，跟之前 createOrder一样的逻辑
        }
        const payment = pick(requestParams, [
          'paymentMethods',
          'paymentCategory',
          'paymentGatewayId',
          'paymentMethodOptions',
          'identifier',
          'client',
          'identifier',
          'redirectUrl',
          'cancelUrl',
        ]);
        const channelParams = this.getChannelParams();
        const extra = merge(requestParams.extra, {
          multiCurrency,
        });
        let params = {};
        let api = '';
        if (requestParams?.token) {
          api = 'payTraditionPlaceOrder';
          params = {
            ...pick(requestParams, [
              'token',
              'billingAddress',
              'message',
            ]),
            ...payment,
            extra,
          };
        } else {
          api = 'createTraditionPlaceOrder';
          params = {
            ...channelParams,
            ...basicParams,
            deliveryInfo: pick(previewDetail.deliveryInfo, ['selectedDeliveryMethod', 'shippingInfo', 'locationInfo']),
            lineItems: previewDetail?.lineItems?.map(item => pick(item, ['goodsId', 'quantity', 'variantId'])),
            confirmTotalPrice: previewDetail?.totalPrice || 0,
            discountInfo: {
              discountCode: previewDetail?.discountInfo?.discountCode || null,
            },
            autoConfirm: 0, // 固定传0，统一走0元单自动确认，跟之前 createOrder一样的逻辑
            payment,
            extra,
          };
        }
        orderApi[api](params)
          .then((res: IPayOrderVO) => {
            this.payOrderRes = res;
            this.payOrderSuccessCallback();
            resolve(res);
          })
          .catch((err) => {
            this.payOrderErrorCallback(err);
            reject(err);
          })
          .finally(() => {
            // loading false
          });
      });
    });
  }
}

/**
 * 非弃单 服务商品 Payment
 */
class TraditionServicePayment extends Payment {
  constructor(props) {
    super(props);
    this.payCompletePath = PAY.APPOINTMENT_COMPLETE_ONLY_PATH;
    this.orderDetailPath = PAY.APPOINTMENT_ORDER_DETAIL_PATH;
  }
  payOrder(payOrderParam: IPayOrderParam) {
    return new Promise((resolve, reject) => {
      this.beforePayOrder(payOrderParam).then((requestParams) => {
        const { previewDetail, multiCurrency } = this;
        const basicParams = {
          ...pick(previewDetail, [
            'appointmentInfo',
            'basicInfo',
            'buyerInfo',
            'pointsInfo',
            'contactEmail',
            'requestId',
            'encryptRequestId',
          ]),
          ...pick(requestParams, [
            'token',
            'billingAddress',
            'message',
          ]),
          autoConfirm: 0, // 固定传0，统一走0元单自动确认，跟之前 createOrder一样的逻辑
        }
        const payment = pick(requestParams, [
          'paymentMethods',
          'paymentCategory',
          'paymentGatewayId',
          'paymentMethodOptions',
          'identifier',
          'client',
          'identifier',
          'redirectUrl',
          'cancelUrl',
        ]);
        const channelParams = this.getChannelParams();
        const extra = merge(requestParams.extra, {
          multiCurrency,
        });
        let params = {};
        let api = '';
        if (requestParams?.token) {
          api = 'rePayAppointment';
          params = {
            ...pick(requestParams, [
              'token',
              'billingAddress',
              'message',
            ]),
            ...payment,
            extra,
          };
        } else {
          api = 'createAppointment';
          params = {
            ...channelParams,
            ...basicParams,
            lineItems: previewDetail?.lineItems?.map(item => pick(item, ['goodsId', 'quantity', 'variantId'])),
            confirmTotalPrice: previewDetail?.totalPrice || 0,
            discountInfo: {
              discountCode: previewDetail?.discountInfo?.discountCode || null,
            },
            payment,
            extra,
          };
        }
        appointmentApi[api](params)
          .then((res: IPayOrderVO) => {
            this.payOrderRes = res;
            this.payOrderSuccessCallback();
            resolve(res);
          })
          .catch((err) => {
            this.payOrderErrorCallback(err);
            reject(err);
          })
          .finally(() => {
             // loading false
          });
      });
    })
  }
}

/**
 * Payment 工厂函数
 */
export const paymentFactory = (params: {
  intl: any
  orderDetail: any,
  isAutoEvokePayment?: boolean,
}) => {
  const { orderDetail } = params || {};
  if (orderDetail.orderType === OrderType.Member) {
    return new MemberPayment({
      ...params,
      paymentType: PaymentFactoryTypeEnum.member,
    });
  } else if (orderDetail.isPointsExchange) {
    return new TraditionGoodsPayment({
      ...params,
      paymentType: PaymentFactoryTypeEnum.goods,
    });
  } else if (orderDetail.isAppointmentOrder) {
    return new TraditionServicePayment({
      ...params,
      paymentType: PaymentFactoryTypeEnum.service,
    });
  } else {
    return new Payment({
      ...params,
      paymentType: PaymentFactoryTypeEnum.default,
    });
  }
}
