import styled from 'styled-components';
import { getSizeVw } from '../../../../style/fn';

export default styled.div`
  .sub-header-container {
    padding: 24px ${getSizeVw(80)};
    position: relative;
    background: #fff; // 头部背景色不随全局设置改变
    border-bottom: 1px solid var(--color_borders);
    display: none;
  }

  .distribution-logo {
    max-width: 150px;
  }

  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    .mobile-header-menu {
      flex: 1;
    }
    .operate-icon {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    .header-mobile-menu-header {
      text-align: left;
    }
  }

  .logo {
    max-width: 160px;
    max-height: 40px;
  }
  .first-menu-title {
    font-weight: 500;
  }
  .zent-menu.zent-menu__inline {
    font-size: 16px !important;
    width: 100% !important;
    min-height: 100%;
  }
  .zent-menu__inline-item {
    height: 64px !important;
    line-height: 64px !important;
  }
  .zent-menu__inline-item-selected {
    background: none;
  }
  .zent-menu__inline-submenu .zenticon-right {
    top: 25px !important;
  }
  .zent-menu-item:hover {
    background-color: inherit !important;
    color: inherit !important;
  }

  .zent-menu__inline-submenu-title[data-zv='8.5.11']:not(.zent-submenu-disabled):hover {
    background-color: inherit !important;
    color: inherit !important;
  }
  .zent-menu__inline-submenu-title[data-zv='8.5.11']:not(.zent-submenu-disabled):hover .zenticon {
    color: inherit !important;
  }
  .zent-menu__inline-submenu-title {
    height: 64px !important;
    line-height: 64px !important;
  }
  .header-mobile-first-menu {
    position: relative;
  }
  .first-menu-name {
    cursor: pointer;
    width: 70%;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .menu-back-header {
    text-align: left;
    .slide-container-head-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      fill: var(--color_text);
    }
    font-size: var(--font-18-14);
    font-weight: 400;
    color: var(--color_text);
    /* color: #1a1a1b; */
  }

  /* .menu-of-mobile {
        display: none;
    } */
  .sub-menu-title {
    display: inline-block;
    word-break: break-word;
    color: var(--color_text);
  }
  .header-mobile-first-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-mobile-menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .first-menu-name {
    cursor: pointer;
    padding: 24px 0;
  }

  .zent-menu-item {
    a {
      font-size: 16px;
      font-weight: 400;
      color: #1a1a1b;
      &.is-active {
        height: 16px;
        font-size: 16px;
        font-weight: 600;
        fill: var(--color_button);
        line-height: 16px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .sub-header-container {
      display: block;
    }
    .slide-container-head-icon {
      fill: var(--color_button);
    }

    .page-bg {
      position: relative;
      text-align: center;
      z-index: 0;
    }
    .page-bg:after {
      width: 140%;
      height: 210px;
      position: absolute;
      left: -20%;
      top: 0;
      z-index: -1;
      content: '';
      border-radius: 0 0 50% 50%;
      background: ${(props) => props.bgColor || '#fff'};
    }
  }
`;
