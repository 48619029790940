import React, { useEffect, useState, useCallback } from 'react';
import { FormInputField, Form, FormStrategy, Validators, Button, Notify, ValidateOption } from 'zent';
import Style from './style';

const InfoCard = (props) => {
  const { title, style = {}, className } = props;
  return (
    <Style>
      <div className={`info-card  ${className}`} style={style}>
        {title && <p className='info-card-title'>{title}</p>}
        {props.children}
      </div>
    </Style>
  );
};

export default InfoCard;
