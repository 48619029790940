import styled from 'styled-components';

export default styled.div`
  .border-b {
    border-bottom: 1px solid var(--color_borders);
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
  }

  .main-title {
    color: rgba(var(--color_body_text_rgb), 0.6);
    line-height: 14px;
    padding: var(--pm-24-16) 0;
  }
  .detail {
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color_body_text);
  }
  .layout-cell-right {
    text-align: right;
    line-height: 14px;
    padding: var(--pm-24-16) 0;
  }
  .edit-icon {
    fill: rgba(var(--color_body_text_rgb), 0.6);
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  .cannot-edit-true {
    cursor: not-allowed;
  }
`;
