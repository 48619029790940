import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormControl } from 'zent';

/** 这个组件必须搭配input框一起使用 */
const AutoAddressSelectFiled = (props) => {
  const intl = useIntl();
  const { autoAddressList = [], showOptions = false, onFiledChange = () => {}, hasAddress1 = true } = props;
  const latitude = Form.useField('latitude', '');
  const longitude = Form.useField('longitude', '');
  const showAutoTip = hasAddress1 && (!latitude.value || !longitude.value);

  return (
    <>
      <FormControl withoutLabel>
        <div className='auto-address-list-container'>
          {!!autoAddressList?.length && showOptions && (
            <div className='auto-address-list'>
              {autoAddressList?.map((item) => (
                <div className='auto-address-item' key={item?.place_id} onClick={() => onFiledChange(item)}>
                  <div className='auto-address-item_title'>{item?.main_text}</div>
                  <div className='auto-address-item_desc'>{item?.secondary_text}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </FormControl>
      {showAutoTip && (
        <div className='auto-address-warn-tip'>
          {intl.formatMessage({
            id: '9c7b0fa521dc45fca4e4302ffc590c31',
            defaultMessage: '地址无法精准定位，请修改后并从推荐地址中选择',
          })}
        </div>
      )}
    </>
  );
};

export default AutoAddressSelectFiled;
