import styled from 'styled-components';

export default styled.div`
  .example.example2 {
    background-color: #fff;
    margin-top: 8px;
  }

  .example.example2 * {
    font-size: 16px;
    font-weight: 500;
  }

  .example.example2 .row {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
  }

  .example.example2 .field {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 8px;
    border: solid 1px #d1d5db;
    .input {
      padding-left: 16px;
    }
    .zent-loading--inline {
      float: right;
      margin-right: 24px;
      margin-top: 18px;
    }
  }

  .example.example2 .field.half-width {
    width: 50%;
  }

  .example.example2 .field.quarter-width {
    width: calc(25% - 10px);
  }

  .example.example2 .baseline {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #cfd7df;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 label {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 8px;
    padding-left: 16px;
    color: #9b9ea4;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
  }

  .example.example2 .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .hidden {
    display: none;
  }

  .example.example2 .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .example.example2 .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
  }

  .example.example2 .input.focused,
  .example.example2 .input:not(.empty) {
    opacity: 1;
  }

  .example.example2 .input.focused::-webkit-input-placeholder,
  .example.example2 .input:not(.empty)::-webkit-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused::-moz-placeholder,
  .example.example2 .input:not(.empty)::-moz-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused:-ms-input-placeholder,
  .example.example2 .input:not(.empty):-ms-input-placeholder {
    color: #cfd7df;
  }

  .example.example2 .input.focused + label,
  .example.example2 .input:not(.empty) + label {
    color: #aab7c4;
    transform: scale(0.85) translateY(-25px);
    cursor: default;
    padding-left: 16px;
  }

  .example.example2 .input.focused + label {
    color: #9b9ea4;
  }

  .example.example2 .input.invalid + label {
    color: #ffa27b;
  }

  .example.example2 .input.focused + label + .baseline {
    background-color: #24b47e;
  }

  .example.example2 .input.focused.invalid + label + .baseline {
    background-color: #e25950;
  }

  .example.example2 input,
  .example.example2 button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  .example.example2 input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .example.example2 input,
  .example.example2 button {
    -webkit-animation: 1ms void-animation-out;
  }

  .example.example2 .submit-wrapper {
    text-align: right;
    padding-right: 12px;
    padding-bottom: 8px;
  }
  .example.example2 button {
    display: inline-block;
    height: 44px;
    background-color: #041b4a;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
  }

  .example.example2 .error svg {
    margin-top: 0 !important;
  }

  .example.example2 .error svg .base {
    fill: #e25950;
  }

  .example.example2 .error svg .glyph {
    fill: #fff;
  }

  .example.example2 .error .message {
    color: #e25950;
    padding-left: 8px;
  }

  .example.example2 .success .icon .border {
    stroke: #abe9d2;
  }

  .example.example2 .success .icon .checkmark {
    stroke: #24b47e;
  }

  .example.example2 .success .title {
    color: #32325d;
    font-size: 16px !important;
  }

  .example.example2 .success .message {
    color: #8898aa;
    font-size: 13px !important;
  }

  .example.example2 .success .reset path {
    fill: #24b47e;
  }
`;
