import styled from 'styled-components';

export default styled.div`
  .welcome-message {
    font-size: 14px;
    font-weight: 400;
    color: #646566;
    line-height: 20px;
  }

  .rule-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #15161b;
    line-height: 20px;
    margin: 16px 0px;
    position: relative;
    &::before {
      content: '';
      width: 2px;
      left: 0;
      top: 3px;
      display: block;
      position: absolute;
      background: var(--color_button);
      height: 14px;
    }
    span {
      padding-left: 6px;
    }
  }

  .rule-item {
    font-size: 14px;
    font-weight: 400;
    color: #15161b;
    line-height: 20px;
  }

  .mixin-text {
    display: inline;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1b;
    line-height: 14px;
    position: absolute;
  }

  .mixin-profit {
    display: inline;
    font-weight: bold;
    color: #b477d9;
    line-height: 14px;
    position: absolute;
  }

  .rule-img-container {
    position: relative;
  }

  .rule-img-bg {
    background: linear-gradient(220deg, #f5f9ff 0%, #f7f7f7 100%);
    border-radius: 4px;
    border: 1px solid #ebedf0;
    margin: 16px 0;

    .share {
      position: relative;
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      padding-left: 12px;
      display: inline-block;
      margin-right: 16px;
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 0;
        top: calc(50% - 5px);
        background: #9aa9ea;
        border-radius: 50%;
      }
    }
    .friend-profit {
      position: relative;
      font-size: 12px;
      font-weight: 400;
      color: #646566;
      display: inline-block;
      padding-left: 12px;
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 0;
        top: calc(50% - 5px);
        background: #b477d9;
        border-radius: 50%;
      }
    }
  }

  .rule-explanation {
    margin-top: 16px;
  }

  @media screen and (max-width: 750px) {
    padding-bottom: 130px;
  }
`;
