import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  .main-title {
    color: rgba(var(--color_body_text_rgb), 0.6);
    max-width: 180px;
    line-height: 20px;
    min-height: 20px;
  }
  .sub-title {
    line-height: 20px;
    min-height: 20px;
  }
  .points-amount {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    line-height: 14px;
    &.points-used {
      color: var(--color_sale_text);
    }
    &.points-null {
      color: rgba(var(--color_body_text_rgb), 0.6);
    }
    .icon {
      width: 16px;
      height: 16px;
      fill: rgba(var(--color_body_text_rgb), 0.8);
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
