import styled from 'styled-components';

export default styled.div`
  .zent-form-control {
    margin-bottom: 0;
    .zent-form-control-content {
      margin-left: 0 !important;
    }
  }
  .deliver-time-input-container {
    position: relative;
    .deliver-time-input {
      padding: 14px 40px 14px 16px;
      height: 52px !important;
      background-color: var(--color_text_field);
      input {
        padding: 0;
      }
    }
    .deliver-time-input_error {
      border-color: #ec5b56 !important;
    }
    .deliver-time-icon {
      position: absolute;
      top: 14px;
      right: 16px;
      fill: var(--color_text_field_text);
    }
    .zent-input:disabled {
      background-color: transparent;
    }
  }
  .deliver-time-error {
    color: #ec5b56;
    margin-top: 8px;
  }
`;

export const DatePickerPanelStyle = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  min-width: 226px;
  border: 0.5px solid var(--color_text_field_border);
  box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  background: var(--color_card);
  color: var(--color_text_field_text);
  z-index: var(--zindex-picker);
  .panel {
    display: flex;
  }
  .header {
    height: 48px;
    padding: 16px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    border-bottom: 1px solid var(--color_text_field_border);
    .header-icon {
      width: 16px;
      height: 16px;
      fill: var(--color_text_field_text);
      cursor: pointer;
    }
    .header-icon__disabled {
      fill: rgba(var(--color_text_field_text_rgb), 0.2);
      cursor: not-allowed;
    }
    .header-title {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      .header-icon {
        fill: rgba(var(--color_text_field_text_rgb), 0.4);
        cursor: pointer;
      }

      .header-year {
        display: flex;
        gap: 4px;
        cursor: pointer;
      }
      .header-month {
        display: flex;
        gap: 4px;
        cursor: pointer;
      }
    }
  }
  .main-panel {
    .calendar {
      max-width: 226px;
      padding: 8px;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 17px;
      .calendar-item {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .calendar-item:not(.calendar-item_active, .calendar-item_disabled):hover {
        background: rgba(var(--color_text_field_text_rgb), 0.1);
      }
      .calendar-item_active {
        background-color: var(--color_button);
        color: var(--color_button_text);
        font-weight: 600;
      }
      .calendar-item_disabled {
        color: rgba(var(--color_text_field_text_rgb), 0.2);
        cursor: not-allowed;
      }
      .calendar-item_today {
        width: 28px;
        height: 28px;
        border: 1px solid var(--color_button);
      }
      .calendar-header {
        font-weight: 600;
        display: flex;
        align-items: center;
        .calendar-item {
          cursor: default;
        }
        .calendar-item:hover {
          background: none;
        }
      }
      .calendar-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px 0;
      padding: 14px 8px;
      width: 226px;
      box-sizing: border-box;
      .content-item {
        width: 70px;
        height: 30px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .content-item:not(.content-item_active, .content-item_disabled):hover {
        background: rgba(var(--color_text_field_text_rgb), 0.1);
      }

      .content-item_active {
        background-color: var(--color_button);
        color: var(--color_button_text);
        font-weight: 600;
      }
      .content-item_disabled {
        color: rgba(var(--color_text_field_text_rgb), 0.2);
        cursor: not-allowed;
      }
    }
  }
  .extend-panel {
    width: 120px;
    .time-picker {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 226px;
      .time-picker-item {
        height: 28px;
        box-sizing: border-box;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .time-picker-item:not(.time-picker-item-active):hover {
        background: rgba(var(--color_text_field_text_rgb), 0.1);
      }
      .time-picker-item-active {
        background: rgba(var(--color_button_rgb), 0.1);
        color: var(--color_text_field_text);
      }
    }
  }
  .main-panel + .extend-panel {
    border-left: 1px solid var(--color_text_field_border);
  }

  .footer {
    padding: 12px 16px;
    border-top: 1px solid var(--color_text_field_border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .today-btn {
      cursor: pointer;
      color: var(--color_button);
      background: transparent;
      background-color: transparent !important;
      border: none;
    }
    .zent-btn-small {
      height: 28px !important;
      line-height: 28px !important;
    }
  }
`;
