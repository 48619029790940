import styled from 'styled-components';

export default styled.div`
  .header {
    padding: 12px;
    border-radius: 4px;
    background-color: rgba(var(--color_button_rgb), 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    span:nth-child(2) {
      color: var(--color_button);
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    .content-item {
      padding: 16px 0;
      & + .content-item {
        border-top: 1px solid rgba(var(--color_body_text_rgb), 0.08);
      }
      &_radio {
        display: flex;
        align-items: center;
        .zent-radio-label {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }
      &_amount {
        color: var(--color_activity_label);
      }
      &_desc {
        color: rgba(var(--color_body_text_rgb), 0.6);
      }
    }
    .content-customer-box {
      padding: 12px;
      border-radius: 4px;
      background-color: rgba(var(--color_body_text_rgb), 0.04);
      margin-bottom: 24px;
      &_input {
        display: flex;
        align-items: center;
        .zent-input-wrapper {
          flex: 1;
          height: 40px !important;
        }
      }
      &_tip {
        margin-top: 4px;
        color: rgba(var(--color_body_text_rgb), 0.6);
        span {
          color: var(--color_activity_label);
        }
      }
      &_error {
        margin-top: 4px;
        color: #ec5b56;
      }
    }
  }
  .footer {
    padding-top: ${(props) => props.theme.getSpace(props.theme.pCardsNew)};
    text-align: right;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: ${(props) => `calc(100% + ${props.theme.getSpace(props.theme.pCardsNew)} * 2)`};
      height: 1px;
      background-color: #dcdee0;
      position: absolute;
      top: 0;
      left: -24px;
    }
  }
`;
