import { Popover } from 'zent';
import { useIntl } from 'react-intl';
import Style from './style';
import cn from 'classnames';

export const ExtraLogoShow = (props) => {
  const intl = useIntl();
  const { paymentMethods = [], style, level = 1 } = props;
  const PAY_LOGO_NUM = window?.innerWidth > 750 ? 6 : 4;
  const extraPaymentMethodsList = paymentMethods.slice(PAY_LOGO_NUM);
  return (
    <div className='extra-content'>
      <Popover className='zent-doc-popover' position={Popover.Position.BottomRight} cushion={5}>
        <Popover.Trigger.Click>
          <Style
            style={style}
            className={cn('more-logo-wrapper', {
              'wrapper-level-1': level === 1,
              'wrapper-level-2': level !== 1,
            })}
          >
            <span
              className='more-logo'
              onClick={(e) => {
                e.stopPropagation;
              }}
            >
              {`${intl.formatMessage({ defaultMessage: '更多', id: 'general.more' })}(${
                extraPaymentMethodsList?.length
              })`}
            </span>
          </Style>
        </Popover.Trigger.Click>
        <Popover.Content>
          <Style>
            <div
              className={cn('logo-wrapper', { 'top-zero': level === 2 })}
              style={{ width: `${extraPaymentMethodsList?.length * 48 + 32}px` }}
            >
              {extraPaymentMethodsList.map((item) => {
                return (
                  <div className='logo'>
                    <img src={item?.logoUrl || item?.logo} alt={item?.paymentMethodName || item?.name} />
                  </div>
                );
              })}
            </div>
          </Style>
        </Popover.Content>
      </Popover>
    </div>
  );
};
