import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Pop, Popover } from 'zent';
import openRuleDialog from '../rule-dialog';
import { delDistributorInfo } from '../../hooks/use-distributor-info';
import distributionApi from '../../../../api/distribution';
import * as cartHelper from 'helper/cart';
import Style from './style';

const HeaderSetting = (props) => {
  const { history, affiliateId, currency, style = {} } = props;
  const [mobileStyle, setMobileStyle] = useState(false);
  const settingStyle = {
    lineHeight: '44px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#15161b',
    cursor: 'pointer',
    position: 'relative',
    paddingLeft: '12px',
    width: '108px',
    background: '#fff',
    zIndex: '6',
  };
  const intl = useIntl();

  useEffect(() => {
    const screenWidth = window.document.body.clientWidth;
    if (screenWidth <= 750) {
      setMobileStyle(true);
    }
  }, []);

  const editAccount = () => {
    history.push('/affiliate/me');
  };

  const loginOut = () => {
    distributionApi.logout().then(() => {
      cartHelper.notifyCartCountChange();
      cartHelper.removeStorage();
      delDistributorInfo();
      window.location.href = '/';
    });
  };

  const viewRule = () => {
    if (!mobileStyle) {
      return openRuleDialog({
        affiliateId,
        currency,
        intl,
      });
    }
    history.push(`/affiliate/rule/${affiliateId}`);
  };

  return (
    <Style>
      <div className='distribution-setting'>
        <div className='distribution-question pointer' onClick={viewRule}>
          <Pop
            trigger='hover'
            position='bottom-center'
            content={
              <span>
                {intl.formatMessage({
                  id: 'distribution_rules',
                  defaultMessage: '分销规则',
                  description: '分销规则',
                })}
              </span>
            }
          >
            <svg className='distribution-setting-question-icon' style={style} aria-hidden='true'>
              <use xlinkHref='#iconic-yiwen' />
            </svg>
          </Pop>
        </div>
        <div>
          <Popover trigger='click' className='zent-doc-popover' position={Popover.Position.BottomRight} cushion={5}>
            <Popover.Trigger.Click>
              <svg className='distribution-setting-header-icon' style={style} aria-hidden='true'>
                <use xlinkHref='#iconic-gerenzhanghao' />
              </svg>
            </Popover.Trigger.Click>
            <Popover.Content>
              <div
                style={{
                  boxShadow: '0px 1px 4px 0px rgba(21, 34, 50, 0.08)',
                  position: 'relative',
                  zIndex: 3,
                }}
              >
                <div style={settingStyle} onClick={editAccount}>
                  {intl.formatMessage({
                    id: 'my_profile',
                    defaultMessage: '我的资料',
                    description: '我的资料',
                  })}
                </div>
                <div style={settingStyle} onClick={loginOut}>
                  {intl.formatMessage({
                    id: 'account.me.logout',
                    defaultMessage: '退出',
                  })}
                </div>
              </div>
            </Popover.Content>
          </Popover>
        </div>
      </div>
    </Style>
  );
};

export default HeaderSetting;
