// 结算信息类型
export enum CHECKOUT_RULE_TYPE {
  file = 'file',
  image = 'image',
  text = 'text',
  idcard = 'id_card',
}

// 结算信息 内部组件类型
export enum TEMPLATE_TYPE {
  text = 'text',
  image = 'image',
  file = 'file',
}