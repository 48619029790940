import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const DialogStyle = createGlobalStyle`
  .person-form-dialog {
    .zent-dialog-r-body {
      padding: 0 !important;
    }
    /* .zent-dialog-r-body {
      padding: 0;
    }
    .zent-dialog-r-title {
      font-size: 18px;
      line-height: 24px;
      border-bottom: none;
      padding: 0 24px 16px;
    } */
  }
`;

export const Style = styled.div`
  .person-form-container {
    .form-item-wrap {
      padding: 24px;
    }
    .form-item-inline {
      display: flex;
      gap: 12px;
      + .form-item-inline {
        margin-top: 16px;
      }
      &.area-phone-item {
        .hunt-select-trigger {
          height: 56px !important;
          line-height: 56px !important;
          box-sizing: border-box;
          .hunt-select-switch {
            line-height: 61px !important;
          }
          input {
            outline: none !important;
            height: 50% !important;
            margin-top: 16px !important;
            width: 100%;
          }
        }
      }
      &.names-item > div {
        max-width: calc((100% - 12px) / 2);
      }
    }
    .person-form-footer-btn {
      border-top: 1px solid var(--color_borders);
      padding: 20px 24px 0;
      display: flex;
      justify-content: flex-end;
      button {
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 24px;
        font-size: 14px !important;
        + button {
          margin-left: 12px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .person-form-container {
        .person-form {
          padding: 16px;
        }
        .person-form-footer-btn {
          padding: 16px 16px 0;
        }
      }
    }
  }
`;
