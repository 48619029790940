import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ec903a;
  font-size: 12px;
  line-height: 17px;
  margin-top: 8px;
  cursor: pointer;
  .tip_icon {
    width: 14px;
    height: 14px;
    fill: #ec903a;
  }
`;
