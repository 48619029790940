import styled, { createGlobalStyle } from 'styled-components';

export default styled.div`
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .form-item-row {
      width: calc((100% - 16px) / 2);
    }
  }
  .zent-form-actions {
    width: 100%;
    margin-top: 24px;
    .zent-btn {
      width: 100%;
    }
  }
  @media screen and (max-width: 750px) {
    .zent-input-wrapper {
      height: 50px !important;
    }
  }
`;

export const DialogStyle = createGlobalStyle`
     .card-from-dialog {
         .zent-dialog-r-title {
            border-bottom-width: 0px !important;
         }
         .zent-dialog-r-title-text {
             font-size: 18px !important;
         }
         .zent-dialog-r-body {
            padding: 24px !important;
            padding-top: 0 !important;
            padding-bottom: 4px !important
        }
        .zent-dialog-r {
            padding: 24px 0 !important;
        }
        .zent-dialog-r-close {
            top: 24px !important;
            right: 24px !important;
        }
        .zent-dialog-r-header {
            padding: 0 10px;
        }
        @media screen and (max-width:560px){
            &.zent-dialog-r  {
                width: 85% !important;
            }
        } 
       
     }
`;
