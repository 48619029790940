import styled from 'styled-components';

export default styled.div`
  .customized-checkout-title {
    font-weight: 600;
    margin-bottom: 24px;
    color: var(--color_body_text);
    font-size: 20px;
  }
  .customized-checkout-list {
    .customized-checkout-list-item {
      +.customized-checkout-list-item {
        margin-top: 24px;
      }
      font-size: 14px;
      line-height: 20px;
      color: var(--color_text_field_text);
      .list-item__left {
        position: relative;
        font-size: var(--font-16-14);
        line-height: var(--lh-22-16);
        font-weight: 500;
        &.label-required::before {
          content: '*';
          position: absolute;
          left: -8px;
          font-size: 14px;
          color: #EC5B56;
        }
      }
      .list-item__right {
        margin-top: 16px;
        *zoom: 1;
        &::after {
          content: '';
          display: table;
          clear: both;
        }
        .upload-block {
          float: left;
          + .upload-block {
            margin-left: 8px;
          }
        }
      }
      .item-error-msg {
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        color: #df4545;
      }
    }
    .upload-block {
      .image-upload-trigger,
      .review-image-item {
        margin-bottom: 0;
      }
      .upload-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 12px;
        color: rgba(var(--color_body_text_rgb), .4);
        text-align: center;
        svg {
          width: 24px;
          height: 24px;
          fill: rgba(var(--color_body_text_rgb), .4);
        }
      }
    }
    .message-desc {
      margin-top: 8px;
      font-size: var(--font-14-12);
      line-height: var(--lh-14-12);
      color: rgba(var(--color_body_text_rgb), .6);
    }
    .zent-input-wrapper {
      height: 50px;
      line-height: 50px;
      .zent-input {
        padding: 0 12px;
      }
    }
    .zent-textarea-wrapper {
      height: 80px;
      line-height: 1.2;
      .zent-textarea {
        padding: 8px 12px;
        height: 100%;
        resize: none;
      }
    }
  }
`;
