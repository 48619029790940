import styled from 'styled-components';

export default styled.div`
  .distribution-setting {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .distribution-question {
    margin-right: 30px;
  }

  .distribution-setting-question-icon {
    width: 24px;
    height: 24px;
    fill: var(--color_button);
  }

  .distribution-setting-header-icon {
    height: 24px;
    width: 24px;
    fill: var(--color_button);
  }

  .zent-popover-content .me-item {
    line-height: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #15161b;
  }

  .pointer {
    cursor: pointer;
  }

  .zent-pop {
    background: #646566;
  }

  /* @media screen and (max-width: 750px) {
        .distribution-setting .distribution-setting-question-icon {
            fill: var(--color_button);
        }
        .distribution-setting .distribution-setting-header-icon {
            fill: var(--color_button);
        }
    } */
`;
