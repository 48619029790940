import styled, { css } from 'styled-components';

const getFilterItemTextStyleCSS = (props) => {
  const { filterItemTextStyle = 'none' } = props;
  const textStyleList = ['none', 'lowercase', 'uppercase', 'capitalized']; // capitalized --> capitalize

  if (textStyleList.includes(filterItemTextStyle)) {
    return css`
      text-transform: ${filterItemTextStyle === 'capitalized' ? 'capitalize' : filterItemTextStyle};
    `;
  }
};

const getLayoutCSS = (props) => {
  const { direction } = props;
  if (direction === 'column') {
    return css`
      .category-item-checkbox {
        flex-direction: column;
        .filter-category-items {
          display: flex;
          flex-direction: column;
          gap: 12px 0;
        }
        .filter-category-item-name {
          margin-right: 0;
          display: flex;
          gap: 12px 0;
        }
      }
    `;
  }
};

export default styled.div`
    ${(props) => getLayoutCSS(props)}
        .category-item-checkbox {
            display: flex;
            .filter-category-items {
                display: flex;
                flex-wrap: wrap;
                gap: 12px 0;
                .zent-checkbox-wrap {
                    display: inline-flex;
                    align-items: center;
                }
            }
           
            .zent-checkbox-label {
                word-break: break-word;
                ${(props) => getFilterItemTextStyleCSS(props)}
            }
            .filter-category-item-name {
                &.zent-checkbox-checked {
                    .zent-checkbox > .zent-checkbox-inner {
                        background-color: ${(props) => props.theme.colorButton} !important;
                    }
                    .zent-checkbox-label {
                        font-weight: 600;
                    }
                }
                .zent-checkbox > .zent-checkbox-inner {
                    background-color: transparent;
                }
            }
        }
       
    }
`;
