export const sortList = [
  {
    id: 0,
    value: {
      sortBy: 'min_price',
      sort: 'asc',
    },
    formatMessage: {
      id: 'price_low_height',
      defaultMessage: '价格从低到高',
    },
  },
  {
    id: 1,
    value: {
      sortBy: 'min_price',
      sort: 'desc',
    },
    formatMessage: {
      id: 'price_height_low',
      defaultMessage: '价格从高到低',
    },
  },
  {
    id: 2,
    value: {
      sortBy: 'sold_num',
      sort: 'desc',
    },
    formatMessage: {
      id: 'Highest_sales',
      defaultMessage: '销量最高',
    },
  },
  {
    id: 3,
    value: {
      sortBy: 'created_at',
      sort: 'desc',
    },
    formatMessage: {
      id: 'new_arrival',
      defaultMessage: '最新上架',
    },
  },
];

export const CURRENT_PAGE_TYPE = {
  allGoodsPage: 'allGoodsPage',
};
